import { formatNumberWithCommas, formatToDecimalPlaces } from '../../../utils/formatting';
import { ChartStyles } from './useChartStyles';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { RowDefinition, ChartTableDefinition } from '../../../components/Table';
import { DatasetConfiguration } from './getChartAndTableConfiguration';
import { GraphData, MetricData } from '@clinintell/api/apiSchemas';

const generateGraphTableView = (
  dataset: GraphData[],
  metric: keyof typeof Metrics,
  chartStyles: ChartStyles[],
  chartConfigurations: DatasetConfiguration[]
): ChartTableDefinition => {
  const columns: string[] = [''];
  const rows: RowDefinition[] = [];

  dataset.forEach(set => {
    const styles = chartStyles.filter(style => style.id === set.dataSetType);
    const config = chartConfigurations.find(config => config.id === set.dataSetType);
    if (!config) {
      throw new Error(
        `Can't find config for the ${set.dataSetType} datatable. Check the chart and table configuration module.`
      );
    }

    if (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions') {
      if (config.label && config.label.toLowerCase() === 'total') config.label += ` (Princ. + Sec.)`;
      if (styles && styles[0].label.toLowerCase() === 'total') styles[0].label += ` (Princ. + Sec.)`;
    }

    // ANNOTATION: remove null/undefined as value types for dataSetType, MetricData, and MetricData -> name properties. dataSetType should be enum?
    const row: RowDefinition = {
      text: !!config.label ? config.label : styles ? styles[0].label : (set.dataSetType as string),
      className: styles ? styles[0].tableClassName : '',
      cells: [],
      toggleable:
        (metric === 'los' && set.dataSetType !== 'Discharges') ||
        ((metric === 'cmi' || metric === 'docScore' || metric === 'severityCmi') && set.dataSetType === 'COVID')
          ? true
          : false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      id: set.dataSetType,
      tooltip: styles ? styles[0].tooltip : ''
    };

    (set.dataSet as MetricData[]).forEach(({ name, value }) => {
      if (!columns.includes(name as string)) {
        columns.push(name as string);
      }

      let formattedValue;
      if (value === 0) {
        if (config.usePercentage) {
          formattedValue = '0%';
        } else if (config.precision > 0) {
          formattedValue = Number(0).toFixed(config.precision);
        } else {
          formattedValue = '0';
        }
      } else if (!value) {
        formattedValue = '-';
      } else {
        formattedValue = `${formatNumberWithCommas(formatToDecimalPlaces(value, config.precision))}${
          config.usePercentage ? '%' : ''
        }`;
      }

      row.cells.push(formattedValue);

      // Update className for report data tables
      if (
        (row.id === 'Actual' &&
          (metric === Metrics.docScore ||
            metric === Metrics.severityCmi ||
            metric === Metrics.los ||
            metric === Metrics.raf ||
            metric === Metrics.elixhauserMortality ||
            metric === Metrics.elixhauserReadmission ||
            metric === Metrics.psi)) ||
        (row.id === 'ClinicallyExpected' &&
          (metric === Metrics.docScore ||
            metric === Metrics.elixhauserMortality ||
            metric === Metrics.elixhauserReadmission ||
            metric === Metrics.psi ||
            metric === Metrics.raf))
      ) {
        row.className = '';
      }
    });

    // hide if user does not have permission for metricsAll
    if (row.className.indexOf('notDisplayed') === -1) {
      rows.push(row);
    }
  });

  return {
    columns: columns,
    rows: rows
  };
};

export default generateGraphTableView;
