import { userCan } from '@clinintell/containers/authentication/rules';
import { MenuItem } from '@clinintell/modules/menuNavigation';
import { useMenuNavigationState, useUser } from '@clinintell/modules/store';
import useIsMobileView from '@clinintell/utils/useIsMobileView';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import ResourceMenuButton from './components/ResourceMenuButton';
import AccountMenuButton from './components/AccountMenuButton';
import DesktopView from '@clinintell/utils/DesktopView';
import { EXPANDED_SIDEBAR_WIDTH, COLLAPSED_SIDEBAR_WIDTH } from '../sidebar/Sidebar';

type MenuNavigationProps = {
  renderMenuNavigationLink: (props: MenuItem) => React.ReactElement | null;
};

const MenuNavigation: React.FC<MenuNavigationProps> = ({ renderMenuNavigationLink }) => {
  const { menuItems, menuIsExpanded } = useMenuNavigationState();
  const user = useUser();
  const theme = useTheme();

  const isMobileView = useIsMobileView();
  const allowedMenuItems = menuItems.filter(menuItem => {
    if (!userCan(user, menuItem.permissions)) {
      return false;
    }

    return menuItem.hideIfDesktopView && !isMobileView ? false : true;
  });

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& > div:not(:last-child)': {
          transition: 'margin-bottom 400ms ease-in-out'
        }
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100%',
          // 100vh - (142px as fixed height bottom) * 2 (for top and bottom)
          // + 10px (to account for bottom padding of side menu)
          maxHeight: `calc(100vh - ${142 * 2 + 10}px)`
        }}
      >
        {allowedMenuItems.map(menuItem => renderMenuNavigationLink(menuItem))}
      </Box>
      <DesktopView>
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            boxSizing: 'border-box',
            position: 'fixed',
            bottom: 0,
            padding: 2,
            height: '142px',
            backgroundColor: theme.palette.common.white
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: `calc(${menuIsExpanded ? EXPANDED_SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH}px - ${theme.spacing(
                2 * 2
              )})`
            }}
          >
            <ResourceMenuButton />
            <AccountMenuButton />
          </Box>
        </Box>
      </DesktopView>
    </Box>
  );
};

export default MenuNavigation;
