import { all, put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { Feature } from '../containers/authentication/rules';
import { SelectOptionType } from '@clinintell/types/common';

interface FundamentalGroup {
  id: number;
  fundamentalSpecialtyName: string;
}
interface BaseRoleFeature {
  id: number;
  displayName: string;
  description: string;
}

export interface UserFeature extends BaseRoleFeature {
  featureName: Feature;
}

export interface Role extends BaseRoleFeature {
  roleName: string;
  features: UserFeature[];
}

export interface User {
  id: number;
  email: string;
  name: string;
  rootId: number;
  rootEntity: string;
  homeOrgId: number;
  homeEntity: string;
  dashboardOrgId: number;
  dashboardEntity: string;
  specialtyGroupId?: number;
  specialtyGroupEntity: string;
  fundamentalSpecialtyId?: number;
  npi: string;
  npiValid: boolean;
  roleId: number;
  roleName: string;
  auth0TokenId: string;
  authAccountVerified: boolean;
  insertDate: string;
  isLoaded: boolean;
  enrollTraining: boolean;
  lastLogin: string;
  blocked: boolean;
}

export interface UserDataset {
  dataset: User[];
  user: User | null;
  roles: Role[];
  role: Role | null;
  rolesInitialized: boolean;
  features: UserFeature[];
  feature: UserFeature | null;
  fundamentalSpecialties: FundamentalGroup[];
  isLoading: boolean;
  isLoadingUser: boolean;
  isLoadingRole: boolean;
  isLoadingRoles: boolean;
  isLoadingFeatures: boolean;
  isLoadingFeature: boolean;
  error: string;
  universityGroups: SelectOptionType[];
}

// Actions
export enum UserManagementActions {
  INITIALIZE_USER_MANAGEMENT = 'INITIALIZE_USER_MANAGEMENT',
  INITIALIZE_USER_MANAGEMENT_BEGIN = 'INITIALIZE_USER_MANAGEMENT_BEGIN',
  INITIALIZE_USER_MANAGEMENT_SUCCESSFUL = 'INITIALIZE_USER_MANAGEMENT_SUCCESSFUL',
  INITIALIZE_USER_MANAGEMENT_FAILED = 'INITIALIZE_USER_MANAGEMENT_FAILED',
  LOAD_USERS = 'LOAD_USERS',
  LOAD_USERS_BEGIN = 'LOAD_USERS_BEGIN',
  LOAD_USERS_SUCCESSFUL = 'LOAD_USERS_SUCCESSFUL',
  LOAD_USERS_FAILED = 'LOAD_USERS_FAILED',
  LOAD_USER_DETAILS = 'LOAD_USER_DETAILS',
  LOAD_USER_DETAILS_BEGIN = 'LOAD_USER_DETAILS_BEGIN',
  LOAD_USER_DETAILS_SUCCESSFUL = 'LOAD_USER_DETAILS_SUCCESSFUL',
  LOAD_USER_DETAILS_FAILED = 'LOAD_USER_DETAILS_FAILED',
  SET_USER_DETAILS = 'SET_USER_DETAILS',
  LOAD_ROLES = 'LOAD_ROLES',
  LOAD_ROLES_BEGIN = 'LOAD_ROLES_BEGIN',
  LOAD_ROLES_SUCCESSFUL = 'LOAD_ROLES_SUCCESSFUL',
  LOAD_ROLES_FAILED = 'LOAD_ROLES_FAILED',
  LOAD_ROLE_DETAILS = 'LOAD_ROLE_DETAILS',
  LOAD_ROLE_DETAILS_BEGIN = 'LOAD_ROLE_DETAILS_BEGIN',
  LOAD_ROLE_DETAILS_SUCCESSFUL = 'LOAD_ROLE_DETAILS_SUCCESSFUL',
  LOAD_ROLE_DETAILS_FAILED = 'LOAD_ROLE_DETAILS_FAILED',
  SET_ROLE_DETAILS = 'SET_ROLE_DETAILS',
  LOAD_FEATURES = 'LOAD_FEATURES',
  LOAD_FEATURES_BEGIN = 'LOAD_FEATURES_BEGIN',
  LOAD_FEATURES_SUCCESSFUL = 'LOAD_FEATURES_SUCCESSFUL',
  LOAD_FEATURES_FAILED = 'LOAD_FEATURES_FAILED',
  LOAD_FEATURE_DETAILS = 'LOAD_FEATURE_DETAILS',
  LOAD_FEATURE_DETAILS_BEGIN = 'LOAD_FEATURE_DETAILS_BEGIN',
  LOAD_FEATURE_DETAILS_SUCCESSFUL = 'LOAD_FEATURE_DETAILS_SUCCESSFUL',
  LOAD_FEATURE_DETAILS_FAILED = 'LOAD_FEATURE_DETAILS_FAILED',
  SET_FEATURE_DETAILS = 'SET_FEATURE_DETAILS',
  GET_UNIVERSITY_GROUPS = 'GET_UNIVERSITY_GROUPS',
  GET_UNIVERSITY_GROUPS_BEGIN = 'GET_UNIVERSITY_GROUPS_BEGIN',
  GET_UNIVERSITY_GROUPS_SUCCESSFUL = 'GET_UNIVERSITY_GROUPS_SUCCESSFUL',
  GET_UNIVERSITY_GROUPS_FAILED = 'GET_UNIVERSITY_GROUPS_FAILED',
  SET_UNIVERSITY_GROUPS = 'SET_UNIVERSITY_GROUPS',
  SET_UNIVERSITY_GROUPS_BEGIN = 'SET_UNIVERSITY_GROUPS_BEGIN',
  SET_UNIVERSITY_GROUPS_SUCCESSFUL = 'SET_UNIVERSITY_GROUPS_SUCCESSFUL',
  SET_UNIVERSITY_GROUPS_FAILED = 'SET_UNIVERSITY_GROUPS_FAILED'
}

type RoleFeaturePayload = {
  roles?: Role[];
  features?: UserFeature[];
  fundamentalGroups?: FundamentalGroup[];
  universityGroups?: SelectOptionType[];
};

export type UserManagementAction<T> = {
  type: keyof typeof UserManagementActions;
  payload?: T;
};

export const initialUser: User = {
  id: -1,
  name: '',
  email: '',
  rootId: 1,
  rootEntity: '',
  homeOrgId: 0,
  homeEntity: '',
  specialtyGroupId: 0,
  specialtyGroupEntity: '',
  fundamentalSpecialtyId: 0,
  dashboardOrgId: 0,
  dashboardEntity: '',
  npi: '',
  npiValid: false,
  auth0TokenId: '',
  isLoaded: false,
  roleId: -1,
  roleName: '',
  authAccountVerified: false,
  enrollTraining: false,
  insertDate: '',
  lastLogin: '',
  blocked: false
};

export const initialUserDataset: UserDataset = {
  dataset: [],
  user: initialUser,
  roles: [],
  role: null,
  rolesInitialized: false,
  feature: null,
  features: [],
  fundamentalSpecialties: [],
  isLoading: false,
  isLoadingUser: false,
  isLoadingRole: false,
  isLoadingRoles: false,
  isLoadingFeature: false,
  isLoadingFeatures: false,
  error: '',
  universityGroups: []
};

// Reducer
const userManagementReducer = (
  state: UserDataset = initialUserDataset,
  action: UserManagementAction<
    number | string | User | User[] | UserDataset | Role | Role[] | UserFeature | UserFeature[] | SelectOptionType[]
  >
): UserDataset => {
  switch (action.type) {
    case UserManagementActions.INITIALIZE_USER_MANAGEMENT_BEGIN: {
      return {
        ...initialUserDataset,
        isLoadingRoles: true
      };
    }
    case UserManagementActions.INITIALIZE_USER_MANAGEMENT_SUCCESSFUL: {
      const { roles, features, fundamentalGroups, universityGroups } = action.payload as RoleFeaturePayload;
      return {
        ...state,
        rolesInitialized: true,
        isLoadingRoles: false,
        roles: roles
          ? roles.sort((a, b) => {
              return a.displayName.toLowerCase() > b.displayName.toLowerCase()
                ? 1
                : a.displayName.toLowerCase() < b.displayName.toLowerCase()
                ? -1
                : 0;
            })
          : [],
        features: features
          ? features.sort((a, b) => {
              return a.displayName.toLowerCase() > b.displayName.toLowerCase()
                ? 1
                : a.displayName.toLowerCase() < b.displayName.toLowerCase()
                ? -1
                : 0;
            })
          : [],
        fundamentalSpecialties: fundamentalGroups ?? [],
        universityGroups: universityGroups ?? []
      };
    }
    case UserManagementActions.INITIALIZE_USER_MANAGEMENT_FAILED: {
      return {
        ...state,
        error: action.payload as string,
        isLoadingRoles: false
      };
    }
    case UserManagementActions.LOAD_USERS_BEGIN: {
      return {
        ...state,
        dataset: [],
        user: initialUser,
        isLoading: true
      };
    }
    case UserManagementActions.LOAD_USERS_SUCCESSFUL: {
      return {
        ...state,
        dataset: (action.payload as User[]).sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
        }),
        isLoading: false
      };
    }
    case UserManagementActions.LOAD_USERS_FAILED: {
      return {
        ...state,
        dataset: [],
        isLoading: false,
        error: action.payload as string
      };
    }
    case UserManagementActions.LOAD_USER_DETAILS_BEGIN: {
      return {
        ...state,
        user: null,
        isLoadingUser: true
      };
    }
    case UserManagementActions.LOAD_USER_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        user: action.payload as User,
        isLoadingUser: false
      };
    }
    case UserManagementActions.LOAD_USER_DETAILS_FAILED: {
      return {
        ...state,
        user: null,
        isLoadingUser: false,
        error: action.payload as string
      };
    }
    case UserManagementActions.SET_USER_DETAILS: {
      const user = action.payload as User;
      if (!user.roleName.toLowerCase().includes('provider') && !user.roleName.toLowerCase().includes('clinical')) {
        // force these values in case existing users have nulls
        user.specialtyGroupId = 0;
        user.fundamentalSpecialtyId = 0;
      }
      return {
        ...state,
        user
      };
    }
    case UserManagementActions.LOAD_ROLES_BEGIN: {
      return {
        ...state,
        roles: [],
        isLoadingRoles: true
      };
    }
    case UserManagementActions.LOAD_ROLES_SUCCESSFUL: {
      return {
        ...state,
        roles: (action.payload as Role[]).sort((a, b) => {
          return a.displayName.toLowerCase() > b.displayName.toLowerCase()
            ? 1
            : a.displayName.toLowerCase() < b.displayName.toLowerCase()
            ? -1
            : 0;
        }),
        isLoadingRoles: false
      };
    }
    case UserManagementActions.LOAD_ROLES_FAILED: {
      return {
        ...state,
        roles: [],
        error: action.payload as string,
        isLoadingRoles: false
      };
    }
    case UserManagementActions.LOAD_ROLE_DETAILS_BEGIN: {
      return {
        ...state,
        role: null,
        error: action.payload as string,
        isLoadingRole: true
      };
    }
    case UserManagementActions.LOAD_ROLE_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        role: action.payload as Role,
        isLoadingRole: false
      };
    }
    case UserManagementActions.LOAD_ROLE_DETAILS_FAILED: {
      return {
        ...state,
        role: null,
        error: action.payload as string,
        isLoadingRole: false
      };
    }
    case UserManagementActions.SET_ROLE_DETAILS: {
      return {
        ...state,
        role: action.payload as Role
      };
    }
    case UserManagementActions.LOAD_FEATURES_BEGIN: {
      return {
        ...state,
        features: [],
        isLoadingFeatures: true
      };
    }
    case UserManagementActions.LOAD_FEATURES_SUCCESSFUL: {
      return {
        ...state,
        features: (action.payload as UserFeature[]).sort((a, b) => {
          return a.displayName.toLowerCase() > b.displayName.toLowerCase()
            ? 1
            : a.displayName.toLowerCase() < b.displayName.toLowerCase()
            ? -1
            : 0;
        }),
        isLoadingFeatures: false
      };
    }
    case UserManagementActions.LOAD_FEATURES_FAILED: {
      return {
        ...state,
        features: [],
        error: action.payload as string,
        isLoadingFeatures: false
      };
    }
    case UserManagementActions.LOAD_FEATURE_DETAILS_BEGIN: {
      return {
        ...state,
        feature: null,
        isLoadingFeature: true
      };
    }
    case UserManagementActions.LOAD_FEATURE_DETAILS_SUCCESSFUL: {
      return {
        ...state,
        feature: action.payload as UserFeature,
        isLoadingFeature: false
      };
    }
    case UserManagementActions.LOAD_FEATURE_DETAILS_FAILED: {
      return {
        ...state,
        feature: null,
        error: action.payload as string,
        isLoadingFeature: false
      };
    }
    case UserManagementActions.SET_FEATURE_DETAILS: {
      return {
        ...state,
        feature: action.payload as UserFeature
      };
    }
    default: {
      return state;
    }
  }
};

// Sagas
export function* initUserManagementSaga(): SagaIterator {
  yield put({ type: UserManagementActions.INITIALIZE_USER_MANAGEMENT_BEGIN });

  const [rolesOutput, featuresOutput, fundamentalGroupOutput, universityGroupOutput]: [
    AsyncOutput<Role[]>,
    AsyncOutput<UserFeature[]>,
    AsyncOutput<FundamentalGroup[]>,
    AsyncOutput<{ id: number; title: string }[]>
  ] = yield all([
    call(ApplicationAPI.get, { endpoint: 'Roles' }),
    call(ApplicationAPI.get, { endpoint: 'Features' }),
    call(ApplicationAPI.get, { endpoint: 'org/fundamentalgroups' }),
    call(ApplicationAPI.get, { endpoint: 'CIUniversity/groups' })
  ]);

  if (rolesOutput.error || featuresOutput.error || fundamentalGroupOutput.error || universityGroupOutput.error) {
    const errorArray: string[] = [];
    if (rolesOutput.error) errorArray.push(rolesOutput.error);
    if (featuresOutput.error) errorArray.push(featuresOutput.error);
    if (fundamentalGroupOutput.error) errorArray.push(fundamentalGroupOutput.error);
    if (universityGroupOutput.error) errorArray.push(universityGroupOutput.error);

    yield put({ type: UserManagementActions.INITIALIZE_USER_MANAGEMENT_FAILED, payload: errorArray.join(' / ') });
  } else {
    yield put({
      type: UserManagementActions.INITIALIZE_USER_MANAGEMENT_SUCCESSFUL,
      payload: {
        roles: rolesOutput.data,
        features: featuresOutput.data,
        fundamentalGroups: fundamentalGroupOutput.data,
        universityGroups: universityGroupOutput.data?.map(option => {
          return { value: option.id, label: option.title };
        })
      }
    });
  }
}

export function* fetchUsersSaga(): SagaIterator {
  yield put({ type: UserManagementActions.LOAD_USERS_BEGIN });

  const usersOutput: AsyncOutput<User[]> = yield call(ApplicationAPI.get, {
    endpoint: 'Users'
  });

  if (usersOutput.error) {
    yield put({ type: UserManagementActions.LOAD_USERS_FAILED, payload: usersOutput.error });
  } else {
    yield put({ type: UserManagementActions.LOAD_USERS_SUCCESSFUL, payload: usersOutput.data });
  }
}

export function* fetchUserDetailsSaga({ payload }: UserManagementAction<number>): SagaIterator {
  if (!payload) throw new Error('Missing id');

  yield put({ type: UserManagementActions.LOAD_USER_DETAILS_BEGIN });

  const userOutput: AsyncOutput<User> = yield call(ApplicationAPI.get, {
    endpoint: `users?id=${payload}`
  });

  if (userOutput.error) {
    yield put({ type: UserManagementActions.LOAD_USER_DETAILS_FAILED, payload: userOutput.error });
  } else {
    yield put({ type: UserManagementActions.LOAD_USER_DETAILS_SUCCESSFUL, payload: userOutput.data });
  }
}

export function* fetchRolesSaga(): SagaIterator {
  yield put({ type: UserManagementActions.LOAD_ROLES_BEGIN });

  const [rolesOutput, featuresOutput]: [AsyncOutput<Role[]>, AsyncOutput<UserFeature[]>] = yield all([
    call(ApplicationAPI.get, { endpoint: 'Roles' }),
    call(ApplicationAPI.get, { endpoint: 'Features' })
  ]);

  if (rolesOutput.error || featuresOutput.error) {
    const errorArray: string[] = [];
    if (rolesOutput.error) errorArray.push(rolesOutput.error);
    if (featuresOutput.error) errorArray.push(featuresOutput.error);

    yield put({ type: UserManagementActions.LOAD_ROLES_FAILED, payload: errorArray.join(' / ') });
  } else {
    yield put({ type: UserManagementActions.LOAD_ROLES_SUCCESSFUL, payload: rolesOutput.data });
  }
}

export function* fetchRoleDetailsSaga({ payload }: UserManagementAction<number>): SagaIterator {
  if (!payload) throw new Error('Missing id');

  yield put({ type: UserManagementActions.LOAD_ROLE_DETAILS_BEGIN });

  const roleOutput: AsyncOutput<Role> = yield call(ApplicationAPI.get, {
    endpoint: `roles?id=${payload}`
  });

  if (roleOutput.error) {
    yield put({ type: UserManagementActions.LOAD_ROLE_DETAILS_FAILED, payload: roleOutput.error });
  } else {
    yield put({ type: UserManagementActions.LOAD_ROLE_DETAILS_SUCCESSFUL, payload: roleOutput.data });
  }
}

export function* fetchFeaturesSaga(): SagaIterator {
  yield put({ type: UserManagementActions.LOAD_FEATURES_BEGIN });

  const featuresOutput: AsyncOutput<UserFeature[]> = yield call(ApplicationAPI.get, {
    endpoint: 'features'
  });

  if (featuresOutput.error) {
    yield put({ type: UserManagementActions.LOAD_FEATURES_FAILED, payload: featuresOutput.error });
  } else {
    yield put({ type: UserManagementActions.LOAD_FEATURES_SUCCESSFUL, payload: featuresOutput.data });
  }
}

export function* fetchFeatureDetailsSaga({ payload }: UserManagementAction<number>): SagaIterator {
  if (!payload) throw new Error('Missing id');

  yield put({ type: UserManagementActions.LOAD_FEATURE_DETAILS_BEGIN });

  const featureOutput: AsyncOutput<UserFeature> = yield call(ApplicationAPI.get, {
    endpoint: `features?id=${payload}`
  });

  if (featureOutput.error) {
    yield put({ type: UserManagementActions.LOAD_FEATURE_DETAILS_FAILED, payload: featureOutput.error });
  } else {
    yield put({ type: UserManagementActions.LOAD_FEATURE_DETAILS_SUCCESSFUL, payload: featureOutput.data });
  }
}

export function* userManagementSaga(): SagaIterator {
  yield takeLatest(UserManagementActions.INITIALIZE_USER_MANAGEMENT, initUserManagementSaga);
  yield takeLatest(UserManagementActions.LOAD_USER_DETAILS, fetchUserDetailsSaga);
  yield takeLatest(UserManagementActions.LOAD_USERS, fetchUsersSaga);
  yield takeLatest(UserManagementActions.LOAD_ROLES, fetchRolesSaga);
  yield takeLatest(UserManagementActions.LOAD_ROLE_DETAILS, fetchRoleDetailsSaga);
  yield takeLatest(UserManagementActions.LOAD_FEATURES, fetchFeaturesSaga);
  yield takeLatest(UserManagementActions.LOAD_FEATURE_DETAILS, fetchFeatureDetailsSaga);
}

export default userManagementReducer;
