import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { GridApi } from 'ag-grid-community/dist/lib/main';
import PageNavigation from './pageNavigation';
import useIsMobileView from '@clinintell/utils/useIsMobileView';

type Props = {
  api: GridApi;
};

export interface UpdatedGridApi extends GridApi {
  api: GridApi;
}

const CustomStatusBar: React.FC<Props> = forwardRef(({ api }, ref) => {
  const isMobileView = useIsMobileView();
  const [gridApi, setGridApi] = useState(api);
  const [totalRows, setTotalRows] = useState(0);
  const [showingFirstRow, setShowingFirstRow] = useState(0);
  const [showingLastRow, setShowingLastRow] = useState(0);

  useImperativeHandle(ref, () => {
    return {
      setGridApi: (api: GridApi) => {
        setGridApi(api);
        api.addEventListener('filterChanged', gridFilterChanged);
        api.addEventListener('paginationChanged', updateShowingRows);
        const totalPages = api.paginationGetRowCount();
        setTotalRows(totalPages);
        setShowingFirstRow(api.getFirstDisplayedRow() + 1);
        setShowingLastRow(api.getLastDisplayedRow() + 1);

        return () => {
          api.removeEventListener('filterChanged', gridFilterChanged);
          api.removeEventListener('paginationChanged', updateShowingRows);
        };
      }
    };
  });

  const updateShowingRows = (updatedApi: UpdatedGridApi) => {
    setShowingFirstRow(updatedApi.api.getFirstDisplayedRow() + 1);
    setShowingLastRow(updatedApi.api.getLastDisplayedRow() + 1);
  };

  const gridFilterChanged = (updatedApi: UpdatedGridApi): void => {
    setGridApi(updatedApi.api);
    setShowingFirstRow(updatedApi.api.getFirstDisplayedRow() + 1);
    setShowingLastRow(updatedApi.api.getLastDisplayedRow() + 1);
    setTotalRows(updatedApi.api.paginationGetRowCount());
  };

  // Render the paging once we have rows > 0
  if (gridApi && totalRows > 0) {
    return (
      <Box sx={{ height: 48, display: 'flex', gap: 2, alignItems: 'center' }}>
        {!isMobileView && (
          <>
            <Typography variant="p1" component="div">
              {/* agGrid is overriding the color inside the statusbar */}
              <Box sx={{ color: 'grey.600' }}>
                Showing {showingFirstRow}-{showingLastRow} of {totalRows} results
              </Box>
            </Typography>
          </>
        )}
        <PageNavigation api={gridApi} />
      </Box>
    );
  }

  return null;
});

export default CustomStatusBar;
