import React from 'react';
import AgGrid from '@clinintell/components/agGrid';
import { ColDef, ICellRendererParams } from 'ag-grid-community/dist/lib/main';
import { Row } from '../agGrid/tablesTypes';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import * as Cells from '@clinintell/components/agGrid/cells';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { sortingComparator } from '@clinintell/containers/metrics/sections/metricsAgGridTable';
import { SevCMIGroupShare } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';

type SeverityImpactTableProps = {
  tableRows: SevCMIGroupShare[];
  footerRow?: SevCMIGroupShare;
  sortDirection: 'asc' | 'desc';
};

const SeverityImpactTable: React.FC<SeverityImpactTableProps> = ({ tableRows, footerRow, sortDirection }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const commonFieldProps = {
    suppressMenu: true,
    sort: null,
    cellRenderer: 'cellRender',
    comparator: sortingComparator
  };
  const baseHeaderClass = 'resizable-header column-header-style';
  const footerStyle = {
    color: theme.palette.common.black,
    background: theme.palette.blue.light5,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  const columnDefs: Array<ColDef> = [
    {
      ...commonFieldProps,
      field: 'conditionDescription',
      headerName: 'Clinical Condition',
      headerClass: `condition ${baseHeaderClass} left-header`,
      cellStyle: {
        textAlign: 'left'
      },
      headerComponentParams: {
        align: 'flex-start'
      },
      width: 450
    },
    {
      ...commonFieldProps,
      field: 'deltaOtoCE',
      headerName: `O/CE Change`,
      headerClass: `historical ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'deltaCodedRate',
      headerName: `Coded Rate Change`,
      headerClass: `current ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'cmiImpact',
      headerName: 'CMI Impact',
      headerClass: `cmiImpact ${baseHeaderClass} centered-header`,
      sort: sortDirection
    }
  ].filter(col => (isMobileView ? ['conditionDescription', 'cmiImpact'].includes(col.field) : true));

  let totalCmiImpact = 0;
  const agGridRows = tableRows.map(
    (row): Row => {
      totalCmiImpact += Number(formatToDecimalPlaces(row.cmiImpact, 3));

      return {
        conditionDescription: {
          rawValue: row.conditionDescription,
          component: (
            <Box
              fontSize={theme.typography.p2.fontSize}
              fontFamily={theme.typography.fontFamily}
              width="100%"
              textAlign="left"
              component="span"
            >
              {row.conditionDescription}
            </Box>
          )
        },
        deltaOtoCE: {
          rawValue: row.deltaOtoCE,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {row.deltaOtoCE === null ? '-' : `${Number(formatToDecimalPlaces(row.deltaOtoCE, 2)).toFixed(0)}%`}
            </Box>
          )
        },
        deltaCodedRate: {
          rawValue: row.deltaCodedRate,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {`${Number(formatToDecimalPlaces(row.deltaCodedRate, 2)).toFixed(0)}%`}
            </Box>
          )
        },
        cmiImpact: {
          rawValue: row.cmiImpact,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {`${formatToDecimalPlaces(row.cmiImpact, 3)}`}
            </Box>
          )
        }
      };
    }
  );

  const getRenderCells = (params: ICellRendererParams): unknown => {
    const { key, rawValue, rowId, component } = params.value;

    return {
      [key]: Cells['DefaultCell'],
      restProps: { rawValue },
      value: rawValue,
      key,
      rowId,
      component
    };
  };

  return (
    <AgGrid
      headerHeight={38}
      getRenderCells={getRenderCells}
      rowData={agGridRows}
      columnDefs={columnDefs}
      rowsPerPage={10}
      tableFinalHeight={10 * 38 + (!!footerRow ? 128 : 128 - 39)}
      defaultColDef={{
        sortable: true,
        resizable: true,
        flex: 1,
        sortingOrder: ['asc', 'desc']
      }}
      footer={
        ({
          conditionDescription: { component: <Box component="span" /> },
          deltaOtoCE: { component: <Box component="span" /> },
          deltaCodedRate: { component: <Box component="span" /> },
          cmiImpact: {
            component: (
              <Box width="100%" textAlign="center" component="span">
                {formatToDecimalPlaces(totalCmiImpact, 3)}
              </Box>
            )
          },
          rowId: 0
        } as unknown) as Row
      }
      footerStyle={footerStyle}
    />
  );
};

export default SeverityImpactTable;
