import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, PageTitle, TimeIcon } from '@clinintell/components/index';
import { Box } from '@mui/material';
import { userCan } from '../authentication/rules';
import { CardSelectorLayout } from '../cmiAnalysisLanding/CMIAnalysisLanding';
import CIReportsModal from './sections/reportModal/CIReportsModal';
import HospitalAndGroupFilter from './sections/hospitalAndGroupFilter/HospitalAndGroupFilter';
import { ReportType } from './types/actionTypes';
import SystemReport, { ExecutiveReport } from './sections/systemReport/SystemReport';
import TrainingProgressFilter from './sections/trainingProgressFilter/TrainingProgressFilter';
import ReportCard from './components/ReportCard';
import { ReportsContext } from './sections/context/ReportContext';
import QuarterlySummaryModal from './sections/quarterlySummary/QuarterlySummaryModal';
import { useScheduledReportState, useScheduledReportDispatch, useUser } from '@clinintell/modules/store';
import { ScheduledReportActionTypes } from '@clinintell/modules/reports';
import ScheduleReport from './sections/quarterlySummary/ScheduleReport';
import Can from '@clinintell/components/Can';
import { TrainingProgressRouteProps } from './types/routeProps';
import { isMobile } from 'react-device-detect';
import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles(() => ({
  containerClass: {
    width: '100%'
  }
}));

const Reports = () => {
  const { containerClass } = useStyle();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUser();
  const userHasAccess = userCan(user, ['schedulerReportEdit']);

  const scheduledReportDispatch = useScheduledReportDispatch();
  const { hasReports } = useScheduledReportState();

  const {
    systemId,
    hospitalId,
    groupId,
    setReportSystemId,
    setReportHospitalId,
    setReportGrouplId,
    setReportDateHandler,
    setReportTrainingGroupHandler,
    setReportBtnDisabled
  } = useContext(ReportsContext);

  useEffect(() => {
    if (!userHasAccess) return;
    scheduledReportDispatch({ type: ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS });
  }, [scheduledReportDispatch, userHasAccess]);

  return (
    // TODO: Need to find a case where the button is active and we need to show it exactly as it is in figma/prod
    <Box sx={{ my: 3, mx: 3, maxWidth: 750 }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageTitle title="Reports" subTitle="Choose the report you would like to view or download." sx={containerClass}>
          {hasReports && userHasAccess && (
            <Button
              type={`primary`}
              variant={`contained`}
              sx={{ marginLeft: 'auto' }}
              label={`Scheduled Reports`}
              leftAdornment={<TimeIcon sx={{ height: 18, mt: 0.5 }} />}
              onClick={() => {
                navigate(`${location.pathname}/scheduled`);
              }}
            />
          )}
        </PageTitle>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
        <CardSelectorLayout>
          <ReportCard typeReport={ReportType.Provider}>
            <CIReportsModal
              reportLabel="View Report"
              reportModalTitle="Provider Report"
              reportType={ReportType.Provider}
            >
              <HospitalAndGroupFilter
                hospitalId={hospitalId}
                groupId={groupId}
                reportType={ReportType.Provider}
                onHospitalChange={(id: number | null) => {
                  setReportHospitalId(id);
                  setReportBtnDisabled(true);
                }}
                onPhysicianGroupChange={(id: number | null) => {
                  setReportGrouplId(id);
                  setReportBtnDisabled(false);
                }}
                width={isMobile ? 325 : '100%'}
              />
            </CIReportsModal>
          </ReportCard>
          <ReportCard typeReport={ReportType.Group}>
            <CIReportsModal reportLabel="View Report" reportModalTitle="Group Report" reportType={ReportType.Group}>
              <HospitalAndGroupFilter
                hospitalId={hospitalId}
                groupId={groupId}
                onHospitalChange={(id: number | null) => {
                  setReportHospitalId(id);
                  setReportBtnDisabled(true);
                }}
                onPhysicianGroupChange={(id: number | null) => {
                  setReportGrouplId(id);
                  setReportBtnDisabled(false);
                }}
                width={isMobile ? 325 : '100%'}
              />
            </CIReportsModal>
          </ReportCard>
        </CardSelectorLayout>
        <CardSelectorLayout>
          <ReportCard typeReport={ReportType.Quarterly}>
            <QuarterlySummaryModal />
            <Can
              permissions={['schedulerReportEdit']}
              yes={(): JSX.Element => <ScheduleReport />}
              no={(): null => null}
            />
          </ReportCard>
          <ReportCard typeReport={ReportType.System}>
            <CIReportsModal
              reportLabel="Download Report"
              modalBtnLabel="Download Report"
              reportModalTitle="System Report"
              reportType={ReportType.System}
              // need to delay disabling the button
              closeCallback={() => setTimeout(() => setReportBtnDisabled(true), 500)}
            >
              <SystemReport dateChngTracker={setReportDateHandler} />
            </CIReportsModal>
          </ReportCard>
        </CardSelectorLayout>
        <CardSelectorLayout>
          <ReportCard typeReport={ReportType.Executive}>
            <CIReportsModal
              reportLabel="Download Report"
              modalBtnLabel="Download Report"
              reportModalTitle="Executive Summary Report"
              reportType={ReportType.Executive}
              closeCallback={() => setTimeout(() => setReportBtnDisabled(true), 500)}
            >
              <HospitalAndGroupFilter
                systemId={systemId}
                hospitalId={hospitalId}
                groupId={groupId}
                onSystemChange={setReportSystemId}
                onHospitalChange={setReportHospitalId}
                onPhysicianGroupChange={setReportGrouplId}
                width={isMobile ? 325 : '100%'}
              />
              <ExecutiveReport dateChngTracker={setReportDateHandler} />
            </CIReportsModal>
          </ReportCard>
          <ReportCard typeReport={ReportType.Training}>
            <CIReportsModal
              reportLabel="View Report"
              reportModalTitle="Training Progress Report"
              reportType={ReportType.Training}
            >
              <Box sx={{ mx: isMobile ? 'auto' : 0 }}>
                <TrainingProgressFilter
                  onFilterChanged={(filters: TrainingProgressRouteProps) => {
                    setReportTrainingGroupHandler(filters);
                    setReportBtnDisabled(false);
                  }}
                />
              </Box>
            </CIReportsModal>
          </ReportCard>
        </CardSelectorLayout>
      </Box>
    </Box>
  );
};

export default Reports;
