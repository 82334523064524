import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { siteTheme } from '@clinintell/theme/theme';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { useUser, useAuthenticationStatus } from '@clinintell/modules/store';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import UnauthenticatedRoutes from '@clinintell/routes/authentication/UnauthenticatedRoutes';
import AuthenticatedRoutes from './routes/authentication/AuthenticatedRoutes';
import WindowSizeContextProvider from './modules/windowSizeContext';
import useCreateAuth0Client from './containers/app/useCreateAuth0Client';
import useSetAuthenticationStatus from './containers/app/useSetAuthenticationStatus';
import useFetchInitialData from './containers/app/useFetchInitialData';
import { ConfidentialityAgreementProvider } from './containers/authentication/ConfidentialityAgreementContext';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorFallback, handleErrorBoundaryError } from './errors';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 2,
      staleTime: 1000 * 60 * 60
    }
  }
});

const App: React.FC = () => {
  const user = useUser();
  const { isAuthenticated } = useAuthenticationStatus();

  const isCookiesEnabled = window.navigator.cookieEnabled;

  const { auth0Client } = useCreateAuth0Client();

  useSetAuthenticationStatus(auth0Client);
  useFetchInitialData(isAuthenticated === null ? false : isAuthenticated);

  if (isAuthenticated === null || (isAuthenticated === true && !user.isLoaded)) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={siteTheme}>
          <LoadingScreen backgroundHeight="100vh" loadingIndicatorSize={250} />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return isCookiesEnabled && !user.blocked ? (
    <QueryClientProvider client={queryClient}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={siteTheme}>
              <BrowserRouter>
                <ErrorBoundary onReset={reset} onError={handleErrorBoundaryError} FallbackComponent={ErrorFallback}>
                  <SentryRoutes>
                    <Route
                      path="/*"
                      element={
                        <WindowSizeContextProvider>
                          {isAuthenticated ? (
                            <AuthenticatedRoutes />
                          ) : (
                            <ConfidentialityAgreementProvider>
                              <UnauthenticatedRoutes />
                            </ConfidentialityAgreementProvider>
                          )}
                        </WindowSizeContextProvider>
                      }
                    />
                  </SentryRoutes>
                </ErrorBoundary>
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        )}
      </QueryErrorResetBoundary>
    </QueryClientProvider>
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={siteTheme}>
        <RouteErrorPage
          error={{
            name: user.blocked ? 'blocked' : '403',
            message: 'We are unable to log you in. Please update your browser settings to enable cookies.'
          }}
        ></RouteErrorPage>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
