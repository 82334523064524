import React from 'react';
import { Box } from '@mui/material';
import { GridApi } from 'ag-grid-community/dist/lib/main';
import PagingButton from './pagingButton';
import usePageNavigation from '../logic/usePageNavigation';

type Props = {
  api: GridApi;
};

const PageNavigation: React.FC<Props> = ({ api }) => {
  const {
    totalPages,
    currentPage,
    currentRange,
    prevPage,
    nextPage,
    goToPage,
    isFirstPage,
    isLastPage,
    prevRange,
    nextRange,
    displayFirstPage,
    displayLastPage
  } = usePageNavigation(api);

  // If coming back and loading the pager
  const updatedPageNumber =
    api.paginationGetCurrentPage() > currentPage && currentPage === 0 ? api.paginationGetCurrentPage() : currentPage;

  if (totalPages <= 1) return null;

  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <PagingButton iconPosition="left" clickEvent={prevPage} disabled={isFirstPage} />
      {displayFirstPage && (
        <>
          <PagingButton
            iconPosition="none"
            title="1"
            clickEvent={(): void => goToPage(0)}
            disabled={isFirstPage}
            isCurrent={updatedPageNumber === 0}
          />
          <PagingButton iconPosition="none" title="..." clickEvent={prevRange} />
        </>
      )}
      {currentRange.map(page => {
        return (
          <PagingButton
            key={page}
            title={String(page + 1)}
            clickEvent={(): void => goToPage(page)}
            isCurrent={page === updatedPageNumber}
          />
        );
      })}
      {displayLastPage && (
        <>
          <PagingButton iconPosition="none" title="..." clickEvent={nextRange} />
          <PagingButton
            iconPosition="none"
            title={String(totalPages)}
            clickEvent={(): void => goToPage(totalPages - 1)}
            disabled={isLastPage}
          />
        </>
      )}
      <PagingButton iconPosition="right" clickEvent={nextPage} disabled={isLastPage} />
    </Box>
  );
};

export default PageNavigation;
