import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
interface Props {
  rawValue: string | number;
}

const useStyles = makeStyles(theme => ({
  headerTooltip: {
    marginLeft: 5,
    top: 3,
    position: 'relative'
  }
}));

const ConditionCell: React.FC<Props> = ({ children }) => {
  const { headerTooltip } = useStyles();
  return (
    <Box style={{ width: '100%' }}>
      <Box className="ellipse">
        {children}
        <Tooltip content="Clinical content currently unavailable">
          <span className={headerTooltip}>
            <HelpOutlineIcon component="svg" style={{ fontSize: '1.2rem' }} />
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ConditionCell;
