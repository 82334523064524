import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Navigate, useLocation } from 'react-router-dom';
import { parseQueryString } from '@clinintell/utils/querystring';
import useAuth0WidgetStyles from '@clinintell/theme/auth0WidgetTheme';
import { useClinIntellAuthWidget } from '@clinintell/containers/authentication/useClinIntellAuthWidget';
import { useAppConfig } from '@clinintell/modules/store';
import { LoginAPI } from '@clinintell/utils/api';
import useAppendClinIntellLogo from './useAppendClinIntellLogo';

const useStyles = makeStyles(theme => ({
  widgetContainer: {
    minWidth: '420px',
    width: '25%',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      marginTop: '2rem'
    }
  }
}));

type SignUpPostData = {
  email: string;
};

const createAuth0LockConstructorOptions = (
  email: string | undefined,
  environment: string,
  color: string
): Auth0LockConstructorOptions => ({
  container: 'signInContainer',
  initialScreen: 'signUp',
  loginAfterSignUp: true,
  avatar: null,
  prefill: {
    email
  },
  allowLogin: false,
  theme: {
    primaryColor: color
  },
  languageDictionary: {
    signUpTitle: 'Please set your Clinintell Account',
    signUpSubmitLabel: 'Set up Account',
    passwordInputPlaceholder: 'Enter Password'
  },
  auth: {
    redirectUrl: environment
  }
});

const SignUp: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const [guid] = parseQueryString(location.search);
  const [userCanSignUp, setUserCanSignUp] = useState<boolean | undefined>();
  const [email, setEmail] = useState<string>();
  const { widgetContainer } = useStyles();
  const { auth0WidgetStyles } = useAuth0WidgetStyles();
  const { environment } = useAppConfig();
  const options = useMemo(() => createAuth0LockConstructorOptions(email, environment, theme.palette.blue.main), [
    email,
    environment,
    theme.palette.blue.main
  ]);
  const clinintellAuthWidget = useClinIntellAuthWidget(options, userCanSignUp === true);
  const containerRef = useRef<Element>(null);
  useAppendClinIntellLogo(containerRef.current, clinintellAuthWidget);

  useEffect(() => {
    // Fetch user information baesd on their GUID. Determine if they have signed up already or not
    if (userCanSignUp === undefined) {
      const fetchUserSignUpStatus = async (): Promise<void> => {
        const { data, status } = await LoginAPI.post<SignUpPostData, { id: string }>({
          endpoint: `signup`,
          data: {
            id: guid.value
          }
        });

        if (status === 200 && data) {
          setEmail(data.email);
          setUserCanSignUp(true);
        } else {
          setUserCanSignUp(false);
        }
      };

      fetchUserSignUpStatus();
    }
  }, [guid, userCanSignUp]);

  useEffect(() => {
    if (clinintellAuthWidget && userCanSignUp) {
      clinintellAuthWidget.on('signup ready', () => {
        const emailInput = document.querySelector('[type="email"]') as HTMLInputElement;
        emailInput.disabled = true;
        emailInput.style.color = theme.palette.grey[400];
        const submitBtn = document.querySelector('[type="submit"]') as HTMLButtonElement;
        submitBtn.style.marginBottom = '40px';
        // remove icon from the submit button
        const submitButtonIcon = document.querySelector('.icon-text') as HTMLButtonElement;
        if (submitButtonIcon !== null) submitButtonIcon.remove();
      });

      clinintellAuthWidget.show();
    }
  }, [clinintellAuthWidget, theme.palette.grey, userCanSignUp]);

  // If the user cannot sign up (either they already have or the GUID is invalid) then redirect to the login page
  if (userCanSignUp === false) {
    return <Navigate to="/" />;
  }

  return <Box ref={containerRef} className={`${widgetContainer} ${auth0WidgetStyles}`} id="signInContainer" />;
};

export default SignUp;
