import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';

type ReportType = {
  id: number;
  typeDescription: string;
};

type Frequency = {
  id: number;
  frequencyName: string;
};

export type ScheduledReportItem = {
  id: number;
  groupOrgHierarchy: number;
  reportType: ReportType;
  frequency: Frequency;
  recipients: string;
  reportName: string;
  parameters: string;
  insertBy: string;
  lastDelivery: string | null;
};

type Dataset = {
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
};

export interface ScheduledReportState extends Dataset {
  reportList: ScheduledReportItem[];
  hasReports: boolean;
}

export enum ScheduledReportActionTypes {
  FETCH_SCHEDULED_REPORTS = 'FETCH_SCHEDULED_REPORTS',
  FETCH_SCHEDULED_REPORTS_BEGIN = 'FETCH_SCHEDULED_REPORTS_BEGIN',
  FETCH_SCHEDULED_REPORTS_FAILED = 'FETCH_SCHEDULED_REPORTS_FAILED',
  FETCH_SCHEDULED_REPORTS_SUCCESS = 'FETCH_SCHEDULED_REPORTS_SUCCESS'
}

export type ScheduledReportAction<T> = {
  type: keyof typeof ScheduledReportActionTypes;
  payload?: T;
};

const initialScheduledReportState: ScheduledReportState = {
  reportList: [],
  hasReports: false,
  isLoading: false,
  hasError: false
};

export default function reducer(
  state: ScheduledReportState = initialScheduledReportState,
  action: ScheduledReportAction<number | string | ScheduledReportItem | ScheduledReportItem[]>
): ScheduledReportState {
  switch (action.type) {
    case ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS_BEGIN: {
      return {
        ...initialScheduledReportState,
        isLoading: true
      };
    }
    case ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS_FAILED: {
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload as string
      };
    }
    case ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS_SUCCESS: {
      const reportList = action.payload as ScheduledReportItem[];
      return {
        ...state,
        reportList,
        hasReports: reportList && reportList.length > 0,
        isLoading: false,
        hasError: false,
        errorMessage: undefined
      };
    }
    default: {
      return state;
    }
  }
}

export function* fetchScheduledReports(): SagaIterator {
  yield put({ type: ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS_BEGIN });

  type OutputType = { scheduleReportList: ScheduledReportItem[] };
  const output: AsyncOutput<OutputType> = yield call(ApplicationAPI.get, { endpoint: `schedule/reports` });

  if (output.error) {
    yield put({ type: ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS_FAILED, payload: output.error });
  } else {
    yield put({
      type: ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS_SUCCESS,
      payload: output.data?.scheduleReportList
    });
  }
}

export function* scheduledReportDataSaga(): SagaIterator {
  yield takeLatest(ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS, fetchScheduledReports);
}
