import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import AgGrid from '@clinintell/components/agGrid';
import { ColDef, ICellRendererParams } from 'ag-grid-community/dist/lib/main';
import { OverlayBubbles, Row } from '@clinintell/components/agGrid/tablesTypes';
import * as Cells from '@clinintell/components/agGrid/cells';
import { sortingComparator } from '@clinintell/containers/metrics/sections/metricsAgGridTable';
import { Timeline } from '@mui/icons-material';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import Footnotes from '@clinintell/components/Footnotes';
import { useDashboard2 } from '@clinintell/modules/store';
import { DRGConditionsOpportunityData, DrgConditionOpportunityData } from '@clinintell/modules/dashboard2';
import { getOverlay } from '@clinintell/containers/metrics/logic/useMetricsDataConversionForTable';
import { BubbleCell } from '@clinintell/components/agGrid/cells';
import { formatNumberWithCommas, parseDateIntoPeriod } from '@clinintell/utils/formatting';

type Props = {
  startDate: string | Date;
  endDate: string | Date;
  onGraphClick: (conditionId: number, conditionDescription: string) => void;
};

export type DRGDataType = {
  drgOpportunityData: DRGConditionsOpportunityData;
  widgetTableData: DrgConditionOpportunityData;
};

const DrgConditionsWidgetTable: React.FC<Props> = ({ startDate, endDate, onGraphClick }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { drgConditionsByOpportunityWidget } = useDashboard2();

  const { drgOpportunityData } = drgConditionsByOpportunityWidget.data as DRGDataType;
  const commonFieldProps = {
    suppressMenu: true,
    sort: null,
    cellRenderer: 'cellRender',
    comparator: sortingComparator
  };
  const baseHeaderClass = 'resizable-header column-header-style';

  const columnDefs: Array<ColDef> = [
    {
      ...commonFieldProps,
      field: 'name',
      headerName: 'Clinical Condition',
      headerClass: `condition ${baseHeaderClass} left-header`,
      cellStyle: {
        textAlign: 'left'
      },
      headerComponentParams: {
        align: 'flex-start'
      },
      width: 350
    },
    {
      ...commonFieldProps,
      field: 'action',
      headerName: 'Action',
      headerClass: `action ${baseHeaderClass} centered-header`,
      checkboxSelection: false,
      headerCheckboxSelection: false,
      suppressMenu: true,
      sortable: false,
      sort: null,
      width: 120
    },
    {
      ...commonFieldProps,
      field: 'current',
      headerName: 'Coded Rate',
      headerClass: `current ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'clinicallyExpected',
      headerName: 'Clinically Expected',
      headerClass: `clinicallyExpected ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'gapVsCe',
      headerName: 'Coded Rate Gap',
      headerClass: `gapVsCe ${baseHeaderClass} centered-header`,
      headerComponentParams: {
        tooltipMessage: (
          <Box>
            <p>
              Gap is the percent of the patient population for which a clinical condition was underreported as a
              secondary diagnosis.
            </p>
            <div>
              The bubble color indicates:
              <ul style={{ marginLeft: -16 }}>
                <li>Red – Gap is statistically significant and lower than Clinically Expected</li>
                <li>Amber – Gap is statistically significant and higher than Clinically Expected</li>
                <li>No bubble – Gap is not statistically significant</li>
              </ul>
            </div>
          </Box>
        ),
        align: 'center'
      }
    },
    {
      ...commonFieldProps,
      field: 'otoE',
      headerName: 'Secondary O/CE',
      headerClass: `otoE ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'opportunityRW',
      headerName: 'Coded Rate Opportunity',
      headerClass: `opportunityRW ${baseHeaderClass} right-header`,
      resizable: false,
      headerComponentParams: {
        tooltipMessage: (
          <Box>
            <p>
              Estimate of the RW value of the clinical condition gap based on all the claims for which it was the only
              CC or MCC for the particular provider, physician group or hospital.
            </p>
          </Box>
        ),
        align: 'center'
      }
    }
  ].filter(col => (isMobileView ? ['name', 'action', 'current', 'gapVsCe'].includes(col.field) : true));

  const getStatusColor = (value: number): OverlayBubbles => {
    const overlayValue = getOverlay('gapVsCe', value);
    return overlayValue as OverlayBubbles;
  };

  const agGridRows = drgOpportunityData.metrics.map(
    (row): Row => {
      return {
        name: {
          rawValue: row.name,
          component: (
            <Box
              fontSize={theme.typography.p2.fontSize}
              fontFamily={theme.typography.fontFamily}
              width="100%"
              textAlign="left"
              component="span"
            >
              {row.name}
            </Box>
          )
        },
        action: {
          rawValue: row.action,
          component: (
            <Box
              onClick={(): void => onGraphClick(row.conditionId, row.name)}
              onTouchEnd={(): void => onGraphClick(row.conditionId, row.name)}
              sx={{ marginTop: 1, cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <Tooltip content="View Graph" position="right">
                <Timeline />
              </Tooltip>
            </Box>
          )
        },
        current: {
          rawValue: row.current,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {`${row.current}%`}
            </Box>
          )
        },
        clinicallyExpected: {
          rawValue: row.clinicallyExpected,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {`${row.clinicallyExpected}%`}
            </Box>
          )
        },
        gapVsCe: {
          rawValue: row.gapVsCe,
          component: (
            <Box component="span" sx={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>
              <BubbleCell overlayStyle={getStatusColor(row.gapVsCeInErrorRange)}>{`${row.gapVsCe}%`}</BubbleCell>
            </Box>
          )
        },
        otoE: {
          rawValue: row.otoE,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {`${row.otoE}%`}
            </Box>
          )
        },
        opportunityRW: {
          rawValue: row.opportunityRW,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {formatNumberWithCommas(row.opportunityRW)}
            </Box>
          )
        }
      };
    }
  );

  const getRenderCells = (params: ICellRendererParams): unknown => {
    const { key, rawValue, rowId, component } = params.value;

    return {
      [key]: key === 'action' ? Cells['ActionCell'] : Cells['DefaultCell'],
      restProps: { rawValue },
      value: rawValue,
      key,
      rowId,
      component
    };
  };

  const footnotes: string[] = [
    `Period: ${parseDateIntoPeriod(new Date(startDate))}-${parseDateIntoPeriod(new Date(endDate))}`
  ];

  return (
    <Box>
      <Typography variant={'h5'} style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
        Condition Performance Table
      </Typography>
      <Box mt={2}>
        <AgGrid
          headerHeight={38}
          getRenderCells={getRenderCells}
          rowData={agGridRows}
          columnDefs={columnDefs}
          rowsPerPage={10}
          tableFinalHeight={10 * 38 + 128}
          defaultColDef={{
            sortable: true,
            resizable: true,
            flex: 1,
            sortingOrder: ['asc', 'desc']
          }}
        />
        <Box mt={1} ml={-2}>
          <Footnotes footnotes={footnotes} />
        </Box>
      </Box>
    </Box>
  );
};

export default DrgConditionsWidgetTable;
