import React, { useState, useEffect } from 'react';
import { Typography, Box, TextField, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { validateEmail } from '@clinintell/utils/validators';

interface Props {
  rawValue: string | number;
  rowId: number;
  editColumnName?: string;
  onEditColumnUpdate?: (id: number, value: string) => void;
}

const useStyles = makeStyles(theme => ({
  editInput: {
    margin: '4px auto',
    '& > .MuiOutlinedInput-root': {
      fontSize: '0.9rem',
      height: 30,
      '& > input': {
        width: 200
      }
    }
  },
  editLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    textAlign: 'center',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
    padding: `5px 14px`,
    margin: `4px auto`,
    width: 200,
    minHeight: theme.spacing(2),
    height: 18,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const EmailCell: React.FC<Props> = ({ rowId, rawValue, onEditColumnUpdate }) => {
  const theme = useTheme();
  const { editInput, editLabel } = useStyles();
  const [edit, setEdit] = useState(false);
  const [isInputInvalid, setIsInputInvalid] = useState(true);

  useEffect(() => {
    if (!!rawValue) handleFieldValidation(rawValue as string);
  }, [rawValue]);

  const handleFieldValidation = (value: string): void => {
    const isValid = validateEmail(value);
    setIsInputInvalid(isValid);
  };

  const handleInlineEditToggle = (): void => {
    setEdit(true);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
      {edit ? (
        <TextField
          id={`edit-${rowId}`}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          error={!isInputInvalid}
          role="input"
          className={editInput}
          variant="outlined"
          size="small"
          defaultValue={rawValue}
          onClick={(event): void => event.stopPropagation()}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onBlur={(event: any): void => {
            handleFieldValidation(event.target.value);
            if (!!onEditColumnUpdate && rawValue !== event.target.value) {
              onEditColumnUpdate(rowId, event.target.value);
            }
          }}
          // onKeyUp={(event: React.KeyboardEvent): void => {
          //   if (event.key === 'Enter') {
          //     const input = event.currentTarget.getElementsByTagName('input').item(0) as HTMLInputElement;
          //     if (input && input.value && !!onEditColumnUpdate) {
          //       onEditColumnUpdate(rowId, input.value);
          //       handleFieldValidation(input.value);
          //     }
          //   }
          // }}
        />
      ) : (
        <Typography
          variant="p2"
          component="div"
          className={editLabel}
          onClick={(event: React.MouseEvent): void => {
            event.stopPropagation();
            handleInlineEditToggle();
          }}
          style={!isInputInvalid ? { borderColor: theme.palette.red[500] } : undefined}
        >
          {rawValue}
        </Typography>
      )}
    </Box>
  );
};

export default EmailCell;
