import React from 'react';
import { Box } from '@mui/material';
import ProgressBar from '@clinintell/components/ProgressBar';

interface Props {
  rawValue: number;
}

const ProgressCell: React.FC<Props> = ({ rawValue }) => {
  return (
    <Box width="100%" display="flex" flexDirection="column" py={1}>
      <ProgressBar value={rawValue / 100} size="small" top={-6} />
    </Box>
  );
};

export default ProgressCell;
