import React, { useEffect, useMemo, useRef } from 'react';
import { useTheme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useAuth0WidgetStyles from '@clinintell/theme/auth0WidgetTheme';
import { useClinIntellAuthWidget } from '@clinintell/containers/authentication/useClinIntellAuthWidget';
import { useAppConfig, useAuth0Config } from '@clinintell/modules/store';
import { validateEmail } from '@clinintell/utils/validators';
import { LoginAPI } from '@clinintell/utils/api';
import useAppendClinIntellLogo from './useAppendClinIntellLogo';

const useStyles = makeStyles(theme => ({
  widgetContainer: {
    width: '35rem',
    [theme.breakpoints.down('md')]: {
      width: '90vw',
      height: '70vh'
    }
  }
}));

const createAuth0LockConstructorOptions = (
  environment: string,
  audienceId: string,
  color: string
): Auth0LockConstructorOptions => ({
  allowAutocomplete: true,
  container: 'signInContainer',
  initialScreen: 'login',
  rememberLastLogin: true,
  avatar: null,
  allowSignUp: false,
  allowForgotPassword: true,
  usernameStyle: 'email',
  auth: {
    redirectUrl: `${environment}/callback`,
    redirect: true,
    responseType: 'token id_token',
    audience: audienceId,
    autoParseHash: true
  },
  theme: {
    primaryColor: color
  },
  languageDictionary: {
    error: {
      login: {
        'lock.invalid_email_password': 'The email or password you entered is incorrect.',
        'lock.invalid_username_password': 'The email or password you entered is incorrect.'
      }
    },
    loginSubmitLabel: 'Sign In',
    title: 'Sign in to ClinIntell',
    passwordInputPlaceholder: 'Password',
    emailInputPlaceholder: 'Email',
    usernameInputPlaceholder: 'Corporate Username',
    usernameOrEmailInputPlaceholder: 'Corporate Username',
    enterpriseActiveLoginInstructions: 'Please enter your corporate credentials below.',
    forgotPasswordAction: 'Forgot password?',
    forgotPasswordTitle: 'Forgot Password?',
    forgotPasswordInstructions: 'Please enter your email below to receive your reset password instructions',
    forgotPasswordSubmitLabel: 'Reset Password'
  }
});

const SignIn: React.FC = () => {
  const { widgetContainer } = useStyles();
  const theme = useTheme();
  const { auth0WidgetStyles } = useAuth0WidgetStyles();
  const { environment } = useAppConfig();
  const { audienceId } = useAuth0Config();

  const options = useMemo(() => createAuth0LockConstructorOptions(environment, audienceId, theme.palette.blue.main), [
    environment,
    audienceId,
    theme.palette.blue.main
  ]);

  const clinintellAuthWidget = useClinIntellAuthWidget(options, true);

  const containerRef = useRef<Element>(null);
  useAppendClinIntellLogo(containerRef.current, clinintellAuthWidget);

  const hideErrorMessages = () => {
    const globalMessage = document.getElementsByClassName('auth0-global-message')[0] as HTMLDivElement;
    if (globalMessage) {
      globalMessage.style.display = 'none';
    }

    const errorLabels = document.querySelectorAll('[role="alert"]');
    errorLabels.forEach(error => {
      (error as HTMLElement).style.display = 'none';
    });

    return errorLabels;
  };

  const addErrorBanner = () => {
    // Adding the error banner to the document
    const formHeaderElement =
      document.querySelector('.auth0-lock-header') === null
        ? (document.querySelector('.auth0-lock-header') as HTMLDivElement)
        : (document.querySelector('.auth0-lock-header-welcome') as HTMLDivElement);
    const errorBanner = document.createElement('div');
    errorBanner.classList.add('error-banner');
    const errorIcon = document.createElement('span');
    errorIcon.classList.add('error-banner-icon');
    errorIcon.innerHTML = '&#9888;';
    const errorMsg = document.createElement('p');
    errorMsg.classList.add('error-banner-msg');
    errorBanner.appendChild(errorIcon);
    errorBanner.appendChild(errorMsg);
    errorBanner.style.display = 'none';
    formHeaderElement.insertAdjacentElement('afterend', errorBanner);
  };

  useEffect(() => {
    if (clinintellAuthWidget) {
      clinintellAuthWidget.on('signin ready', () => {
        // Remove hijacked forgot password onclick event as this form is shared between signin and forgot password.
        const form = document.getElementsByClassName('auth0-lock-widget')[0] as HTMLFormElement;

        /*
          The following will retrieve the submit button from the template and place it into the wrapper the other form elements
          exists in. We will then style it into the manner the story requires. This was done in this method because this template
          is retrieved from auth0 account in liquid.
        */
        // remove icon from the submit button
        const submitButtonIcon = document.querySelector('.icon-text') as HTMLButtonElement;
        if (submitButtonIcon !== null) submitButtonIcon.remove();

        // Contact us footer
        const formContainer = document.querySelector('.auth0-lock') as HTMLDivElement;
        const contactUsLink = document.createElement('a');
        contactUsLink.innerHTML = 'support@clinintell.com';
        contactUsLink.href = 'https://clinintelldev.zendesk.com/hc/en-us/requests/new';
        contactUsLink.title = 'Clinintell Support';
        contactUsLink.target = '_blank';
        contactUsLink.rel = 'noopener noreferrer';

        const footerSupportLink = document.querySelector('.footer-support') as HTMLButtonElement;
        if (footerSupportLink === null) {
          const contactUsElememt = document.createElement('div');
          contactUsElememt.innerHTML = 'Need Help? Contact us at ';
          contactUsElememt.classList.add('footer-support');
          contactUsElememt.appendChild(contactUsLink);
          formContainer.appendChild(contactUsElememt);
        }

        // check first that one doesn't already exist
        if (document.querySelector('.error-banner') === null) {
          addErrorBanner();
        }

        const errorBanner = document.querySelector('.error-banner') as HTMLDivElement;
        const errorMsg = document.querySelector('.error-banner-msg') as HTMLParagraphElement;

        // Listen for submit event as this will not trigger any of the auth0 lifecycle events
        // this will allow us to check if they are leaving inputs blank
        form.addEventListener('submit', async event => {
          const errorArray = hideErrorMessages();
          if (errorArray.length) {
            let errorBannerMsg = '';
            const emailInput = document.querySelector('[type="email"]') as HTMLInputElement;
            const passwordInput = document.querySelector('[type="password"]') as HTMLInputElement;

            if ((!emailInput?.value && !passwordInput?.value) || !emailInput?.value || !passwordInput?.value) {
              errorBannerMsg = 'Email and password are required.';
            }

            if (errorArray.length) {
              if ((errorArray[0] as HTMLElement).innerText.includes('invalid')) {
                errorBannerMsg = 'Email is invalid.';
              }
            }

            errorMsg.innerHTML = errorBannerMsg;
            errorBanner.style.display = '';
          }

          event.preventDefault();
        });

        const forgotPasswordLink = document.getElementsByClassName('auth0-lock-alternative-link')[0] as HTMLFormElement;
        forgotPasswordLink.addEventListener('click', async event => {
          // If errors are displaying reset
          errorMsg.innerHTML = '';
          errorBanner.style.display = 'none';
          const submitBtn = document.querySelector('[type="submit"]') as HTMLButtonElement;
          submitBtn.style.marginBottom = '60px';
        });

        form.onclick = null;
      });

      clinintellAuthWidget.on('authorization_error', async () => {
        // Blocked account that can be used to test
        // On Dev2
        // test123@clinintelltest.com
        const globalMessage = document.getElementsByClassName('auth0-global-message')[0] as HTMLDivElement;
        if (globalMessage) {
          if ((globalMessage as HTMLDivElement).innerText.toLocaleLowerCase().includes('incorrect')) {
            const globalErrorBanner = document.querySelector('.auth0-global-message-error') as HTMLDivElement;
            globalErrorBanner.style.display = 'none';
            const errorBanner = document.querySelector('.error-banner') as HTMLDivElement;
            const errorMsg = document.querySelector('.error-banner-msg') as HTMLParagraphElement;
            errorMsg.innerHTML = 'The email or password was incorrect.';
            errorBanner.style.display = '';
          }
        }
      });

      clinintellAuthWidget.on('forgot_password ready', async () => {
        // check first that one doesn't already exist
        if (document.querySelector('.error-banner') === null) {
          addErrorBanner();
        } else {
          const errorBannerCheck = document.querySelector('.error-banner') as HTMLDivElement;
          if (
            errorBannerCheck &&
            errorBannerCheck.innerHTML !== '' &&
            window.getComputedStyle(errorBannerCheck).getPropertyValue('display') !== 'none'
          ) {
            // Clear the innerHTML content and set the display property to none
            errorBannerCheck.innerHTML = '';
            errorBannerCheck.style.display = 'none';
          }
        }

        // Get the main elements that we will be keeping track off
        const backButton = document.getElementsByClassName('auth0-lock-back-button')[0] as HTMLButtonElement;
        const errorBanner = document.querySelector('.error-banner') as HTMLDivElement;
        const errorMsg = document.querySelector('.error-banner-msg') as HTMLParagraphElement;
        const form = document.querySelector('form') as HTMLFormElement;

        // Get the element with the ID "undefined-back-button"
        //const backButton = document.getElementById('undefined-back-button');

        // Check if the element exists before continuing
        if (backButton) {
          const svgElement = backButton.querySelector('svg');
          const paragraphElement = document.createElement('p');
          paragraphElement.classList.add('back-button-label');
          paragraphElement.textContent = 'Back';
          // Replace the SVG element with the paragraph element
          backButton.replaceChild(paragraphElement, svgElement as SVGSVGElement);
        }

        // Prevent default form submission. Perform validation ourselves and then call forgot password API endpoint.
        form.onclick = async (event): Promise<void> => {
          // Don't want other input clicks to trigger this
          if (
            (event.target as Element).className !== 'auth0-label-submit' &&
            (event.target as Element).className !== 'auth0-lock-submit'
          ) {
            return;
          }

          event.preventDefault();

          const submitBtn = document.querySelector('[type="submit"]') as HTMLButtonElement;

          // Get email field
          const email = document.querySelector('[type="email"]') as HTMLInputElement;
          if (!email) {
            throw new Error('Failed to find form input - email');
          }

          // Validate email
          if (!email.value) {
            errorMsg.innerHTML = `Email can't be blank`;
            errorBanner.style.display = '';
          } else if (!validateEmail(email.value)) {
            errorMsg.innerHTML = 'Email is invalid';
            errorBanner.style.display = '';
          } else {
            errorMsg.innerHTML = '';
            errorBanner.style.display = 'none';
          }

          const parentElement = email.parentElement as HTMLElement;
          const containerElement = parentElement.parentElement as HTMLElement;

          if (errorMsg.innerHTML) return;

          submitBtn.disabled = true;
          // Show loading indicator
          const reactRoot = document.getElementById('root');
          if (reactRoot) reactRoot.classList.add('auth0-lock-mode-loading');

          if (containerElement.childElementCount > 1) {
            containerElement.removeChild(containerElement.children[1]);
          }

          parentElement.style.borderColor = '#f1f1f1';

          // Perform API call now
          await LoginAPI.post({
            endpoint: 'users/passresreq',
            data: {
              Email: email.value
            }
          });
          if (reactRoot) reactRoot.classList.remove('auth0-lock-mode-loading');
          // reset the submit button
          submitBtn.disabled = false;

          // Send user back to sign in by emulating the back button click
          backButton?.click();
          submitBtn.style.marginBottom = '70px';

          const messageWrapper = document.getElementsByClassName(
            'auth0-lock-content-body-wrapper'
          )[0] as HTMLDivElement;

          const messageBackground = document.createElement('div');
          messageBackground.className = 'auth0-global-message auth0-global-message-success';

          const messageAnimateSpan = document.createElement('span');
          messageAnimateSpan.className = 'animated fadeInUp';

          const message = document.createElement('span');
          message.innerText =
            'If you have a valid account with us, you should receive a password reset link shortly. Please contact us at support@clinintell.com if you need further assistance.';

          messageAnimateSpan.appendChild(message);
          messageBackground.appendChild(messageAnimateSpan);
          messageWrapper.insertBefore(messageBackground, messageWrapper.firstChild);
        };

        backButton.addEventListener('click', (event: UIEvent) => {
          // reset the error banner on return
          errorMsg.innerHTML = '';
          errorBanner.style.display = 'none';
          const submitBtn = document.querySelector('[type="submit"]') as HTMLButtonElement;
          submitBtn.style.marginBottom = '70px';
        });
      });

      clinintellAuthWidget.show();
    }
  }, [clinintellAuthWidget, theme.palette.blue.main, environment]);

  return (
    // Box does in fact accept a ref, but Typescript check fails. This was fixed in Material-UI v5.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Box ref={containerRef} className={`${widgetContainer} ${auth0WidgetStyles}`} id="signInContainer" />
  );
};

export default SignIn;
