import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import DropDownIcon from '@clinintell/components/icons/DropDownIcon';
import { containerBorderRadius } from '@clinintell/theme/theme';

type Props = {
  title?: string;
  iconPosition?: 'none' | 'left' | 'right';
  clickEvent: () => void;
  disabled?: boolean;
  isCurrent?: boolean;
};

const PagingButton: React.FC<Props> = ({
  title,
  iconPosition = 'none',
  clickEvent,
  disabled = false,
  isCurrent = false
}): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: 28,
        height: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        color: isCurrent ? theme.palette.blue.main : theme.palette.grey[900],
        border: `2px solid ${isCurrent ? theme.palette.blue.main : theme.palette.grey[300]}`,
        borderRadius: `${containerBorderRadius}px`,
        cursor: disabled ? 'none' : 'pointer',
        pointerEvents: disabled || isCurrent ? 'none' : 'auto',
        '&:hover': {
          color: theme.palette.blue.main,
          border: `2px solid ${theme.palette.blue.main}`
        }
      }}
      onClick={clickEvent}
    >
      {iconPosition === 'left' && (
        <Box sx={{ display: 'flex', alignItems: 'center', transform: `rotate(90deg)` }}>
          <DropDownIcon sx={{ fill: isCurrent ? 'blue.main' : 'grey.600', height: 18, width: 18 }} />
        </Box>
      )}
      <Typography variant="p1" style={{ cursor: disabled ? 'none' : 'pointer' }}>
        <Box sx={{ color: isCurrent ? 'blue.main' : 'grey.900', '&:hover': { color: 'blue.main' } }}>{title}</Box>
      </Typography>
      {iconPosition === 'right' && (
        <Box sx={{ display: 'flex', alignItems: 'center', transform: `rotate(-90deg)` }}>
          <DropDownIcon sx={{ fill: isCurrent ? 'blue.main' : 'grey.600', height: 18, width: 18 }} />
        </Box>
      )}
    </Box>
  );
};

export default PagingButton;
