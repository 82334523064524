import React, { createContext, useCallback, useContext, useState } from 'react';

type ReportDownloadContextArgs = {
  downloadIsEnabled: boolean;
  enableDownload: () => void;
  disableDownload: () => void;
  sortColumn: string | undefined;
  changeSortColumn: (value: string | undefined) => void;
  sortDescending: boolean;
  toggleSortDescending: (value: boolean) => void;
};

const ReportDownloadContext = createContext<ReportDownloadContextArgs>({
  downloadIsEnabled: true,
  enableDownload: () => {
    /* empty function */
  },
  disableDownload: () => {
    /* empty function */
  },
  sortColumn: undefined,
  changeSortColumn: () => {
    /* empty function */
  },
  sortDescending: false,
  toggleSortDescending: () => {
    /* empty function */
  }
});

export const ReportDownloadProvider: React.FC = ({ children }) => {
  const [downloadIsEnabled, setDownloadIsEnabled] = useState(true);
  const [sortColumn, setSortColumn] = useState<string>();
  const [sortDescending, setSortDescending] = useState(false);

  const enableDownload = () => {
    setDownloadIsEnabled(true);
  };

  const disableDownload = () => {
    setDownloadIsEnabled(false);
  };

  const changeSortColumn = useCallback((value: string | undefined) => {
    setSortColumn(value);
  }, []);

  const toggleSortDescending = useCallback((value: boolean) => {
    setSortDescending(value);
  }, []);

  return (
    <ReportDownloadContext.Provider
      value={{
        downloadIsEnabled,
        enableDownload,
        disableDownload,
        sortColumn,
        changeSortColumn,
        toggleSortDescending,
        sortDescending
      }}
    >
      {children}
    </ReportDownloadContext.Provider>
  );
};

export const useReportDownloadStatus = () => {
  const context = useContext(ReportDownloadContext);
  if (!context) {
    throw new Error('useReportDownloadStatus must be used within a ReportDownloadProvider');
  }

  return context;
};

export default ReportDownloadProvider;
