import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IHeaderParams } from 'ag-grid-community/dist/lib/main';
import Tooltip from '@clinintell/components/tooltip/Tooltip';

interface Props extends IHeaderParams {
  tooltipMessage?: string;
  sx?: SxProps<Theme>;
}

const ColumnHeader: React.FC<Props> = ({
  showColumnMenu,
  column,
  setSort,
  enableMenu,
  enableSorting,
  displayName,
  tooltipMessage,
  sx
}): JSX.Element => {
  const [ascSort, setAscSort] = useState('inactive');
  const [descSort, setDescSort] = useState('inactive');
  const refButton = useRef<HTMLDivElement>(null);
  const [filterActive, setFilterActive] = useState(false);

  const onMenuClicked = (): void => {
    showColumnMenu(refButton.current as HTMLDivElement);
  };

  const onSortChanged = useCallback(() => {
    setAscSort(column.isSortAscending() ? 'active' : 'inactive');
    setDescSort(column.isSortDescending() ? 'active' : 'inactive');
  }, [setAscSort, setDescSort, column]);

  const onFilterActiveChanged = useCallback(() => {
    setFilterActive(column.isFilterActive());
  }, [column]);

  const onSortRequested = (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent): void => {
    const propSort = column.getSort();
    setSort(propSort === 'asc' ? 'desc' : 'asc', event.shiftKey);
  };

  useEffect(() => {
    column.addEventListener('filterActiveChanged', onFilterActiveChanged);
    column.addEventListener('sortChanged', onSortChanged);
    onSortChanged();

    return (): void => {
      column.removeEventListener('sortChanged', onSortChanged);
      column.removeEventListener('filterActiveChanged', onFilterActiveChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let menu = null;
  if (enableMenu) {
    menu = (
      <div
        ref={refButton}
        role="button"
        tabIndex={-1}
        className="ag-header-icon ag-header-cell-menu-button ag-header-menu-always-show"
        onClick={onMenuClicked}
        onKeyPress={onMenuClicked}
        onTouchEnd={onMenuClicked}
      >
        <span className="ag-icon ag-icon-menu" role="presentation" />
      </div>
    );
  }

  let sort = null;
  if (enableSorting) {
    sort = (
      <>
        <Box component="span" className="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true">
          1
        </Box>
        <Box
          mr={1}
          component="span"
          onClick={onSortRequested}
          onTouchEnd={onSortRequested}
          className={`ag-header-icon ag-header-label-icon ag-sort-ascending-icon`}
          display={ascSort === 'inactive' ? 'none' : 'inline-block'}
        >
          <Box component="span" className="ag-icon ag-icon-asc" role="presentation" />
        </Box>
        <Box
          mr={1}
          component="span"
          onClick={onSortRequested}
          onTouchEnd={onSortRequested}
          className={`ag-header-icon ag-header-label-icon ag-sort-descending-icon`}
          display={descSort === 'inactive' ? 'none' : 'inline-block'}
        >
          <Box component="span" className="ag-icon ag-icon-desc" role="presentation" />
        </Box>
        <Box
          component="span"
          className="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"
          aria-hidden="true"
        >
          <Box component="span" className="ag-icon ag-icon-none" unselectable="on" role="presentation" />
        </Box>
      </>
    );
  }

  const coldef = column.getColDef();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
      <Box
        sx={{
          ...sx,
          display: 'flex',
          alignItems: 'center',
          width: displayName === '...' ? 24 : '100%'
        }}
      >
        <Box
          onClick={(event): void => onSortRequested(event)}
          onTouchEnd={(event): void => onSortRequested(event)}
          display="flex"
          className="ag-header-cell-label"
          role="presentation"
        >
          {/* Only check for the isTargetCondition display name "..." */}
          {displayName === '...' ? (
            <Box component="span" display="inline" width={10} height={38} />
          ) : (
            <>
              <Box component="span" className="ag-header-cell-text" unselectable="on">
                {displayName}
              </Box>
              {tooltipMessage && (
                <Tooltip
                  content={tooltipMessage}
                  position={coldef.field?.toLowerCase().includes('opportunity') ? 'left' : 'bottom'}
                >
                  <Box component="span" ml={1} mt={0.5}>
                    <HelpOutlineIcon component="svg" />
                  </Box>
                </Tooltip>
              )}
            </>
          )}
          {filterActive && (
            <Box component="span" ml={2} className="ag-header-icon ag-header-label-icon ag-filter-icon">
              <Box component="span" className="ag-icon ag-icon-filter" unselectable="on" role="presentation" />
            </Box>
          )}
          {sort}
        </Box>
      </Box>
      {menu}
    </Box>
  );
};

export default ColumnHeader;
