import Table from '@clinintell/components/Table';
import React from 'react';
import { GeneratedHospitalReportRecord } from '../types/generatedReportDataTypes';
import GenerateSystemTrainingReportTableProps from '../logic/generateSystemTrainingReportTableProps';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { OrgTableProps } from '../TrainingStatusReport';
import { useReportDownloadStatus } from '../context/ReportDownloadContext';

const SystemGeneratedReport: React.FC<OrgTableProps> = ({ orgId, onSort, onRowsPerPageChanged, rowsPerPageValues }) => {
  const { isLoading, output } = useGetAPICAll<GeneratedHospitalReportRecord[]>({
    endpoint: `reports/training/system/${orgId}`,
    isWaiting: false
  });

  const { enableDownload, disableDownload } = useReportDownloadStatus();

  if (isLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="25rem" width="100%" />;
  }

  if (output && output.data && output.data.length === 0) {
    disableDownload();
  } else {
    enableDownload();
  }

  return (
    <Table
      tableId="trainingStatusSystem"
      onRowsPerPageChange={onRowsPerPageChanged}
      onSort={onSort}
      {...GenerateSystemTrainingReportTableProps(output && output.data ? output.data : [], rowsPerPageValues)}
    />
  );
};

export default SystemGeneratedReport;
