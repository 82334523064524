import Table from '@clinintell/components/Table';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { ProviderTrainingConditionType } from '@clinintell/containers/providerTrainingAssignment/types/providerTrainingConditionType';
import React from 'react';
import { Box, useTheme } from '@mui/material';
import GenerateProviderTrainingReportTableProps from '../logic/generateProviderTrainingReportTableProps';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { OrgTableProps } from '../TrainingStatusReport';
import { useReportDownloadStatus } from '../context/ReportDownloadContext';

const ProviderGeneratedReport: React.FC<OrgTableProps> = ({
  orgId,
  onSort,
  onRowsPerPageChanged,
  rowsPerPageValues
}) => {
  const theme = useTheme();

  const { isLoading: providerIsLoading, output: providers } = useGetAPICAll<ProviderTrainingConditionType[]>({
    endpoint: `reports/training/physician/${orgId}`,
    isWaiting: false
  });

  const { enableDownload, disableDownload } = useReportDownloadStatus();

  if (providerIsLoading || !providers) {
    return <ClinIntellSkeleton variant="rectangular" height="25rem" width="100%" />;
  }

  if (!providerIsLoading && providers && providers.data && providers.data.length === 0) {
    disableDownload();
    return (
      <Box component="span" color={theme.palette.red[500]}>
        This provider is not enrolled in ClinIntell's online training.
      </Box>
    );
  } else {
    enableDownload();
  }

  return (
    <Table
      tableId="trainingStatusProvider"
      onRowsPerPageChange={onRowsPerPageChanged}
      onSort={onSort}
      {...GenerateProviderTrainingReportTableProps(providers?.data ? providers.data : [], rowsPerPageValues)}
    />
  );
};

export default ProviderGeneratedReport;
