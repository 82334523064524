import getChartAndTableConfiguration, { YAxesConfiguration } from './getChartAndTableConfiguration';
import useChartStyles from './useChartStyles';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { LegendItemProps } from '@clinintell/components/Chart/Legend';
import GenerateChartProps from '@clinintell/containers/metricsTimeSeries/logic/generateChartProps';
import { ChartDataSetAverageTypes, ChartDataSetType } from '../typings/metricChartTypes';
import { useTheme } from '@mui/material';
import generateGraphTableView from './generateGraphTableView';
import { ChartDataset, XAxisSet, YAxisSet } from '@clinintell/components/Chart/types';
import { ChartTableDefinition } from '@clinintell/components/index';
import { useMenuNavigationState } from '@clinintell/modules/store';
import { GraphData, GraphDto } from '@clinintell/api/apiSchemas';

export type UseGraphContainerProps = {
  metric: keyof typeof Metrics;
  dataset: GraphDto;
  chartPeriodType: keyof typeof ChartDataSetAverageTypes;
  chartDataSets: GraphData[];
  forOCE?: boolean;
  hideCovid?: boolean;
  historicalAvgHigher?: boolean;
  disableErrorBands?: boolean;
};

export type UseGraphContainerPropsOutput = {
  xAxis: XAxisSet;
  yAxes: YAxisSet[];
  chartDatasets: ChartDataset[];
  legendItems: LegendItemProps[];
  tableData: ChartTableDefinition;
  tableFooter: string[];
  onToggle?: (id: ChartDataSetType) => void;
  disableToggling?: boolean;
  forOCE?: boolean;
};

const useGraphContainerProps = ({
  metric,
  dataset,
  chartPeriodType,
  chartDataSets,
  forOCE = false,
  hideCovid = false,
  historicalAvgHigher = false,
  disableErrorBands = false
}: UseGraphContainerProps): UseGraphContainerPropsOutput => {
  const theme = useTheme();
  const config = getChartAndTableConfiguration(metric, forOCE);
  const chartStyles = useChartStyles(metric, historicalAvgHigher);

  const { menuItems } = useMenuNavigationState();
  const metricsMenu = menuItems.find(menu => menu.name === 'metrics' && menu.isActive);
  const metricsActive = metricsMenu ? metricsMenu.isActive : false;

  if (hideCovid) {
    config.chartDataSets = config.chartDataSets.filter(ds => ds.id !== 'COVID');
    config.dataTableDataSets = config.dataTableDataSets.filter(ds => ds.id !== 'COVID');
  }
  if (metricsActive) {
    config.chartDataSets = config.chartDataSets.filter(
      ds => !['Historical Avg.', 'Current Avg.'].includes(String(ds.label))
    );
  }
  const sortedChartDataSets: GraphData[] = [];
  // Keep the datasets in the order they are sorted by in the configuration
  config.chartDataSets.forEach(config => {
    sortedChartDataSets.push(...chartDataSets.filter(set => config.id === set.dataSetType));
  });

  let hasLineGraphDatasets = false;
  let hasBarChartDatasets = false;
  const chartDataSetStyles = sortedChartDataSets
    .filter(dataset => dataset.dataSetAverageType === chartPeriodType)
    .map(dataset => {
      // ANNOTATION: remove null/undefined as value types for dataSetType properties. dataSetType should be enum?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const styles = chartStyles[dataset.dataSetType];
      if (styles.chartType === 'Bar') {
        hasBarChartDatasets = true;
      } else if (styles.chartType === 'Line') {
        hasLineGraphDatasets = true;
      }

      // ANNOTATION: remove null/undefined as value types for dataSetType properties. dataSetType should be enum?
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return chartStyles[dataset.dataSetType];
    });

  const legendItems: LegendItemProps[] = chartDataSetStyles.map(dataset => ({
    color: dataset.color,
    type: dataset.lineType,
    label: dataset.label
  }));

  const filteredYAxes: YAxesConfiguration[] = [];
  if (hasLineGraphDatasets) {
    filteredYAxes.push(config.yAxes[0]);
  }

  if (hasBarChartDatasets && config.yAxes.length === 2) {
    filteredYAxes.push(config.yAxes[1]);
  }

  const { xAxis, yAxes, chartDatasets, hasErrorBand } = GenerateChartProps({
    chartDatasets: sortedChartDataSets,
    chartStyles: chartDataSetStyles,
    chartPeriodType,
    datasetConfigurations: config.chartDataSets,
    yAxes: filteredYAxes,
    defaultHiddenChartDatasets: config.chartDatasetsHiddenByDefault,
    forOCE
  });

  if (hasErrorBand && !disableErrorBands) {
    legendItems.push({
      color: theme.palette.chart.grey.light,
      type: 'Solid',
      label: 'Error Band'
    });
  }

  const filteredTableData = dataset.data
    ? dataset.data
        .filter(set => config.dataTableDataSets.find(dataset => dataset.id === set.dataSetType))
        .filter(set => set.dataSetAverageType === chartPeriodType)
    : [];

  const tableDataSetStyles = config.dataTableDataSets.map(dataset => chartStyles[dataset.id]);
  const tableData = generateGraphTableView(filteredTableData, metric, tableDataSetStyles, config.dataTableDataSets);
  const tableFooter = dataset.footerNotes || [];

  return {
    xAxis,
    yAxes,
    chartDatasets,
    legendItems,
    tableData,
    tableFooter,
    forOCE
  };
};

export default useGraphContainerProps;
