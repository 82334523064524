import React from 'react';
import { Box } from '@mui/material';
import { ColDef } from 'ag-grid-community/dist/lib/main';
import { sortingComparator } from '@clinintell/containers/metrics/sections/metricsAgGridTable';
import { NodeTypeIds } from '@clinintell/modules/orgTree';

const commonFieldProps = {
  suppressMenu: true,
  sort: null,
  cellRenderer: 'cellRender',
  comparator: sortingComparator
};

const baseHeaderClass = 'resizable-header column-header-style';

// Build an array of columns
const constantColumnsArray: Array<ColDef> = [
  {
    ...commonFieldProps,
    field: 'implementationDate',
    headerName: 'Implementation Date',
    headerClass: `implementationDate ${baseHeaderClass} centered-header`
  },
  {
    ...commonFieldProps,
    field: 'docScore',
    headerName: 'Doc Score - Implementation',
    headerClass: `docScore ${baseHeaderClass} centered-header`
  },
  {
    ...commonFieldProps,
    field: 'docScoreGoal',
    headerName: 'Doc Score - Goal',
    headerClass: `docScoreGoal ${baseHeaderClass} centered-header`
  },
  {
    ...commonFieldProps,
    field: 'status',
    headerName: 'Status',
    headerClass: `status ${baseHeaderClass} centered-header`,
    resizable: false,
    headerComponentParams: {
      tooltipMessage: (
        <Box>
          <p>Met: The Doc Score – Implementation is at or above Doc Score – Goal</p>
          <p>Near: The Doc Score – Implementation is within the margin of error of Doc Score – Goal</p>
          <p>Below: The Doc Score – Implementation is significantly lower than Doc Score – Goal</p>
        </Box>
      ),
      align: 'center'
    }
  }
];

export const tableColumnDefinition = (entityTableType: NodeTypeIds): Array<ColDef> => {
  switch (entityTableType) {
    case NodeTypeIds.Entity:
      return [
        {
          ...commonFieldProps,
          field: 'hospitalName',
          headerName: 'Hospital',
          headerClass: `hospitalName ${baseHeaderClass} left-header`,
          cellStyle: {
            textAlign: 'left'
          },
          headerComponentParams: {
            align: 'flex-start'
          },
          width: 450
        },
        {
          ...commonFieldProps,
          field: 'physicianGroupName',
          headerName: 'Physician Group',
          headerClass: `physicianGroup ${baseHeaderClass} left-header`,
          cellStyle: {
            textAlign: 'left'
          }
        },
        ...constantColumnsArray
      ];
    case NodeTypeIds.Hospital:
      return [
        {
          ...commonFieldProps,
          field: 'physicianGroupName',
          headerName: 'Physician Group',
          headerClass: `physicianGroup ${baseHeaderClass} left-header`,
          cellStyle: {
            textAlign: 'left'
          },
          headerComponentParams: {
            align: 'flex-start'
          },
          width: 450
        },
        ...constantColumnsArray
      ];
    case NodeTypeIds.SpecialtyGroup:
      return [
        {
          ...commonFieldProps,
          field: 'providerName',
          headerName: 'Provider Name',
          headerClass: `providerName ${baseHeaderClass} left-header`,
          cellStyle: {
            textAlign: 'left'
          },
          headerComponentParams: {
            align: 'flex-start'
          },
          width: 450
        },
        ...constantColumnsArray
      ];
    default:
      return constantColumnsArray;
  }
};
