import React from 'react';
import DoughnutChart from '@clinintell/components/Chart/DoughnutChart';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatToDecimalPlaces, roundDecimal } from '@clinintell/utils/formatting';

type DashboardDoughnutChartProp = {
  chartData: number[];
  chartLabels: string[];
  labelMsg: string;
  onClick?: () => void;
  testId: string;
};

const useStyles = makeStyles(theme => ({
  ChartGraphContainer: {
    display: 'grid',
    width: '100%',
    height: 'fit-content',
    margin: '0 auto'
  },
  ChartTextContainer: {
    gridArea: '1/1/2/2',
    display: 'flex',
    alignItems: 'flex-end',
    alignSelf: 'center',
    width: '105px',
    height: '60px',
    margin: '0 auto',
    marginTop: '.4rem',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    zIndex: 5
  },
  ChartText: {
    color: theme.palette.teal.light4,
    margin: '0 auto'
  },
  ChartLegend: {
    display: 'grid',
    gridTemplateColumns: '0fr 1fr 0fr',
    gridGap: '3px',
    width: 'fit-content',
    paddingTop: '30px',
    margin: '0 auto',
    fontWeight: 'bold',
    fontSize: '10px',
    '&>*:nth-child(1)': {
      textAlign: 'end',
      color: theme.palette.teal.light5
    },
    '&>*:nth-child(2)': {
      color: theme.palette.grey[500],
      padding: '0 3px',
      '&::before': {
        content: '"| "'
      },
      '&::after': {
        content: '" |"'
      }
    },
    '&>*:nth-child(3)': {
      color: theme.palette.red[500]
    }
  }
}));

const DashboardDoughnutChart: React.FC<DashboardDoughnutChartProp> = ({
  chartData,
  chartLabels,
  labelMsg,
  onClick,
  testId
}) => {
  const theme = useTheme();
  const { ChartGraphContainer, ChartTextContainer, ChartText, ChartLegend } = useStyles(theme);
  const formattedChartData = chartData.map(num => parseFloat(roundDecimal(num, 0).toString()));
  return (
    <>
      <Box className={ChartGraphContainer}>
        <DoughnutChart
          chartData={formattedChartData}
          chartBackGroundColor={[theme.palette.teal.light3, theme.palette.grey[200], theme.palette.red[300]]}
          labels={chartLabels}
          enableTooltip={true}
          styles={{ gridArea: '1/1/2/2', zIndex: 10, width: '165px', height: '165px', margin: '0 auto' }}
          onClick={onClick}
          innerDoughnutPercentage={formatToDecimalPlaces(chartData[0], 0) + '%'}
          innerDoughnutStats={labelMsg}
          testId={testId}
        />
        <Box className={ChartTextContainer}>
          <Box className={ChartText}>
            <Typography fontSize={'23px'} fontWeight={'bold'} textAlign={'center'}>
              {formatToDecimalPlaces(chartData[0], 0) + '%'}
            </Typography>
            <Typography fontSize={'10px'} fontWeight={'bold'} textAlign={'center'}>
              {labelMsg}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={ChartLegend}>
        {chartLabels.map((label, index) => {
          return (
            <Typography variant="p2" key={label.concat('_' + index)}>
              {label}
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default DashboardDoughnutChart;
