import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDashboard2 } from '@clinintell/modules/store';
import AgGrid from '@clinintell/components/agGrid';
import * as Cells from '@clinintell/components/agGrid/cells';
import { Row } from '@clinintell/components/agGrid/tablesTypes';
import { ColDef, ICellRendererParams } from 'ag-grid-community/dist/lib/main';
import { HospitalsWithSystem } from '@clinintell/modules/dashboard2';
import { MetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import { Timeline } from '@mui/icons-material';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import { formatToDecimalPlaces, formatNumberWithCommas, parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { CMIDocScoreType } from './SeverityOpportunity';
import Footnotes from '@clinintell/components/Footnotes';

type Props = {
  onGraphClick: (orgId: number, name: string) => void;
};

const SeverityOpportunityModalTable: React.FC<Props> = ({ onGraphClick }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { severityOpportunityWidget, entity, groupName, dashboardDefaultDates } = useDashboard2();
  const { modalTableData } = severityOpportunityWidget;
  const { nodeTypeId } = entity as HospitalsWithSystem;

  const commonFieldProps = {
    suppressMenu: true,
    sort: null,
    cellRenderer: 'cellRender'
  };
  const baseHeaderClass = 'resizable-header column-header-style';
  const columnDefs: ColDef[] = [
    {
      ...commonFieldProps,
      field: 'name',
      headerName: nodeTypeId === 1 ? 'Hospital' : groupName === 'All' ? 'Physician Group' : 'Physician Name',
      headerClass: `name ${baseHeaderClass} left-header`,
      cellStyle: {
        textAlign: 'left'
      },
      headerComponentParams: {
        align: 'flex-start'
      },
      minWidth: isMobileView ? 200 : 400
    },
    {
      ...commonFieldProps,
      field: 'action',
      headerName: 'Action',
      headerClass: `action ${baseHeaderClass} centered-header`,
      checkboxSelection: false,
      headerCheckboxSelection: false,
      sortable: false,
      minWidth: 90,
      maxWidth: 90
    },
    {
      ...commonFieldProps,
      field: 'docScore',
      headerName: 'CMI Doc Score',
      headerClass: `docScore ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'gapVsCe',
      headerName: 'Gap',
      headerClass: `gap ${baseHeaderClass} centered-header`,
      headerComponentParams: {
        tooltipMessage: (
          <Box>
            <p>
              Gap is the unreported MS-DRG severity relative to full available MS-DRG severity in the unique patient
              mix.
            </p>
          </Box>
        )
      }
    },
    {
      ...commonFieldProps,
      field: 'cases',
      headerName: 'Cases',
      headerClass: `cases ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'opportunityRW',
      headerName: 'Opportunity',
      headerClass: `opportunity ${baseHeaderClass} centered-header`,
      headerComponentParams: {
        tooltipMessage: (
          <Box>
            <p>Opportunity in Relative Weight points is calculated by multiplying the Gap and Cases.</p>
            <p>
              Please note that the calculation uses the full precision Gap and not the displayed Gap which is rounded.
            </p>
            <p>For example, if the full precision Gap is 0.04935, then 0.05 would be displayed.</p>
          </Box>
        )
      }
    }
  ].filter(col => (isMobileView ? !['docScore', 'gapVsCe', 'cases'].includes(col.field) : true));

  const { severityOpportunityData, docScoreData } = modalTableData as CMIDocScoreType;

  const agGridRows = severityOpportunityData.map(
    (row): Row => {
      const currentDocScoreRecord = docScoreData.find(rec => rec.orgId === row.orgId) as MetricRecordJSON;
      return {
        name: {
          rawValue: row.name,
          component: <Box>{row.name}</Box>
        },
        action: {
          rawValue: row.orgId,
          component: (
            <Box
              onClick={(): void => onGraphClick(row.orgId, row.name)}
              onTouchEnd={(): void => onGraphClick(row.orgId, row.name)}
              sx={{ marginTop: 1, cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <Tooltip content="View Graph" position="right">
                <Timeline />
              </Tooltip>
            </Box>
          )
        },
        docScore: {
          rawValue: currentDocScoreRecord.current,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {Number(formatToDecimalPlaces(currentDocScoreRecord.current, 2)).toFixed(0)}%
            </Box>
          )
        },
        gapVsCe: {
          rawValue: row.gapVsCe,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {formatToDecimalPlaces(row.gapVsCe, 2)}
            </Box>
          )
        },
        cases: {
          rawValue: row.cases,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {formatNumberWithCommas(row.cases)}
            </Box>
          )
        },
        opportunityRW: {
          rawValue: row.opportunityRW,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {formatNumberWithCommas(row.opportunityRW)}
            </Box>
          )
        }
      };
    }
  );

  const getRenderCells = (params: ICellRendererParams): unknown => {
    const { key, rawValue, rowId, component } = params.value;

    return {
      [key]: key === 'action' ? Cells['ActionCell'] : Cells['DefaultCell'],
      restProps: { rawValue },
      value: rawValue,
      key,
      rowId,
      component
    };
  };

  const footnote = [
    `Period: ${parseDateIntoPeriod(
      new Date(dashboardDefaultDates ? dashboardDefaultDates.currentStart : '')
    )}-${parseDateIntoPeriod(new Date(dashboardDefaultDates ? dashboardDefaultDates.currentEnd : ''))}`
  ];

  return (
    <Box>
      <Typography variant={'h5'} style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
        Performance Table
      </Typography>
      <Box mt={2}>
        <AgGrid
          headerHeight={38}
          getRenderCells={getRenderCells}
          rowData={agGridRows}
          columnDefs={columnDefs}
          rowsPerPage={10}
          tableFinalHeight={10 * 38 + 128}
          defaultColDef={{
            sortable: false,
            resizable: true,
            flex: 1,
            sortingOrder: ['asc', 'desc']
          }}
        />
      </Box>
      <Box mt={1} ml={-2}>
        <Footnotes footnotes={footnote} />
      </Box>
    </Box>
  );
};

export default SeverityOpportunityModalTable;
