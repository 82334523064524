/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type AuthGetIdentitiesQueryParams = {
  /**
   * @format email
   */
  email: string;
};

export type AuthGetIdentitiesHeaders = {
  ['x-api-version']?: string;
};

export type AuthGetIdentitiesError = Fetcher.ErrorWrapper<undefined>;

export type AuthGetIdentitiesResponse = Schemas.AuthIdentityDto[];

export type AuthGetIdentitiesVariables = {
  headers?: AuthGetIdentitiesHeaders;
  queryParams: AuthGetIdentitiesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchAuthGetIdentities = (variables: AuthGetIdentitiesVariables, signal?: AbortSignal) =>
  apiFetch<
    AuthGetIdentitiesResponse,
    AuthGetIdentitiesError,
    undefined,
    AuthGetIdentitiesHeaders,
    AuthGetIdentitiesQueryParams,
    {}
  >({ url: '/api/Auth/identities', method: 'get', ...variables, signal });

export const useAuthGetIdentities = <TData = AuthGetIdentitiesResponse>(
  variables: AuthGetIdentitiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AuthGetIdentitiesResponse, AuthGetIdentitiesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<AuthGetIdentitiesResponse, AuthGetIdentitiesError, TData>(
    queryKeyFn({ path: '/api/Auth/identities', operationId: 'authGetIdentities', variables }),
    ({ signal }) => fetchAuthGetIdentities({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type AuthGetUserHeaders = {
  ['x-api-version']?: string;
};

export type AuthGetUserError = Fetcher.ErrorWrapper<undefined>;

export type AuthGetUserVariables = {
  headers?: AuthGetUserHeaders;
} & ApiContext['fetcherOptions'];

/**
 * For provider users, the providerDetails will be populated with org details of the hospital, group and provider
 */
export const fetchAuthGetUser = (variables: AuthGetUserVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.AuthorizedUserDto, AuthGetUserError, undefined, AuthGetUserHeaders, {}, {}>({
    url: '/api/Auth/user',
    method: 'get',
    ...variables,
    signal
  });

/**
 * For provider users, the providerDetails will be populated with org details of the hospital, group and provider
 */
export const useAuthGetUser = <TData = Schemas.AuthorizedUserDto>(
  variables: AuthGetUserVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.AuthorizedUserDto, AuthGetUserError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.AuthorizedUserDto, AuthGetUserError, TData>(
    queryKeyFn({ path: '/api/Auth/user', operationId: 'authGetUser', variables }),
    ({ signal }) => fetchAuthGetUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CIUniversityGetLearningGroupsHeaders = {
  ['x-api-version']?: string;
};

export type CIUniversityGetLearningGroupsError = Fetcher.ErrorWrapper<undefined>;

export type CIUniversityGetLearningGroupsResponse = Schemas.LearnUponApiLearningGroup[];

export type CIUniversityGetLearningGroupsVariables = {
  headers?: CIUniversityGetLearningGroupsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchCIUniversityGetLearningGroups = (
  variables: CIUniversityGetLearningGroupsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    CIUniversityGetLearningGroupsResponse,
    CIUniversityGetLearningGroupsError,
    undefined,
    CIUniversityGetLearningGroupsHeaders,
    {},
    {}
  >({ url: '/api/CIUniversity/groups', method: 'get', ...variables, signal });

export const useCIUniversityGetLearningGroups = <TData = CIUniversityGetLearningGroupsResponse>(
  variables: CIUniversityGetLearningGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<CIUniversityGetLearningGroupsResponse, CIUniversityGetLearningGroupsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<CIUniversityGetLearningGroupsResponse, CIUniversityGetLearningGroupsError, TData>(
    queryKeyFn({ path: '/api/CIUniversity/groups', operationId: 'cIUniversityGetLearningGroups', variables }),
    ({ signal }) => fetchCIUniversityGetLearningGroups({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CIUniversityAddMembersToGroupPathParams = {
  /**
   * the learning group ID
   *
   * @format int32
   */
  groupId: number;
};

export type CIUniversityAddMembersToGroupHeaders = {
  ['x-api-version']?: string;
};

export type CIUniversityAddMembersToGroupError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type CIUniversityAddMembersToGroupVariables = {
  body?: Schemas.ClinIntellUniversityAddMembersToGroupDto;
  headers?: CIUniversityAddMembersToGroupHeaders;
  pathParams: CIUniversityAddMembersToGroupPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCIUniversityAddMembersToGroup = (
  variables: CIUniversityAddMembersToGroupVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    CIUniversityAddMembersToGroupError,
    Schemas.ClinIntellUniversityAddMembersToGroupDto,
    CIUniversityAddMembersToGroupHeaders,
    {},
    CIUniversityAddMembersToGroupPathParams
  >({ url: '/api/CIUniversity/groups/{groupId}/members', method: 'post', ...variables, signal });

export const useCIUniversityAddMembersToGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CIUniversityAddMembersToGroupError,
      CIUniversityAddMembersToGroupVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, CIUniversityAddMembersToGroupError, CIUniversityAddMembersToGroupVariables>(
    (variables: CIUniversityAddMembersToGroupVariables) =>
      fetchCIUniversityAddMembersToGroup({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CmiAnalysisGetCmiAnalysisWidgetPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type CmiAnalysisGetCmiAnalysisWidgetHeaders = {
  ['x-api-version']?: string;
};

export type CmiAnalysisGetCmiAnalysisWidgetError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type CmiAnalysisGetCmiAnalysisWidgetVariables = {
  headers?: CmiAnalysisGetCmiAnalysisWidgetHeaders;
  pathParams: CmiAnalysisGetCmiAnalysisWidgetPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCmiAnalysisGetCmiAnalysisWidget = (
  variables: CmiAnalysisGetCmiAnalysisWidgetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisGetCmiAnalysisWidgetError,
    undefined,
    CmiAnalysisGetCmiAnalysisWidgetHeaders,
    {},
    CmiAnalysisGetCmiAnalysisWidgetPathParams
  >({ url: '/api/reports/CmiAnalysis/widgetdates/{orgId}', method: 'get', ...variables, signal });

export const useCmiAnalysisGetCmiAnalysisWidget = <TData = Schemas.DomainModelOrgCmiAnalysisDates>(
  variables: CmiAnalysisGetCmiAnalysisWidgetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetCmiAnalysisWidgetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetCmiAnalysisWidgetError, TData>(
    queryKeyFn({
      path: '/api/reports/CmiAnalysis/widgetdates/{orgId}',
      operationId: 'cmiAnalysisGetCmiAnalysisWidget',
      variables
    }),
    ({ signal }) => fetchCmiAnalysisGetCmiAnalysisWidget({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CmiAnalysisGetDatesPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type CmiAnalysisGetDatesQueryParams = {
  bestFit?: Schemas.DomainServiceBestFitType;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type CmiAnalysisGetDatesHeaders = {
  ['x-api-version']?: string;
};

export type CmiAnalysisGetDatesError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: string;
}>;

export type CmiAnalysisGetDatesVariables = {
  headers?: CmiAnalysisGetDatesHeaders;
  pathParams: CmiAnalysisGetDatesPathParams;
  queryParams?: CmiAnalysisGetDatesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchCmiAnalysisGetDates = (variables: CmiAnalysisGetDatesVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisGetDatesError,
    undefined,
    CmiAnalysisGetDatesHeaders,
    CmiAnalysisGetDatesQueryParams,
    CmiAnalysisGetDatesPathParams
  >({ url: '/api/reports/CmiAnalysis/dates/{orgId}', method: 'get', ...variables, signal });

export const useCmiAnalysisGetDates = <TData = Schemas.DomainModelOrgCmiAnalysisDates>(
  variables: CmiAnalysisGetDatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetDatesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetDatesError, TData>(
    queryKeyFn({ path: '/api/reports/CmiAnalysis/dates/{orgId}', operationId: 'cmiAnalysisGetDates', variables }),
    ({ signal }) => fetchCmiAnalysisGetDates({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CmiAnalysisSaveDatesPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type CmiAnalysisSaveDatesHeaders = {
  ['x-api-version']?: string;
};

export type CmiAnalysisSaveDatesError = Fetcher.ErrorWrapper<
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type CmiAnalysisSaveDatesVariables = {
  body: Schemas.CmiAnalysisDatesRequestDto;
  headers?: CmiAnalysisSaveDatesHeaders;
  pathParams: CmiAnalysisSaveDatesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCmiAnalysisSaveDates = (variables: CmiAnalysisSaveDatesVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisSaveDatesError,
    Schemas.CmiAnalysisDatesRequestDto,
    CmiAnalysisSaveDatesHeaders,
    {},
    CmiAnalysisSaveDatesPathParams
  >({ url: '/api/reports/CmiAnalysis/dates/{orgId}', method: 'put', ...variables, signal });

export const useCmiAnalysisSaveDates = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DomainModelOrgCmiAnalysisDates,
      CmiAnalysisSaveDatesError,
      CmiAnalysisSaveDatesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisSaveDatesError,
    CmiAnalysisSaveDatesVariables
  >(
    (variables: CmiAnalysisSaveDatesVariables) => fetchCmiAnalysisSaveDates({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ConditionsGetQueryParams = {
  /**
   * @format int32
   */
  orgId?: number;
};

export type ConditionsGetHeaders = {
  ['x-api-version']?: string;
};

export type ConditionsGetError = Fetcher.ErrorWrapper<undefined>;

export type ConditionsGetResponse = Schemas.ConditionDto[];

export type ConditionsGetVariables = {
  headers?: ConditionsGetHeaders;
  queryParams?: ConditionsGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchConditionsGet = (variables: ConditionsGetVariables, signal?: AbortSignal) =>
  apiFetch<ConditionsGetResponse, ConditionsGetError, undefined, ConditionsGetHeaders, ConditionsGetQueryParams, {}>({
    url: '/api/Conditions',
    method: 'get',
    ...variables,
    signal
  });

export const useConditionsGet = <TData = ConditionsGetResponse>(
  variables: ConditionsGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<ConditionsGetResponse, ConditionsGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<ConditionsGetResponse, ConditionsGetError, TData>(
    queryKeyFn({ path: '/api/Conditions', operationId: 'conditionsGet', variables }),
    ({ signal }) => fetchConditionsGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ConditionsGetIcd10CodesPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ConditionsGetIcd10CodesHeaders = {
  ['x-api-version']?: string;
};

export type ConditionsGetIcd10CodesError = Fetcher.ErrorWrapper<undefined>;

export type ConditionsGetIcd10CodesVariables = {
  headers?: ConditionsGetIcd10CodesHeaders;
  pathParams: ConditionsGetIcd10CodesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchConditionsGetIcd10Codes = (variables: ConditionsGetIcd10CodesVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.Icd10CodeConditionDto,
    ConditionsGetIcd10CodesError,
    undefined,
    ConditionsGetIcd10CodesHeaders,
    {},
    ConditionsGetIcd10CodesPathParams
  >({ url: '/api/Conditions/icd10codes/{id}', method: 'get', ...variables, signal });

export const useConditionsGetIcd10Codes = <TData = Schemas.Icd10CodeConditionDto>(
  variables: ConditionsGetIcd10CodesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Icd10CodeConditionDto, ConditionsGetIcd10CodesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Icd10CodeConditionDto, ConditionsGetIcd10CodesError, TData>(
    queryKeyFn({ path: '/api/Conditions/icd10codes/{id}', operationId: 'conditionsGetIcd10Codes', variables }),
    ({ signal }) => fetchConditionsGetIcd10Codes({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ConditionsGetIcd10CodesDownloadPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ConditionsGetIcd10CodesDownloadHeaders = {
  ['x-api-version']?: string;
};

export type ConditionsGetIcd10CodesDownloadError = Fetcher.ErrorWrapper<undefined>;

export type ConditionsGetIcd10CodesDownloadVariables = {
  headers?: ConditionsGetIcd10CodesDownloadHeaders;
  pathParams: ConditionsGetIcd10CodesDownloadPathParams;
} & ApiContext['fetcherOptions'];

export const fetchConditionsGetIcd10CodesDownload = (
  variables: ConditionsGetIcd10CodesDownloadVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ConditionsGetIcd10CodesDownloadError,
    undefined,
    ConditionsGetIcd10CodesDownloadHeaders,
    {},
    ConditionsGetIcd10CodesDownloadPathParams
  >({ url: '/api/Conditions/icd10codes/download/{id}', method: 'get', ...variables, signal });

export const useConditionsGetIcd10CodesDownload = <TData = undefined>(
  variables: ConditionsGetIcd10CodesDownloadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ConditionsGetIcd10CodesDownloadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ConditionsGetIcd10CodesDownloadError, TData>(
    queryKeyFn({
      path: '/api/Conditions/icd10codes/download/{id}',
      operationId: 'conditionsGetIcd10CodesDownload',
      variables
    }),
    ({ signal }) => fetchConditionsGetIcd10CodesDownload({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DashboardDeletePathParams = {
  /**
   * Current User ID
   *
   * @format int32
   */
  userId: number;
};

export type DashboardDeleteHeaders = {
  ['x-api-version']?: string;
};

export type DashboardDeleteError = Fetcher.ErrorWrapper<undefined>;

export type DashboardDeleteVariables = {
  headers?: DashboardDeleteHeaders;
  pathParams: DashboardDeletePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDashboardDelete = (variables: DashboardDeleteVariables, signal?: AbortSignal) =>
  apiFetch<undefined, DashboardDeleteError, undefined, DashboardDeleteHeaders, {}, DashboardDeletePathParams>({
    url: '/api/Dashboard/{userId}',
    method: 'delete',
    ...variables,
    signal
  });

export const useDashboardDelete = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, DashboardDeleteError, DashboardDeleteVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DashboardDeleteError, DashboardDeleteVariables>(
    (variables: DashboardDeleteVariables) => fetchDashboardDelete({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DashboardGetPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type DashboardGetHeaders = {
  ['x-api-version']?: string;
};

export type DashboardGetError = Fetcher.ErrorWrapper<undefined>;

export type DashboardGetVariables = {
  headers?: DashboardGetHeaders;
  pathParams: DashboardGetPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDashboardGet = (variables: DashboardGetVariables, signal?: AbortSignal) =>
  apiFetch<undefined, DashboardGetError, undefined, DashboardGetHeaders, {}, DashboardGetPathParams>({
    url: '/api/Dashboard/{userId}',
    method: 'get',
    ...variables,
    signal
  });

export const useDashboardGet = <TData = undefined>(
  variables: DashboardGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, DashboardGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DashboardGetError, TData>(
    queryKeyFn({ path: '/api/Dashboard/{userId}', operationId: 'dashboardGet', variables }),
    ({ signal }) => fetchDashboardGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DashboardUpdatePathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type DashboardUpdateHeaders = {
  ['x-api-version']?: string;
};

export type DashboardUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DashboardUpdateRequestBody = Schemas.Widget[];

export type DashboardUpdateVariables = {
  body?: DashboardUpdateRequestBody;
  headers?: DashboardUpdateHeaders;
  pathParams: DashboardUpdatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDashboardUpdate = (variables: DashboardUpdateVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DashboardUpdateError,
    DashboardUpdateRequestBody,
    DashboardUpdateHeaders,
    {},
    DashboardUpdatePathParams
  >({ url: '/api/Dashboard/{userId}', method: 'put', ...variables, signal });

export const useDashboardUpdate = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, DashboardUpdateError, DashboardUpdateVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DashboardUpdateError, DashboardUpdateVariables>(
    (variables: DashboardUpdateVariables) => fetchDashboardUpdate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DefaultDatesGetDefaultDatesPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DefaultDatesGetDefaultDatesHeaders = {
  ['x-api-version']?: string;
};

export type DefaultDatesGetDefaultDatesError = Fetcher.ErrorWrapper<undefined>;

export type DefaultDatesGetDefaultDatesVariables = {
  headers?: DefaultDatesGetDefaultDatesHeaders;
  pathParams: DefaultDatesGetDefaultDatesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDefaultDatesGetDefaultDates = (
  variables: DefaultDatesGetDefaultDatesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DatesDto,
    DefaultDatesGetDefaultDatesError,
    undefined,
    DefaultDatesGetDefaultDatesHeaders,
    {},
    DefaultDatesGetDefaultDatesPathParams
  >({ url: '/api/DefaultDates/{orgId}', method: 'get', ...variables, signal });

export const useDefaultDatesGetDefaultDates = <TData = Schemas.DatesDto>(
  variables: DefaultDatesGetDefaultDatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DatesDto, DefaultDatesGetDefaultDatesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DatesDto, DefaultDatesGetDefaultDatesError, TData>(
    queryKeyFn({ path: '/api/DefaultDates/{orgId}', operationId: 'defaultDatesGetDefaultDates', variables }),
    ({ signal }) => fetchDefaultDatesGetDefaultDates({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetAllDocScoreGoalsHistoryHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetAllDocScoreGoalsHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetAllDocScoreGoalsHistoryResponse = Schemas.TargetDocScoreGoalResponseDto[];

export type DocScoreGoalGetAllDocScoreGoalsHistoryVariables = {
  headers?: DocScoreGoalGetAllDocScoreGoalsHistoryHeaders;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetAllDocScoreGoalsHistory = (
  variables: DocScoreGoalGetAllDocScoreGoalsHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DocScoreGoalGetAllDocScoreGoalsHistoryResponse,
    DocScoreGoalGetAllDocScoreGoalsHistoryError,
    undefined,
    DocScoreGoalGetAllDocScoreGoalsHistoryHeaders,
    {},
    {}
  >({ url: '/api/DocScoreGoal/history', method: 'get', ...variables, signal });

export const useDocScoreGoalGetAllDocScoreGoalsHistory = <TData = DocScoreGoalGetAllDocScoreGoalsHistoryResponse>(
  variables: DocScoreGoalGetAllDocScoreGoalsHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocScoreGoalGetAllDocScoreGoalsHistoryResponse,
      DocScoreGoalGetAllDocScoreGoalsHistoryError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    DocScoreGoalGetAllDocScoreGoalsHistoryResponse,
    DocScoreGoalGetAllDocScoreGoalsHistoryError,
    TData
  >(
    queryKeyFn({ path: '/api/DocScoreGoal/history', operationId: 'docScoreGoalGetAllDocScoreGoalsHistory', variables }),
    ({ signal }) => fetchDocScoreGoalGetAllDocScoreGoalsHistory({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetAllDocScoreGoalsHistoryExcelQueryParams = {
  status?: string;
};

export type DocScoreGoalGetAllDocScoreGoalsHistoryExcelHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetAllDocScoreGoalsHistoryExcelError = Fetcher.ErrorWrapper<undefined>;

export type DocScoreGoalGetAllDocScoreGoalsHistoryExcelVariables = {
  headers?: DocScoreGoalGetAllDocScoreGoalsHistoryExcelHeaders;
  queryParams?: DocScoreGoalGetAllDocScoreGoalsHistoryExcelQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetAllDocScoreGoalsHistoryExcel = (
  variables: DocScoreGoalGetAllDocScoreGoalsHistoryExcelVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DocScoreGoalGetAllDocScoreGoalsHistoryExcelError,
    undefined,
    DocScoreGoalGetAllDocScoreGoalsHistoryExcelHeaders,
    DocScoreGoalGetAllDocScoreGoalsHistoryExcelQueryParams,
    {}
  >({ url: '/api/DocScoreGoal/history/download', method: 'get', ...variables, signal });

export const useDocScoreGoalGetAllDocScoreGoalsHistoryExcel = <TData = undefined>(
  variables: DocScoreGoalGetAllDocScoreGoalsHistoryExcelVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DocScoreGoalGetAllDocScoreGoalsHistoryExcelError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DocScoreGoalGetAllDocScoreGoalsHistoryExcelError, TData>(
    queryKeyFn({
      path: '/api/DocScoreGoal/history/download',
      operationId: 'docScoreGoalGetAllDocScoreGoalsHistoryExcel',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetAllDocScoreGoalsHistoryExcel({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetDocScoreGoalsForHospitalPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
};

export type DocScoreGoalGetDocScoreGoalsForHospitalQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type DocScoreGoalGetDocScoreGoalsForHospitalHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetDocScoreGoalsForHospitalError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetDocScoreGoalsForHospitalResponse = Schemas.PhysicianGroupImprovementGoalsDto[];

export type DocScoreGoalGetDocScoreGoalsForHospitalVariables = {
  headers?: DocScoreGoalGetDocScoreGoalsForHospitalHeaders;
  pathParams: DocScoreGoalGetDocScoreGoalsForHospitalPathParams;
  queryParams?: DocScoreGoalGetDocScoreGoalsForHospitalQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetDocScoreGoalsForHospital = (
  variables: DocScoreGoalGetDocScoreGoalsForHospitalVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DocScoreGoalGetDocScoreGoalsForHospitalResponse,
    DocScoreGoalGetDocScoreGoalsForHospitalError,
    undefined,
    DocScoreGoalGetDocScoreGoalsForHospitalHeaders,
    DocScoreGoalGetDocScoreGoalsForHospitalQueryParams,
    DocScoreGoalGetDocScoreGoalsForHospitalPathParams
  >({ url: '/api/DocScoreGoal/{hospitalOrgHierarchyId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetDocScoreGoalsForHospital = <TData = DocScoreGoalGetDocScoreGoalsForHospitalResponse>(
  variables: DocScoreGoalGetDocScoreGoalsForHospitalVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocScoreGoalGetDocScoreGoalsForHospitalResponse,
      DocScoreGoalGetDocScoreGoalsForHospitalError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    DocScoreGoalGetDocScoreGoalsForHospitalResponse,
    DocScoreGoalGetDocScoreGoalsForHospitalError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}',
      operationId: 'docScoreGoalGetDocScoreGoalsForHospital',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetDocScoreGoalsForHospital({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format double
   * @default 0
   */
  groupTotalAddressableOpportunityRW?: number;
  /**
   * @default false
   */
  useSavedTargetConds?: boolean;
  targetCondIds?: string;
  /**
   * @format double
   * @default 0
   */
  adjustedTargetDocScore?: number;
};

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupVariables = {
  headers?: DocScoreGoalGetDocScoreGoalsForPhysicianGroupHeaders;
  pathParams: DocScoreGoalGetDocScoreGoalsForPhysicianGroupPathParams;
  queryParams?: DocScoreGoalGetDocScoreGoalsForPhysicianGroupQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetDocScoreGoalsForPhysicianGroup = (
  variables: DocScoreGoalGetDocScoreGoalsForPhysicianGroupVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PhysicianGroupImprovementGoalsDto,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupError,
    undefined,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupHeaders,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupQueryParams,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupPathParams
  >({
    url: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}',
    method: 'get',
    ...variables,
    signal
  });

export const useDocScoreGoalGetDocScoreGoalsForPhysicianGroup = <TData = Schemas.PhysicianGroupImprovementGoalsDto>(
  variables: DocScoreGoalGetDocScoreGoalsForPhysicianGroupVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PhysicianGroupImprovementGoalsDto,
      DocScoreGoalGetDocScoreGoalsForPhysicianGroupError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PhysicianGroupImprovementGoalsDto,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}',
      operationId: 'docScoreGoalGetDocScoreGoalsForPhysicianGroup',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetDocScoreGoalsForPhysicianGroup({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupsQueryParams = {
  csvPhysicianGroupOrgHierarchyIds?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format double
   * @default 0
   */
  groupTotalAddressableOpportunityRW?: number;
  /**
   * @default false
   */
  useSavedTargetConds?: boolean;
  targetCondIds?: string;
  /**
   * @format double
   * @default 0
   */
  adjustedTargetDocScore?: number;
};

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupsHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetDocScoreGoalsForPhysicianGroupsVariables = {
  headers?: DocScoreGoalGetDocScoreGoalsForPhysicianGroupsHeaders;
  queryParams?: DocScoreGoalGetDocScoreGoalsForPhysicianGroupsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetDocScoreGoalsForPhysicianGroups = (
  variables: DocScoreGoalGetDocScoreGoalsForPhysicianGroupsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PhysicianGroupImprovementGoalsDto,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupsError,
    undefined,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupsHeaders,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupsQueryParams,
    {}
  >({ url: '/api/DocScoreGoal', method: 'get', ...variables, signal });

export const useDocScoreGoalGetDocScoreGoalsForPhysicianGroups = <TData = Schemas.PhysicianGroupImprovementGoalsDto>(
  variables: DocScoreGoalGetDocScoreGoalsForPhysicianGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PhysicianGroupImprovementGoalsDto,
      DocScoreGoalGetDocScoreGoalsForPhysicianGroupsError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PhysicianGroupImprovementGoalsDto,
    DocScoreGoalGetDocScoreGoalsForPhysicianGroupsError,
    TData
  >(
    queryKeyFn({ path: '/api/DocScoreGoal', operationId: 'docScoreGoalGetDocScoreGoalsForPhysicianGroups', variables }),
    ({ signal }) => fetchDocScoreGoalGetDocScoreGoalsForPhysicianGroups({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalAddDocScoreGoalsQueryParams = {
  /**
   * @default false
   */
  isBulkSave?: boolean;
};

export type DocScoreGoalAddDocScoreGoalsHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalAddDocScoreGoalsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalAddDocScoreGoalsVariables = {
  body: Schemas.NewTargetDocScoreGoalRequestDto;
  headers?: DocScoreGoalAddDocScoreGoalsHeaders;
  queryParams?: DocScoreGoalAddDocScoreGoalsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalAddDocScoreGoals = (
  variables: DocScoreGoalAddDocScoreGoalsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    number,
    DocScoreGoalAddDocScoreGoalsError,
    Schemas.NewTargetDocScoreGoalRequestDto,
    DocScoreGoalAddDocScoreGoalsHeaders,
    DocScoreGoalAddDocScoreGoalsQueryParams,
    {}
  >({ url: '/api/DocScoreGoal', method: 'post', ...variables, signal });

export const useDocScoreGoalAddDocScoreGoals = (
  options?: Omit<
    reactQuery.UseMutationOptions<number, DocScoreGoalAddDocScoreGoalsError, DocScoreGoalAddDocScoreGoalsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<number, DocScoreGoalAddDocScoreGoalsError, DocScoreGoalAddDocScoreGoalsVariables>(
    (variables: DocScoreGoalAddDocScoreGoalsVariables) =>
      fetchDocScoreGoalAddDocScoreGoals({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DocScoreGoalSaveDocScoreGoalsHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalSaveDocScoreGoalsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalSaveDocScoreGoalsVariables = {
  body: Schemas.UpdateTargetDocScoreGoalRequestDto;
  headers?: DocScoreGoalSaveDocScoreGoalsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalSaveDocScoreGoals = (
  variables: DocScoreGoalSaveDocScoreGoalsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DocScoreGoalSaveDocScoreGoalsError,
    Schemas.UpdateTargetDocScoreGoalRequestDto,
    DocScoreGoalSaveDocScoreGoalsHeaders,
    {},
    {}
  >({ url: '/api/DocScoreGoal', method: 'put', ...variables, signal });

export const useDocScoreGoalSaveDocScoreGoals = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DocScoreGoalSaveDocScoreGoalsError,
      DocScoreGoalSaveDocScoreGoalsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DocScoreGoalSaveDocScoreGoalsError, DocScoreGoalSaveDocScoreGoalsVariables>(
    (variables: DocScoreGoalSaveDocScoreGoalsVariables) =>
      fetchDocScoreGoalSaveDocScoreGoals({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DocScoreGoalGetDocScoreGoalsForSystemQueryParams = {
  systemsSpecialtyGroupName?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type DocScoreGoalGetDocScoreGoalsForSystemHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetDocScoreGoalsForSystemError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetDocScoreGoalsForSystemResponse = Schemas.PhysicianGroupImprovementGoalsDto[];

export type DocScoreGoalGetDocScoreGoalsForSystemVariables = {
  headers?: DocScoreGoalGetDocScoreGoalsForSystemHeaders;
  queryParams?: DocScoreGoalGetDocScoreGoalsForSystemQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetDocScoreGoalsForSystem = (
  variables: DocScoreGoalGetDocScoreGoalsForSystemVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DocScoreGoalGetDocScoreGoalsForSystemResponse,
    DocScoreGoalGetDocScoreGoalsForSystemError,
    undefined,
    DocScoreGoalGetDocScoreGoalsForSystemHeaders,
    DocScoreGoalGetDocScoreGoalsForSystemQueryParams,
    {}
  >({ url: '/api/DocScoreGoal/systemsspecialtygroup', method: 'get', ...variables, signal });

export const useDocScoreGoalGetDocScoreGoalsForSystem = <TData = DocScoreGoalGetDocScoreGoalsForSystemResponse>(
  variables: DocScoreGoalGetDocScoreGoalsForSystemVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocScoreGoalGetDocScoreGoalsForSystemResponse,
      DocScoreGoalGetDocScoreGoalsForSystemError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    DocScoreGoalGetDocScoreGoalsForSystemResponse,
    DocScoreGoalGetDocScoreGoalsForSystemError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/systemsspecialtygroup',
      operationId: 'docScoreGoalGetDocScoreGoalsForSystem',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetDocScoreGoalsForSystem({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetDocScoreGoalsHistoryPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type DocScoreGoalGetDocScoreGoalsHistoryHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetDocScoreGoalsHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetDocScoreGoalsHistoryResponse = Schemas.TargetDocScoreGoalResponseDto[];

export type DocScoreGoalGetDocScoreGoalsHistoryVariables = {
  headers?: DocScoreGoalGetDocScoreGoalsHistoryHeaders;
  pathParams: DocScoreGoalGetDocScoreGoalsHistoryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetDocScoreGoalsHistory = (
  variables: DocScoreGoalGetDocScoreGoalsHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DocScoreGoalGetDocScoreGoalsHistoryResponse,
    DocScoreGoalGetDocScoreGoalsHistoryError,
    undefined,
    DocScoreGoalGetDocScoreGoalsHistoryHeaders,
    {},
    DocScoreGoalGetDocScoreGoalsHistoryPathParams
  >({
    url: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}/history',
    method: 'get',
    ...variables,
    signal
  });

export const useDocScoreGoalGetDocScoreGoalsHistory = <TData = DocScoreGoalGetDocScoreGoalsHistoryResponse>(
  variables: DocScoreGoalGetDocScoreGoalsHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocScoreGoalGetDocScoreGoalsHistoryResponse,
      DocScoreGoalGetDocScoreGoalsHistoryError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    DocScoreGoalGetDocScoreGoalsHistoryResponse,
    DocScoreGoalGetDocScoreGoalsHistoryError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}/history',
      operationId: 'docScoreGoalGetDocScoreGoalsHistory',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetDocScoreGoalsHistory({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetSmartGoalsModalPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetSmartGoalsModalHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetSmartGoalsModalError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetSmartGoalsModalVariables = {
  headers?: DocScoreGoalGetSmartGoalsModalHeaders;
  pathParams: DocScoreGoalGetSmartGoalsModalPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetSmartGoalsModal = (
  variables: DocScoreGoalGetSmartGoalsModalVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DocScoreGoalModalDto,
    DocScoreGoalGetSmartGoalsModalError,
    undefined,
    DocScoreGoalGetSmartGoalsModalHeaders,
    {},
    DocScoreGoalGetSmartGoalsModalPathParams
  >({ url: '/api/DocScoreGoal/smartgoalsmodal/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetSmartGoalsModal = <TData = Schemas.DocScoreGoalModalDto>(
  variables: DocScoreGoalGetSmartGoalsModalVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DocScoreGoalModalDto, DocScoreGoalGetSmartGoalsModalError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DocScoreGoalModalDto, DocScoreGoalGetSmartGoalsModalError, TData>(
    queryKeyFn({
      path: '/api/DocScoreGoal/smartgoalsmodal/{orgId}',
      operationId: 'docScoreGoalGetSmartGoalsModal',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetSmartGoalsModal({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetSmartGoalsWidgetPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetSmartGoalsWidgetHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetSmartGoalsWidgetError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetSmartGoalsWidgetVariables = {
  headers?: DocScoreGoalGetSmartGoalsWidgetHeaders;
  pathParams: DocScoreGoalGetSmartGoalsWidgetPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetSmartGoalsWidget = (
  variables: DocScoreGoalGetSmartGoalsWidgetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DocScoreGoalPieChartDto,
    DocScoreGoalGetSmartGoalsWidgetError,
    undefined,
    DocScoreGoalGetSmartGoalsWidgetHeaders,
    {},
    DocScoreGoalGetSmartGoalsWidgetPathParams
  >({ url: '/api/DocScoreGoal/smartgoalswidget/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetSmartGoalsWidget = <TData = Schemas.DocScoreGoalPieChartDto>(
  variables: DocScoreGoalGetSmartGoalsWidgetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DocScoreGoalPieChartDto, DocScoreGoalGetSmartGoalsWidgetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DocScoreGoalPieChartDto, DocScoreGoalGetSmartGoalsWidgetError, TData>(
    queryKeyFn({
      path: '/api/DocScoreGoal/smartgoalswidget/{orgId}',
      operationId: 'docScoreGoalGetSmartGoalsWidget',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetSmartGoalsWidget({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetTargetedConditionsPerformanceTablePathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetTargetedConditionsPerformanceTableHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetTargetedConditionsPerformanceTableError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetTargetedConditionsPerformanceTableVariables = {
  headers?: DocScoreGoalGetTargetedConditionsPerformanceTableHeaders;
  pathParams: DocScoreGoalGetTargetedConditionsPerformanceTablePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetTargetedConditionsPerformanceTable = (
  variables: DocScoreGoalGetTargetedConditionsPerformanceTableVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DashboardTargetedConditionsPerfTableDto,
    DocScoreGoalGetTargetedConditionsPerformanceTableError,
    undefined,
    DocScoreGoalGetTargetedConditionsPerformanceTableHeaders,
    {},
    DocScoreGoalGetTargetedConditionsPerformanceTablePathParams
  >({ url: '/api/DocScoreGoal/targetedconditionstable/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetTargetedConditionsPerformanceTable = <
  TData = Schemas.DashboardTargetedConditionsPerfTableDto
>(
  variables: DocScoreGoalGetTargetedConditionsPerformanceTableVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DashboardTargetedConditionsPerfTableDto,
      DocScoreGoalGetTargetedConditionsPerformanceTableError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.DashboardTargetedConditionsPerfTableDto,
    DocScoreGoalGetTargetedConditionsPerformanceTableError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/targetedconditionstable/{orgId}',
      operationId: 'docScoreGoalGetTargetedConditionsPerformanceTable',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetTargetedConditionsPerformanceTable({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetTargetedConditionsPerformanceWidgetPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetTargetedConditionsPerformanceWidgetHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetTargetedConditionsPerformanceWidgetError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetTargetedConditionsPerformanceWidgetVariables = {
  headers?: DocScoreGoalGetTargetedConditionsPerformanceWidgetHeaders;
  pathParams: DocScoreGoalGetTargetedConditionsPerformanceWidgetPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetTargetedConditionsPerformanceWidget = (
  variables: DocScoreGoalGetTargetedConditionsPerformanceWidgetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DocScoreGoalPieChartDto,
    DocScoreGoalGetTargetedConditionsPerformanceWidgetError,
    undefined,
    DocScoreGoalGetTargetedConditionsPerformanceWidgetHeaders,
    {},
    DocScoreGoalGetTargetedConditionsPerformanceWidgetPathParams
  >({ url: '/api/DocScoreGoal/targetedconditionswidget/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetTargetedConditionsPerformanceWidget = <TData = Schemas.DocScoreGoalPieChartDto>(
  variables: DocScoreGoalGetTargetedConditionsPerformanceWidgetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DocScoreGoalPieChartDto,
      DocScoreGoalGetTargetedConditionsPerformanceWidgetError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.DocScoreGoalPieChartDto,
    DocScoreGoalGetTargetedConditionsPerformanceWidgetError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/targetedconditionswidget/{orgId}',
      operationId: 'docScoreGoalGetTargetedConditionsPerformanceWidget',
      variables
    }),
    ({ signal }) =>
      fetchDocScoreGoalGetTargetedConditionsPerformanceWidget({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalDeleteDocScoreGoalPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DocScoreGoalDeleteDocScoreGoalHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalDeleteDocScoreGoalError = Fetcher.ErrorWrapper<undefined>;

export type DocScoreGoalDeleteDocScoreGoalVariables = {
  headers?: DocScoreGoalDeleteDocScoreGoalHeaders;
  pathParams: DocScoreGoalDeleteDocScoreGoalPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalDeleteDocScoreGoal = (
  variables: DocScoreGoalDeleteDocScoreGoalVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DocScoreGoalDeleteDocScoreGoalError,
    undefined,
    DocScoreGoalDeleteDocScoreGoalHeaders,
    {},
    DocScoreGoalDeleteDocScoreGoalPathParams
  >({ url: '/api/DocScoreGoal/{id}', method: 'delete', ...variables, signal });

export const useDocScoreGoalDeleteDocScoreGoal = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DocScoreGoalDeleteDocScoreGoalError,
      DocScoreGoalDeleteDocScoreGoalVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DocScoreGoalDeleteDocScoreGoalError,
    DocScoreGoalDeleteDocScoreGoalVariables
  >(
    (variables: DocScoreGoalDeleteDocScoreGoalVariables) =>
      fetchDocScoreGoalDeleteDocScoreGoal({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FeaturesGetAllFeaturesHeaders = {
  ['x-api-version']?: string;
};

export type FeaturesGetAllFeaturesError = Fetcher.ErrorWrapper<undefined>;

export type FeaturesGetAllFeaturesVariables = {
  headers?: FeaturesGetAllFeaturesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchFeaturesGetAllFeatures = (variables: FeaturesGetAllFeaturesVariables, signal?: AbortSignal) =>
  apiFetch<undefined, FeaturesGetAllFeaturesError, undefined, FeaturesGetAllFeaturesHeaders, {}, {}>({
    url: '/api/Features',
    method: 'get',
    ...variables,
    signal
  });

export const useFeaturesGetAllFeatures = <TData = undefined>(
  variables: FeaturesGetAllFeaturesVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, FeaturesGetAllFeaturesError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, FeaturesGetAllFeaturesError, TData>(
    queryKeyFn({ path: '/api/Features', operationId: 'featuresGetAllFeatures', variables }),
    ({ signal }) => fetchFeaturesGetAllFeatures({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type FeaturesGetFeaturePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type FeaturesGetFeatureHeaders = {
  ['x-api-version']?: string;
};

export type FeaturesGetFeatureError = Fetcher.ErrorWrapper<undefined>;

export type FeaturesGetFeatureVariables = {
  headers?: FeaturesGetFeatureHeaders;
  pathParams: FeaturesGetFeaturePathParams;
} & ApiContext['fetcherOptions'];

export const fetchFeaturesGetFeature = (variables: FeaturesGetFeatureVariables, signal?: AbortSignal) =>
  apiFetch<undefined, FeaturesGetFeatureError, undefined, FeaturesGetFeatureHeaders, {}, FeaturesGetFeaturePathParams>({
    url: '/api/Features/{id}',
    method: 'get',
    ...variables,
    signal
  });

export const useFeaturesGetFeature = <TData = undefined>(
  variables: FeaturesGetFeatureVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, FeaturesGetFeatureError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, FeaturesGetFeatureError, TData>(
    queryKeyFn({ path: '/api/Features/{id}', operationId: 'featuresGetFeature', variables }),
    ({ signal }) => fetchFeaturesGetFeature({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type FeaturesUpdateFeaturePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type FeaturesUpdateFeatureHeaders = {
  ['x-api-version']?: string;
};

export type FeaturesUpdateFeatureError = Fetcher.ErrorWrapper<undefined>;

export type FeaturesUpdateFeatureVariables = {
  body?: Schemas.FeatureDto;
  headers?: FeaturesUpdateFeatureHeaders;
  pathParams: FeaturesUpdateFeaturePathParams;
} & ApiContext['fetcherOptions'];

export const fetchFeaturesUpdateFeature = (variables: FeaturesUpdateFeatureVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    FeaturesUpdateFeatureError,
    Schemas.FeatureDto,
    FeaturesUpdateFeatureHeaders,
    {},
    FeaturesUpdateFeaturePathParams
  >({ url: '/api/Features/{id}', method: 'put', ...variables, signal });

export const useFeaturesUpdateFeature = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, FeaturesUpdateFeatureError, FeaturesUpdateFeatureVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, FeaturesUpdateFeatureError, FeaturesUpdateFeatureVariables>(
    (variables: FeaturesUpdateFeatureVariables) => fetchFeaturesUpdateFeature({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FinancialTrackerGetTrackerQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  preserveFormulas?: boolean;
  /**
   * @default true
   */
  useDefaultDates?: boolean;
};

export type FinancialTrackerGetTrackerHeaders = {
  ['x-api-version']?: string;
};

export type FinancialTrackerGetTrackerError = Fetcher.ErrorWrapper<undefined>;

export type FinancialTrackerGetTrackerVariables = {
  headers?: FinancialTrackerGetTrackerHeaders;
  queryParams?: FinancialTrackerGetTrackerQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchFinancialTrackerGetTracker = (variables: FinancialTrackerGetTrackerVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    FinancialTrackerGetTrackerError,
    undefined,
    FinancialTrackerGetTrackerHeaders,
    FinancialTrackerGetTrackerQueryParams,
    {}
  >({ url: '/api/financialtracker', method: 'get', ...variables, signal });

export const useFinancialTrackerGetTracker = <TData = undefined>(
  variables: FinancialTrackerGetTrackerVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, FinancialTrackerGetTrackerError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, FinancialTrackerGetTrackerError, TData>(
    queryKeyFn({ path: '/api/financialtracker', operationId: 'financialTrackerGetTracker', variables }),
    ({ signal }) => fetchFinancialTrackerGetTracker({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetCmiPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetCmiQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetCmiHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetCmiError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetCmiVariables = {
  headers?: GraphGetCmiHeaders;
  pathParams: GraphGetCmiPathParams;
  queryParams?: GraphGetCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetCmi = (variables: GraphGetCmiVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetCmiError,
    undefined,
    GraphGetCmiHeaders,
    GraphGetCmiQueryParams,
    GraphGetCmiPathParams
  >({ url: '/api/Graph/cmi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetCmi = <TData = Schemas.GraphDto>(
  variables: GraphGetCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetCmiError, TData>(
    queryKeyFn({ path: '/api/Graph/cmi/{orgId}', operationId: 'graphGetCmi', variables }),
    ({ signal }) => fetchGraphGetCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetConditionsForOrgPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetConditionsForOrgQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
};

export type GraphGetConditionsForOrgHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetConditionsForOrgError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetConditionsForOrgVariables = {
  headers?: GraphGetConditionsForOrgHeaders;
  pathParams: GraphGetConditionsForOrgPathParams;
  queryParams?: GraphGetConditionsForOrgQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetConditionsForOrg = (variables: GraphGetConditionsForOrgVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetConditionsForOrgError,
    undefined,
    GraphGetConditionsForOrgHeaders,
    GraphGetConditionsForOrgQueryParams,
    GraphGetConditionsForOrgPathParams
  >({ url: '/api/Graph/conditions/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetConditionsForOrg = <TData = Schemas.GraphDto>(
  variables: GraphGetConditionsForOrgVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetConditionsForOrgError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetConditionsForOrgError, TData>(
    queryKeyFn({ path: '/api/Graph/conditions/{orgId}', operationId: 'graphGetConditionsForOrg', variables }),
    ({ signal }) => fetchGraphGetConditionsForOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetConditionsForOrgsQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
};

export type GraphGetConditionsForOrgsHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetConditionsForOrgsError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetConditionsForOrgsVariables = {
  headers?: GraphGetConditionsForOrgsHeaders;
  queryParams?: GraphGetConditionsForOrgsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetConditionsForOrgs = (variables: GraphGetConditionsForOrgsVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetConditionsForOrgsError,
    undefined,
    GraphGetConditionsForOrgsHeaders,
    GraphGetConditionsForOrgsQueryParams,
    {}
  >({ url: '/api/Graph/conditions', method: 'get', ...variables, signal });

export const useGraphGetConditionsForOrgs = <TData = Schemas.GraphDto>(
  variables: GraphGetConditionsForOrgsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetConditionsForOrgsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetConditionsForOrgsError, TData>(
    queryKeyFn({ path: '/api/Graph/conditions', operationId: 'graphGetConditionsForOrgs', variables }),
    ({ signal }) => fetchGraphGetConditionsForOrgs({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetConditionsSmartModalPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetConditionsSmartModalQueryParams = {
  /**
   * @format date-time
   */
  historicStartDate?: string;
  /**
   * @format date-time
   */
  historicEndDate?: string;
  /**
   * @format date-time
   */
  currentStartDate?: string;
  /**
   * @format date-time
   */
  currentEndDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
};

export type GraphGetConditionsSmartModalHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetConditionsSmartModalError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetConditionsSmartModalVariables = {
  headers?: GraphGetConditionsSmartModalHeaders;
  pathParams: GraphGetConditionsSmartModalPathParams;
  queryParams?: GraphGetConditionsSmartModalQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetConditionsSmartModal = (
  variables: GraphGetConditionsSmartModalVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetConditionsSmartModalError,
    undefined,
    GraphGetConditionsSmartModalHeaders,
    GraphGetConditionsSmartModalQueryParams,
    GraphGetConditionsSmartModalPathParams
  >({ url: '/api/Graph/conditionssmartmodal/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetConditionsSmartModal = <TData = Schemas.GraphDto>(
  variables: GraphGetConditionsSmartModalVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetConditionsSmartModalError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetConditionsSmartModalError, TData>(
    queryKeyFn({
      path: '/api/Graph/conditionssmartmodal/{orgId}',
      operationId: 'graphGetConditionsSmartModal',
      variables
    }),
    ({ signal }) => fetchGraphGetConditionsSmartModal({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetDocScoreForOrgPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetDocScoreForOrgQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetDocScoreForOrgHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetDocScoreForOrgError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetDocScoreForOrgVariables = {
  headers?: GraphGetDocScoreForOrgHeaders;
  pathParams: GraphGetDocScoreForOrgPathParams;
  queryParams?: GraphGetDocScoreForOrgQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetDocScoreForOrg = (variables: GraphGetDocScoreForOrgVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetDocScoreForOrgError,
    undefined,
    GraphGetDocScoreForOrgHeaders,
    GraphGetDocScoreForOrgQueryParams,
    GraphGetDocScoreForOrgPathParams
  >({ url: '/api/Graph/docscore/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetDocScoreForOrg = <TData = Schemas.GraphDto>(
  variables: GraphGetDocScoreForOrgVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetDocScoreForOrgError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetDocScoreForOrgError, TData>(
    queryKeyFn({ path: '/api/Graph/docscore/{orgId}', operationId: 'graphGetDocScoreForOrg', variables }),
    ({ signal }) => fetchGraphGetDocScoreForOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetDocScoresForOrgsQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetDocScoresForOrgsHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetDocScoresForOrgsError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetDocScoresForOrgsVariables = {
  headers?: GraphGetDocScoresForOrgsHeaders;
  queryParams?: GraphGetDocScoresForOrgsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetDocScoresForOrgs = (variables: GraphGetDocScoresForOrgsVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetDocScoresForOrgsError,
    undefined,
    GraphGetDocScoresForOrgsHeaders,
    GraphGetDocScoresForOrgsQueryParams,
    {}
  >({ url: '/api/Graph/docscore', method: 'get', ...variables, signal });

export const useGraphGetDocScoresForOrgs = <TData = Schemas.GraphDto>(
  variables: GraphGetDocScoresForOrgsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetDocScoresForOrgsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetDocScoresForOrgsError, TData>(
    queryKeyFn({ path: '/api/Graph/docscore', operationId: 'graphGetDocScoresForOrgs', variables }),
    ({ signal }) => fetchGraphGetDocScoresForOrgs({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetElixhauserMortalityPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetElixhauserMortalityQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetElixhauserMortalityHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetElixhauserMortalityError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetElixhauserMortalityVariables = {
  headers?: GraphGetElixhauserMortalityHeaders;
  pathParams: GraphGetElixhauserMortalityPathParams;
  queryParams?: GraphGetElixhauserMortalityQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetElixhauserMortality = (
  variables: GraphGetElixhauserMortalityVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetElixhauserMortalityError,
    undefined,
    GraphGetElixhauserMortalityHeaders,
    GraphGetElixhauserMortalityQueryParams,
    GraphGetElixhauserMortalityPathParams
  >({ url: '/api/Graph/elixhausermortality/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetElixhauserMortality = <TData = Schemas.GraphDto>(
  variables: GraphGetElixhauserMortalityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetElixhauserMortalityError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetElixhauserMortalityError, TData>(
    queryKeyFn({
      path: '/api/Graph/elixhausermortality/{orgId}',
      operationId: 'graphGetElixhauserMortality',
      variables
    }),
    ({ signal }) => fetchGraphGetElixhauserMortality({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetElixhauserReadmissionPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetElixhauserReadmissionQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetElixhauserReadmissionHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetElixhauserReadmissionError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetElixhauserReadmissionVariables = {
  headers?: GraphGetElixhauserReadmissionHeaders;
  pathParams: GraphGetElixhauserReadmissionPathParams;
  queryParams?: GraphGetElixhauserReadmissionQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetElixhauserReadmission = (
  variables: GraphGetElixhauserReadmissionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetElixhauserReadmissionError,
    undefined,
    GraphGetElixhauserReadmissionHeaders,
    GraphGetElixhauserReadmissionQueryParams,
    GraphGetElixhauserReadmissionPathParams
  >({ url: '/api/Graph/elixhauserreadmission/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetElixhauserReadmission = <TData = Schemas.GraphDto>(
  variables: GraphGetElixhauserReadmissionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetElixhauserReadmissionError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetElixhauserReadmissionError, TData>(
    queryKeyFn({
      path: '/api/Graph/elixhauserreadmission/{orgId}',
      operationId: 'graphGetElixhauserReadmission',
      variables
    }),
    ({ signal }) => fetchGraphGetElixhauserReadmission({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetHcupCmiPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetHcupCmiQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetHcupCmiHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetHcupCmiError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetHcupCmiVariables = {
  headers?: GraphGetHcupCmiHeaders;
  pathParams: GraphGetHcupCmiPathParams;
  queryParams?: GraphGetHcupCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetHcupCmi = (variables: GraphGetHcupCmiVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetHcupCmiError,
    undefined,
    GraphGetHcupCmiHeaders,
    GraphGetHcupCmiQueryParams,
    GraphGetHcupCmiPathParams
  >({ url: '/api/Graph/hcupcmi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetHcupCmi = <TData = Schemas.GraphDto>(
  variables: GraphGetHcupCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetHcupCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetHcupCmiError, TData>(
    queryKeyFn({ path: '/api/Graph/hcupcmi/{orgId}', operationId: 'graphGetHcupCmi', variables }),
    ({ signal }) => fetchGraphGetHcupCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetLosPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetLosQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetLosHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetLosError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetLosVariables = {
  headers?: GraphGetLosHeaders;
  pathParams: GraphGetLosPathParams;
  queryParams?: GraphGetLosQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetLos = (variables: GraphGetLosVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetLosError,
    undefined,
    GraphGetLosHeaders,
    GraphGetLosQueryParams,
    GraphGetLosPathParams
  >({ url: '/api/Graph/los/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetLos = <TData = Schemas.GraphDto>(
  variables: GraphGetLosVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetLosError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetLosError, TData>(
    queryKeyFn({ path: '/api/Graph/los/{orgId}', operationId: 'graphGetLos', variables }),
    ({ signal }) => fetchGraphGetLos({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetPsiPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetPsiQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetPsiHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetPsiError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetPsiVariables = {
  headers?: GraphGetPsiHeaders;
  pathParams: GraphGetPsiPathParams;
  queryParams?: GraphGetPsiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetPsi = (variables: GraphGetPsiVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetPsiError,
    undefined,
    GraphGetPsiHeaders,
    GraphGetPsiQueryParams,
    GraphGetPsiPathParams
  >({ url: '/api/Graph/psi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetPsi = <TData = Schemas.GraphDto>(
  variables: GraphGetPsiVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetPsiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetPsiError, TData>(
    queryKeyFn({ path: '/api/Graph/psi/{orgId}', operationId: 'graphGetPsi', variables }),
    ({ signal }) => fetchGraphGetPsi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetRafPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetRafQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetRafHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetRafError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetRafVariables = {
  headers?: GraphGetRafHeaders;
  pathParams: GraphGetRafPathParams;
  queryParams?: GraphGetRafQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetRaf = (variables: GraphGetRafVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetRafError,
    undefined,
    GraphGetRafHeaders,
    GraphGetRafQueryParams,
    GraphGetRafPathParams
  >({ url: '/api/Graph/raf/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetRaf = <TData = Schemas.GraphDto>(
  variables: GraphGetRafVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetRafError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetRafError, TData>(
    queryKeyFn({ path: '/api/Graph/raf/{orgId}', operationId: 'graphGetRaf', variables }),
    ({ signal }) => fetchGraphGetRaf({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetSeveritycmiPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetSeveritycmiQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetSeveritycmiHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetSeveritycmiError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetSeveritycmiVariables = {
  headers?: GraphGetSeveritycmiHeaders;
  pathParams: GraphGetSeveritycmiPathParams;
  queryParams?: GraphGetSeveritycmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetSeveritycmi = (variables: GraphGetSeveritycmiVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetSeveritycmiError,
    undefined,
    GraphGetSeveritycmiHeaders,
    GraphGetSeveritycmiQueryParams,
    GraphGetSeveritycmiPathParams
  >({ url: '/api/Graph/severitycmi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetSeveritycmi = <TData = Schemas.GraphDto>(
  variables: GraphGetSeveritycmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetSeveritycmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetSeveritycmiError, TData>(
    queryKeyFn({ path: '/api/Graph/severitycmi/{orgId}', operationId: 'graphGetSeveritycmi', variables }),
    ({ signal }) => fetchGraphGetSeveritycmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetSpeedometerHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetSpeedometerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type GraphGetSpeedometerResponse = Schemas.DocScoreSpeedometerDto[];

export type GraphGetSpeedometerVariables = {
  headers?: GraphGetSpeedometerHeaders;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetSpeedometer = (variables: GraphGetSpeedometerVariables, signal?: AbortSignal) =>
  apiFetch<GraphGetSpeedometerResponse, GraphGetSpeedometerError, undefined, GraphGetSpeedometerHeaders, {}, {}>({
    url: '/api/Graph/speedometer',
    method: 'get',
    ...variables,
    signal
  });

export const useGraphGetSpeedometer = <TData = GraphGetSpeedometerResponse>(
  variables: GraphGetSpeedometerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GraphGetSpeedometerResponse, GraphGetSpeedometerError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<GraphGetSpeedometerResponse, GraphGetSpeedometerError, TData>(
    queryKeyFn({ path: '/api/Graph/speedometer', operationId: 'graphGetSpeedometer', variables }),
    ({ signal }) => fetchGraphGetSpeedometer({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphDataDownloadGetAllPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphDataDownloadGetAllQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  monthRange?: string;
  /**
   * @default CMI, LOS, SeverityCMI, DocScore, RAF
   */
  csvViewList?: string;
  /**
   * optional, which items are selected on the LOS table and any COVID inclusions. Full list
   * looks like cmiCOVID,docScoreCOVID,severityCmiCOVID,Actual,HCUPAvg,CEHCUPAvg,Gap
   *
   * @default HCUPAvg,CEHCUPAvg
   */
  metricViewList?: string;
};

export type GraphDataDownloadGetAllHeaders = {
  ['x-api-version']?: string;
};

export type GraphDataDownloadGetAllError = Fetcher.ErrorWrapper<undefined>;

export type GraphDataDownloadGetAllVariables = {
  headers?: GraphDataDownloadGetAllHeaders;
  pathParams: GraphDataDownloadGetAllPathParams;
  queryParams?: GraphDataDownloadGetAllQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphDataDownloadGetAll = (variables: GraphDataDownloadGetAllVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    GraphDataDownloadGetAllError,
    undefined,
    GraphDataDownloadGetAllHeaders,
    GraphDataDownloadGetAllQueryParams,
    GraphDataDownloadGetAllPathParams
  >({ url: '/api/Download/Graph/{orgId}', method: 'get', ...variables, signal });

export const useGraphDataDownloadGetAll = <TData = undefined>(
  variables: GraphDataDownloadGetAllVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, GraphDataDownloadGetAllError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, GraphDataDownloadGetAllError, TData>(
    queryKeyFn({ path: '/api/Download/Graph/{orgId}', operationId: 'graphDataDownloadGetAll', variables }),
    ({ signal }) => fetchGraphDataDownloadGetAll({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphDataDownloadGetConditionsPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphDataDownloadGetConditionsQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
  monthRange?: string;
  /**
   * optional, default value false
   *
   * @default false
   */
  isOtoCE?: boolean;
  /**
   * optional, which items are selected in the table (not applicable when IsOtoCE = true). Full
   * list is Secondary,CESecondary,Total,SecondarySingle,AvgImpactSingle
   *
   * @default Secondary,CESecondary
   */
  metricViewList?: string;
};

export type GraphDataDownloadGetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type GraphDataDownloadGetConditionsError = Fetcher.ErrorWrapper<undefined>;

export type GraphDataDownloadGetConditionsVariables = {
  headers?: GraphDataDownloadGetConditionsHeaders;
  pathParams: GraphDataDownloadGetConditionsPathParams;
  queryParams?: GraphDataDownloadGetConditionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphDataDownloadGetConditions = (
  variables: GraphDataDownloadGetConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    GraphDataDownloadGetConditionsError,
    undefined,
    GraphDataDownloadGetConditionsHeaders,
    GraphDataDownloadGetConditionsQueryParams,
    GraphDataDownloadGetConditionsPathParams
  >({ url: '/api/Download/Graph/conditions/{orgId}', method: 'get', ...variables, signal });

export const useGraphDataDownloadGetConditions = <TData = undefined>(
  variables: GraphDataDownloadGetConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GraphDataDownloadGetConditionsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, GraphDataDownloadGetConditionsError, TData>(
    queryKeyFn({
      path: '/api/Download/Graph/conditions/{orgId}',
      operationId: 'graphDataDownloadGetConditions',
      variables
    }),
    ({ signal }) => fetchGraphDataDownloadGetConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GroupReportGenerateMonthlyReportsPathParams = {
  /**
   * @format int32
   */
  physicianGroupId: number;
};

export type GroupReportGenerateMonthlyReportsHeaders = {
  ['x-api-version']?: string;
};

export type GroupReportGenerateMonthlyReportsError = Fetcher.ErrorWrapper<undefined>;

export type GroupReportGenerateMonthlyReportsVariables = {
  body?: Schemas.RequestMonthlyReportRequest;
  headers?: GroupReportGenerateMonthlyReportsHeaders;
  pathParams: GroupReportGenerateMonthlyReportsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGroupReportGenerateMonthlyReports = (
  variables: GroupReportGenerateMonthlyReportsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    GroupReportGenerateMonthlyReportsError,
    Schemas.RequestMonthlyReportRequest,
    GroupReportGenerateMonthlyReportsHeaders,
    {},
    GroupReportGenerateMonthlyReportsPathParams
  >({ url: '/api/reports/group/monthly/{physicianGroupId}', method: 'post', ...variables, signal });

export const useGroupReportGenerateMonthlyReports = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      GroupReportGenerateMonthlyReportsError,
      GroupReportGenerateMonthlyReportsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    GroupReportGenerateMonthlyReportsError,
    GroupReportGenerateMonthlyReportsVariables
  >(
    (variables: GroupReportGenerateMonthlyReportsVariables) =>
      fetchGroupReportGenerateMonthlyReports({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GroupReportGenerateQuarterlyReportsPathParams = {
  /**
   * @format int32
   */
  physicianGroupId: number;
};

export type GroupReportGenerateQuarterlyReportsHeaders = {
  ['x-api-version']?: string;
};

export type GroupReportGenerateQuarterlyReportsError = Fetcher.ErrorWrapper<undefined>;

export type GroupReportGenerateQuarterlyReportsVariables = {
  body?: Schemas.RequestQuarterlyReportRequest;
  headers?: GroupReportGenerateQuarterlyReportsHeaders;
  pathParams: GroupReportGenerateQuarterlyReportsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGroupReportGenerateQuarterlyReports = (
  variables: GroupReportGenerateQuarterlyReportsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    GroupReportGenerateQuarterlyReportsError,
    Schemas.RequestQuarterlyReportRequest,
    GroupReportGenerateQuarterlyReportsHeaders,
    {},
    GroupReportGenerateQuarterlyReportsPathParams
  >({ url: '/api/reports/group/quarterly/{physicianGroupId}', method: 'post', ...variables, signal });

export const useGroupReportGenerateQuarterlyReports = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      GroupReportGenerateQuarterlyReportsError,
      GroupReportGenerateQuarterlyReportsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    GroupReportGenerateQuarterlyReportsError,
    GroupReportGenerateQuarterlyReportsVariables
  >(
    (variables: GroupReportGenerateQuarterlyReportsVariables) =>
      fetchGroupReportGenerateQuarterlyReports({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GroupReportGenerateQuarterlySummaryReportsPathParams = {
  /**
   * @format int32
   */
  physicianGroupId: number;
};

export type GroupReportGenerateQuarterlySummaryReportsHeaders = {
  ['x-api-version']?: string;
};

export type GroupReportGenerateQuarterlySummaryReportsError = Fetcher.ErrorWrapper<undefined>;

export type GroupReportGenerateQuarterlySummaryReportsVariables = {
  body?: Schemas.RequestQuarterlyReportRequest;
  headers?: GroupReportGenerateQuarterlySummaryReportsHeaders;
  pathParams: GroupReportGenerateQuarterlySummaryReportsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGroupReportGenerateQuarterlySummaryReports = (
  variables: GroupReportGenerateQuarterlySummaryReportsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    GroupReportGenerateQuarterlySummaryReportsError,
    Schemas.RequestQuarterlyReportRequest,
    GroupReportGenerateQuarterlySummaryReportsHeaders,
    {},
    GroupReportGenerateQuarterlySummaryReportsPathParams
  >({ url: '/api/reports/group/quarterlysummary/{physicianGroupId}', method: 'post', ...variables, signal });

export const useGroupReportGenerateQuarterlySummaryReports = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      GroupReportGenerateQuarterlySummaryReportsError,
      GroupReportGenerateQuarterlySummaryReportsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    GroupReportGenerateQuarterlySummaryReportsError,
    GroupReportGenerateQuarterlySummaryReportsVariables
  >(
    (variables: GroupReportGenerateQuarterlySummaryReportsVariables) =>
      fetchGroupReportGenerateQuarterlySummaryReports({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GroupReportGetAllPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GroupReportGetAllHeaders = {
  ['x-api-version']?: string;
};

export type GroupReportGetAllError = Fetcher.ErrorWrapper<undefined>;

export type GroupReportGetAllVariables = {
  headers?: GroupReportGetAllHeaders;
  pathParams: GroupReportGetAllPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGroupReportGetAll = (variables: GroupReportGetAllVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GroupReportDto,
    GroupReportGetAllError,
    undefined,
    GroupReportGetAllHeaders,
    {},
    GroupReportGetAllPathParams
  >({ url: '/api/reports/group/physiciangroup/{id}', method: 'get', ...variables, signal });

export const useGroupReportGetAll = <TData = Schemas.GroupReportDto>(
  variables: GroupReportGetAllVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GroupReportDto, GroupReportGetAllError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GroupReportDto, GroupReportGetAllError, TData>(
    queryKeyFn({ path: '/api/reports/group/physiciangroup/{id}', operationId: 'groupReportGetAll', variables }),
    ({ signal }) => fetchGroupReportGetAll({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GroupReportGetReportPathParams = {
  /**
   * @format int32
   */
  reportId: number;
};

export type GroupReportGetReportHeaders = {
  ['x-api-version']?: string;
};

export type GroupReportGetReportError = Fetcher.ErrorWrapper<undefined>;

export type GroupReportGetReportVariables = {
  headers?: GroupReportGetReportHeaders;
  pathParams: GroupReportGetReportPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGroupReportGetReport = (variables: GroupReportGetReportVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    GroupReportGetReportError,
    undefined,
    GroupReportGetReportHeaders,
    {},
    GroupReportGetReportPathParams
  >({ url: '/api/reports/group/{reportId}', method: 'get', ...variables, signal });

export const useGroupReportGetReport = <TData = undefined>(
  variables: GroupReportGetReportVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, GroupReportGetReportError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, GroupReportGetReportError, TData>(
    queryKeyFn({ path: '/api/reports/group/{reportId}', operationId: 'groupReportGetReport', variables }),
    ({ signal }) => fetchGroupReportGetReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GroupReportUploadAndSendReportsHeaders = {
  ['x-api-version']?: string;
};

export type GroupReportUploadAndSendReportsError = Fetcher.ErrorWrapper<undefined>;

export type GroupReportUploadAndSendReportsRequestBody = {
  GroupReportFileList?: Blob[];
  /**
   * @format int32
   */
  PhysicianGroupId?: number;
  /**
   * @format date-time
   */
  ReportDate?: string;
  ReportTypeIds?: number[];
};

export type GroupReportUploadAndSendReportsVariables = {
  body?: GroupReportUploadAndSendReportsRequestBody;
  headers?: GroupReportUploadAndSendReportsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchGroupReportUploadAndSendReports = (
  variables: GroupReportUploadAndSendReportsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    GroupReportUploadAndSendReportsError,
    GroupReportUploadAndSendReportsRequestBody,
    GroupReportUploadAndSendReportsHeaders,
    {},
    {}
  >({ url: '/api/reports/group/UploadAndSend', method: 'post', ...variables, signal });

export const useGroupReportUploadAndSendReports = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      GroupReportUploadAndSendReportsError,
      GroupReportUploadAndSendReportsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    GroupReportUploadAndSendReportsError,
    GroupReportUploadAndSendReportsVariables
  >(
    (variables: GroupReportUploadAndSendReportsVariables) =>
      fetchGroupReportUploadAndSendReports({ ...fetcherOptions, ...variables }),
    options
  );
};

export type GroupReportUploadReportHeaders = {
  ['x-api-version']?: string;
};

export type GroupReportUploadReportError = Fetcher.ErrorWrapper<undefined>;

export type GroupReportUploadReportRequestBody = {
  /**
   * @format binary
   */
  GroupReportFile: Blob;
  /**
   * @format int32
   */
  PhysicianGroupId?: number;
  /**
   * @format date-time
   */
  ReportDate?: string;
  /**
   * @format int32
   */
  ReportTypeId?: number;
};

export type GroupReportUploadReportVariables = {
  body: GroupReportUploadReportRequestBody;
  headers?: GroupReportUploadReportHeaders;
} & ApiContext['fetcherOptions'];

export const fetchGroupReportUploadReport = (variables: GroupReportUploadReportVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    GroupReportUploadReportError,
    GroupReportUploadReportRequestBody,
    GroupReportUploadReportHeaders,
    {},
    {}
  >({ url: '/api/reports/group/Upload', method: 'post', ...variables, signal });

export const useGroupReportUploadReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, GroupReportUploadReportError, GroupReportUploadReportVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, GroupReportUploadReportError, GroupReportUploadReportVariables>(
    (variables: GroupReportUploadReportVariables) => fetchGroupReportUploadReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type HealthGetHealthHeaders = {
  ['x-api-version']?: string;
};

export type HealthGetHealthError = Fetcher.ErrorWrapper<undefined>;

export type HealthGetHealthVariables = {
  headers?: HealthGetHealthHeaders;
} & ApiContext['fetcherOptions'];

export const fetchHealthGetHealth = (variables: HealthGetHealthVariables, signal?: AbortSignal) =>
  apiFetch<undefined, HealthGetHealthError, undefined, HealthGetHealthHeaders, {}, {}>({
    url: '/api/Health',
    method: 'get',
    ...variables,
    signal
  });

export const useHealthGetHealth = <TData = undefined>(
  variables: HealthGetHealthVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, HealthGetHealthError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, HealthGetHealthError, TData>(
    queryKeyFn({ path: '/api/Health', operationId: 'healthGetHealth', variables }),
    ({ signal }) => fetchHealthGetHealth({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type HospitalsGetAllQueryParams = {
  /**
   * @format int32
   */
  ItemCount?: number;
  /**
   * @format int32
   */
  PageNumber?: number;
  Search?: string;
};

export type HospitalsGetAllHeaders = {
  ['x-api-version']?: string;
};

export type HospitalsGetAllError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type HospitalsGetAllVariables = {
  headers?: HospitalsGetAllHeaders;
  queryParams?: HospitalsGetAllQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchHospitalsGetAll = (variables: HospitalsGetAllVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelPagedHospital,
    HospitalsGetAllError,
    undefined,
    HospitalsGetAllHeaders,
    HospitalsGetAllQueryParams,
    {}
  >({ url: '/api/Hospitals', method: 'get', ...variables, signal });

export const useHospitalsGetAll = <TData = Schemas.DomainModelPagedHospital>(
  variables: HospitalsGetAllVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelPagedHospital, HospitalsGetAllError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelPagedHospital, HospitalsGetAllError, TData>(
    queryKeyFn({ path: '/api/Hospitals', operationId: 'hospitalsGetAll', variables }),
    ({ signal }) => fetchHospitalsGetAll({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type HospitalsGetBDSystemHospitalsPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type HospitalsGetBDSystemHospitalsHeaders = {
  ['x-api-version']?: string;
};

export type HospitalsGetBDSystemHospitalsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type HospitalsGetBDSystemHospitalsVariables = {
  headers?: HospitalsGetBDSystemHospitalsHeaders;
  pathParams: HospitalsGetBDSystemHospitalsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchHospitalsGetBDSystemHospitals = (
  variables: HospitalsGetBDSystemHospitalsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelPagedHospital,
    HospitalsGetBDSystemHospitalsError,
    undefined,
    HospitalsGetBDSystemHospitalsHeaders,
    {},
    HospitalsGetBDSystemHospitalsPathParams
  >({ url: '/api/Hospitals/BDSystemHospitals/{orgId}', method: 'get', ...variables, signal });

export const useHospitalsGetBDSystemHospitals = <TData = Schemas.DomainModelPagedHospital>(
  variables: HospitalsGetBDSystemHospitalsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelPagedHospital, HospitalsGetBDSystemHospitalsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelPagedHospital, HospitalsGetBDSystemHospitalsError, TData>(
    queryKeyFn({
      path: '/api/Hospitals/BDSystemHospitals/{orgId}',
      operationId: 'hospitalsGetBDSystemHospitals',
      variables
    }),
    ({ signal }) => fetchHospitalsGetBDSystemHospitals({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type HospitalsGetAllPlusSystemHeaders = {
  ['x-api-version']?: string;
};

export type HospitalsGetAllPlusSystemError = Fetcher.ErrorWrapper<undefined>;

export type HospitalsGetAllPlusSystemVariables = {
  headers?: HospitalsGetAllPlusSystemHeaders;
} & ApiContext['fetcherOptions'];

export const fetchHospitalsGetAllPlusSystem = (variables: HospitalsGetAllPlusSystemVariables, signal?: AbortSignal) =>
  apiFetch<undefined, HospitalsGetAllPlusSystemError, undefined, HospitalsGetAllPlusSystemHeaders, {}, {}>({
    url: '/api/Hospitals/hospitalsWithSystem',
    method: 'get',
    ...variables,
    signal
  });

export const useHospitalsGetAllPlusSystem = <TData = undefined>(
  variables: HospitalsGetAllPlusSystemVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, HospitalsGetAllPlusSystemError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, HospitalsGetAllPlusSystemError, TData>(
    queryKeyFn({ path: '/api/Hospitals/hospitalsWithSystem', operationId: 'hospitalsGetAllPlusSystem', variables }),
    ({ signal }) => fetchHospitalsGetAllPlusSystem({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type HospitalsGetSpecificPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type HospitalsGetSpecificQueryParams = {
  /**
   * @format int32
   * @default 0
   */
  includeAllGroups?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeHiddenSpecialtyGroups?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeOtherGroups?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeOtherMDs?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeHiddenProviders?: number;
};

export type HospitalsGetSpecificHeaders = {
  ['x-api-version']?: string;
};

export type HospitalsGetSpecificError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type HospitalsGetSpecificVariables = {
  headers?: HospitalsGetSpecificHeaders;
  pathParams: HospitalsGetSpecificPathParams;
  queryParams?: HospitalsGetSpecificQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchHospitalsGetSpecific = (variables: HospitalsGetSpecificVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelHospitalWithPhysicianGroup,
    HospitalsGetSpecificError,
    undefined,
    HospitalsGetSpecificHeaders,
    HospitalsGetSpecificQueryParams,
    HospitalsGetSpecificPathParams
  >({ url: '/api/Hospitals/{id}', method: 'get', ...variables, signal });

export const useHospitalsGetSpecific = <TData = Schemas.DomainModelHospitalWithPhysicianGroup>(
  variables: HospitalsGetSpecificVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelHospitalWithPhysicianGroup, HospitalsGetSpecificError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelHospitalWithPhysicianGroup, HospitalsGetSpecificError, TData>(
    queryKeyFn({ path: '/api/Hospitals/{id}', operationId: 'hospitalsGetSpecific', variables }),
    ({ signal }) => fetchHospitalsGetSpecific({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MdcGetAllMdcHeaders = {
  ['x-api-version']?: string;
};

export type MdcGetAllMdcError = Fetcher.ErrorWrapper<undefined>;

export type MdcGetAllMdcResponse = Schemas.DomainModelMdc[];

export type MdcGetAllMdcVariables = {
  headers?: MdcGetAllMdcHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMdcGetAllMdc = (variables: MdcGetAllMdcVariables, signal?: AbortSignal) =>
  apiFetch<MdcGetAllMdcResponse, MdcGetAllMdcError, undefined, MdcGetAllMdcHeaders, {}, {}>({
    url: '/api/Mdc',
    method: 'get',
    ...variables,
    signal
  });

export const useMdcGetAllMdc = <TData = MdcGetAllMdcResponse>(
  variables: MdcGetAllMdcVariables,
  options?: Omit<reactQuery.UseQueryOptions<MdcGetAllMdcResponse, MdcGetAllMdcError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MdcGetAllMdcResponse, MdcGetAllMdcError, TData>(
    queryKeyFn({ path: '/api/Mdc', operationId: 'mdcGetAllMdc', variables }),
    ({ signal }) => fetchMdcGetAllMdc({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MdcGetSpecificMdcPathParams = {
  /**
   * @format int32
   */
  mdcId: number;
};

export type MdcGetSpecificMdcHeaders = {
  ['x-api-version']?: string;
};

export type MdcGetSpecificMdcError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type MdcGetSpecificMdcVariables = {
  headers?: MdcGetSpecificMdcHeaders;
  pathParams: MdcGetSpecificMdcPathParams;
} & ApiContext['fetcherOptions'];

export const fetchMdcGetSpecificMdc = (variables: MdcGetSpecificMdcVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelMdc,
    MdcGetSpecificMdcError,
    undefined,
    MdcGetSpecificMdcHeaders,
    {},
    MdcGetSpecificMdcPathParams
  >({ url: '/api/Mdc/{mdcId}', method: 'get', ...variables, signal });

export const useMdcGetSpecificMdc = <TData = Schemas.DomainModelMdc>(
  variables: MdcGetSpecificMdcVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelMdc, MdcGetSpecificMdcError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelMdc, MdcGetSpecificMdcError, TData>(
    queryKeyFn({ path: '/api/Mdc/{mdcId}', operationId: 'mdcGetSpecificMdc', variables }),
    ({ signal }) => fetchMdcGetSpecificMdc({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetCmiPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetCmiQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetCmiHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetCmiError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetCmiResponse = Schemas.CmiDto[];

export type MetricsGetCmiVariables = {
  headers?: MetricsGetCmiHeaders;
  pathParams: MetricsGetCmiPathParams;
  queryParams?: MetricsGetCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetCmi = (variables: MetricsGetCmiVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetCmiResponse,
    MetricsGetCmiError,
    undefined,
    MetricsGetCmiHeaders,
    MetricsGetCmiQueryParams,
    MetricsGetCmiPathParams
  >({ url: '/api/Metrics/cmi/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetCmi = <TData = MetricsGetCmiResponse>(
  variables: MetricsGetCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<MetricsGetCmiResponse, MetricsGetCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetCmiResponse, MetricsGetCmiError, TData>(
    queryKeyFn({ path: '/api/Metrics/cmi/{orgId}', operationId: 'metricsGetCmi', variables }),
    ({ signal }) => fetchMetricsGetCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetConditionsForOrgPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetConditionsForOrgQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConds?: number;
  targetConditionIds?: string;
  /**
   * @format int32
   * @default 0
   */
  conditionId?: number;
  /**
   * @format int32
   * @default 2147483647
   */
  count?: number;
  /**
   * @default false
   */
  sortByGapVsCe?: boolean;
  /**
   * @format int32
   * @default 1
   */
  shareDrg?: number;
  /**
   * @format int32
   * @default 1
   */
  shareHcc?: number;
  /**
   * @format int32
   * @default 1
   */
  shareMortality?: number;
  /**
   * @format int32
   * @default 1
   */
  shareReadmission?: number;
  /**
   * @format int32
   * @default 1
   */
  sharePsi?: number;
  mdcIds?: string;
  conditionTypeIds?: string;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if
   * not sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetConditionsForOrgHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetConditionsForOrgError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsGetConditionsForOrgVariables = {
  headers?: MetricsGetConditionsForOrgHeaders;
  pathParams: MetricsGetConditionsForOrgPathParams;
  queryParams?: MetricsGetConditionsForOrgQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetConditionsForOrg = (variables: MetricsGetConditionsForOrgVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.ConditionsMetricDtoConditionMetric,
    MetricsGetConditionsForOrgError,
    undefined,
    MetricsGetConditionsForOrgHeaders,
    MetricsGetConditionsForOrgQueryParams,
    MetricsGetConditionsForOrgPathParams
  >({ url: '/api/Metrics/conditions/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetConditionsForOrg = <TData = Schemas.ConditionsMetricDtoConditionMetric>(
  variables: MetricsGetConditionsForOrgVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConditionsMetricDtoConditionMetric, MetricsGetConditionsForOrgError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ConditionsMetricDtoConditionMetric, MetricsGetConditionsForOrgError, TData>(
    queryKeyFn({ path: '/api/Metrics/conditions/{orgId}', operationId: 'metricsGetConditionsForOrg', variables }),
    ({ signal }) => fetchMetricsGetConditionsForOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsSaveTargetConditionsPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsSaveTargetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type MetricsSaveTargetConditionsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsSaveTargetConditionsVariables = {
  body: Schemas.TargetConditionsDto;
  headers?: MetricsSaveTargetConditionsHeaders;
  pathParams: MetricsSaveTargetConditionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsSaveTargetConditions = (
  variables: MetricsSaveTargetConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsSaveTargetConditionsError,
    Schemas.TargetConditionsDto,
    MetricsSaveTargetConditionsHeaders,
    {},
    MetricsSaveTargetConditionsPathParams
  >({ url: '/api/Metrics/conditions/{orgId}', method: 'post', ...variables, signal });

export const useMetricsSaveTargetConditions = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, MetricsSaveTargetConditionsError, MetricsSaveTargetConditionsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, MetricsSaveTargetConditionsError, MetricsSaveTargetConditionsVariables>(
    (variables: MetricsSaveTargetConditionsVariables) =>
      fetchMetricsSaveTargetConditions({ ...fetcherOptions, ...variables }),
    options
  );
};

export type MetricsGetConditionsForOrgsQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConds?: number;
  targetConditionIds?: string;
  /**
   * @format int32
   * @default 0
   */
  conditionId?: number;
  /**
   * @format int32
   * @default 2147483647
   */
  count?: number;
  /**
   * @default false
   */
  sortByGapVsCe?: boolean;
  /**
   * @format int32
   * @default 1
   */
  shareDrg?: number;
  /**
   * @format int32
   * @default 1
   */
  shareHcc?: number;
  /**
   * @format int32
   * @default 1
   */
  shareMortality?: number;
  /**
   * @format int32
   * @default 1
   */
  shareReadmission?: number;
  /**
   * @format int32
   * @default 1
   */
  sharePsi?: number;
  mdcIds?: string;
  conditionTypeIds?: string;
  /**
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetConditionsForOrgsHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetConditionsForOrgsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsGetConditionsForOrgsVariables = {
  headers?: MetricsGetConditionsForOrgsHeaders;
  queryParams?: MetricsGetConditionsForOrgsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetConditionsForOrgs = (
  variables: MetricsGetConditionsForOrgsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ConditionsMetricDtoConditionMetric,
    MetricsGetConditionsForOrgsError,
    undefined,
    MetricsGetConditionsForOrgsHeaders,
    MetricsGetConditionsForOrgsQueryParams,
    {}
  >({ url: '/api/Metrics/conditions', method: 'get', ...variables, signal });

export const useMetricsGetConditionsForOrgs = <TData = Schemas.ConditionsMetricDtoConditionMetric>(
  variables: MetricsGetConditionsForOrgsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConditionsMetricDtoConditionMetric, MetricsGetConditionsForOrgsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ConditionsMetricDtoConditionMetric, MetricsGetConditionsForOrgsError, TData>(
    queryKeyFn({ path: '/api/Metrics/conditions', operationId: 'metricsGetConditionsForOrgs', variables }),
    ({ signal }) => fetchMetricsGetConditionsForOrgs({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetDocScorePathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetDocScoreQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetDocScoreHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetDocScoreError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetDocScoreResponse = Schemas.OEMetricDto[];

export type MetricsGetDocScoreVariables = {
  headers?: MetricsGetDocScoreHeaders;
  pathParams: MetricsGetDocScorePathParams;
  queryParams?: MetricsGetDocScoreQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetDocScore = (variables: MetricsGetDocScoreVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetDocScoreResponse,
    MetricsGetDocScoreError,
    undefined,
    MetricsGetDocScoreHeaders,
    MetricsGetDocScoreQueryParams,
    MetricsGetDocScorePathParams
  >({ url: '/api/Metrics/docscore/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetDocScore = <TData = MetricsGetDocScoreResponse>(
  variables: MetricsGetDocScoreVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetDocScoreResponse, MetricsGetDocScoreError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetDocScoreResponse, MetricsGetDocScoreError, TData>(
    queryKeyFn({ path: '/api/Metrics/docscore/{orgId}', operationId: 'metricsGetDocScore', variables }),
    ({ signal }) => fetchMetricsGetDocScore({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetDrgMixPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetDrgMixQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetDrgMixHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetDrgMixError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetDrgMixVariables = {
  headers?: MetricsGetDrgMixHeaders;
  pathParams: MetricsGetDrgMixPathParams;
  queryParams?: MetricsGetDrgMixQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetDrgMix = (variables: MetricsGetDrgMixVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.PatientMixAnalysisDto,
    MetricsGetDrgMixError,
    undefined,
    MetricsGetDrgMixHeaders,
    MetricsGetDrgMixQueryParams,
    MetricsGetDrgMixPathParams
  >({ url: '/api/Metrics/drgmix/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetDrgMix = <TData = Schemas.PatientMixAnalysisDto>(
  variables: MetricsGetDrgMixVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PatientMixAnalysisDto, MetricsGetDrgMixError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.PatientMixAnalysisDto, MetricsGetDrgMixError, TData>(
    queryKeyFn({ path: '/api/Metrics/drgmix/{orgId}', operationId: 'metricsGetDrgMix', variables }),
    ({ signal }) => fetchMetricsGetDrgMix({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetDrgMixComparisonPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetDrgMixComparisonQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type MetricsGetDrgMixComparisonHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetDrgMixComparisonError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetDrgMixComparisonVariables = {
  headers?: MetricsGetDrgMixComparisonHeaders;
  pathParams: MetricsGetDrgMixComparisonPathParams;
  queryParams?: MetricsGetDrgMixComparisonQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetDrgMixComparison = (variables: MetricsGetDrgMixComparisonVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DrgMixComparisonDto,
    MetricsGetDrgMixComparisonError,
    undefined,
    MetricsGetDrgMixComparisonHeaders,
    MetricsGetDrgMixComparisonQueryParams,
    MetricsGetDrgMixComparisonPathParams
  >({ url: '/api/Metrics/drgmixcomparison/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetDrgMixComparison = <TData = Schemas.DrgMixComparisonDto>(
  variables: MetricsGetDrgMixComparisonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DrgMixComparisonDto, MetricsGetDrgMixComparisonError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DrgMixComparisonDto, MetricsGetDrgMixComparisonError, TData>(
    queryKeyFn({ path: '/api/Metrics/drgmixcomparison/{orgId}', operationId: 'metricsGetDrgMixComparison', variables }),
    ({ signal }) => fetchMetricsGetDrgMixComparison({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetElixhauserMortalityPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetElixhauserMortalityQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetElixhauserMortalityHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetElixhauserMortalityError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetElixhauserMortalityResponse = Schemas.OEMetricDto[];

export type MetricsGetElixhauserMortalityVariables = {
  headers?: MetricsGetElixhauserMortalityHeaders;
  pathParams: MetricsGetElixhauserMortalityPathParams;
  queryParams?: MetricsGetElixhauserMortalityQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetElixhauserMortality = (
  variables: MetricsGetElixhauserMortalityVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    MetricsGetElixhauserMortalityResponse,
    MetricsGetElixhauserMortalityError,
    undefined,
    MetricsGetElixhauserMortalityHeaders,
    MetricsGetElixhauserMortalityQueryParams,
    MetricsGetElixhauserMortalityPathParams
  >({ url: '/api/Metrics/elixhausermortality/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetElixhauserMortality = <TData = MetricsGetElixhauserMortalityResponse>(
  variables: MetricsGetElixhauserMortalityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetElixhauserMortalityResponse, MetricsGetElixhauserMortalityError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetElixhauserMortalityResponse, MetricsGetElixhauserMortalityError, TData>(
    queryKeyFn({
      path: '/api/Metrics/elixhausermortality/{orgId}',
      operationId: 'metricsGetElixhauserMortality',
      variables
    }),
    ({ signal }) => fetchMetricsGetElixhauserMortality({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetElixhauserReadmissionPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetElixhauserReadmissionQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetElixhauserReadmissionHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetElixhauserReadmissionError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetElixhauserReadmissionResponse = Schemas.OEMetricDto[];

export type MetricsGetElixhauserReadmissionVariables = {
  headers?: MetricsGetElixhauserReadmissionHeaders;
  pathParams: MetricsGetElixhauserReadmissionPathParams;
  queryParams?: MetricsGetElixhauserReadmissionQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetElixhauserReadmission = (
  variables: MetricsGetElixhauserReadmissionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    MetricsGetElixhauserReadmissionResponse,
    MetricsGetElixhauserReadmissionError,
    undefined,
    MetricsGetElixhauserReadmissionHeaders,
    MetricsGetElixhauserReadmissionQueryParams,
    MetricsGetElixhauserReadmissionPathParams
  >({ url: '/api/Metrics/elixhauserreadmission/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetElixhauserReadmission = <TData = MetricsGetElixhauserReadmissionResponse>(
  variables: MetricsGetElixhauserReadmissionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetElixhauserReadmissionResponse, MetricsGetElixhauserReadmissionError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetElixhauserReadmissionResponse, MetricsGetElixhauserReadmissionError, TData>(
    queryKeyFn({
      path: '/api/Metrics/elixhauserreadmission/{orgId}',
      operationId: 'metricsGetElixhauserReadmission',
      variables
    }),
    ({ signal }) => fetchMetricsGetElixhauserReadmission({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetLosPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetLosQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetLosHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetLosError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetLosResponse = Schemas.LosDto[];

export type MetricsGetLosVariables = {
  headers?: MetricsGetLosHeaders;
  pathParams: MetricsGetLosPathParams;
  queryParams?: MetricsGetLosQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetLos = (variables: MetricsGetLosVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetLosResponse,
    MetricsGetLosError,
    undefined,
    MetricsGetLosHeaders,
    MetricsGetLosQueryParams,
    MetricsGetLosPathParams
  >({ url: '/api/Metrics/los/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetLos = <TData = MetricsGetLosResponse>(
  variables: MetricsGetLosVariables,
  options?: Omit<reactQuery.UseQueryOptions<MetricsGetLosResponse, MetricsGetLosError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetLosResponse, MetricsGetLosError, TData>(
    queryKeyFn({ path: '/api/Metrics/los/{orgId}', operationId: 'metricsGetLos', variables }),
    ({ signal }) => fetchMetricsGetLos({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetOpportunityShareForOrgPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetOpportunityShareForOrgHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetOpportunityShareForOrgError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetOpportunityShareForOrgVariables = {
  headers?: MetricsGetOpportunityShareForOrgHeaders;
  pathParams: MetricsGetOpportunityShareForOrgPathParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetOpportunityShareForOrg = (
  variables: MetricsGetOpportunityShareForOrgVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelOpportunityShare,
    MetricsGetOpportunityShareForOrgError,
    undefined,
    MetricsGetOpportunityShareForOrgHeaders,
    {},
    MetricsGetOpportunityShareForOrgPathParams
  >({ url: '/api/Metrics/conditions/shares/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetOpportunityShareForOrg = <TData = Schemas.DomainModelOpportunityShare>(
  variables: MetricsGetOpportunityShareForOrgVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOpportunityShare, MetricsGetOpportunityShareForOrgError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOpportunityShare, MetricsGetOpportunityShareForOrgError, TData>(
    queryKeyFn({
      path: '/api/Metrics/conditions/shares/{orgId}',
      operationId: 'metricsGetOpportunityShareForOrg',
      variables
    }),
    ({ signal }) => fetchMetricsGetOpportunityShareForOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetOpportunityShareForSystemHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetOpportunityShareForSystemError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetOpportunityShareForSystemVariables = {
  headers?: MetricsGetOpportunityShareForSystemHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetOpportunityShareForSystem = (
  variables: MetricsGetOpportunityShareForSystemVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelOpportunityShare,
    MetricsGetOpportunityShareForSystemError,
    undefined,
    MetricsGetOpportunityShareForSystemHeaders,
    {},
    {}
  >({ url: '/api/Metrics/conditions/shares', method: 'get', ...variables, signal });

export const useMetricsGetOpportunityShareForSystem = <TData = Schemas.DomainModelOpportunityShare>(
  variables: MetricsGetOpportunityShareForSystemVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOpportunityShare, MetricsGetOpportunityShareForSystemError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOpportunityShare, MetricsGetOpportunityShareForSystemError, TData>(
    queryKeyFn({
      path: '/api/Metrics/conditions/shares',
      operationId: 'metricsGetOpportunityShareForSystem',
      variables
    }),
    ({ signal }) => fetchMetricsGetOpportunityShareForSystem({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsUpdateOpportunityShareHeaders = {
  ['x-api-version']?: string;
};

export type MetricsUpdateOpportunityShareError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsUpdateOpportunityShareVariables = {
  body: Schemas.OpportunityShareDto;
  headers?: MetricsUpdateOpportunityShareHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMetricsUpdateOpportunityShare = (
  variables: MetricsUpdateOpportunityShareVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelOpportunityShare,
    MetricsUpdateOpportunityShareError,
    Schemas.OpportunityShareDto,
    MetricsUpdateOpportunityShareHeaders,
    {},
    {}
  >({ url: '/api/Metrics/conditions/shares', method: 'put', ...variables, signal });

export const useMetricsUpdateOpportunityShare = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DomainModelOpportunityShare,
      MetricsUpdateOpportunityShareError,
      MetricsUpdateOpportunityShareVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DomainModelOpportunityShare,
    MetricsUpdateOpportunityShareError,
    MetricsUpdateOpportunityShareVariables
  >(
    (variables: MetricsUpdateOpportunityShareVariables) =>
      fetchMetricsUpdateOpportunityShare({ ...fetcherOptions, ...variables }),
    options
  );
};

export type MetricsGetOverallPerformanceQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  includeChildren?: boolean;
  /**
   * @default false
   */
  aggregateOrgIds?: boolean;
};

export type MetricsGetOverallPerformanceHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetOverallPerformanceError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetOverallPerformanceResponse = Schemas.OverallPerformanceDto[];

export type MetricsGetOverallPerformanceVariables = {
  headers?: MetricsGetOverallPerformanceHeaders;
  queryParams?: MetricsGetOverallPerformanceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetOverallPerformance = (
  variables: MetricsGetOverallPerformanceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    MetricsGetOverallPerformanceResponse,
    MetricsGetOverallPerformanceError,
    undefined,
    MetricsGetOverallPerformanceHeaders,
    MetricsGetOverallPerformanceQueryParams,
    {}
  >({ url: '/api/Metrics/overallperformance', method: 'get', ...variables, signal });

export const useMetricsGetOverallPerformance = <TData = MetricsGetOverallPerformanceResponse>(
  variables: MetricsGetOverallPerformanceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetOverallPerformanceResponse, MetricsGetOverallPerformanceError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetOverallPerformanceResponse, MetricsGetOverallPerformanceError, TData>(
    queryKeyFn({ path: '/api/Metrics/overallperformance', operationId: 'metricsGetOverallPerformance', variables }),
    ({ signal }) => fetchMetricsGetOverallPerformance({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetPsiPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetPsiQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetPsiHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetPsiError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetPsiResponse = Schemas.OEMetricDto[];

export type MetricsGetPsiVariables = {
  headers?: MetricsGetPsiHeaders;
  pathParams: MetricsGetPsiPathParams;
  queryParams?: MetricsGetPsiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetPsi = (variables: MetricsGetPsiVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetPsiResponse,
    MetricsGetPsiError,
    undefined,
    MetricsGetPsiHeaders,
    MetricsGetPsiQueryParams,
    MetricsGetPsiPathParams
  >({ url: '/api/Metrics/psi/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetPsi = <TData = MetricsGetPsiResponse>(
  variables: MetricsGetPsiVariables,
  options?: Omit<reactQuery.UseQueryOptions<MetricsGetPsiResponse, MetricsGetPsiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetPsiResponse, MetricsGetPsiError, TData>(
    queryKeyFn({ path: '/api/Metrics/psi/{orgId}', operationId: 'metricsGetPsi', variables }),
    ({ signal }) => fetchMetricsGetPsi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetPsiPiesPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetPsiPiesQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConditions?: number;
  targetConditionIds?: string;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if
   * not sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
};

export type MetricsGetPsiPiesHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetPsiPiesError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetPsiPiesResponse = Schemas.PsiIndicator[];

export type MetricsGetPsiPiesVariables = {
  headers?: MetricsGetPsiPiesHeaders;
  pathParams: MetricsGetPsiPiesPathParams;
  queryParams?: MetricsGetPsiPiesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetPsiPies = (variables: MetricsGetPsiPiesVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetPsiPiesResponse,
    MetricsGetPsiPiesError,
    undefined,
    MetricsGetPsiPiesHeaders,
    MetricsGetPsiPiesQueryParams,
    MetricsGetPsiPiesPathParams
  >({ url: '/api/Metrics/psipies/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetPsiPies = <TData = MetricsGetPsiPiesResponse>(
  variables: MetricsGetPsiPiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetPsiPiesResponse, MetricsGetPsiPiesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetPsiPiesResponse, MetricsGetPsiPiesError, TData>(
    queryKeyFn({ path: '/api/Metrics/psipies/{orgId}', operationId: 'metricsGetPsiPies', variables }),
    ({ signal }) => fetchMetricsGetPsiPies({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetRafPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetRafQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetRafHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetRafError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetRafResponse = Schemas.OEMetricDto[];

export type MetricsGetRafVariables = {
  headers?: MetricsGetRafHeaders;
  pathParams: MetricsGetRafPathParams;
  queryParams?: MetricsGetRafQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetRaf = (variables: MetricsGetRafVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetRafResponse,
    MetricsGetRafError,
    undefined,
    MetricsGetRafHeaders,
    MetricsGetRafQueryParams,
    MetricsGetRafPathParams
  >({ url: '/api/Metrics/raf/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetRaf = <TData = MetricsGetRafResponse>(
  variables: MetricsGetRafVariables,
  options?: Omit<reactQuery.UseQueryOptions<MetricsGetRafResponse, MetricsGetRafError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetRafResponse, MetricsGetRafError, TData>(
    queryKeyFn({ path: '/api/Metrics/raf/{orgId}', operationId: 'metricsGetRaf', variables }),
    ({ signal }) => fetchMetricsGetRaf({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetSeverityCmiPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetSeverityCmiQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetSeverityCmiHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetSeverityCmiError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetSeverityCmiResponse = Schemas.SeverityCmiDto[];

export type MetricsGetSeverityCmiVariables = {
  headers?: MetricsGetSeverityCmiHeaders;
  pathParams: MetricsGetSeverityCmiPathParams;
  queryParams?: MetricsGetSeverityCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetSeverityCmi = (variables: MetricsGetSeverityCmiVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetSeverityCmiResponse,
    MetricsGetSeverityCmiError,
    undefined,
    MetricsGetSeverityCmiHeaders,
    MetricsGetSeverityCmiQueryParams,
    MetricsGetSeverityCmiPathParams
  >({ url: '/api/Metrics/severitycmi/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetSeverityCmi = <TData = MetricsGetSeverityCmiResponse>(
  variables: MetricsGetSeverityCmiVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetSeverityCmiResponse, MetricsGetSeverityCmiError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetSeverityCmiResponse, MetricsGetSeverityCmiError, TData>(
    queryKeyFn({ path: '/api/Metrics/severitycmi/{orgId}', operationId: 'metricsGetSeverityCmi', variables }),
    ({ signal }) => fetchMetricsGetSeverityCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetSeverityCmiConditionImpactPathParams = {
  /**
   * org hierarchy ID
   *
   * @format int32
   */
  orgId: number;
};

export type MetricsGetSeverityCmiConditionImpactQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetSeverityCmiConditionImpactHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetSeverityCmiConditionImpactError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetSeverityCmiConditionImpactVariables = {
  headers?: MetricsGetSeverityCmiConditionImpactHeaders;
  pathParams: MetricsGetSeverityCmiConditionImpactPathParams;
  queryParams?: MetricsGetSeverityCmiConditionImpactQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetSeverityCmiConditionImpact = (
  variables: MetricsGetSeverityCmiConditionImpactVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.SeverityCmiImpactDto,
    MetricsGetSeverityCmiConditionImpactError,
    undefined,
    MetricsGetSeverityCmiConditionImpactHeaders,
    MetricsGetSeverityCmiConditionImpactQueryParams,
    MetricsGetSeverityCmiConditionImpactPathParams
  >({ url: '/api/Metrics/severityCmiConditions/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetSeverityCmiConditionImpact = <TData = Schemas.SeverityCmiImpactDto>(
  variables: MetricsGetSeverityCmiConditionImpactVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.SeverityCmiImpactDto, MetricsGetSeverityCmiConditionImpactError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.SeverityCmiImpactDto, MetricsGetSeverityCmiConditionImpactError, TData>(
    queryKeyFn({
      path: '/api/Metrics/severityCmiConditions/{orgId}',
      operationId: 'metricsGetSeverityCmiConditionImpact',
      variables
    }),
    ({ signal }) => fetchMetricsGetSeverityCmiConditionImpact({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsSeverityCmiComparisonPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsSeverityCmiComparisonQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type MetricsSeverityCmiComparisonHeaders = {
  ['x-api-version']?: string;
};

export type MetricsSeverityCmiComparisonError = Fetcher.ErrorWrapper<undefined>;

export type MetricsSeverityCmiComparisonVariables = {
  headers?: MetricsSeverityCmiComparisonHeaders;
  pathParams: MetricsSeverityCmiComparisonPathParams;
  queryParams?: MetricsSeverityCmiComparisonQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsSeverityCmiComparison = (
  variables: MetricsSeverityCmiComparisonVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.SeverityCmiComparisonDto,
    MetricsSeverityCmiComparisonError,
    undefined,
    MetricsSeverityCmiComparisonHeaders,
    MetricsSeverityCmiComparisonQueryParams,
    MetricsSeverityCmiComparisonPathParams
  >({ url: '/api/Metrics/severitycmicomparison/{orgId}', method: 'get', ...variables, signal });

export const useMetricsSeverityCmiComparison = <TData = Schemas.SeverityCmiComparisonDto>(
  variables: MetricsSeverityCmiComparisonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.SeverityCmiComparisonDto, MetricsSeverityCmiComparisonError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.SeverityCmiComparisonDto, MetricsSeverityCmiComparisonError, TData>(
    queryKeyFn({
      path: '/api/Metrics/severitycmicomparison/{orgId}',
      operationId: 'metricsSeverityCmiComparison',
      variables
    }),
    ({ signal }) => fetchMetricsSeverityCmiComparison({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsRebuildSystemViewTargetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type MetricsRebuildSystemViewTargetConditionsError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsRebuildSystemViewTargetConditionsVariables = {
  headers?: MetricsRebuildSystemViewTargetConditionsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMetricsRebuildSystemViewTargetConditions = (
  variables: MetricsRebuildSystemViewTargetConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsRebuildSystemViewTargetConditionsError,
    undefined,
    MetricsRebuildSystemViewTargetConditionsHeaders,
    {},
    {}
  >({ url: '/api/Metrics/conditions/RebuildSystemViewTargetConditions', method: 'put', ...variables, signal });

export const useMetricsRebuildSystemViewTargetConditions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      MetricsRebuildSystemViewTargetConditionsError,
      MetricsRebuildSystemViewTargetConditionsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    MetricsRebuildSystemViewTargetConditionsError,
    MetricsRebuildSystemViewTargetConditionsVariables
  >(
    (variables: MetricsRebuildSystemViewTargetConditionsVariables) =>
      fetchMetricsRebuildSystemViewTargetConditions({ ...fetcherOptions, ...variables }),
    options
  );
};

export type MetricsDataDownloadGenerateCmiAnalysisReportPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsDataDownloadGenerateCmiAnalysisReportQueryParams = {
  /**
   * Full date range for graphs start date
   *
   * @format date-time
   */
  periodStart?: string;
  /**
   * Full date range for graphs end date
   *
   * @format date-time
   */
  periodEnd?: string;
  /**
   * Historical date range start date
   *
   * @format date-time
   */
  historicalStart?: string;
  /**
   * Historical date range end date
   *
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * Current date range start date
   *
   * @format date-time
   */
  currentStart?: string;
  /**
   * Current date range end date
   *
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsDataDownloadGenerateCmiAnalysisReportHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGenerateCmiAnalysisReportError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGenerateCmiAnalysisReportVariables = {
  headers?: MetricsDataDownloadGenerateCmiAnalysisReportHeaders;
  pathParams: MetricsDataDownloadGenerateCmiAnalysisReportPathParams;
  queryParams?: MetricsDataDownloadGenerateCmiAnalysisReportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGenerateCmiAnalysisReport = (
  variables: MetricsDataDownloadGenerateCmiAnalysisReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGenerateCmiAnalysisReportError,
    undefined,
    MetricsDataDownloadGenerateCmiAnalysisReportHeaders,
    MetricsDataDownloadGenerateCmiAnalysisReportQueryParams,
    MetricsDataDownloadGenerateCmiAnalysisReportPathParams
  >({ url: '/api/Download/Metrics/patientmixanalysisreport/{orgId}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGenerateCmiAnalysisReport = <TData = undefined>(
  variables: MetricsDataDownloadGenerateCmiAnalysisReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGenerateCmiAnalysisReportError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGenerateCmiAnalysisReportError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/patientmixanalysisreport/{orgId}',
      operationId: 'metricsDataDownloadGenerateCmiAnalysisReport',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGenerateCmiAnalysisReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGenerateCmiComparisonReportPathParams = {
  /**
   * First org selected
   *
   * @format int32
   */
  orgId: number;
  /**
   * Comparison org selected
   *
   * @format int32
   */
  compareToOrgId: number;
};

export type MetricsDataDownloadGenerateCmiComparisonReportQueryParams = {
  /**
   * Full date range for graphs start date
   *
   * @format date-time
   */
  periodStart?: string;
  /**
   * Full date range for graphs end date
   *
   * @format date-time
   */
  periodEnd?: string;
  /**
   * Comparison range start date
   *
   * @format date-time
   */
  rangeStart?: string;
  /**
   * Comparison range end date
   *
   * @format date-time
   */
  rangeEnd?: string;
};

export type MetricsDataDownloadGenerateCmiComparisonReportHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGenerateCmiComparisonReportError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGenerateCmiComparisonReportVariables = {
  headers?: MetricsDataDownloadGenerateCmiComparisonReportHeaders;
  pathParams: MetricsDataDownloadGenerateCmiComparisonReportPathParams;
  queryParams?: MetricsDataDownloadGenerateCmiComparisonReportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGenerateCmiComparisonReport = (
  variables: MetricsDataDownloadGenerateCmiComparisonReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGenerateCmiComparisonReportError,
    undefined,
    MetricsDataDownloadGenerateCmiComparisonReportHeaders,
    MetricsDataDownloadGenerateCmiComparisonReportQueryParams,
    MetricsDataDownloadGenerateCmiComparisonReportPathParams
  >({ url: '/api/Download/Metrics/cmicomparisonreport/{orgId}/{compareToOrgId}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGenerateCmiComparisonReport = <TData = undefined>(
  variables: MetricsDataDownloadGenerateCmiComparisonReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGenerateCmiComparisonReportError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGenerateCmiComparisonReportError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/cmicomparisonreport/{orgId}/{compareToOrgId}',
      operationId: 'metricsDataDownloadGenerateCmiComparisonReport',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGenerateCmiComparisonReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetCmiPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetCmiQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetCmiHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetCmiError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetCmiVariables = {
  headers?: MetricsDataDownloadGetCmiHeaders;
  pathParams: MetricsDataDownloadGetCmiPathParams;
  queryParams?: MetricsDataDownloadGetCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetCmi = (variables: MetricsDataDownloadGetCmiVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetCmiError,
    undefined,
    MetricsDataDownloadGetCmiHeaders,
    MetricsDataDownloadGetCmiQueryParams,
    MetricsDataDownloadGetCmiPathParams
  >({ url: '/api/Download/Metrics/cmi/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetCmi = <TData = undefined>(
  variables: MetricsDataDownloadGetCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetCmiError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/cmi/{id}', operationId: 'metricsDataDownloadGetCmi', variables }),
    ({ signal }) => fetchMetricsDataDownloadGetCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetConditionsPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetConditionsQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
  /**
   * @format int32
   * @default 0
   */
  conditionId?: number;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConds?: number;
  targetConditionIds?: string;
  /**
   * @format int32
   * @default 1
   */
  shareDrg?: number;
  /**
   * @format int32
   * @default 1
   */
  shareHcc?: number;
  /**
   * @format int32
   * @default 1
   */
  shareMortality?: number;
  /**
   * @format int32
   * @default 1
   */
  shareReadmission?: number;
  /**
   * @format int32
   * @default 1
   */
  sharePsi?: number;
  mdcIds?: string;
  conditionTypeIds?: string;
  /**
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
};

export type MetricsDataDownloadGetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetConditionsError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetConditionsVariables = {
  headers?: MetricsDataDownloadGetConditionsHeaders;
  pathParams: MetricsDataDownloadGetConditionsPathParams;
  queryParams?: MetricsDataDownloadGetConditionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetConditions = (
  variables: MetricsDataDownloadGetConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetConditionsError,
    undefined,
    MetricsDataDownloadGetConditionsHeaders,
    MetricsDataDownloadGetConditionsQueryParams,
    MetricsDataDownloadGetConditionsPathParams
  >({ url: '/api/Download/Metrics/conditions/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetConditions = <TData = undefined>(
  variables: MetricsDataDownloadGetConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetConditionsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetConditionsError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/conditions/{id}',
      operationId: 'metricsDataDownloadGetConditions',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetDocScorePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetDocScoreQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetDocScoreHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetDocScoreError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetDocScoreVariables = {
  headers?: MetricsDataDownloadGetDocScoreHeaders;
  pathParams: MetricsDataDownloadGetDocScorePathParams;
  queryParams?: MetricsDataDownloadGetDocScoreQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetDocScore = (
  variables: MetricsDataDownloadGetDocScoreVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetDocScoreError,
    undefined,
    MetricsDataDownloadGetDocScoreHeaders,
    MetricsDataDownloadGetDocScoreQueryParams,
    MetricsDataDownloadGetDocScorePathParams
  >({ url: '/api/Download/Metrics/docscore/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetDocScore = <TData = undefined>(
  variables: MetricsDataDownloadGetDocScoreVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetDocScoreError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetDocScoreError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/docscore/{id}',
      operationId: 'metricsDataDownloadGetDocScore',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetDocScore({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetDrgMixComparisonPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetDrgMixComparisonQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type MetricsDataDownloadGetDrgMixComparisonHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetDrgMixComparisonError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetDrgMixComparisonVariables = {
  headers?: MetricsDataDownloadGetDrgMixComparisonHeaders;
  pathParams: MetricsDataDownloadGetDrgMixComparisonPathParams;
  queryParams?: MetricsDataDownloadGetDrgMixComparisonQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetDrgMixComparison = (
  variables: MetricsDataDownloadGetDrgMixComparisonVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetDrgMixComparisonError,
    undefined,
    MetricsDataDownloadGetDrgMixComparisonHeaders,
    MetricsDataDownloadGetDrgMixComparisonQueryParams,
    MetricsDataDownloadGetDrgMixComparisonPathParams
  >({ url: '/api/Download/Metrics/drgmixcomparison/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetDrgMixComparison = <TData = undefined>(
  variables: MetricsDataDownloadGetDrgMixComparisonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetDrgMixComparisonError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetDrgMixComparisonError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/drgmixcomparison/{id}',
      operationId: 'metricsDataDownloadGetDrgMixComparison',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetDrgMixComparison({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetElixhauserMortalityPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetElixhauserMortalityQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetElixhauserMortalityHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetElixhauserMortalityError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetElixhauserMortalityVariables = {
  headers?: MetricsDataDownloadGetElixhauserMortalityHeaders;
  pathParams: MetricsDataDownloadGetElixhauserMortalityPathParams;
  queryParams?: MetricsDataDownloadGetElixhauserMortalityQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetElixhauserMortality = (
  variables: MetricsDataDownloadGetElixhauserMortalityVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetElixhauserMortalityError,
    undefined,
    MetricsDataDownloadGetElixhauserMortalityHeaders,
    MetricsDataDownloadGetElixhauserMortalityQueryParams,
    MetricsDataDownloadGetElixhauserMortalityPathParams
  >({ url: '/api/Download/Metrics/elixhausermortality/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetElixhauserMortality = <TData = undefined>(
  variables: MetricsDataDownloadGetElixhauserMortalityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetElixhauserMortalityError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetElixhauserMortalityError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/elixhausermortality/{id}',
      operationId: 'metricsDataDownloadGetElixhauserMortality',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetElixhauserMortality({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetElixhauserReadmissionPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetElixhauserReadmissionQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetElixhauserReadmissionHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetElixhauserReadmissionError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetElixhauserReadmissionVariables = {
  headers?: MetricsDataDownloadGetElixhauserReadmissionHeaders;
  pathParams: MetricsDataDownloadGetElixhauserReadmissionPathParams;
  queryParams?: MetricsDataDownloadGetElixhauserReadmissionQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetElixhauserReadmission = (
  variables: MetricsDataDownloadGetElixhauserReadmissionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetElixhauserReadmissionError,
    undefined,
    MetricsDataDownloadGetElixhauserReadmissionHeaders,
    MetricsDataDownloadGetElixhauserReadmissionQueryParams,
    MetricsDataDownloadGetElixhauserReadmissionPathParams
  >({ url: '/api/Download/Metrics/elixhauserreadmission/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetElixhauserReadmission = <TData = undefined>(
  variables: MetricsDataDownloadGetElixhauserReadmissionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetElixhauserReadmissionError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetElixhauserReadmissionError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/elixhauserreadmission/{id}',
      operationId: 'metricsDataDownloadGetElixhauserReadmission',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetElixhauserReadmission({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetLosPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetLosQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetLosHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetLosError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetLosVariables = {
  headers?: MetricsDataDownloadGetLosHeaders;
  pathParams: MetricsDataDownloadGetLosPathParams;
  queryParams?: MetricsDataDownloadGetLosQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetLos = (variables: MetricsDataDownloadGetLosVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetLosError,
    undefined,
    MetricsDataDownloadGetLosHeaders,
    MetricsDataDownloadGetLosQueryParams,
    MetricsDataDownloadGetLosPathParams
  >({ url: '/api/Download/Metrics/los/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetLos = <TData = undefined>(
  variables: MetricsDataDownloadGetLosVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetLosError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetLosError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/los/{id}', operationId: 'metricsDataDownloadGetLos', variables }),
    ({ signal }) => fetchMetricsDataDownloadGetLos({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetPatientMixAnalysisPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetPatientMixAnalysisQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type MetricsDataDownloadGetPatientMixAnalysisHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetPatientMixAnalysisError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetPatientMixAnalysisVariables = {
  headers?: MetricsDataDownloadGetPatientMixAnalysisHeaders;
  pathParams: MetricsDataDownloadGetPatientMixAnalysisPathParams;
  queryParams?: MetricsDataDownloadGetPatientMixAnalysisQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetPatientMixAnalysis = (
  variables: MetricsDataDownloadGetPatientMixAnalysisVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetPatientMixAnalysisError,
    undefined,
    MetricsDataDownloadGetPatientMixAnalysisHeaders,
    MetricsDataDownloadGetPatientMixAnalysisQueryParams,
    MetricsDataDownloadGetPatientMixAnalysisPathParams
  >({ url: '/api/Download/Metrics/patientmixanalysis/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetPatientMixAnalysis = <TData = undefined>(
  variables: MetricsDataDownloadGetPatientMixAnalysisVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetPatientMixAnalysisError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetPatientMixAnalysisError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/patientmixanalysis/{id}',
      operationId: 'metricsDataDownloadGetPatientMixAnalysis',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetPatientMixAnalysis({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetPsiPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetPsiQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetPsiHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetPsiError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetPsiVariables = {
  headers?: MetricsDataDownloadGetPsiHeaders;
  pathParams: MetricsDataDownloadGetPsiPathParams;
  queryParams?: MetricsDataDownloadGetPsiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetPsi = (variables: MetricsDataDownloadGetPsiVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetPsiError,
    undefined,
    MetricsDataDownloadGetPsiHeaders,
    MetricsDataDownloadGetPsiQueryParams,
    MetricsDataDownloadGetPsiPathParams
  >({ url: '/api/Download/Metrics/psi/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetPsi = <TData = undefined>(
  variables: MetricsDataDownloadGetPsiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetPsiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetPsiError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/psi/{id}', operationId: 'metricsDataDownloadGetPsi', variables }),
    ({ signal }) => fetchMetricsDataDownloadGetPsi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetRafPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetRafQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetRafHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetRafError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetRafVariables = {
  headers?: MetricsDataDownloadGetRafHeaders;
  pathParams: MetricsDataDownloadGetRafPathParams;
  queryParams?: MetricsDataDownloadGetRafQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetRaf = (variables: MetricsDataDownloadGetRafVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetRafError,
    undefined,
    MetricsDataDownloadGetRafHeaders,
    MetricsDataDownloadGetRafQueryParams,
    MetricsDataDownloadGetRafPathParams
  >({ url: '/api/Download/Metrics/raf/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetRaf = <TData = undefined>(
  variables: MetricsDataDownloadGetRafVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetRafError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetRafError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/raf/{id}', operationId: 'metricsDataDownloadGetRaf', variables }),
    ({ signal }) => fetchMetricsDataDownloadGetRaf({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetSeverityCmiPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetSeverityCmiQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type MetricsDataDownloadGetSeverityCmiHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetSeverityCmiError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetSeverityCmiVariables = {
  headers?: MetricsDataDownloadGetSeverityCmiHeaders;
  pathParams: MetricsDataDownloadGetSeverityCmiPathParams;
  queryParams?: MetricsDataDownloadGetSeverityCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetSeverityCmi = (
  variables: MetricsDataDownloadGetSeverityCmiVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetSeverityCmiError,
    undefined,
    MetricsDataDownloadGetSeverityCmiHeaders,
    MetricsDataDownloadGetSeverityCmiQueryParams,
    MetricsDataDownloadGetSeverityCmiPathParams
  >({ url: '/api/Download/Metrics/severitycmi/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetSeverityCmi = <TData = undefined>(
  variables: MetricsDataDownloadGetSeverityCmiVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetSeverityCmiError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetSeverityCmiError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/severitycmi/{id}',
      operationId: 'metricsDataDownloadGetSeverityCmi',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetSeverityCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsDataDownloadGetSeverityCmiConditionImpactPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type MetricsDataDownloadGetSeverityCmiConditionImpactQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type MetricsDataDownloadGetSeverityCmiConditionImpactHeaders = {
  ['x-api-version']?: string;
};

export type MetricsDataDownloadGetSeverityCmiConditionImpactError = Fetcher.ErrorWrapper<undefined>;

export type MetricsDataDownloadGetSeverityCmiConditionImpactVariables = {
  headers?: MetricsDataDownloadGetSeverityCmiConditionImpactHeaders;
  pathParams: MetricsDataDownloadGetSeverityCmiConditionImpactPathParams;
  queryParams?: MetricsDataDownloadGetSeverityCmiConditionImpactQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsDataDownloadGetSeverityCmiConditionImpact = (
  variables: MetricsDataDownloadGetSeverityCmiConditionImpactVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsDataDownloadGetSeverityCmiConditionImpactError,
    undefined,
    MetricsDataDownloadGetSeverityCmiConditionImpactHeaders,
    MetricsDataDownloadGetSeverityCmiConditionImpactQueryParams,
    MetricsDataDownloadGetSeverityCmiConditionImpactPathParams
  >({ url: '/api/Download/Metrics/severityCmiConditionImpact/{id}', method: 'get', ...variables, signal });

export const useMetricsDataDownloadGetSeverityCmiConditionImpact = <TData = undefined>(
  variables: MetricsDataDownloadGetSeverityCmiConditionImpactVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, MetricsDataDownloadGetSeverityCmiConditionImpactError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, MetricsDataDownloadGetSeverityCmiConditionImpactError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/severityCmiConditionImpact/{id}',
      operationId: 'metricsDataDownloadGetSeverityCmiConditionImpact',
      variables
    }),
    ({ signal }) => fetchMetricsDataDownloadGetSeverityCmiConditionImpact({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type OrgGetQueryParams = {
  /**
   * @default false
   */
  includeLineage?: boolean;
  /**
   * @default false
   */
  includeHiddenNodes?: boolean;
  /**
   * @default true
   */
  includeOtherMds?: boolean;
};

export type OrgGetHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetVariables = {
  headers?: OrgGetHeaders;
  pathParams: OrgGetPathParams;
  queryParams?: OrgGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrgGet = (variables: OrgGetVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.OrgDto, OrgGetError, undefined, OrgGetHeaders, OrgGetQueryParams, OrgGetPathParams>({
    url: '/api/Org/{orgId}',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgGet = <TData = Schemas.OrgDto>(
  variables: OrgGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.OrgDto, OrgGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.OrgDto, OrgGetError, TData>(
    queryKeyFn({ path: '/api/Org/{orgId}', operationId: 'orgGet', variables }),
    ({ signal }) => fetchOrgGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetBDSystemOrgsHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetBDSystemOrgsError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetBDSystemOrgsResponse = Schemas.OrgDto[];

export type OrgGetBDSystemOrgsVariables = {
  headers?: OrgGetBDSystemOrgsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgGetBDSystemOrgs = (variables: OrgGetBDSystemOrgsVariables, signal?: AbortSignal) =>
  apiFetch<OrgGetBDSystemOrgsResponse, OrgGetBDSystemOrgsError, undefined, OrgGetBDSystemOrgsHeaders, {}, {}>({
    url: '/api/Org/BDSystemOrgs',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgGetBDSystemOrgs = <TData = OrgGetBDSystemOrgsResponse>(
  variables: OrgGetBDSystemOrgsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<OrgGetBDSystemOrgsResponse, OrgGetBDSystemOrgsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<OrgGetBDSystemOrgsResponse, OrgGetBDSystemOrgsError, TData>(
    queryKeyFn({ path: '/api/Org/BDSystemOrgs', operationId: 'orgGetBDSystemOrgs', variables }),
    ({ signal }) => fetchOrgGetBDSystemOrgs({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetFundamentalSpecialtyGroupsHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetFundamentalSpecialtyGroupsError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetFundamentalSpecialtyGroupsResponse = Schemas.FundamentalSpecialty[];

export type OrgGetFundamentalSpecialtyGroupsVariables = {
  headers?: OrgGetFundamentalSpecialtyGroupsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgGetFundamentalSpecialtyGroups = (
  variables: OrgGetFundamentalSpecialtyGroupsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    OrgGetFundamentalSpecialtyGroupsResponse,
    OrgGetFundamentalSpecialtyGroupsError,
    undefined,
    OrgGetFundamentalSpecialtyGroupsHeaders,
    {},
    {}
  >({ url: '/api/Org/FundamentalGroups', method: 'get', ...variables, signal });

export const useOrgGetFundamentalSpecialtyGroups = <TData = OrgGetFundamentalSpecialtyGroupsResponse>(
  variables: OrgGetFundamentalSpecialtyGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<OrgGetFundamentalSpecialtyGroupsResponse, OrgGetFundamentalSpecialtyGroupsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<OrgGetFundamentalSpecialtyGroupsResponse, OrgGetFundamentalSpecialtyGroupsError, TData>(
    queryKeyFn({ path: '/api/Org/FundamentalGroups', operationId: 'orgGetFundamentalSpecialtyGroups', variables }),
    ({ signal }) => fetchOrgGetFundamentalSpecialtyGroups({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetSystemViewPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type OrgGetSystemViewHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetSystemViewError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetSystemViewVariables = {
  headers?: OrgGetSystemViewHeaders;
  pathParams: OrgGetSystemViewPathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrgGetSystemView = (variables: OrgGetSystemViewVariables, signal?: AbortSignal) =>
  apiFetch<undefined, OrgGetSystemViewError, undefined, OrgGetSystemViewHeaders, {}, OrgGetSystemViewPathParams>({
    url: '/api/Org/SystemView/{id}',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgGetSystemView = <TData = undefined>(
  variables: OrgGetSystemViewVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, OrgGetSystemViewError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, OrgGetSystemViewError, TData>(
    queryKeyFn({ path: '/api/Org/SystemView/{id}', operationId: 'orgGetSystemView', variables }),
    ({ signal }) => fetchOrgGetSystemView({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgSearchQueryParams = {
  search?: string;
};

export type OrgSearchHeaders = {
  ['x-api-version']?: string;
};

export type OrgSearchError = Fetcher.ErrorWrapper<undefined>;

export type OrgSearchVariables = {
  headers?: OrgSearchHeaders;
  queryParams?: OrgSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrgSearch = (variables: OrgSearchVariables, signal?: AbortSignal) =>
  apiFetch<undefined, OrgSearchError, undefined, OrgSearchHeaders, OrgSearchQueryParams, {}>({
    url: '/api/Org',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgSearch = <TData = undefined>(
  variables: OrgSearchVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, OrgSearchError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, OrgSearchError, TData>(
    queryKeyFn({ path: '/api/Org', operationId: 'orgSearch', variables }),
    ({ signal }) => fetchOrgSearch({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsBulkUploadOrgChangesHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsBulkUploadOrgChangesError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsBulkUploadOrgChangesRequestBody = {
  /**
   * @format binary
   */
  BulkUploadFile?: Blob;
};

export type OrgChangeRequestsBulkUploadOrgChangesVariables = {
  body?: OrgChangeRequestsBulkUploadOrgChangesRequestBody;
  headers?: OrgChangeRequestsBulkUploadOrgChangesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsBulkUploadOrgChanges = (
  variables: OrgChangeRequestsBulkUploadOrgChangesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    OrgChangeRequestsBulkUploadOrgChangesError,
    OrgChangeRequestsBulkUploadOrgChangesRequestBody,
    OrgChangeRequestsBulkUploadOrgChangesHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/BulkUpload', method: 'put', ...variables, signal });

export const useOrgChangeRequestsBulkUploadOrgChanges = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrgChangeRequestsBulkUploadOrgChangesError,
      OrgChangeRequestsBulkUploadOrgChangesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OrgChangeRequestsBulkUploadOrgChangesError,
    OrgChangeRequestsBulkUploadOrgChangesVariables
  >(
    (variables: OrgChangeRequestsBulkUploadOrgChangesVariables) =>
      fetchOrgChangeRequestsBulkUploadOrgChanges({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrgChangeRequestsDownloadOrgHierarchySpreadsheetHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsDownloadOrgHierarchySpreadsheetError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsDownloadOrgHierarchySpreadsheetVariables = {
  headers?: OrgChangeRequestsDownloadOrgHierarchySpreadsheetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsDownloadOrgHierarchySpreadsheet = (
  variables: OrgChangeRequestsDownloadOrgHierarchySpreadsheetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    OrgChangeRequestsDownloadOrgHierarchySpreadsheetError,
    undefined,
    OrgChangeRequestsDownloadOrgHierarchySpreadsheetHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/hierarchy', method: 'get', ...variables, signal });

export const useOrgChangeRequestsDownloadOrgHierarchySpreadsheet = <TData = undefined>(
  variables: OrgChangeRequestsDownloadOrgHierarchySpreadsheetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, OrgChangeRequestsDownloadOrgHierarchySpreadsheetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, OrgChangeRequestsDownloadOrgHierarchySpreadsheetError, TData>(
    queryKeyFn({
      path: '/api/OrgChangeRequests/hierarchy',
      operationId: 'orgChangeRequestsDownloadOrgHierarchySpreadsheet',
      variables
    }),
    ({ signal }) => fetchOrgChangeRequestsDownloadOrgHierarchySpreadsheet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsAddGroupHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsAddGroupError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type OrgChangeRequestsAddGroupVariables = {
  body: Schemas.AddGroupDto;
  headers?: OrgChangeRequestsAddGroupHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsAddGroup = (variables: OrgChangeRequestsAddGroupVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.PhysicianGroupDto,
    OrgChangeRequestsAddGroupError,
    Schemas.AddGroupDto,
    OrgChangeRequestsAddGroupHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/group', method: 'post', ...variables, signal });

export const useOrgChangeRequestsAddGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PhysicianGroupDto,
      OrgChangeRequestsAddGroupError,
      OrgChangeRequestsAddGroupVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PhysicianGroupDto,
    OrgChangeRequestsAddGroupError,
    OrgChangeRequestsAddGroupVariables
  >(
    (variables: OrgChangeRequestsAddGroupVariables) =>
      fetchOrgChangeRequestsAddGroup({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrgChangeRequestsGetOrgChangeRequestQueueHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsGetOrgChangeRequestQueueError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsGetOrgChangeRequestQueueVariables = {
  headers?: OrgChangeRequestsGetOrgChangeRequestQueueHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsGetOrgChangeRequestQueue = (
  variables: OrgChangeRequestsGetOrgChangeRequestQueueVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.OrgChangeRequestOrgChangeRequestQueue,
    OrgChangeRequestsGetOrgChangeRequestQueueError,
    undefined,
    OrgChangeRequestsGetOrgChangeRequestQueueHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests', method: 'get', ...variables, signal });

export const useOrgChangeRequestsGetOrgChangeRequestQueue = <TData = Schemas.OrgChangeRequestOrgChangeRequestQueue>(
  variables: OrgChangeRequestsGetOrgChangeRequestQueueVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrgChangeRequestOrgChangeRequestQueue,
      OrgChangeRequestsGetOrgChangeRequestQueueError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrgChangeRequestOrgChangeRequestQueue,
    OrgChangeRequestsGetOrgChangeRequestQueueError,
    TData
  >(
    queryKeyFn({ path: '/api/OrgChangeRequests', operationId: 'orgChangeRequestsGetOrgChangeRequestQueue', variables }),
    ({ signal }) => fetchOrgChangeRequestsGetOrgChangeRequestQueue({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsSaveOrgChangeRequestQueueHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsSaveOrgChangeRequestQueueError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsSaveOrgChangeRequestQueueVariables = {
  body?: Schemas.OrgChangeRequestOrgChangeRequestQueue;
  headers?: OrgChangeRequestsSaveOrgChangeRequestQueueHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsSaveOrgChangeRequestQueue = (
  variables: OrgChangeRequestsSaveOrgChangeRequestQueueVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    OrgChangeRequestsSaveOrgChangeRequestQueueError,
    Schemas.OrgChangeRequestOrgChangeRequestQueue,
    OrgChangeRequestsSaveOrgChangeRequestQueueHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests', method: 'put', ...variables, signal });

export const useOrgChangeRequestsSaveOrgChangeRequestQueue = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrgChangeRequestsSaveOrgChangeRequestQueueError,
      OrgChangeRequestsSaveOrgChangeRequestQueueVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OrgChangeRequestsSaveOrgChangeRequestQueueError,
    OrgChangeRequestsSaveOrgChangeRequestQueueVariables
  >(
    (variables: OrgChangeRequestsSaveOrgChangeRequestQueueVariables) =>
      fetchOrgChangeRequestsSaveOrgChangeRequestQueue({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetVariables = {
  headers?: OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsDownloadUserOrgHierarchySpreadsheet = (
  variables: OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetError,
    undefined,
    OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/UserHierarchy', method: 'get', ...variables, signal });

export const useOrgChangeRequestsDownloadUserOrgHierarchySpreadsheet = <TData = Blob>(
  variables: OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetError, TData>(
    queryKeyFn({
      path: '/api/OrgChangeRequests/UserHierarchy',
      operationId: 'orgChangeRequestsDownloadUserOrgHierarchySpreadsheet',
      variables
    }),
    ({ signal }) =>
      fetchOrgChangeRequestsDownloadUserOrgHierarchySpreadsheet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetVariables = {
  headers?: OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheet = (
  variables: OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetError,
    undefined,
    OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/template', method: 'get', ...variables, signal });

export const useOrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheet = <TData = Blob>(
  variables: OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetError, TData>(
    queryKeyFn({
      path: '/api/OrgChangeRequests/template',
      operationId: 'orgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheet',
      variables
    }),
    ({ signal }) =>
      fetchOrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsDownloadOrgChangeRequestHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsDownloadOrgChangeRequestError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsDownloadOrgChangeRequestVariables = {
  headers?: OrgChangeRequestsDownloadOrgChangeRequestHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsDownloadOrgChangeRequest = (
  variables: OrgChangeRequestsDownloadOrgChangeRequestVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    OrgChangeRequestsDownloadOrgChangeRequestError,
    undefined,
    OrgChangeRequestsDownloadOrgChangeRequestHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/history', method: 'get', ...variables, signal });

export const useOrgChangeRequestsDownloadOrgChangeRequest = <TData = Blob>(
  variables: OrgChangeRequestsDownloadOrgChangeRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, OrgChangeRequestsDownloadOrgChangeRequestError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, OrgChangeRequestsDownloadOrgChangeRequestError, TData>(
    queryKeyFn({
      path: '/api/OrgChangeRequests/history',
      operationId: 'orgChangeRequestsDownloadOrgChangeRequest',
      variables
    }),
    ({ signal }) => fetchOrgChangeRequestsDownloadOrgChangeRequest({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PhysicianGroupsGetPhysicianGroupsQueryParams = {
  /**
   * @format int32
   */
  hospitalId?: number;
  specialtyGroupName?: string;
  /**
   * @default true
   */
  excludeHiddenSpecialtyGroups?: boolean;
  /**
   * @default true
   */
  excludeOtherGroups?: boolean;
  /**
   * @default false
   */
  excludeOtherMDs?: boolean;
  /**
   * @default false
   */
  excludeHiddenProviders?: boolean;
  /**
   * @default false
   */
  mustHavePerfData?: boolean;
};

export type PhysicianGroupsGetPhysicianGroupsHeaders = {
  ['x-api-version']?: string;
};

export type PhysicianGroupsGetPhysicianGroupsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type PhysicianGroupsGetPhysicianGroupsVariables = {
  headers?: PhysicianGroupsGetPhysicianGroupsHeaders;
  queryParams?: PhysicianGroupsGetPhysicianGroupsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPhysicianGroupsGetPhysicianGroups = (
  variables: PhysicianGroupsGetPhysicianGroupsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PhysicianGroupDto,
    PhysicianGroupsGetPhysicianGroupsError,
    undefined,
    PhysicianGroupsGetPhysicianGroupsHeaders,
    PhysicianGroupsGetPhysicianGroupsQueryParams,
    {}
  >({ url: '/api/PhysicianGroups', method: 'get', ...variables, signal });

export const usePhysicianGroupsGetPhysicianGroups = <TData = Schemas.PhysicianGroupDto>(
  variables: PhysicianGroupsGetPhysicianGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PhysicianGroupDto, PhysicianGroupsGetPhysicianGroupsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.PhysicianGroupDto, PhysicianGroupsGetPhysicianGroupsError, TData>(
    queryKeyFn({ path: '/api/PhysicianGroups', operationId: 'physicianGroupsGetPhysicianGroups', variables }),
    ({ signal }) => fetchPhysicianGroupsGetPhysicianGroups({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ProviderSaveNewProviderHeaders = {
  ['x-api-version']?: string;
};

export type ProviderSaveNewProviderError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type ProviderSaveNewProviderVariables = {
  body: Schemas.AddProviderDto;
  headers?: ProviderSaveNewProviderHeaders;
} & ApiContext['fetcherOptions'];

export const fetchProviderSaveNewProvider = (variables: ProviderSaveNewProviderVariables, signal?: AbortSignal) =>
  apiFetch<undefined, ProviderSaveNewProviderError, Schemas.AddProviderDto, ProviderSaveNewProviderHeaders, {}, {}>({
    url: '/api/Provider',
    method: 'post',
    ...variables,
    signal
  });

export const useProviderSaveNewProvider = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ProviderSaveNewProviderError, ProviderSaveNewProviderVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ProviderSaveNewProviderError, ProviderSaveNewProviderVariables>(
    (variables: ProviderSaveNewProviderVariables) => fetchProviderSaveNewProvider({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProviderGetProvidersBySearchQueryParams = {
  searchTerm?: string;
};

export type ProviderGetProvidersBySearchHeaders = {
  ['x-api-version']?: string;
};

export type ProviderGetProvidersBySearchError = Fetcher.ErrorWrapper<undefined>;

export type ProviderGetProvidersBySearchResponse = Schemas.DomainModelProviderOrgDetails[];

export type ProviderGetProvidersBySearchVariables = {
  headers?: ProviderGetProvidersBySearchHeaders;
  queryParams?: ProviderGetProvidersBySearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProviderGetProvidersBySearch = (
  variables: ProviderGetProvidersBySearchVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    ProviderGetProvidersBySearchResponse,
    ProviderGetProvidersBySearchError,
    undefined,
    ProviderGetProvidersBySearchHeaders,
    ProviderGetProvidersBySearchQueryParams,
    {}
  >({ url: '/api/Provider', method: 'get', ...variables, signal });

export const useProviderGetProvidersBySearch = <TData = ProviderGetProvidersBySearchResponse>(
  variables: ProviderGetProvidersBySearchVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<ProviderGetProvidersBySearchResponse, ProviderGetProvidersBySearchError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<ProviderGetProvidersBySearchResponse, ProviderGetProvidersBySearchError, TData>(
    queryKeyFn({ path: '/api/Provider', operationId: 'providerGetProvidersBySearch', variables }),
    ({ signal }) => fetchProviderGetProvidersBySearch({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ProviderReportGetProviderReportPathParams = {
  /**
   * @format int32
   */
  providerOrgId: number;
};

export type ProviderReportGetProviderReportQueryParams = {
  /**
   * @default false
   */
  createNew?: boolean;
};

export type ProviderReportGetProviderReportHeaders = {
  ['x-api-version']?: string;
};

export type ProviderReportGetProviderReportError = Fetcher.ErrorWrapper<undefined>;

export type ProviderReportGetProviderReportVariables = {
  headers?: ProviderReportGetProviderReportHeaders;
  pathParams: ProviderReportGetProviderReportPathParams;
  queryParams?: ProviderReportGetProviderReportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProviderReportGetProviderReport = (
  variables: ProviderReportGetProviderReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ProviderReportGetProviderReportError,
    undefined,
    ProviderReportGetProviderReportHeaders,
    ProviderReportGetProviderReportQueryParams,
    ProviderReportGetProviderReportPathParams
  >({ url: '/api/reports/provider/{providerOrgId}', method: 'get', ...variables, signal });

export const useProviderReportGetProviderReport = <TData = Blob>(
  variables: ProviderReportGetProviderReportVariables,
  options?: Omit<reactQuery.UseQueryOptions<Blob, ProviderReportGetProviderReportError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ProviderReportGetProviderReportError, TData>(
    queryKeyFn({
      path: '/api/reports/provider/{providerOrgId}',
      operationId: 'providerReportGetProviderReport',
      variables
    }),
    ({ signal }) => fetchProviderReportGetProviderReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ProviderReportGetProviderReportLogPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgId: number;
};

export type ProviderReportGetProviderReportLogHeaders = {
  ['x-api-version']?: string;
};

export type ProviderReportGetProviderReportLogError = Fetcher.ErrorWrapper<undefined>;

export type ProviderReportGetProviderReportLogVariables = {
  headers?: ProviderReportGetProviderReportLogHeaders;
  pathParams: ProviderReportGetProviderReportLogPathParams;
} & ApiContext['fetcherOptions'];

export const fetchProviderReportGetProviderReportLog = (
  variables: ProviderReportGetProviderReportLogVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ProviderReportGetProviderReportLogError,
    undefined,
    ProviderReportGetProviderReportLogHeaders,
    {},
    ProviderReportGetProviderReportLogPathParams
  >({ url: '/api/reports/provider/log/PhysicianGroup/{physicianGroupOrgId}', method: 'get', ...variables, signal });

export const useProviderReportGetProviderReportLog = <TData = undefined>(
  variables: ProviderReportGetProviderReportLogVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProviderReportGetProviderReportLogError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ProviderReportGetProviderReportLogError, TData>(
    queryKeyFn({
      path: '/api/reports/provider/log/PhysicianGroup/{physicianGroupOrgId}',
      operationId: 'providerReportGetProviderReportLog',
      variables
    }),
    ({ signal }) => fetchProviderReportGetProviderReportLog({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ProviderReportSendProviderReportHeaders = {
  ['x-api-version']?: string;
};

export type ProviderReportSendProviderReportError = Fetcher.ErrorWrapper<undefined>;

export type ProviderReportSendProviderReportVariables = {
  body: Schemas.ProviderReportPhysicianDataDto;
  headers?: ProviderReportSendProviderReportHeaders;
} & ApiContext['fetcherOptions'];

export const fetchProviderReportSendProviderReport = (
  variables: ProviderReportSendProviderReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ProviderReportSendProviderReportError,
    Schemas.ProviderReportPhysicianDataDto,
    ProviderReportSendProviderReportHeaders,
    {},
    {}
  >({ url: '/api/reports/provider/send', method: 'post', ...variables, signal });

export const useProviderReportSendProviderReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ProviderReportSendProviderReportError,
      ProviderReportSendProviderReportVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ProviderReportSendProviderReportError,
    ProviderReportSendProviderReportVariables
  >(
    (variables: ProviderReportSendProviderReportVariables) =>
      fetchProviderReportSendProviderReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProviderReportGenerateProviderReportsQueryParams = {
  groupOrgId?: number[];
};

export type ProviderReportGenerateProviderReportsHeaders = {
  ['x-api-version']?: string;
};

export type ProviderReportGenerateProviderReportsError = Fetcher.ErrorWrapper<undefined>;

export type ProviderReportGenerateProviderReportsVariables = {
  headers?: ProviderReportGenerateProviderReportsHeaders;
  queryParams?: ProviderReportGenerateProviderReportsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProviderReportGenerateProviderReports = (
  variables: ProviderReportGenerateProviderReportsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ProviderReportGenerateProviderReportsError,
    undefined,
    ProviderReportGenerateProviderReportsHeaders,
    ProviderReportGenerateProviderReportsQueryParams,
    {}
  >({ url: '/api/reports/provider/generate', method: 'get', ...variables, signal });

export const useProviderReportGenerateProviderReports = <TData = undefined>(
  variables: ProviderReportGenerateProviderReportsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ProviderReportGenerateProviderReportsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ProviderReportGenerateProviderReportsError, TData>(
    queryKeyFn({
      path: '/api/reports/provider/generate',
      operationId: 'providerReportGenerateProviderReports',
      variables
    }),
    ({ signal }) => fetchProviderReportGenerateProviderReports({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type RolesGetAllRolesHeaders = {
  ['x-api-version']?: string;
};

export type RolesGetAllRolesError = Fetcher.ErrorWrapper<undefined>;

export type RolesGetAllRolesVariables = {
  headers?: RolesGetAllRolesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchRolesGetAllRoles = (variables: RolesGetAllRolesVariables, signal?: AbortSignal) =>
  apiFetch<undefined, RolesGetAllRolesError, undefined, RolesGetAllRolesHeaders, {}, {}>({
    url: '/api/Roles',
    method: 'get',
    ...variables,
    signal
  });

export const useRolesGetAllRoles = <TData = undefined>(
  variables: RolesGetAllRolesVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, RolesGetAllRolesError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, RolesGetAllRolesError, TData>(
    queryKeyFn({ path: '/api/Roles', operationId: 'rolesGetAllRoles', variables }),
    ({ signal }) => fetchRolesGetAllRoles({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type RolesGetRolePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type RolesGetRoleHeaders = {
  ['x-api-version']?: string;
};

export type RolesGetRoleError = Fetcher.ErrorWrapper<undefined>;

export type RolesGetRoleVariables = {
  headers?: RolesGetRoleHeaders;
  pathParams: RolesGetRolePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRolesGetRole = (variables: RolesGetRoleVariables, signal?: AbortSignal) =>
  apiFetch<undefined, RolesGetRoleError, undefined, RolesGetRoleHeaders, {}, RolesGetRolePathParams>({
    url: '/api/Roles/{id}',
    method: 'get',
    ...variables,
    signal
  });

export const useRolesGetRole = <TData = undefined>(
  variables: RolesGetRoleVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, RolesGetRoleError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, RolesGetRoleError, TData>(
    queryKeyFn({ path: '/api/Roles/{id}', operationId: 'rolesGetRole', variables }),
    ({ signal }) => fetchRolesGetRole({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type RolesUpdateRolePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type RolesUpdateRoleHeaders = {
  ['x-api-version']?: string;
};

export type RolesUpdateRoleError = Fetcher.ErrorWrapper<undefined>;

export type RolesUpdateRoleVariables = {
  body?: Schemas.RoleDto;
  headers?: RolesUpdateRoleHeaders;
  pathParams: RolesUpdateRolePathParams;
} & ApiContext['fetcherOptions'];

export const fetchRolesUpdateRole = (variables: RolesUpdateRoleVariables, signal?: AbortSignal) =>
  apiFetch<undefined, RolesUpdateRoleError, Schemas.RoleDto, RolesUpdateRoleHeaders, {}, RolesUpdateRolePathParams>({
    url: '/api/Roles/{id}',
    method: 'put',
    ...variables,
    signal
  });

export const useRolesUpdateRole = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RolesUpdateRoleError, RolesUpdateRoleVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, RolesUpdateRoleError, RolesUpdateRoleVariables>(
    (variables: RolesUpdateRoleVariables) => fetchRolesUpdateRole({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SafConditionsGetConditonsForOrgQueryParams = {
  /**
   * @format int32
   */
  orgId?: number;
};

export type SafConditionsGetConditonsForOrgHeaders = {
  ['x-api-version']?: string;
};

export type SafConditionsGetConditonsForOrgError = Fetcher.ErrorWrapper<undefined>;

export type SafConditionsGetConditonsForOrgVariables = {
  headers?: SafConditionsGetConditonsForOrgHeaders;
  queryParams?: SafConditionsGetConditonsForOrgQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafConditionsGetConditonsForOrg = (
  variables: SafConditionsGetConditonsForOrgVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafConditionsGetConditonsForOrgError,
    undefined,
    SafConditionsGetConditonsForOrgHeaders,
    SafConditionsGetConditonsForOrgQueryParams,
    {}
  >({ url: '/api/saf/conditions', method: 'get', ...variables, signal });

export const useSafConditionsGetConditonsForOrg = <TData = undefined>(
  variables: SafConditionsGetConditonsForOrgVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafConditionsGetConditonsForOrgError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafConditionsGetConditonsForOrgError, TData>(
    queryKeyFn({ path: '/api/saf/conditions', operationId: 'safConditionsGetConditonsForOrg', variables }),
    ({ signal }) => fetchSafConditionsGetConditonsForOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGraphGetCmiPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGraphGetCmiQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGraphGetCmiHeaders = {
  ['x-api-version']?: string;
};

export type SafGraphGetCmiError = Fetcher.ErrorWrapper<undefined>;

export type SafGraphGetCmiVariables = {
  headers?: SafGraphGetCmiHeaders;
  pathParams: SafGraphGetCmiPathParams;
  queryParams?: SafGraphGetCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGraphGetCmi = (variables: SafGraphGetCmiVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafGraphGetCmiError,
    undefined,
    SafGraphGetCmiHeaders,
    SafGraphGetCmiQueryParams,
    SafGraphGetCmiPathParams
  >({ url: '/api/saf/graph/cmi/{id}', method: 'get', ...variables, signal });

export const useSafGraphGetCmi = <TData = undefined>(
  variables: SafGraphGetCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGraphGetCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGraphGetCmiError, TData>(
    queryKeyFn({ path: '/api/saf/graph/cmi/{id}', operationId: 'safGraphGetCmi', variables }),
    ({ signal }) => fetchSafGraphGetCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGraphGetDocScorePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGraphGetDocScoreQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGraphGetDocScoreHeaders = {
  ['x-api-version']?: string;
};

export type SafGraphGetDocScoreError = Fetcher.ErrorWrapper<undefined>;

export type SafGraphGetDocScoreVariables = {
  headers?: SafGraphGetDocScoreHeaders;
  pathParams: SafGraphGetDocScorePathParams;
  queryParams?: SafGraphGetDocScoreQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGraphGetDocScore = (variables: SafGraphGetDocScoreVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafGraphGetDocScoreError,
    undefined,
    SafGraphGetDocScoreHeaders,
    SafGraphGetDocScoreQueryParams,
    SafGraphGetDocScorePathParams
  >({ url: '/api/saf/graph/docscore/{id}', method: 'get', ...variables, signal });

export const useSafGraphGetDocScore = <TData = undefined>(
  variables: SafGraphGetDocScoreVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGraphGetDocScoreError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGraphGetDocScoreError, TData>(
    queryKeyFn({ path: '/api/saf/graph/docscore/{id}', operationId: 'safGraphGetDocScore', variables }),
    ({ signal }) => fetchSafGraphGetDocScore({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGraphGetHcupCmiPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGraphGetHcupCmiQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGraphGetHcupCmiHeaders = {
  ['x-api-version']?: string;
};

export type SafGraphGetHcupCmiError = Fetcher.ErrorWrapper<undefined>;

export type SafGraphGetHcupCmiVariables = {
  headers?: SafGraphGetHcupCmiHeaders;
  pathParams: SafGraphGetHcupCmiPathParams;
  queryParams?: SafGraphGetHcupCmiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGraphGetHcupCmi = (variables: SafGraphGetHcupCmiVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafGraphGetHcupCmiError,
    undefined,
    SafGraphGetHcupCmiHeaders,
    SafGraphGetHcupCmiQueryParams,
    SafGraphGetHcupCmiPathParams
  >({ url: '/api/saf/graph/hcupcmi/{id}', method: 'get', ...variables, signal });

export const useSafGraphGetHcupCmi = <TData = undefined>(
  variables: SafGraphGetHcupCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGraphGetHcupCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGraphGetHcupCmiError, TData>(
    queryKeyFn({ path: '/api/saf/graph/hcupcmi/{id}', operationId: 'safGraphGetHcupCmi', variables }),
    ({ signal }) => fetchSafGraphGetHcupCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafHistogramGenerateSafHistogramReportQueryParams = {
  /**
   * current2 or OtoE, whatever the toggle is set to on condition graphs.
   */
  ConditionGraphType?: string;
  /**
   * The list of IDs for all entities in the graph. The first one is the primary entity, all others are callouts.
   */
  EntityIds?: string;
  BedSizeTypes?: string;
  CommunityTypes?: string;
  ConditionNames?: string;
  DischargeTypes?: string;
  FundamentalSpecialtyIds?: string;
  HospitalTypes?: string;
  MetricTypes?: string;
  /**
   * @format int32
   */
  MinimumDischargeCount?: number;
  RegionTypes?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafHistogramGenerateSafHistogramReportHeaders = {
  ['x-api-version']?: string;
};

export type SafHistogramGenerateSafHistogramReportError = Fetcher.ErrorWrapper<undefined>;

export type SafHistogramGenerateSafHistogramReportVariables = {
  headers?: SafHistogramGenerateSafHistogramReportHeaders;
  queryParams?: SafHistogramGenerateSafHistogramReportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafHistogramGenerateSafHistogramReport = (
  variables: SafHistogramGenerateSafHistogramReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafHistogramGenerateSafHistogramReportError,
    undefined,
    SafHistogramGenerateSafHistogramReportHeaders,
    SafHistogramGenerateSafHistogramReportQueryParams,
    {}
  >({ url: '/api/saf/histogram/safHistogramReport', method: 'get', ...variables, signal });

export const useSafHistogramGenerateSafHistogramReport = <TData = undefined>(
  variables: SafHistogramGenerateSafHistogramReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafHistogramGenerateSafHistogramReportError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafHistogramGenerateSafHistogramReportError, TData>(
    queryKeyFn({
      path: '/api/saf/histogram/safHistogramReport',
      operationId: 'safHistogramGenerateSafHistogramReport',
      variables
    }),
    ({ signal }) => fetchSafHistogramGenerateSafHistogramReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafHistogramGetFiltersHeaders = {
  ['x-api-version']?: string;
};

export type SafHistogramGetFiltersError = Fetcher.ErrorWrapper<undefined>;

export type SafHistogramGetFiltersVariables = {
  headers?: SafHistogramGetFiltersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSafHistogramGetFilters = (variables: SafHistogramGetFiltersVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SafHistogramGetFiltersError, undefined, SafHistogramGetFiltersHeaders, {}, {}>({
    url: '/api/saf/histogram/filters',
    method: 'get',
    ...variables,
    signal
  });

export const useSafHistogramGetFilters = <TData = undefined>(
  variables: SafHistogramGetFiltersVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafHistogramGetFiltersError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafHistogramGetFiltersError, TData>(
    queryKeyFn({ path: '/api/saf/histogram/filters', operationId: 'safHistogramGetFilters', variables }),
    ({ signal }) => fetchSafHistogramGetFilters({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafHistogramGetFiltersForOrgPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type SafHistogramGetFiltersForOrgQueryParams = {
  fundamentalSpecialtyIds?: string;
};

export type SafHistogramGetFiltersForOrgHeaders = {
  ['x-api-version']?: string;
};

export type SafHistogramGetFiltersForOrgError = Fetcher.ErrorWrapper<undefined>;

export type SafHistogramGetFiltersForOrgVariables = {
  headers?: SafHistogramGetFiltersForOrgHeaders;
  pathParams: SafHistogramGetFiltersForOrgPathParams;
  queryParams?: SafHistogramGetFiltersForOrgQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafHistogramGetFiltersForOrg = (
  variables: SafHistogramGetFiltersForOrgVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafHistogramGetFiltersForOrgError,
    undefined,
    SafHistogramGetFiltersForOrgHeaders,
    SafHistogramGetFiltersForOrgQueryParams,
    SafHistogramGetFiltersForOrgPathParams
  >({ url: '/api/saf/histogram/filters/{orgId}', method: 'get', ...variables, signal });

export const useSafHistogramGetFiltersForOrg = <TData = undefined>(
  variables: SafHistogramGetFiltersForOrgVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafHistogramGetFiltersForOrgError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafHistogramGetFiltersForOrgError, TData>(
    queryKeyFn({ path: '/api/saf/histogram/filters/{orgId}', operationId: 'safHistogramGetFiltersForOrg', variables }),
    ({ signal }) => fetchSafHistogramGetFiltersForOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafHistogramGetHistogramsQueryParams = {
  BedSizeTypes?: string;
  CommunityTypes?: string;
  ConditionNames?: string;
  DischargeTypes?: string;
  FundamentalSpecialtyIds?: string;
  HospitalTypes?: string;
  MetricTypes?: string;
  /**
   * @format int32
   */
  MinimumDischargeCount?: number;
  RegionTypes?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafHistogramGetHistogramsHeaders = {
  ['x-api-version']?: string;
};

export type SafHistogramGetHistogramsError = Fetcher.ErrorWrapper<undefined>;

export type SafHistogramGetHistogramsResponse = Schemas.SAFHistogramDto[];

export type SafHistogramGetHistogramsVariables = {
  headers?: SafHistogramGetHistogramsHeaders;
  queryParams?: SafHistogramGetHistogramsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafHistogramGetHistograms = (variables: SafHistogramGetHistogramsVariables, signal?: AbortSignal) =>
  apiFetch<
    SafHistogramGetHistogramsResponse,
    SafHistogramGetHistogramsError,
    undefined,
    SafHistogramGetHistogramsHeaders,
    SafHistogramGetHistogramsQueryParams,
    {}
  >({ url: '/api/saf/histogram', method: 'get', ...variables, signal });

export const useSafHistogramGetHistograms = <TData = SafHistogramGetHistogramsResponse>(
  variables: SafHistogramGetHistogramsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SafHistogramGetHistogramsResponse, SafHistogramGetHistogramsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SafHistogramGetHistogramsResponse, SafHistogramGetHistogramsError, TData>(
    queryKeyFn({ path: '/api/saf/histogram', operationId: 'safHistogramGetHistograms', variables }),
    ({ signal }) => fetchSafHistogramGetHistograms({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafHistogramGetMetricTypesHeaders = {
  ['x-api-version']?: string;
};

export type SafHistogramGetMetricTypesError = Fetcher.ErrorWrapper<undefined>;

export type SafHistogramGetMetricTypesVariables = {
  headers?: SafHistogramGetMetricTypesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSafHistogramGetMetricTypes = (variables: SafHistogramGetMetricTypesVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SafHistogramGetMetricTypesError, undefined, SafHistogramGetMetricTypesHeaders, {}, {}>({
    url: '/api/saf/histogram/metricTypes',
    method: 'get',
    ...variables,
    signal
  });

export const useSafHistogramGetMetricTypes = <TData = undefined>(
  variables: SafHistogramGetMetricTypesVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafHistogramGetMetricTypesError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafHistogramGetMetricTypesError, TData>(
    queryKeyFn({ path: '/api/saf/histogram/metricTypes', operationId: 'safHistogramGetMetricTypes', variables }),
    ({ signal }) => fetchSafHistogramGetMetricTypes({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafMetricsGetAllConditionsQueryParams = {
  ConditionNames?: string;
  EntityIds?: string;
  FundamentalSpecialtyIds?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafMetricsGetAllConditionsHeaders = {
  ['x-api-version']?: string;
};

export type SafMetricsGetAllConditionsError = Fetcher.ErrorWrapper<undefined>;

export type SafMetricsGetAllConditionsResponse = Schemas.DomainModelConditionsMetric[];

export type SafMetricsGetAllConditionsVariables = {
  headers?: SafMetricsGetAllConditionsHeaders;
  queryParams?: SafMetricsGetAllConditionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafMetricsGetAllConditions = (variables: SafMetricsGetAllConditionsVariables, signal?: AbortSignal) =>
  apiFetch<
    SafMetricsGetAllConditionsResponse,
    SafMetricsGetAllConditionsError,
    undefined,
    SafMetricsGetAllConditionsHeaders,
    SafMetricsGetAllConditionsQueryParams,
    {}
  >({ url: '/api/saf/metrics/conditions', method: 'get', ...variables, signal });

export const useSafMetricsGetAllConditions = <TData = SafMetricsGetAllConditionsResponse>(
  variables: SafMetricsGetAllConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SafMetricsGetAllConditionsResponse, SafMetricsGetAllConditionsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SafMetricsGetAllConditionsResponse, SafMetricsGetAllConditionsError, TData>(
    queryKeyFn({ path: '/api/saf/metrics/conditions', operationId: 'safMetricsGetAllConditions', variables }),
    ({ signal }) => fetchSafMetricsGetAllConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafMetricsGetAllMetricsQueryParams = {
  EntityIds?: string;
  FundamentalSpecialtyIds?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafMetricsGetAllMetricsHeaders = {
  ['x-api-version']?: string;
};

export type SafMetricsGetAllMetricsError = Fetcher.ErrorWrapper<undefined>;

export type SafMetricsGetAllMetricsResponse = Schemas.OverallPerformanceDataDto[];

export type SafMetricsGetAllMetricsVariables = {
  headers?: SafMetricsGetAllMetricsHeaders;
  queryParams?: SafMetricsGetAllMetricsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafMetricsGetAllMetrics = (variables: SafMetricsGetAllMetricsVariables, signal?: AbortSignal) =>
  apiFetch<
    SafMetricsGetAllMetricsResponse,
    SafMetricsGetAllMetricsError,
    undefined,
    SafMetricsGetAllMetricsHeaders,
    SafMetricsGetAllMetricsQueryParams,
    {}
  >({ url: '/api/saf/metrics', method: 'get', ...variables, signal });

export const useSafMetricsGetAllMetrics = <TData = SafMetricsGetAllMetricsResponse>(
  variables: SafMetricsGetAllMetricsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SafMetricsGetAllMetricsResponse, SafMetricsGetAllMetricsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SafMetricsGetAllMetricsResponse, SafMetricsGetAllMetricsError, TData>(
    queryKeyFn({ path: '/api/saf/metrics', operationId: 'safMetricsGetAllMetrics', variables }),
    ({ signal }) => fetchSafMetricsGetAllMetrics({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafMetricsGetDrgMixComparisonPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafMetricsGetDrgMixComparisonQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafMetricsGetDrgMixComparisonHeaders = {
  ['x-api-version']?: string;
};

export type SafMetricsGetDrgMixComparisonError = Fetcher.ErrorWrapper<undefined>;

export type SafMetricsGetDrgMixComparisonVariables = {
  headers?: SafMetricsGetDrgMixComparisonHeaders;
  pathParams: SafMetricsGetDrgMixComparisonPathParams;
  queryParams?: SafMetricsGetDrgMixComparisonQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafMetricsGetDrgMixComparison = (
  variables: SafMetricsGetDrgMixComparisonVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafMetricsGetDrgMixComparisonError,
    undefined,
    SafMetricsGetDrgMixComparisonHeaders,
    SafMetricsGetDrgMixComparisonQueryParams,
    SafMetricsGetDrgMixComparisonPathParams
  >({ url: '/api/saf/metrics/drgmixcomparison/{id}', method: 'get', ...variables, signal });

export const useSafMetricsGetDrgMixComparison = <TData = undefined>(
  variables: SafMetricsGetDrgMixComparisonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafMetricsGetDrgMixComparisonError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafMetricsGetDrgMixComparisonError, TData>(
    queryKeyFn({
      path: '/api/saf/metrics/drgmixcomparison/{id}',
      operationId: 'safMetricsGetDrgMixComparison',
      variables
    }),
    ({ signal }) => fetchSafMetricsGetDrgMixComparison({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafMetricsSeverityCmiComparisonPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafMetricsSeverityCmiComparisonQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafMetricsSeverityCmiComparisonHeaders = {
  ['x-api-version']?: string;
};

export type SafMetricsSeverityCmiComparisonError = Fetcher.ErrorWrapper<undefined>;

export type SafMetricsSeverityCmiComparisonVariables = {
  headers?: SafMetricsSeverityCmiComparisonHeaders;
  pathParams: SafMetricsSeverityCmiComparisonPathParams;
  queryParams?: SafMetricsSeverityCmiComparisonQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafMetricsSeverityCmiComparison = (
  variables: SafMetricsSeverityCmiComparisonVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafMetricsSeverityCmiComparisonError,
    undefined,
    SafMetricsSeverityCmiComparisonHeaders,
    SafMetricsSeverityCmiComparisonQueryParams,
    SafMetricsSeverityCmiComparisonPathParams
  >({ url: '/api/saf/metrics/severitycmicomparison/{id}', method: 'get', ...variables, signal });

export const useSafMetricsSeverityCmiComparison = <TData = undefined>(
  variables: SafMetricsSeverityCmiComparisonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafMetricsSeverityCmiComparisonError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafMetricsSeverityCmiComparisonError, TData>(
    queryKeyFn({
      path: '/api/saf/metrics/severitycmicomparison/{id}',
      operationId: 'safMetricsSeverityCmiComparison',
      variables
    }),
    ({ signal }) => fetchSafMetricsSeverityCmiComparison({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafMetricsDataDownloadGenerateCmiComparisonReportPathParams = {
  /**
   * First org selected
   *
   * @format int32
   */
  orgId: number;
  /**
   * Comparison org selected
   *
   * @format int32
   */
  compareToOrgId: number;
};

export type SafMetricsDataDownloadGenerateCmiComparisonReportQueryParams = {
  /**
   * Full date range for graphs start date
   *
   * @format date-time
   */
  periodStart?: string;
  /**
   * Full date range for graphs end date
   *
   * @format date-time
   */
  periodEnd?: string;
  /**
   * Comparison range start date
   *
   * @format date-time
   */
  rangeStart?: string;
  /**
   * Comparison range end date
   *
   * @format date-time
   */
  rangeEnd?: string;
};

export type SafMetricsDataDownloadGenerateCmiComparisonReportHeaders = {
  ['x-api-version']?: string;
};

export type SafMetricsDataDownloadGenerateCmiComparisonReportError = Fetcher.ErrorWrapper<undefined>;

export type SafMetricsDataDownloadGenerateCmiComparisonReportVariables = {
  headers?: SafMetricsDataDownloadGenerateCmiComparisonReportHeaders;
  pathParams: SafMetricsDataDownloadGenerateCmiComparisonReportPathParams;
  queryParams?: SafMetricsDataDownloadGenerateCmiComparisonReportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafMetricsDataDownloadGenerateCmiComparisonReport = (
  variables: SafMetricsDataDownloadGenerateCmiComparisonReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafMetricsDataDownloadGenerateCmiComparisonReportError,
    undefined,
    SafMetricsDataDownloadGenerateCmiComparisonReportHeaders,
    SafMetricsDataDownloadGenerateCmiComparisonReportQueryParams,
    SafMetricsDataDownloadGenerateCmiComparisonReportPathParams
  >({
    url: '/api/saf/download/metrics/cmicomparisonreport/{orgId}/{compareToOrgId}',
    method: 'get',
    ...variables,
    signal
  });

export const useSafMetricsDataDownloadGenerateCmiComparisonReport = <TData = undefined>(
  variables: SafMetricsDataDownloadGenerateCmiComparisonReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafMetricsDataDownloadGenerateCmiComparisonReportError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafMetricsDataDownloadGenerateCmiComparisonReportError, TData>(
    queryKeyFn({
      path: '/api/saf/download/metrics/cmicomparisonreport/{orgId}/{compareToOrgId}',
      operationId: 'safMetricsDataDownloadGenerateCmiComparisonReport',
      variables
    }),
    ({ signal }) => fetchSafMetricsDataDownloadGenerateCmiComparisonReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafMetricsDataDownloadGetDrgMixComparisonPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafMetricsDataDownloadGetDrgMixComparisonQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type SafMetricsDataDownloadGetDrgMixComparisonHeaders = {
  ['x-api-version']?: string;
};

export type SafMetricsDataDownloadGetDrgMixComparisonError = Fetcher.ErrorWrapper<undefined>;

export type SafMetricsDataDownloadGetDrgMixComparisonVariables = {
  headers?: SafMetricsDataDownloadGetDrgMixComparisonHeaders;
  pathParams: SafMetricsDataDownloadGetDrgMixComparisonPathParams;
  queryParams?: SafMetricsDataDownloadGetDrgMixComparisonQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafMetricsDataDownloadGetDrgMixComparison = (
  variables: SafMetricsDataDownloadGetDrgMixComparisonVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafMetricsDataDownloadGetDrgMixComparisonError,
    undefined,
    SafMetricsDataDownloadGetDrgMixComparisonHeaders,
    SafMetricsDataDownloadGetDrgMixComparisonQueryParams,
    SafMetricsDataDownloadGetDrgMixComparisonPathParams
  >({ url: '/api/saf/download/metrics/drgmixcomparison/{id}', method: 'get', ...variables, signal });

export const useSafMetricsDataDownloadGetDrgMixComparison = <TData = undefined>(
  variables: SafMetricsDataDownloadGetDrgMixComparisonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafMetricsDataDownloadGetDrgMixComparisonError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafMetricsDataDownloadGetDrgMixComparisonError, TData>(
    queryKeyFn({
      path: '/api/saf/download/metrics/drgmixcomparison/{id}',
      operationId: 'safMetricsDataDownloadGetDrgMixComparison',
      variables
    }),
    ({ signal }) => fetchSafMetricsDataDownloadGetDrgMixComparison({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafOrgGetDefaultDatesPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type SafOrgGetDefaultDatesHeaders = {
  ['x-api-version']?: string;
};

export type SafOrgGetDefaultDatesError = Fetcher.ErrorWrapper<undefined>;

export type SafOrgGetDefaultDatesVariables = {
  headers?: SafOrgGetDefaultDatesHeaders;
  pathParams: SafOrgGetDefaultDatesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSafOrgGetDefaultDates = (variables: SafOrgGetDefaultDatesVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafOrgGetDefaultDatesError,
    undefined,
    SafOrgGetDefaultDatesHeaders,
    {},
    SafOrgGetDefaultDatesPathParams
  >({ url: '/api/SAF/org/defaultdates/{orgId}', method: 'get', ...variables, signal });

export const useSafOrgGetDefaultDates = <TData = undefined>(
  variables: SafOrgGetDefaultDatesVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafOrgGetDefaultDatesError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafOrgGetDefaultDatesError, TData>(
    queryKeyFn({ path: '/api/SAF/org/defaultdates/{orgId}', operationId: 'safOrgGetDefaultDates', variables }),
    ({ signal }) => fetchSafOrgGetDefaultDates({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafOrgGetOrgPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafOrgGetOrgQueryParams = {
  /**
   * @default false
   */
  includeLineage?: boolean;
};

export type SafOrgGetOrgHeaders = {
  ['x-api-version']?: string;
};

export type SafOrgGetOrgError = Fetcher.ErrorWrapper<undefined>;

export type SafOrgGetOrgVariables = {
  headers?: SafOrgGetOrgHeaders;
  pathParams: SafOrgGetOrgPathParams;
  queryParams?: SafOrgGetOrgQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafOrgGetOrg = (variables: SafOrgGetOrgVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafOrgGetOrgError,
    undefined,
    SafOrgGetOrgHeaders,
    SafOrgGetOrgQueryParams,
    SafOrgGetOrgPathParams
  >({ url: '/api/SAF/org/{id}', method: 'get', ...variables, signal });

export const useSafOrgGetOrg = <TData = undefined>(
  variables: SafOrgGetOrgVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafOrgGetOrgError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafOrgGetOrgError, TData>(
    queryKeyFn({ path: '/api/SAF/org/{id}', operationId: 'safOrgGetOrg', variables }),
    ({ signal }) => fetchSafOrgGetOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafOrgSearchQueryParams = {
  search?: string;
};

export type SafOrgSearchHeaders = {
  ['x-api-version']?: string;
};

export type SafOrgSearchError = Fetcher.ErrorWrapper<undefined>;

export type SafOrgSearchVariables = {
  headers?: SafOrgSearchHeaders;
  queryParams?: SafOrgSearchQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafOrgSearch = (variables: SafOrgSearchVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SafOrgSearchError, undefined, SafOrgSearchHeaders, SafOrgSearchQueryParams, {}>({
    url: '/api/SAF/org',
    method: 'get',
    ...variables,
    signal
  });

export const useSafOrgSearch = <TData = undefined>(
  variables: SafOrgSearchVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafOrgSearchError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafOrgSearchError, TData>(
    queryKeyFn({ path: '/api/SAF/org', operationId: 'safOrgSearch', variables }),
    ({ signal }) => fetchSafOrgSearch({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleExecuteJobsOnTenantsHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleExecuteJobsOnTenantsError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleExecuteJobsOnTenantsVariables = {
  body?: Schemas.PostKeyDto;
  headers?: ScheduleExecuteJobsOnTenantsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleExecuteJobsOnTenants = (
  variables: ScheduleExecuteJobsOnTenantsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ScheduleExecuteJobsOnTenantsError,
    Schemas.PostKeyDto,
    ScheduleExecuteJobsOnTenantsHeaders,
    {},
    {}
  >({ url: '/api/schedule/main/execute', method: 'post', ...variables, signal });

export const useScheduleExecuteJobsOnTenants = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ScheduleExecuteJobsOnTenantsError, ScheduleExecuteJobsOnTenantsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ScheduleExecuteJobsOnTenantsError, ScheduleExecuteJobsOnTenantsVariables>(
    (variables: ScheduleExecuteJobsOnTenantsVariables) =>
      fetchScheduleExecuteJobsOnTenants({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleImprovementGoalsActivateSmartStrategiesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleImprovementGoalsActivateSmartStrategiesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleImprovementGoalsActivateSmartStrategiesVariables = {
  body?: Schemas.PostKeyDto;
  headers?: ScheduleImprovementGoalsActivateSmartStrategiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleImprovementGoalsActivateSmartStrategies = (
  variables: ScheduleImprovementGoalsActivateSmartStrategiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ScheduleImprovementGoalsActivateSmartStrategiesError,
    Schemas.PostKeyDto,
    ScheduleImprovementGoalsActivateSmartStrategiesHeaders,
    {},
    {}
  >({ url: '/api/schedule/improvementgoals/activatesmartstrategies', method: 'post', ...variables, signal });

export const useScheduleImprovementGoalsActivateSmartStrategies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ScheduleImprovementGoalsActivateSmartStrategiesError,
      ScheduleImprovementGoalsActivateSmartStrategiesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ScheduleImprovementGoalsActivateSmartStrategiesError,
    ScheduleImprovementGoalsActivateSmartStrategiesVariables
  >(
    (variables: ScheduleImprovementGoalsActivateSmartStrategiesVariables) =>
      fetchScheduleImprovementGoalsActivateSmartStrategies({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleImprovementGoalsExpiringSmartStrategiesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleImprovementGoalsExpiringSmartStrategiesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleImprovementGoalsExpiringSmartStrategiesVariables = {
  body?: Schemas.PostKeyDto;
  headers?: ScheduleImprovementGoalsExpiringSmartStrategiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleImprovementGoalsExpiringSmartStrategies = (
  variables: ScheduleImprovementGoalsExpiringSmartStrategiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ScheduleImprovementGoalsExpiringSmartStrategiesError,
    Schemas.PostKeyDto,
    ScheduleImprovementGoalsExpiringSmartStrategiesHeaders,
    {},
    {}
  >({ url: '/api/schedule/improvementgoals/expiringsmartstrategies', method: 'post', ...variables, signal });

export const useScheduleImprovementGoalsExpiringSmartStrategies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ScheduleImprovementGoalsExpiringSmartStrategiesError,
      ScheduleImprovementGoalsExpiringSmartStrategiesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ScheduleImprovementGoalsExpiringSmartStrategiesError,
    ScheduleImprovementGoalsExpiringSmartStrategiesVariables
  >(
    (variables: ScheduleImprovementGoalsExpiringSmartStrategiesVariables) =>
      fetchScheduleImprovementGoalsExpiringSmartStrategies({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleImprovementGoalsAutomaticallyRenewStrategiesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleImprovementGoalsAutomaticallyRenewStrategiesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleImprovementGoalsAutomaticallyRenewStrategiesVariables = {
  body?: Schemas.PostKeyDto;
  headers?: ScheduleImprovementGoalsAutomaticallyRenewStrategiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleImprovementGoalsAutomaticallyRenewStrategies = (
  variables: ScheduleImprovementGoalsAutomaticallyRenewStrategiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ScheduleImprovementGoalsAutomaticallyRenewStrategiesError,
    Schemas.PostKeyDto,
    ScheduleImprovementGoalsAutomaticallyRenewStrategiesHeaders,
    {},
    {}
  >({ url: '/api/schedule/improvementgoals/automaticallyrenewstrategies', method: 'post', ...variables, signal });

export const useScheduleImprovementGoalsAutomaticallyRenewStrategies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ScheduleImprovementGoalsAutomaticallyRenewStrategiesError,
      ScheduleImprovementGoalsAutomaticallyRenewStrategiesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ScheduleImprovementGoalsAutomaticallyRenewStrategiesError,
    ScheduleImprovementGoalsAutomaticallyRenewStrategiesVariables
  >(
    (variables: ScheduleImprovementGoalsAutomaticallyRenewStrategiesVariables) =>
      fetchScheduleImprovementGoalsAutomaticallyRenewStrategies({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleReportAddScheduleHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportAddScheduleError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportAddScheduleVariables = {
  body?: Schemas.ScheduleReportDto;
  headers?: ScheduleReportAddScheduleHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportAddSchedule = (variables: ScheduleReportAddScheduleVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    ScheduleReportAddScheduleError,
    Schemas.ScheduleReportDto,
    ScheduleReportAddScheduleHeaders,
    {},
    {}
  >({ url: '/api/schedule/reports', method: 'post', ...variables, signal });

export const useScheduleReportAddSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ScheduleReportAddScheduleError, ScheduleReportAddScheduleVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ScheduleReportAddScheduleError, ScheduleReportAddScheduleVariables>(
    (variables: ScheduleReportAddScheduleVariables) =>
      fetchScheduleReportAddSchedule({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleReportGetSchedulesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportGetSchedulesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportGetSchedulesVariables = {
  headers?: ScheduleReportGetSchedulesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportGetSchedules = (variables: ScheduleReportGetSchedulesVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.ScheduleReports,
    ScheduleReportGetSchedulesError,
    undefined,
    ScheduleReportGetSchedulesHeaders,
    {},
    {}
  >({ url: '/api/schedule/reports', method: 'get', ...variables, signal });

export const useScheduleReportGetSchedules = <TData = Schemas.ScheduleReports>(
  variables: ScheduleReportGetSchedulesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ScheduleReports, ScheduleReportGetSchedulesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ScheduleReports, ScheduleReportGetSchedulesError, TData>(
    queryKeyFn({ path: '/api/schedule/reports', operationId: 'scheduleReportGetSchedules', variables }),
    ({ signal }) => fetchScheduleReportGetSchedules({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleReportDeleteSchedulePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ScheduleReportDeleteScheduleHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportDeleteScheduleError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportDeleteScheduleVariables = {
  headers?: ScheduleReportDeleteScheduleHeaders;
  pathParams: ScheduleReportDeleteSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportDeleteSchedule = (
  variables: ScheduleReportDeleteScheduleVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ScheduleReportDeleteScheduleError,
    undefined,
    ScheduleReportDeleteScheduleHeaders,
    {},
    ScheduleReportDeleteSchedulePathParams
  >({ url: '/api/schedule/reports/{id}', method: 'delete', ...variables, signal });

export const useScheduleReportDeleteSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ScheduleReportDeleteScheduleError, ScheduleReportDeleteScheduleVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ScheduleReportDeleteScheduleError, ScheduleReportDeleteScheduleVariables>(
    (variables: ScheduleReportDeleteScheduleVariables) =>
      fetchScheduleReportDeleteSchedule({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleReportGetSchedulePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ScheduleReportGetScheduleHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportGetScheduleError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportGetScheduleVariables = {
  headers?: ScheduleReportGetScheduleHeaders;
  pathParams: ScheduleReportGetSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportGetSchedule = (variables: ScheduleReportGetScheduleVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.ScheduleReportDto,
    ScheduleReportGetScheduleError,
    undefined,
    ScheduleReportGetScheduleHeaders,
    {},
    ScheduleReportGetSchedulePathParams
  >({ url: '/api/schedule/reports/{id}', method: 'get', ...variables, signal });

export const useScheduleReportGetSchedule = <TData = Schemas.ScheduleReportDto>(
  variables: ScheduleReportGetScheduleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ScheduleReportDto, ScheduleReportGetScheduleError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ScheduleReportDto, ScheduleReportGetScheduleError, TData>(
    queryKeyFn({ path: '/api/schedule/reports/{id}', operationId: 'scheduleReportGetSchedule', variables }),
    ({ signal }) => fetchScheduleReportGetSchedule({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleReportUpdateSchedulePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ScheduleReportUpdateScheduleHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportUpdateScheduleError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportUpdateScheduleVariables = {
  body?: Schemas.ScheduleReportDto;
  headers?: ScheduleReportUpdateScheduleHeaders;
  pathParams: ScheduleReportUpdateSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportUpdateSchedule = (
  variables: ScheduleReportUpdateScheduleVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ScheduleReportUpdateScheduleError,
    Schemas.ScheduleReportDto,
    ScheduleReportUpdateScheduleHeaders,
    {},
    ScheduleReportUpdateSchedulePathParams
  >({ url: '/api/schedule/reports/{id}', method: 'put', ...variables, signal });

export const useScheduleReportUpdateSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ScheduleReportUpdateScheduleError, ScheduleReportUpdateScheduleVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ScheduleReportUpdateScheduleError, ScheduleReportUpdateScheduleVariables>(
    (variables: ScheduleReportUpdateScheduleVariables) =>
      fetchScheduleReportUpdateSchedule({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleReportDownloadReportPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ScheduleReportDownloadReportHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportDownloadReportError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportDownloadReportVariables = {
  headers?: ScheduleReportDownloadReportHeaders;
  pathParams: ScheduleReportDownloadReportPathParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportDownloadReport = (
  variables: ScheduleReportDownloadReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ScheduleReportDownloadReportError,
    undefined,
    ScheduleReportDownloadReportHeaders,
    {},
    ScheduleReportDownloadReportPathParams
  >({ url: '/api/schedule/reports/download/{id}', method: 'get', ...variables, signal });

export const useScheduleReportDownloadReport = <TData = Blob>(
  variables: ScheduleReportDownloadReportVariables,
  options?: Omit<reactQuery.UseQueryOptions<Blob, ScheduleReportDownloadReportError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ScheduleReportDownloadReportError, TData>(
    queryKeyFn({ path: '/api/schedule/reports/download/{id}', operationId: 'scheduleReportDownloadReport', variables }),
    ({ signal }) => fetchScheduleReportDownloadReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleReportGetReportTypesQueryParams = {
  /**
   * optional, only return ones that are schedulable, defaults to true
   *
   * @default true
   */
  schedulable?: boolean;
};

export type ScheduleReportGetReportTypesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportGetReportTypesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportGetReportTypesVariables = {
  headers?: ScheduleReportGetReportTypesHeaders;
  queryParams?: ScheduleReportGetReportTypesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportGetReportTypes = (
  variables: ScheduleReportGetReportTypesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ReportTypes,
    ScheduleReportGetReportTypesError,
    undefined,
    ScheduleReportGetReportTypesHeaders,
    ScheduleReportGetReportTypesQueryParams,
    {}
  >({ url: '/api/schedule/reports/reportTypes', method: 'get', ...variables, signal });

export const useScheduleReportGetReportTypes = <TData = Schemas.ReportTypes>(
  variables: ScheduleReportGetReportTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ReportTypes, ScheduleReportGetReportTypesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ReportTypes, ScheduleReportGetReportTypesError, TData>(
    queryKeyFn({ path: '/api/schedule/reports/reportTypes', operationId: 'scheduleReportGetReportTypes', variables }),
    ({ signal }) => fetchScheduleReportGetReportTypes({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleReportGetScheduleFrequenciesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportGetScheduleFrequenciesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportGetScheduleFrequenciesVariables = {
  headers?: ScheduleReportGetScheduleFrequenciesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportGetScheduleFrequencies = (
  variables: ScheduleReportGetScheduleFrequenciesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ScheduleFrequencies,
    ScheduleReportGetScheduleFrequenciesError,
    undefined,
    ScheduleReportGetScheduleFrequenciesHeaders,
    {},
    {}
  >({ url: '/api/schedule/reports/frequencies', method: 'get', ...variables, signal });

export const useScheduleReportGetScheduleFrequencies = <TData = Schemas.ScheduleFrequencies>(
  variables: ScheduleReportGetScheduleFrequenciesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ScheduleFrequencies, ScheduleReportGetScheduleFrequenciesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ScheduleFrequencies, ScheduleReportGetScheduleFrequenciesError, TData>(
    queryKeyFn({
      path: '/api/schedule/reports/frequencies',
      operationId: 'scheduleReportGetScheduleFrequencies',
      variables
    }),
    ({ signal }) => fetchScheduleReportGetScheduleFrequencies({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleReportRunReportJobsHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleReportRunReportJobsError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleReportRunReportJobsVariables = {
  body?: Schemas.PostKeyDto;
  headers?: ScheduleReportRunReportJobsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleReportRunReportJobs = (
  variables: ScheduleReportRunReportJobsVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, ScheduleReportRunReportJobsError, Schemas.PostKeyDto, ScheduleReportRunReportJobsHeaders, {}, {}>(
    { url: '/api/schedule/reports/runreportjobs', method: 'post', ...variables, signal }
  );

export const useScheduleReportRunReportJobs = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ScheduleReportRunReportJobsError, ScheduleReportRunReportJobsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ScheduleReportRunReportJobsError, ScheduleReportRunReportJobsVariables>(
    (variables: ScheduleReportRunReportJobsVariables) =>
      fetchScheduleReportRunReportJobs({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SchemaGetAuthorizedUserHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetAuthorizedUserError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetAuthorizedUserVariables = {
  headers?: SchemaGetAuthorizedUserHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetAuthorizedUser = (variables: SchemaGetAuthorizedUserVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetAuthorizedUserError, undefined, SchemaGetAuthorizedUserHeaders, {}, {}>({
    url: '/api/Schema/AuthorizedUser',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetAuthorizedUser = <TData = undefined>(
  variables: SchemaGetAuthorizedUserVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetAuthorizedUserError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetAuthorizedUserError, TData>(
    queryKeyFn({ path: '/api/Schema/AuthorizedUser', operationId: 'schemaGetAuthorizedUser', variables }),
    ({ signal }) => fetchSchemaGetAuthorizedUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetCmiHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetCmiError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetCmiVariables = {
  headers?: SchemaGetCmiHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetCmi = (variables: SchemaGetCmiVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetCmiError, undefined, SchemaGetCmiHeaders, {}, {}>({
    url: '/api/Schema/Cmi',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetCmi = <TData = undefined>(
  variables: SchemaGetCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetCmiError, TData>(
    queryKeyFn({ path: '/api/Schema/Cmi', operationId: 'schemaGetCmi', variables }),
    ({ signal }) => fetchSchemaGetCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetConditionMetricHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetConditionMetricError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetConditionMetricVariables = {
  headers?: SchemaGetConditionMetricHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetConditionMetric = (variables: SchemaGetConditionMetricVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetConditionMetricError, undefined, SchemaGetConditionMetricHeaders, {}, {}>({
    url: '/api/Schema/Condition',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetConditionMetric = <TData = undefined>(
  variables: SchemaGetConditionMetricVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetConditionMetricError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetConditionMetricError, TData>(
    queryKeyFn({ path: '/api/Schema/Condition', operationId: 'schemaGetConditionMetric', variables }),
    ({ signal }) => fetchSchemaGetConditionMetric({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetDefaultDatesHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetDefaultDatesError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetDefaultDatesVariables = {
  headers?: SchemaGetDefaultDatesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetDefaultDates = (variables: SchemaGetDefaultDatesVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetDefaultDatesError, undefined, SchemaGetDefaultDatesHeaders, {}, {}>({
    url: '/api/Schema/DefaultDates',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetDefaultDates = <TData = undefined>(
  variables: SchemaGetDefaultDatesVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetDefaultDatesError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetDefaultDatesError, TData>(
    queryKeyFn({ path: '/api/Schema/DefaultDates', operationId: 'schemaGetDefaultDates', variables }),
    ({ signal }) => fetchSchemaGetDefaultDates({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetDocScoreHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetDocScoreError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetDocScoreVariables = {
  headers?: SchemaGetDocScoreHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetDocScore = (variables: SchemaGetDocScoreVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetDocScoreError, undefined, SchemaGetDocScoreHeaders, {}, {}>({
    url: '/api/Schema/DocScore',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetDocScore = <TData = undefined>(
  variables: SchemaGetDocScoreVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetDocScoreError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetDocScoreError, TData>(
    queryKeyFn({ path: '/api/Schema/DocScore', operationId: 'schemaGetDocScore', variables }),
    ({ signal }) => fetchSchemaGetDocScore({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetElixhauserMortalityHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetElixhauserMortalityError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetElixhauserMortalityVariables = {
  headers?: SchemaGetElixhauserMortalityHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetElixhauserMortality = (
  variables: SchemaGetElixhauserMortalityVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, SchemaGetElixhauserMortalityError, undefined, SchemaGetElixhauserMortalityHeaders, {}, {}>({
    url: '/api/Schema/ElixhauserMortality',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetElixhauserMortality = <TData = undefined>(
  variables: SchemaGetElixhauserMortalityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SchemaGetElixhauserMortalityError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetElixhauserMortalityError, TData>(
    queryKeyFn({ path: '/api/Schema/ElixhauserMortality', operationId: 'schemaGetElixhauserMortality', variables }),
    ({ signal }) => fetchSchemaGetElixhauserMortality({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetElixhauserReadmissionHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetElixhauserReadmissionError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetElixhauserReadmissionVariables = {
  headers?: SchemaGetElixhauserReadmissionHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetElixhauserReadmission = (
  variables: SchemaGetElixhauserReadmissionVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, SchemaGetElixhauserReadmissionError, undefined, SchemaGetElixhauserReadmissionHeaders, {}, {}>({
    url: '/api/Schema/ElixhauserReadmission',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetElixhauserReadmission = <TData = undefined>(
  variables: SchemaGetElixhauserReadmissionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SchemaGetElixhauserReadmissionError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetElixhauserReadmissionError, TData>(
    queryKeyFn({ path: '/api/Schema/ElixhauserReadmission', operationId: 'schemaGetElixhauserReadmission', variables }),
    ({ signal }) => fetchSchemaGetElixhauserReadmission({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetLosHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetLosError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetLosVariables = {
  headers?: SchemaGetLosHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetLos = (variables: SchemaGetLosVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetLosError, undefined, SchemaGetLosHeaders, {}, {}>({
    url: '/api/Schema/Los',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetLos = <TData = undefined>(
  variables: SchemaGetLosVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetLosError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetLosError, TData>(
    queryKeyFn({ path: '/api/Schema/Los', operationId: 'schemaGetLos', variables }),
    ({ signal }) => fetchSchemaGetLos({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetOverallPerformanceHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetOverallPerformanceError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetOverallPerformanceVariables = {
  headers?: SchemaGetOverallPerformanceHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetOverallPerformance = (
  variables: SchemaGetOverallPerformanceVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, SchemaGetOverallPerformanceError, undefined, SchemaGetOverallPerformanceHeaders, {}, {}>({
    url: '/api/Schema/overallperformance',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetOverallPerformance = <TData = undefined>(
  variables: SchemaGetOverallPerformanceVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetOverallPerformanceError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetOverallPerformanceError, TData>(
    queryKeyFn({ path: '/api/Schema/overallperformance', operationId: 'schemaGetOverallPerformance', variables }),
    ({ signal }) => fetchSchemaGetOverallPerformance({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetPsiHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetPsiError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetPsiVariables = {
  headers?: SchemaGetPsiHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetPsi = (variables: SchemaGetPsiVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetPsiError, undefined, SchemaGetPsiHeaders, {}, {}>({
    url: '/api/Schema/Psi',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetPsi = <TData = undefined>(
  variables: SchemaGetPsiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetPsiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetPsiError, TData>(
    queryKeyFn({ path: '/api/Schema/Psi', operationId: 'schemaGetPsi', variables }),
    ({ signal }) => fetchSchemaGetPsi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetRafHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetRafError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetRafVariables = {
  headers?: SchemaGetRafHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetRaf = (variables: SchemaGetRafVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetRafError, undefined, SchemaGetRafHeaders, {}, {}>({
    url: '/api/Schema/Raf',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetRaf = <TData = undefined>(
  variables: SchemaGetRafVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetRafError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetRafError, TData>(
    queryKeyFn({ path: '/api/Schema/Raf', operationId: 'schemaGetRaf', variables }),
    ({ signal }) => fetchSchemaGetRaf({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetSeverityCmiHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetSeverityCmiError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetSeverityCmiVariables = {
  headers?: SchemaGetSeverityCmiHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetSeverityCmi = (variables: SchemaGetSeverityCmiVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetSeverityCmiError, undefined, SchemaGetSeverityCmiHeaders, {}, {}>({
    url: '/api/Schema/SeverityCmi',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetSeverityCmi = <TData = undefined>(
  variables: SchemaGetSeverityCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetSeverityCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetSeverityCmiError, TData>(
    queryKeyFn({ path: '/api/Schema/SeverityCmi', operationId: 'schemaGetSeverityCmi', variables }),
    ({ signal }) => fetchSchemaGetSeverityCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetUserTestDtoHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetUserTestDtoError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetUserTestDtoVariables = {
  headers?: SchemaGetUserTestDtoHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetUserTestDto = (variables: SchemaGetUserTestDtoVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetUserTestDtoError, undefined, SchemaGetUserTestDtoHeaders, {}, {}>({
    url: '/api/Schema/UserTestDTO',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetUserTestDto = <TData = undefined>(
  variables: SchemaGetUserTestDtoVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetUserTestDtoError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetUserTestDtoError, TData>(
    queryKeyFn({ path: '/api/Schema/UserTestDTO', operationId: 'schemaGetUserTestDto', variables }),
    ({ signal }) => fetchSchemaGetUserTestDto({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SettingsDeleteFilterPathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsDeleteFilterHeaders = {
  ['x-api-version']?: string;
};

export type SettingsDeleteFilterError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsDeleteFilterVariables = {
  headers?: SettingsDeleteFilterHeaders;
  pathParams: SettingsDeleteFilterPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsDeleteFilter = (variables: SettingsDeleteFilterVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SettingsDeleteFilterError,
    undefined,
    SettingsDeleteFilterHeaders,
    {},
    SettingsDeleteFilterPathParams
  >({ url: '/api/settings/{userId}/histogram/filters/{id}', method: 'delete', ...variables, signal });

export const useSettingsDeleteFilter = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SettingsDeleteFilterError, SettingsDeleteFilterVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, SettingsDeleteFilterError, SettingsDeleteFilterVariables>(
    (variables: SettingsDeleteFilterVariables) => fetchSettingsDeleteFilter({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsGetFilterPathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsGetFilterHeaders = {
  ['x-api-version']?: string;
};

export type SettingsGetFilterError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsGetFilterResponse = Schemas.HistogramFilterDto[];

export type SettingsGetFilterVariables = {
  headers?: SettingsGetFilterHeaders;
  pathParams: SettingsGetFilterPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsGetFilter = (variables: SettingsGetFilterVariables, signal?: AbortSignal) =>
  apiFetch<
    SettingsGetFilterResponse,
    SettingsGetFilterError,
    undefined,
    SettingsGetFilterHeaders,
    {},
    SettingsGetFilterPathParams
  >({ url: '/api/settings/{userId}/histogram/filters/{id}', method: 'get', ...variables, signal });

export const useSettingsGetFilter = <TData = SettingsGetFilterResponse>(
  variables: SettingsGetFilterVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SettingsGetFilterResponse, SettingsGetFilterError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SettingsGetFilterResponse, SettingsGetFilterError, TData>(
    queryKeyFn({ path: '/api/settings/{userId}/histogram/filters/{id}', operationId: 'settingsGetFilter', variables }),
    ({ signal }) => fetchSettingsGetFilter({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SettingsUpdateFilterPathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsUpdateFilterHeaders = {
  ['x-api-version']?: string;
};

export type SettingsUpdateFilterError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsUpdateFilterVariables = {
  body: Schemas.HistogramNewFilterRequestDto;
  headers?: SettingsUpdateFilterHeaders;
  pathParams: SettingsUpdateFilterPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsUpdateFilter = (variables: SettingsUpdateFilterVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SettingsUpdateFilterError,
    Schemas.HistogramNewFilterRequestDto,
    SettingsUpdateFilterHeaders,
    {},
    SettingsUpdateFilterPathParams
  >({ url: '/api/settings/{userId}/histogram/filters/{id}', method: 'put', ...variables, signal });

export const useSettingsUpdateFilter = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SettingsUpdateFilterError, SettingsUpdateFilterVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, SettingsUpdateFilterError, SettingsUpdateFilterVariables>(
    (variables: SettingsUpdateFilterVariables) => fetchSettingsUpdateFilter({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsUpdateFilterNamePathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsUpdateFilterNameHeaders = {
  ['x-api-version']?: string;
};

export type SettingsUpdateFilterNameError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsUpdateFilterNameVariables = {
  body?: Schemas.HistogramSavedFilter;
  headers?: SettingsUpdateFilterNameHeaders;
  pathParams: SettingsUpdateFilterNamePathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsUpdateFilterName = (variables: SettingsUpdateFilterNameVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SettingsUpdateFilterNameError,
    Schemas.HistogramSavedFilter,
    SettingsUpdateFilterNameHeaders,
    {},
    SettingsUpdateFilterNamePathParams
  >({ url: '/api/settings/{userId}/histogram/filters/{id}', method: 'patch', ...variables, signal });

export const useSettingsUpdateFilterName = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SettingsUpdateFilterNameError, SettingsUpdateFilterNameVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, SettingsUpdateFilterNameError, SettingsUpdateFilterNameVariables>(
    (variables: SettingsUpdateFilterNameVariables) =>
      fetchSettingsUpdateFilterName({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsDuplicateFilterPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type SettingsDuplicateFilterQueryParams = {
  /**
   * @format int32
   */
  sourceId: number;
  newName: string;
};

export type SettingsDuplicateFilterHeaders = {
  ['x-api-version']?: string;
};

export type SettingsDuplicateFilterError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsDuplicateFilterVariables = {
  headers?: SettingsDuplicateFilterHeaders;
  pathParams: SettingsDuplicateFilterPathParams;
  queryParams: SettingsDuplicateFilterQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsDuplicateFilter = (variables: SettingsDuplicateFilterVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SettingsDuplicateFilterError,
    undefined,
    SettingsDuplicateFilterHeaders,
    SettingsDuplicateFilterQueryParams,
    SettingsDuplicateFilterPathParams
  >({ url: '/api/settings/{userId}/histogram/filters/duplicate', method: 'post', ...variables, signal });

export const useSettingsDuplicateFilter = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SettingsDuplicateFilterError, SettingsDuplicateFilterVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, SettingsDuplicateFilterError, SettingsDuplicateFilterVariables>(
    (variables: SettingsDuplicateFilterVariables) => fetchSettingsDuplicateFilter({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsGetFiltersPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type SettingsGetFiltersHeaders = {
  ['x-api-version']?: string;
};

export type SettingsGetFiltersError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsGetFiltersResponse = Schemas.HistogramSavedFiltersDto[];

export type SettingsGetFiltersVariables = {
  headers?: SettingsGetFiltersHeaders;
  pathParams: SettingsGetFiltersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsGetFilters = (variables: SettingsGetFiltersVariables, signal?: AbortSignal) =>
  apiFetch<
    SettingsGetFiltersResponse,
    SettingsGetFiltersError,
    undefined,
    SettingsGetFiltersHeaders,
    {},
    SettingsGetFiltersPathParams
  >({ url: '/api/settings/{userId}/histogram/filters', method: 'get', ...variables, signal });

export const useSettingsGetFilters = <TData = SettingsGetFiltersResponse>(
  variables: SettingsGetFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SettingsGetFiltersResponse, SettingsGetFiltersError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SettingsGetFiltersResponse, SettingsGetFiltersError, TData>(
    queryKeyFn({ path: '/api/settings/{userId}/histogram/filters', operationId: 'settingsGetFilters', variables }),
    ({ signal }) => fetchSettingsGetFilters({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SettingsSaveFilterPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type SettingsSaveFilterHeaders = {
  ['x-api-version']?: string;
};

export type SettingsSaveFilterError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsSaveFilterVariables = {
  body: Schemas.HistogramNewFilterRequestDto;
  headers?: SettingsSaveFilterHeaders;
  pathParams: SettingsSaveFilterPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsSaveFilter = (variables: SettingsSaveFilterVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.HistogramSavedFilter,
    SettingsSaveFilterError,
    Schemas.HistogramNewFilterRequestDto,
    SettingsSaveFilterHeaders,
    {},
    SettingsSaveFilterPathParams
  >({ url: '/api/settings/{userId}/histogram/filters', method: 'post', ...variables, signal });

export const useSettingsSaveFilter = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.HistogramSavedFilter, SettingsSaveFilterError, SettingsSaveFilterVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<Schemas.HistogramSavedFilter, SettingsSaveFilterError, SettingsSaveFilterVariables>(
    (variables: SettingsSaveFilterVariables) => fetchSettingsSaveFilter({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SignupCheckSignupIdHeaders = {
  ['x-api-version']?: string;
};

export type SignupCheckSignupIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type SignupCheckSignupIdVariables = {
  body?: Schemas.SignupRequestDto;
  headers?: SignupCheckSignupIdHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSignupCheckSignupId = (variables: SignupCheckSignupIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.SignupResponseDto,
    SignupCheckSignupIdError,
    Schemas.SignupRequestDto,
    SignupCheckSignupIdHeaders,
    {},
    {}
  >({ url: '/api/Signup', method: 'post', ...variables, signal });

export const useSignupCheckSignupId = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.SignupResponseDto, SignupCheckSignupIdError, SignupCheckSignupIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<Schemas.SignupResponseDto, SignupCheckSignupIdError, SignupCheckSignupIdVariables>(
    (variables: SignupCheckSignupIdVariables) => fetchSignupCheckSignupId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SystemReportGenerateExecutiveSummaryReportQueryParams = {
  /**
   * required
   *
   * @format date-time
   */
  reportDate?: string;
  /**
   * optional, for use on BD specifically. Org ID of the system you are running for.
   *
   * @format int32
   */
  systemOrgId?: number;
  /**
   * optional, org hierarchy ID of hospital
   *
   * @format int32
   */
  hospitalOrgId?: number;
  /**
   * optional, org hierarchy ID of physician group, hospital required if this is sent
   *
   * @format int32
   */
  physicianGroupOrgId?: number;
};

export type SystemReportGenerateExecutiveSummaryReportHeaders = {
  ['x-api-version']?: string;
};

export type SystemReportGenerateExecutiveSummaryReportError = Fetcher.ErrorWrapper<undefined>;

export type SystemReportGenerateExecutiveSummaryReportVariables = {
  headers?: SystemReportGenerateExecutiveSummaryReportHeaders;
  queryParams?: SystemReportGenerateExecutiveSummaryReportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSystemReportGenerateExecutiveSummaryReport = (
  variables: SystemReportGenerateExecutiveSummaryReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SystemReportGenerateExecutiveSummaryReportError,
    undefined,
    SystemReportGenerateExecutiveSummaryReportHeaders,
    SystemReportGenerateExecutiveSummaryReportQueryParams,
    {}
  >({ url: '/api/reports/system/executivesummary', method: 'get', ...variables, signal });

export const useSystemReportGenerateExecutiveSummaryReport = <TData = undefined>(
  variables: SystemReportGenerateExecutiveSummaryReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SystemReportGenerateExecutiveSummaryReportError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SystemReportGenerateExecutiveSummaryReportError, TData>(
    queryKeyFn({
      path: '/api/reports/system/executivesummary',
      operationId: 'systemReportGenerateExecutiveSummaryReport',
      variables
    }),
    ({ signal }) => fetchSystemReportGenerateExecutiveSummaryReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SystemReportGenerateMonthlySummaryReportHeaders = {
  ['x-api-version']?: string;
};

export type SystemReportGenerateMonthlySummaryReportError = Fetcher.ErrorWrapper<undefined>;

export type SystemReportGenerateMonthlySummaryReportVariables = {
  body?: Schemas.RequestMonthlyReportRequest;
  headers?: SystemReportGenerateMonthlySummaryReportHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSystemReportGenerateMonthlySummaryReport = (
  variables: SystemReportGenerateMonthlySummaryReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SystemReportGenerateMonthlySummaryReportError,
    Schemas.RequestMonthlyReportRequest,
    SystemReportGenerateMonthlySummaryReportHeaders,
    {},
    {}
  >({ url: '/api/reports/system/monthly', method: 'post', ...variables, signal });

export const useSystemReportGenerateMonthlySummaryReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SystemReportGenerateMonthlySummaryReportError,
      SystemReportGenerateMonthlySummaryReportVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SystemReportGenerateMonthlySummaryReportError,
    SystemReportGenerateMonthlySummaryReportVariables
  >(
    (variables: SystemReportGenerateMonthlySummaryReportVariables) =>
      fetchSystemReportGenerateMonthlySummaryReport({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TenantSettingsGetAuthConfigHeaders = {
  ['x-api-version']?: string;
};

export type TenantSettingsGetAuthConfigError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TenantSettingsGetAuthConfigVariables = {
  headers?: TenantSettingsGetAuthConfigHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTenantSettingsGetAuthConfig = (
  variables: TenantSettingsGetAuthConfigVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelAuth0TenantConfig,
    TenantSettingsGetAuthConfigError,
    undefined,
    TenantSettingsGetAuthConfigHeaders,
    {},
    {}
  >({ url: '/api/TenantSettings/authConfig', method: 'get', ...variables, signal });

export const useTenantSettingsGetAuthConfig = <TData = Schemas.DomainModelAuth0TenantConfig>(
  variables: TenantSettingsGetAuthConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelAuth0TenantConfig, TenantSettingsGetAuthConfigError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelAuth0TenantConfig, TenantSettingsGetAuthConfigError, TData>(
    queryKeyFn({ path: '/api/TenantSettings/authConfig', operationId: 'tenantSettingsGetAuthConfig', variables }),
    ({ signal }) => fetchTenantSettingsGetAuthConfig({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingAssignmentAddProviderTrainingPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
  /**
   * @format int32
   */
  providerOrgHierarchyId: number;
};

export type TrainingAssignmentAddProviderTrainingHeaders = {
  ['x-api-version']?: string;
};

export type TrainingAssignmentAddProviderTrainingError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingAssignmentAddProviderTrainingResponse = Schemas.TrainingProviderConditionTrainingSummary[];

export type TrainingAssignmentAddProviderTrainingVariables = {
  body?: Schemas.TrainingAssignTrainingRequest;
  headers?: TrainingAssignmentAddProviderTrainingHeaders;
  pathParams: TrainingAssignmentAddProviderTrainingPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingAssignmentAddProviderTraining = (
  variables: TrainingAssignmentAddProviderTrainingVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingAssignmentAddProviderTrainingResponse,
    TrainingAssignmentAddProviderTrainingError,
    Schemas.TrainingAssignTrainingRequest,
    TrainingAssignmentAddProviderTrainingHeaders,
    {},
    TrainingAssignmentAddProviderTrainingPathParams
  >({
    url: '/api/training/assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}',
    method: 'put',
    ...variables,
    signal
  });

export const useTrainingAssignmentAddProviderTraining = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      TrainingAssignmentAddProviderTrainingResponse,
      TrainingAssignmentAddProviderTrainingError,
      TrainingAssignmentAddProviderTrainingVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    TrainingAssignmentAddProviderTrainingResponse,
    TrainingAssignmentAddProviderTrainingError,
    TrainingAssignmentAddProviderTrainingVariables
  >(
    (variables: TrainingAssignmentAddProviderTrainingVariables) =>
      fetchTrainingAssignmentAddProviderTraining({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingAssignmentGetProviderTrainingPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
  /**
   * @format int32
   */
  providerOrgHierarchyId: number;
};

export type TrainingAssignmentGetProviderTrainingHeaders = {
  ['x-api-version']?: string;
};

export type TrainingAssignmentGetProviderTrainingError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingAssignmentGetProviderTrainingResponse = Schemas.TrainingProviderConditionTrainingSummary[];

export type TrainingAssignmentGetProviderTrainingVariables = {
  headers?: TrainingAssignmentGetProviderTrainingHeaders;
  pathParams: TrainingAssignmentGetProviderTrainingPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingAssignmentGetProviderTraining = (
  variables: TrainingAssignmentGetProviderTrainingVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingAssignmentGetProviderTrainingResponse,
    TrainingAssignmentGetProviderTrainingError,
    undefined,
    TrainingAssignmentGetProviderTrainingHeaders,
    {},
    TrainingAssignmentGetProviderTrainingPathParams
  >({
    url: '/api/training/assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}',
    method: 'get',
    ...variables,
    signal
  });

export const useTrainingAssignmentGetProviderTraining = <TData = TrainingAssignmentGetProviderTrainingResponse>(
  variables: TrainingAssignmentGetProviderTrainingVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingAssignmentGetProviderTrainingResponse,
      TrainingAssignmentGetProviderTrainingError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingAssignmentGetProviderTrainingResponse,
    TrainingAssignmentGetProviderTrainingError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}',
      operationId: 'trainingAssignmentGetProviderTraining',
      variables
    }),
    ({ signal }) => fetchTrainingAssignmentGetProviderTraining({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingAssignmentDeleteProviderTrainingPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
  /**
   * @format int32
   */
  providerOrgHierarchyId: number;
  /**
   * @format int32
   */
  conditionTrainingId: number;
};

export type TrainingAssignmentDeleteProviderTrainingHeaders = {
  ['x-api-version']?: string;
};

export type TrainingAssignmentDeleteProviderTrainingError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingAssignmentDeleteProviderTrainingVariables = {
  headers?: TrainingAssignmentDeleteProviderTrainingHeaders;
  pathParams: TrainingAssignmentDeleteProviderTrainingPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingAssignmentDeleteProviderTraining = (
  variables: TrainingAssignmentDeleteProviderTrainingVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingAssignmentDeleteProviderTrainingError,
    undefined,
    TrainingAssignmentDeleteProviderTrainingHeaders,
    {},
    TrainingAssignmentDeleteProviderTrainingPathParams
  >({
    url: '/api/training/assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}/{conditionTrainingId}',
    method: 'delete',
    ...variables,
    signal
  });

export const useTrainingAssignmentDeleteProviderTraining = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingAssignmentDeleteProviderTrainingError,
      TrainingAssignmentDeleteProviderTrainingVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingAssignmentDeleteProviderTrainingError,
    TrainingAssignmentDeleteProviderTrainingVariables
  >(
    (variables: TrainingAssignmentDeleteProviderTrainingVariables) =>
      fetchTrainingAssignmentDeleteProviderTraining({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingAssignmentGetDefaultCreditMonthsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingAssignmentGetDefaultCreditMonthsError = Fetcher.ErrorWrapper<undefined>;

export type TrainingAssignmentGetDefaultCreditMonthsVariables = {
  headers?: TrainingAssignmentGetDefaultCreditMonthsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingAssignmentGetDefaultCreditMonths = (
  variables: TrainingAssignmentGetDefaultCreditMonthsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    number,
    TrainingAssignmentGetDefaultCreditMonthsError,
    undefined,
    TrainingAssignmentGetDefaultCreditMonthsHeaders,
    {},
    {}
  >({ url: '/api/training/assignment/defaultcreditmonths', method: 'get', ...variables, signal });

export const useTrainingAssignmentGetDefaultCreditMonths = <TData = number>(
  variables: TrainingAssignmentGetDefaultCreditMonthsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<number, TrainingAssignmentGetDefaultCreditMonthsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<number, TrainingAssignmentGetDefaultCreditMonthsError, TData>(
    queryKeyFn({
      path: '/api/training/assignment/defaultcreditmonths',
      operationId: 'trainingAssignmentGetDefaultCreditMonths',
      variables
    }),
    ({ signal }) => fetchTrainingAssignmentGetDefaultCreditMonths({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingAssignmentGetGroupTrainingConfigPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type TrainingAssignmentGetGroupTrainingConfigHeaders = {
  ['x-api-version']?: string;
};

export type TrainingAssignmentGetGroupTrainingConfigError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingAssignmentGetGroupTrainingConfigResponse = Schemas.TrainingProviderTrainingSummary[];

export type TrainingAssignmentGetGroupTrainingConfigVariables = {
  headers?: TrainingAssignmentGetGroupTrainingConfigHeaders;
  pathParams: TrainingAssignmentGetGroupTrainingConfigPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingAssignmentGetGroupTrainingConfig = (
  variables: TrainingAssignmentGetGroupTrainingConfigVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingAssignmentGetGroupTrainingConfigResponse,
    TrainingAssignmentGetGroupTrainingConfigError,
    undefined,
    TrainingAssignmentGetGroupTrainingConfigHeaders,
    {},
    TrainingAssignmentGetGroupTrainingConfigPathParams
  >({ url: '/api/training/assignment/{physicianGroupOrgHierarchyId}', method: 'get', ...variables, signal });

export const useTrainingAssignmentGetGroupTrainingConfig = <TData = TrainingAssignmentGetGroupTrainingConfigResponse>(
  variables: TrainingAssignmentGetGroupTrainingConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingAssignmentGetGroupTrainingConfigResponse,
      TrainingAssignmentGetGroupTrainingConfigError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingAssignmentGetGroupTrainingConfigResponse,
    TrainingAssignmentGetGroupTrainingConfigError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/assignment/{physicianGroupOrgHierarchyId}',
      operationId: 'trainingAssignmentGetGroupTrainingConfig',
      variables
    }),
    ({ signal }) => fetchTrainingAssignmentGetGroupTrainingConfig({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingAssignmentGetProviderTrainingConfigPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type TrainingAssignmentGetProviderTrainingConfigHeaders = {
  ['x-api-version']?: string;
};

export type TrainingAssignmentGetProviderTrainingConfigError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingAssignmentGetProviderTrainingConfigResponse = Schemas.TrainingProviderTrainingSummary[];

export type TrainingAssignmentGetProviderTrainingConfigVariables = {
  body?: Schemas.TrainingAssignGroupTrainingRequest;
  headers?: TrainingAssignmentGetProviderTrainingConfigHeaders;
  pathParams: TrainingAssignmentGetProviderTrainingConfigPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingAssignmentGetProviderTrainingConfig = (
  variables: TrainingAssignmentGetProviderTrainingConfigVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingAssignmentGetProviderTrainingConfigResponse,
    TrainingAssignmentGetProviderTrainingConfigError,
    Schemas.TrainingAssignGroupTrainingRequest,
    TrainingAssignmentGetProviderTrainingConfigHeaders,
    {},
    TrainingAssignmentGetProviderTrainingConfigPathParams
  >({ url: '/api/training/assignment/{physicianGroupOrgHierarchyId}', method: 'put', ...variables, signal });

export const useTrainingAssignmentGetProviderTrainingConfig = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      TrainingAssignmentGetProviderTrainingConfigResponse,
      TrainingAssignmentGetProviderTrainingConfigError,
      TrainingAssignmentGetProviderTrainingConfigVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    TrainingAssignmentGetProviderTrainingConfigResponse,
    TrainingAssignmentGetProviderTrainingConfigError,
    TrainingAssignmentGetProviderTrainingConfigVariables
  >(
    (variables: TrainingAssignmentGetProviderTrainingConfigVariables) =>
      fetchTrainingAssignmentGetProviderTrainingConfig({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingAssignmentSendRemindersHeaders = {
  ['x-api-version']?: string;
};

export type TrainingAssignmentSendRemindersError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingAssignmentSendRemindersVariables = {
  body?: Schemas.TrainingSendReminderRequest;
  headers?: TrainingAssignmentSendRemindersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingAssignmentSendReminders = (
  variables: TrainingAssignmentSendRemindersVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingAssignmentSendRemindersError,
    Schemas.TrainingSendReminderRequest,
    TrainingAssignmentSendRemindersHeaders,
    {},
    {}
  >({ url: '/api/training/assignment/reminders', method: 'post', ...variables, signal });

export const useTrainingAssignmentSendReminders = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingAssignmentSendRemindersError,
      TrainingAssignmentSendRemindersVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingAssignmentSendRemindersError,
    TrainingAssignmentSendRemindersVariables
  >(
    (variables: TrainingAssignmentSendRemindersVariables) =>
      fetchTrainingAssignmentSendReminders({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingConditionPerformanceGetConditionPerformanceQueryParams = {
  /**
   * OrgId for Admins
   *
   * @format int32
   */
  orgId?: number;
  /**
   * Number of conditions
   *
   * @format int32
   * @default 2147483647
   */
  count?: number;
  /**
   * Filter only conditions with training content
   *
   * @default false
   */
  withTraining?: boolean;
};

export type TrainingConditionPerformanceGetConditionPerformanceHeaders = {
  ['x-api-version']?: string;
};

export type TrainingConditionPerformanceGetConditionPerformanceError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingConditionPerformanceGetConditionPerformanceVariables = {
  headers?: TrainingConditionPerformanceGetConditionPerformanceHeaders;
  queryParams?: TrainingConditionPerformanceGetConditionPerformanceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingConditionPerformanceGetConditionPerformance = (
  variables: TrainingConditionPerformanceGetConditionPerformanceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ConditionsMetricDtoTrainingTrainingConditionMetric,
    TrainingConditionPerformanceGetConditionPerformanceError,
    undefined,
    TrainingConditionPerformanceGetConditionPerformanceHeaders,
    TrainingConditionPerformanceGetConditionPerformanceQueryParams,
    {}
  >({ url: '/api/training/ConditionPerformance', method: 'get', ...variables, signal });

export const useTrainingConditionPerformanceGetConditionPerformance = <
  TData = Schemas.ConditionsMetricDtoTrainingTrainingConditionMetric
>(
  variables: TrainingConditionPerformanceGetConditionPerformanceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ConditionsMetricDtoTrainingTrainingConditionMetric,
      TrainingConditionPerformanceGetConditionPerformanceError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ConditionsMetricDtoTrainingTrainingConditionMetric,
    TrainingConditionPerformanceGetConditionPerformanceError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/ConditionPerformance',
      operationId: 'trainingConditionPerformanceGetConditionPerformance',
      variables
    }),
    ({ signal }) =>
      fetchTrainingConditionPerformanceGetConditionPerformance({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentAddPocketCardHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentAddPocketCardError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentAddPocketCardVariables = {
  body?: Schemas.TrainingPocketCard;
  headers?: TrainingContentAddPocketCardHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentAddPocketCard = (
  variables: TrainingContentAddPocketCardVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentAddPocketCardError,
    Schemas.TrainingPocketCard,
    TrainingContentAddPocketCardHeaders,
    {},
    {}
  >({ url: '/api/training/content/pocketcards', method: 'post', ...variables, signal });

export const useTrainingContentAddPocketCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, TrainingContentAddPocketCardError, TrainingContentAddPocketCardVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, TrainingContentAddPocketCardError, TrainingContentAddPocketCardVariables>(
    (variables: TrainingContentAddPocketCardVariables) =>
      fetchTrainingContentAddPocketCard({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentAddQuestionHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentAddQuestionError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentAddQuestionVariables = {
  body?: Schemas.TrainingQuestion;
  headers?: TrainingContentAddQuestionHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentAddQuestion = (variables: TrainingContentAddQuestionVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    TrainingContentAddQuestionError,
    Schemas.TrainingQuestion,
    TrainingContentAddQuestionHeaders,
    {},
    {}
  >({ url: '/api/training/content/questions', method: 'post', ...variables, signal });

export const useTrainingContentAddQuestion = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, TrainingContentAddQuestionError, TrainingContentAddQuestionVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, TrainingContentAddQuestionError, TrainingContentAddQuestionVariables>(
    (variables: TrainingContentAddQuestionVariables) =>
      fetchTrainingContentAddQuestion({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentAddSpecialtyBasketHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentAddSpecialtyBasketError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentAddSpecialtyBasketVariables = {
  body: Schemas.TrainingSpecialtyBasket;
  headers?: TrainingContentAddSpecialtyBasketHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentAddSpecialtyBasket = (
  variables: TrainingContentAddSpecialtyBasketVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentAddSpecialtyBasketError,
    Schemas.TrainingSpecialtyBasket,
    TrainingContentAddSpecialtyBasketHeaders,
    {},
    {}
  >({ url: '/api/training/content/specialtybaskets', method: 'post', ...variables, signal });

export const useTrainingContentAddSpecialtyBasket = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingContentAddSpecialtyBasketError,
      TrainingContentAddSpecialtyBasketVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingContentAddSpecialtyBasketError,
    TrainingContentAddSpecialtyBasketVariables
  >(
    (variables: TrainingContentAddSpecialtyBasketVariables) =>
      fetchTrainingContentAddSpecialtyBasket({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentGetSpecialtyBasketsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetSpecialtyBasketsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetSpecialtyBasketsVariables = {
  headers?: TrainingContentGetSpecialtyBasketsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetSpecialtyBaskets = (
  variables: TrainingContentGetSpecialtyBasketsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingSpecialtyBaskets,
    TrainingContentGetSpecialtyBasketsError,
    undefined,
    TrainingContentGetSpecialtyBasketsHeaders,
    {},
    {}
  >({ url: '/api/training/content/specialtybaskets', method: 'get', ...variables, signal });

export const useTrainingContentGetSpecialtyBaskets = <TData = Schemas.TrainingSpecialtyBaskets>(
  variables: TrainingContentGetSpecialtyBasketsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingSpecialtyBaskets, TrainingContentGetSpecialtyBasketsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingSpecialtyBaskets, TrainingContentGetSpecialtyBasketsError, TData>(
    queryKeyFn({
      path: '/api/training/content/specialtybaskets',
      operationId: 'trainingContentGetSpecialtyBaskets',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetSpecialtyBaskets({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentAddTrainingVideoHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentAddTrainingVideoError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentAddTrainingVideoVariables = {
  body?: Schemas.TrainingVideo;
  headers?: TrainingContentAddTrainingVideoHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentAddTrainingVideo = (
  variables: TrainingContentAddTrainingVideoVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentAddTrainingVideoError,
    Schemas.TrainingVideo,
    TrainingContentAddTrainingVideoHeaders,
    {},
    {}
  >({ url: '/api/training/content/trainingvideos', method: 'post', ...variables, signal });

export const useTrainingContentAddTrainingVideo = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingContentAddTrainingVideoError,
      TrainingContentAddTrainingVideoVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingContentAddTrainingVideoError,
    TrainingContentAddTrainingVideoVariables
  >(
    (variables: TrainingContentAddTrainingVideoVariables) =>
      fetchTrainingContentAddTrainingVideo({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentGetConditionReferencePathParams = {
  /**
   * @format int32
   */
  conditionId: number;
};

export type TrainingContentGetConditionReferenceQueryParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId?: number;
  /**
   * optional query param for mobile-specific response behavior
   *
   * @default false
   */
  mobile?: boolean;
};

export type TrainingContentGetConditionReferenceHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetConditionReferenceError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingContentGetConditionReferenceVariables = {
  headers?: TrainingContentGetConditionReferenceHeaders;
  pathParams: TrainingContentGetConditionReferencePathParams;
  queryParams?: TrainingContentGetConditionReferenceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetConditionReference = (
  variables: TrainingContentGetConditionReferenceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingConditionReference,
    TrainingContentGetConditionReferenceError,
    undefined,
    TrainingContentGetConditionReferenceHeaders,
    TrainingContentGetConditionReferenceQueryParams,
    TrainingContentGetConditionReferencePathParams
  >({ url: '/api/training/content/conditions/{conditionId}', method: 'get', ...variables, signal });

export const useTrainingContentGetConditionReference = <TData = Schemas.TrainingConditionReference>(
  variables: TrainingContentGetConditionReferenceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingConditionReference, TrainingContentGetConditionReferenceError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingConditionReference, TrainingContentGetConditionReferenceError, TData>(
    queryKeyFn({
      path: '/api/training/content/conditions/{conditionId}',
      operationId: 'trainingContentGetConditionReference',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetConditionReference({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentGetContentConditionsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetContentConditionsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetContentConditionsVariables = {
  headers?: TrainingContentGetContentConditionsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetContentConditions = (
  variables: TrainingContentGetContentConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingContentConditions,
    TrainingContentGetContentConditionsError,
    undefined,
    TrainingContentGetContentConditionsHeaders,
    {},
    {}
  >({ url: '/api/training/content/contentconditions', method: 'get', ...variables, signal });

export const useTrainingContentGetContentConditions = <TData = Schemas.TrainingContentConditions>(
  variables: TrainingContentGetContentConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingContentConditions, TrainingContentGetContentConditionsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingContentConditions, TrainingContentGetContentConditionsError, TData>(
    queryKeyFn({
      path: '/api/training/content/contentconditions',
      operationId: 'trainingContentGetContentConditions',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetContentConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentGetContentConditionsWithTrainingContentHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetContentConditionsWithTrainingContentError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetContentConditionsWithTrainingContentVariables = {
  headers?: TrainingContentGetContentConditionsWithTrainingContentHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetContentConditionsWithTrainingContent = (
  variables: TrainingContentGetContentConditionsWithTrainingContentVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingContentConditions,
    TrainingContentGetContentConditionsWithTrainingContentError,
    undefined,
    TrainingContentGetContentConditionsWithTrainingContentHeaders,
    {},
    {}
  >({ url: '/api/training/content/contentconditionswithtrainingcontent', method: 'get', ...variables, signal });

export const useTrainingContentGetContentConditionsWithTrainingContent = <TData = Schemas.TrainingContentConditions>(
  variables: TrainingContentGetContentConditionsWithTrainingContentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingContentConditions,
      TrainingContentGetContentConditionsWithTrainingContentError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingContentConditions,
    TrainingContentGetContentConditionsWithTrainingContentError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/content/contentconditionswithtrainingcontent',
      operationId: 'trainingContentGetContentConditionsWithTrainingContent',
      variables
    }),
    ({ signal }) =>
      fetchTrainingContentGetContentConditionsWithTrainingContent({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentGetFundamentalSpecialtiesHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetFundamentalSpecialtiesError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetFundamentalSpecialtiesVariables = {
  headers?: TrainingContentGetFundamentalSpecialtiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetFundamentalSpecialties = (
  variables: TrainingContentGetFundamentalSpecialtiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingFundamentalSpecialties,
    TrainingContentGetFundamentalSpecialtiesError,
    undefined,
    TrainingContentGetFundamentalSpecialtiesHeaders,
    {},
    {}
  >({ url: '/api/training/content/fundamentalspecialties', method: 'get', ...variables, signal });

export const useTrainingContentGetFundamentalSpecialties = <TData = Schemas.TrainingFundamentalSpecialties>(
  variables: TrainingContentGetFundamentalSpecialtiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingFundamentalSpecialties,
      TrainingContentGetFundamentalSpecialtiesError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingFundamentalSpecialties,
    TrainingContentGetFundamentalSpecialtiesError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/content/fundamentalspecialties',
      operationId: 'trainingContentGetFundamentalSpecialties',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetFundamentalSpecialties({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentGetPocketCardPathParams = {
  /**
   * @format int32
   */
  pocketCardId: number;
};

export type TrainingContentGetPocketCardHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetPocketCardError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetPocketCardVariables = {
  headers?: TrainingContentGetPocketCardHeaders;
  pathParams: TrainingContentGetPocketCardPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetPocketCard = (
  variables: TrainingContentGetPocketCardVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingPocketCard,
    TrainingContentGetPocketCardError,
    undefined,
    TrainingContentGetPocketCardHeaders,
    {},
    TrainingContentGetPocketCardPathParams
  >({ url: '/api/training/content/pocketcards/{pocketCardId}', method: 'get', ...variables, signal });

export const useTrainingContentGetPocketCard = <TData = Schemas.TrainingPocketCard>(
  variables: TrainingContentGetPocketCardVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingPocketCard, TrainingContentGetPocketCardError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingPocketCard, TrainingContentGetPocketCardError, TData>(
    queryKeyFn({
      path: '/api/training/content/pocketcards/{pocketCardId}',
      operationId: 'trainingContentGetPocketCard',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetPocketCard({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentUpdatePocketCardPathParams = {
  pocketCardId: string;
};

export type TrainingContentUpdatePocketCardHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentUpdatePocketCardError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentUpdatePocketCardVariables = {
  body?: Schemas.TrainingPocketCard;
  headers?: TrainingContentUpdatePocketCardHeaders;
  pathParams: TrainingContentUpdatePocketCardPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentUpdatePocketCard = (
  variables: TrainingContentUpdatePocketCardVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentUpdatePocketCardError,
    Schemas.TrainingPocketCard,
    TrainingContentUpdatePocketCardHeaders,
    {},
    TrainingContentUpdatePocketCardPathParams
  >({ url: '/api/training/content/pocketcards/{pocketCardId}', method: 'put', ...variables, signal });

export const useTrainingContentUpdatePocketCard = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingContentUpdatePocketCardError,
      TrainingContentUpdatePocketCardVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingContentUpdatePocketCardError,
    TrainingContentUpdatePocketCardVariables
  >(
    (variables: TrainingContentUpdatePocketCardVariables) =>
      fetchTrainingContentUpdatePocketCard({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentGetQuestionPathParams = {
  /**
   * @format int32
   */
  questionId: number;
};

export type TrainingContentGetQuestionHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetQuestionError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetQuestionVariables = {
  headers?: TrainingContentGetQuestionHeaders;
  pathParams: TrainingContentGetQuestionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetQuestion = (variables: TrainingContentGetQuestionVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.TrainingQuestion,
    TrainingContentGetQuestionError,
    undefined,
    TrainingContentGetQuestionHeaders,
    {},
    TrainingContentGetQuestionPathParams
  >({ url: '/api/training/content/questions/{questionId}', method: 'get', ...variables, signal });

export const useTrainingContentGetQuestion = <TData = Schemas.TrainingQuestion>(
  variables: TrainingContentGetQuestionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingQuestion, TrainingContentGetQuestionError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingQuestion, TrainingContentGetQuestionError, TData>(
    queryKeyFn({
      path: '/api/training/content/questions/{questionId}',
      operationId: 'trainingContentGetQuestion',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetQuestion({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentUpdateQuestionPathParams = {
  questionId: string;
};

export type TrainingContentUpdateQuestionHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentUpdateQuestionError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentUpdateQuestionVariables = {
  body?: Schemas.TrainingQuestion;
  headers?: TrainingContentUpdateQuestionHeaders;
  pathParams: TrainingContentUpdateQuestionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentUpdateQuestion = (
  variables: TrainingContentUpdateQuestionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentUpdateQuestionError,
    Schemas.TrainingQuestion,
    TrainingContentUpdateQuestionHeaders,
    {},
    TrainingContentUpdateQuestionPathParams
  >({ url: '/api/training/content/questions/{questionId}', method: 'put', ...variables, signal });

export const useTrainingContentUpdateQuestion = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingContentUpdateQuestionError,
      TrainingContentUpdateQuestionVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, TrainingContentUpdateQuestionError, TrainingContentUpdateQuestionVariables>(
    (variables: TrainingContentUpdateQuestionVariables) =>
      fetchTrainingContentUpdateQuestion({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentGetSpecialtyBasketPathParams = {
  /**
   * @format int32
   */
  specialtyBasketId: number;
};

export type TrainingContentGetSpecialtyBasketHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetSpecialtyBasketError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetSpecialtyBasketVariables = {
  headers?: TrainingContentGetSpecialtyBasketHeaders;
  pathParams: TrainingContentGetSpecialtyBasketPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetSpecialtyBasket = (
  variables: TrainingContentGetSpecialtyBasketVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingSpecialtyBasket,
    TrainingContentGetSpecialtyBasketError,
    undefined,
    TrainingContentGetSpecialtyBasketHeaders,
    {},
    TrainingContentGetSpecialtyBasketPathParams
  >({ url: '/api/training/content/specialtybaskets/{specialtyBasketId}', method: 'get', ...variables, signal });

export const useTrainingContentGetSpecialtyBasket = <TData = Schemas.TrainingSpecialtyBasket>(
  variables: TrainingContentGetSpecialtyBasketVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingSpecialtyBasket, TrainingContentGetSpecialtyBasketError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingSpecialtyBasket, TrainingContentGetSpecialtyBasketError, TData>(
    queryKeyFn({
      path: '/api/training/content/specialtybaskets/{specialtyBasketId}',
      operationId: 'trainingContentGetSpecialtyBasket',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetSpecialtyBasket({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentUpdateSpecialtyBasketPathParams = {
  specialtyBasketId: string;
};

export type TrainingContentUpdateSpecialtyBasketHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentUpdateSpecialtyBasketError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentUpdateSpecialtyBasketVariables = {
  body: Schemas.TrainingSpecialtyBasket;
  headers?: TrainingContentUpdateSpecialtyBasketHeaders;
  pathParams: TrainingContentUpdateSpecialtyBasketPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentUpdateSpecialtyBasket = (
  variables: TrainingContentUpdateSpecialtyBasketVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentUpdateSpecialtyBasketError,
    Schemas.TrainingSpecialtyBasket,
    TrainingContentUpdateSpecialtyBasketHeaders,
    {},
    TrainingContentUpdateSpecialtyBasketPathParams
  >({ url: '/api/training/content/specialtybaskets/{specialtyBasketId}', method: 'put', ...variables, signal });

export const useTrainingContentUpdateSpecialtyBasket = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingContentUpdateSpecialtyBasketError,
      TrainingContentUpdateSpecialtyBasketVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingContentUpdateSpecialtyBasketError,
    TrainingContentUpdateSpecialtyBasketVariables
  >(
    (variables: TrainingContentUpdateSpecialtyBasketVariables) =>
      fetchTrainingContentUpdateSpecialtyBasket({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentGetSpecialtyBasketsForContentConditionPathParams = {
  /**
   * @format int32
   */
  contentConditionId: number;
};

export type TrainingContentGetSpecialtyBasketsForContentConditionHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetSpecialtyBasketsForContentConditionError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetSpecialtyBasketsForContentConditionVariables = {
  headers?: TrainingContentGetSpecialtyBasketsForContentConditionHeaders;
  pathParams: TrainingContentGetSpecialtyBasketsForContentConditionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetSpecialtyBasketsForContentCondition = (
  variables: TrainingContentGetSpecialtyBasketsForContentConditionVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingSpecialtyBaskets,
    TrainingContentGetSpecialtyBasketsForContentConditionError,
    undefined,
    TrainingContentGetSpecialtyBasketsForContentConditionHeaders,
    {},
    TrainingContentGetSpecialtyBasketsForContentConditionPathParams
  >({
    url: '/api/training/content/specialtybasketsforcontentcondition/{contentConditionId}',
    method: 'get',
    ...variables,
    signal
  });

export const useTrainingContentGetSpecialtyBasketsForContentCondition = <TData = Schemas.TrainingSpecialtyBaskets>(
  variables: TrainingContentGetSpecialtyBasketsForContentConditionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingSpecialtyBaskets,
      TrainingContentGetSpecialtyBasketsForContentConditionError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingSpecialtyBaskets,
    TrainingContentGetSpecialtyBasketsForContentConditionError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/content/specialtybasketsforcontentcondition/{contentConditionId}',
      operationId: 'trainingContentGetSpecialtyBasketsForContentCondition',
      variables
    }),
    ({ signal }) =>
      fetchTrainingContentGetSpecialtyBasketsForContentCondition({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentGetTrainingContentPathParams = {
  /**
   * @format int32
   */
  contentConditionId: number;
};

export type TrainingContentGetTrainingContentHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetTrainingContentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingContentGetTrainingContentVariables = {
  headers?: TrainingContentGetTrainingContentHeaders;
  pathParams: TrainingContentGetTrainingContentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetTrainingContent = (
  variables: TrainingContentGetTrainingContentVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTrainingContent,
    TrainingContentGetTrainingContentError,
    undefined,
    TrainingContentGetTrainingContentHeaders,
    {},
    TrainingContentGetTrainingContentPathParams
  >({ url: '/api/training/content/{contentConditionId}', method: 'get', ...variables, signal });

export const useTrainingContentGetTrainingContent = <TData = Schemas.TrainingTrainingContent>(
  variables: TrainingContentGetTrainingContentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingTrainingContent, TrainingContentGetTrainingContentError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingTrainingContent, TrainingContentGetTrainingContentError, TData>(
    queryKeyFn({
      path: '/api/training/content/{contentConditionId}',
      operationId: 'trainingContentGetTrainingContent',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetTrainingContent({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentSaveTrainingContentPathParams = {
  contentConditionId: string;
};

export type TrainingContentSaveTrainingContentHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentSaveTrainingContentError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentSaveTrainingContentVariables = {
  body?: Schemas.TrainingTrainingContent;
  headers?: TrainingContentSaveTrainingContentHeaders;
  pathParams: TrainingContentSaveTrainingContentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentSaveTrainingContent = (
  variables: TrainingContentSaveTrainingContentVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentSaveTrainingContentError,
    Schemas.TrainingTrainingContent,
    TrainingContentSaveTrainingContentHeaders,
    {},
    TrainingContentSaveTrainingContentPathParams
  >({ url: '/api/training/content/{contentConditionId}', method: 'put', ...variables, signal });

export const useTrainingContentSaveTrainingContent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingContentSaveTrainingContentError,
      TrainingContentSaveTrainingContentVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingContentSaveTrainingContentError,
    TrainingContentSaveTrainingContentVariables
  >(
    (variables: TrainingContentSaveTrainingContentVariables) =>
      fetchTrainingContentSaveTrainingContent({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentGetTrainingVideoPathParams = {
  /**
   * @format int32
   */
  trainingVideoId: number;
};

export type TrainingContentGetTrainingVideoHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetTrainingVideoError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentGetTrainingVideoVariables = {
  headers?: TrainingContentGetTrainingVideoHeaders;
  pathParams: TrainingContentGetTrainingVideoPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetTrainingVideo = (
  variables: TrainingContentGetTrainingVideoVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingVideo,
    TrainingContentGetTrainingVideoError,
    undefined,
    TrainingContentGetTrainingVideoHeaders,
    {},
    TrainingContentGetTrainingVideoPathParams
  >({ url: '/api/training/content/trainingvideos/{trainingVideoId}', method: 'get', ...variables, signal });

export const useTrainingContentGetTrainingVideo = <TData = Schemas.TrainingVideo>(
  variables: TrainingContentGetTrainingVideoVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingVideo, TrainingContentGetTrainingVideoError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingVideo, TrainingContentGetTrainingVideoError, TData>(
    queryKeyFn({
      path: '/api/training/content/trainingvideos/{trainingVideoId}',
      operationId: 'trainingContentGetTrainingVideo',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetTrainingVideo({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingContentUpdateTrainingVideoPathParams = {
  trainingVideoId: string;
};

export type TrainingContentUpdateTrainingVideoHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentUpdateTrainingVideoError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingContentUpdateTrainingVideoVariables = {
  body?: Schemas.TrainingVideo;
  headers?: TrainingContentUpdateTrainingVideoHeaders;
  pathParams: TrainingContentUpdateTrainingVideoPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentUpdateTrainingVideo = (
  variables: TrainingContentUpdateTrainingVideoVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingContentUpdateTrainingVideoError,
    Schemas.TrainingVideo,
    TrainingContentUpdateTrainingVideoHeaders,
    {},
    TrainingContentUpdateTrainingVideoPathParams
  >({ url: '/api/training/content/trainingvideos/{trainingVideoId}', method: 'put', ...variables, signal });

export const useTrainingContentUpdateTrainingVideo = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingContentUpdateTrainingVideoError,
      TrainingContentUpdateTrainingVideoVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingContentUpdateTrainingVideoError,
    TrainingContentUpdateTrainingVideoVariables
  >(
    (variables: TrainingContentUpdateTrainingVideoVariables) =>
      fetchTrainingContentUpdateTrainingVideo({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingContentGetVideoLinkTypesHeaders = {
  ['x-api-version']?: string;
};

export type TrainingContentGetVideoLinkTypesError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingContentGetVideoLinkTypesVariables = {
  headers?: TrainingContentGetVideoLinkTypesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingContentGetVideoLinkTypes = (
  variables: TrainingContentGetVideoLinkTypesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingVideoLinkTypes,
    TrainingContentGetVideoLinkTypesError,
    undefined,
    TrainingContentGetVideoLinkTypesHeaders,
    {},
    {}
  >({ url: '/api/training/content/videolinktypes', method: 'get', ...variables, signal });

export const useTrainingContentGetVideoLinkTypes = <TData = Schemas.TrainingVideoLinkTypes>(
  variables: TrainingContentGetVideoLinkTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingVideoLinkTypes, TrainingContentGetVideoLinkTypesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingVideoLinkTypes, TrainingContentGetVideoLinkTypesError, TData>(
    queryKeyFn({
      path: '/api/training/content/videolinktypes',
      operationId: 'trainingContentGetVideoLinkTypes',
      variables
    }),
    ({ signal }) => fetchTrainingContentGetVideoLinkTypes({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingManagementGetTrainingApprovalContentConditionsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingManagementGetTrainingApprovalContentConditionsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingManagementGetTrainingApprovalContentConditionsVariables = {
  headers?: TrainingManagementGetTrainingApprovalContentConditionsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingManagementGetTrainingApprovalContentConditions = (
  variables: TrainingManagementGetTrainingApprovalContentConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTrainingApprovalContentConditions,
    TrainingManagementGetTrainingApprovalContentConditionsError,
    undefined,
    TrainingManagementGetTrainingApprovalContentConditionsHeaders,
    {},
    {}
  >({ url: '/api/training/management/approvaltrainingcontent', method: 'get', ...variables, signal });

export const useTrainingManagementGetTrainingApprovalContentConditions = <
  TData = Schemas.TrainingTrainingApprovalContentConditions
>(
  variables: TrainingManagementGetTrainingApprovalContentConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingTrainingApprovalContentConditions,
      TrainingManagementGetTrainingApprovalContentConditionsError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingTrainingApprovalContentConditions,
    TrainingManagementGetTrainingApprovalContentConditionsError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/management/approvaltrainingcontent',
      operationId: 'trainingManagementGetTrainingApprovalContentConditions',
      variables
    }),
    ({ signal }) =>
      fetchTrainingManagementGetTrainingApprovalContentConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingManagementSaveApprovedTrainingContentHeaders = {
  ['x-api-version']?: string;
};

export type TrainingManagementSaveApprovedTrainingContentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingManagementSaveApprovedTrainingContentVariables = {
  body?: Schemas.TrainingTrainingApprovalContentCondition;
  headers?: TrainingManagementSaveApprovedTrainingContentHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingManagementSaveApprovedTrainingContent = (
  variables: TrainingManagementSaveApprovedTrainingContentVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingManagementSaveApprovedTrainingContentError,
    Schemas.TrainingTrainingApprovalContentCondition,
    TrainingManagementSaveApprovedTrainingContentHeaders,
    {},
    {}
  >({ url: '/api/training/management/approvaltrainingcontent', method: 'put', ...variables, signal });

export const useTrainingManagementSaveApprovedTrainingContent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingManagementSaveApprovedTrainingContentError,
      TrainingManagementSaveApprovedTrainingContentVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingManagementSaveApprovedTrainingContentError,
    TrainingManagementSaveApprovedTrainingContentVariables
  >(
    (variables: TrainingManagementSaveApprovedTrainingContentVariables) =>
      fetchTrainingManagementSaveApprovedTrainingContent({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingManagementGetTrainingContentWithApprovalPathParams = {
  /**
   * @format int32
   */
  conditionId: number;
};

export type TrainingManagementGetTrainingContentWithApprovalHeaders = {
  ['x-api-version']?: string;
};

export type TrainingManagementGetTrainingContentWithApprovalError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingManagementGetTrainingContentWithApprovalVariables = {
  headers?: TrainingManagementGetTrainingContentWithApprovalHeaders;
  pathParams: TrainingManagementGetTrainingContentWithApprovalPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingManagementGetTrainingContentWithApproval = (
  variables: TrainingManagementGetTrainingContentWithApprovalVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTrainingApprovals,
    TrainingManagementGetTrainingContentWithApprovalError,
    undefined,
    TrainingManagementGetTrainingContentWithApprovalHeaders,
    {},
    TrainingManagementGetTrainingContentWithApprovalPathParams
  >({ url: '/api/training/management/content/{conditionId}', method: 'get', ...variables, signal });

export const useTrainingManagementGetTrainingContentWithApproval = <TData = Schemas.TrainingTrainingApprovals>(
  variables: TrainingManagementGetTrainingContentWithApprovalVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingTrainingApprovals,
      TrainingManagementGetTrainingContentWithApprovalError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingTrainingApprovals,
    TrainingManagementGetTrainingContentWithApprovalError,
    TData
  >(
    queryKeyFn({
      path: '/api/training/management/content/{conditionId}',
      operationId: 'trainingManagementGetTrainingContentWithApproval',
      variables
    }),
    ({ signal }) => fetchTrainingManagementGetTrainingContentWithApproval({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportDownloadGroupTrainingSummaryPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type TrainingReportDownloadGroupTrainingSummaryHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportDownloadGroupTrainingSummaryError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingReportDownloadGroupTrainingSummaryVariables = {
  headers?: TrainingReportDownloadGroupTrainingSummaryHeaders;
  pathParams: TrainingReportDownloadGroupTrainingSummaryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportDownloadGroupTrainingSummary = (
  variables: TrainingReportDownloadGroupTrainingSummaryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    TrainingReportDownloadGroupTrainingSummaryError,
    undefined,
    TrainingReportDownloadGroupTrainingSummaryHeaders,
    {},
    TrainingReportDownloadGroupTrainingSummaryPathParams
  >({
    url: '/api/reports/training/physicianGroup/{physicianGroupOrgHierarchyId}/download',
    method: 'get',
    ...variables,
    signal
  });

export const useTrainingReportDownloadGroupTrainingSummary = <TData = Blob>(
  variables: TrainingReportDownloadGroupTrainingSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, TrainingReportDownloadGroupTrainingSummaryError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, TrainingReportDownloadGroupTrainingSummaryError, TData>(
    queryKeyFn({
      path: '/api/reports/training/physicianGroup/{physicianGroupOrgHierarchyId}/download',
      operationId: 'trainingReportDownloadGroupTrainingSummary',
      variables
    }),
    ({ signal }) => fetchTrainingReportDownloadGroupTrainingSummary({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportDownloadHospitalSummaryReporPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
};

export type TrainingReportDownloadHospitalSummaryReporHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportDownloadHospitalSummaryReporError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingReportDownloadHospitalSummaryReporVariables = {
  headers?: TrainingReportDownloadHospitalSummaryReporHeaders;
  pathParams: TrainingReportDownloadHospitalSummaryReporPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportDownloadHospitalSummaryRepor = (
  variables: TrainingReportDownloadHospitalSummaryReporVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    TrainingReportDownloadHospitalSummaryReporError,
    undefined,
    TrainingReportDownloadHospitalSummaryReporHeaders,
    {},
    TrainingReportDownloadHospitalSummaryReporPathParams
  >({ url: '/api/reports/training/hospital/{hospitalOrgHierarchyId}/download', method: 'get', ...variables, signal });

export const useTrainingReportDownloadHospitalSummaryRepor = <TData = Blob>(
  variables: TrainingReportDownloadHospitalSummaryReporVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, TrainingReportDownloadHospitalSummaryReporError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, TrainingReportDownloadHospitalSummaryReporError, TData>(
    queryKeyFn({
      path: '/api/reports/training/hospital/{hospitalOrgHierarchyId}/download',
      operationId: 'trainingReportDownloadHospitalSummaryRepor',
      variables
    }),
    ({ signal }) => fetchTrainingReportDownloadHospitalSummaryRepor({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportDownloadPhysicianTrainingSummaryPathParams = {
  /**
   * @format int32
   */
  physicianOrgHierarchyId: number;
};

export type TrainingReportDownloadPhysicianTrainingSummaryHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportDownloadPhysicianTrainingSummaryError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingReportDownloadPhysicianTrainingSummaryVariables = {
  headers?: TrainingReportDownloadPhysicianTrainingSummaryHeaders;
  pathParams: TrainingReportDownloadPhysicianTrainingSummaryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportDownloadPhysicianTrainingSummary = (
  variables: TrainingReportDownloadPhysicianTrainingSummaryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    TrainingReportDownloadPhysicianTrainingSummaryError,
    undefined,
    TrainingReportDownloadPhysicianTrainingSummaryHeaders,
    {},
    TrainingReportDownloadPhysicianTrainingSummaryPathParams
  >({ url: '/api/reports/training/physician/{physicianOrgHierarchyId}/download', method: 'get', ...variables, signal });

export const useTrainingReportDownloadPhysicianTrainingSummary = <TData = Blob>(
  variables: TrainingReportDownloadPhysicianTrainingSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, TrainingReportDownloadPhysicianTrainingSummaryError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, TrainingReportDownloadPhysicianTrainingSummaryError, TData>(
    queryKeyFn({
      path: '/api/reports/training/physician/{physicianOrgHierarchyId}/download',
      operationId: 'trainingReportDownloadPhysicianTrainingSummary',
      variables
    }),
    ({ signal }) => fetchTrainingReportDownloadPhysicianTrainingSummary({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportDownloadSystemSummaryReporPathParams = {
  /**
   * @format int32
   */
  systemOrgHierarchyId: number;
};

export type TrainingReportDownloadSystemSummaryReporHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportDownloadSystemSummaryReporError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingReportDownloadSystemSummaryReporVariables = {
  headers?: TrainingReportDownloadSystemSummaryReporHeaders;
  pathParams: TrainingReportDownloadSystemSummaryReporPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportDownloadSystemSummaryRepor = (
  variables: TrainingReportDownloadSystemSummaryReporVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    TrainingReportDownloadSystemSummaryReporError,
    undefined,
    TrainingReportDownloadSystemSummaryReporHeaders,
    {},
    TrainingReportDownloadSystemSummaryReporPathParams
  >({ url: '/api/reports/training/system/{systemOrgHierarchyId}/download', method: 'get', ...variables, signal });

export const useTrainingReportDownloadSystemSummaryRepor = <TData = Blob>(
  variables: TrainingReportDownloadSystemSummaryReporVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, TrainingReportDownloadSystemSummaryReporError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, TrainingReportDownloadSystemSummaryReporError, TData>(
    queryKeyFn({
      path: '/api/reports/training/system/{systemOrgHierarchyId}/download',
      operationId: 'trainingReportDownloadSystemSummaryRepor',
      variables
    }),
    ({ signal }) => fetchTrainingReportDownloadSystemSummaryRepor({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportGetGroupTrainingSummaryPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type TrainingReportGetGroupTrainingSummaryHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportGetGroupTrainingSummaryError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingReportGetGroupTrainingSummaryResponse = Schemas.TrainingProviderTrainingSummary[];

export type TrainingReportGetGroupTrainingSummaryVariables = {
  headers?: TrainingReportGetGroupTrainingSummaryHeaders;
  pathParams: TrainingReportGetGroupTrainingSummaryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportGetGroupTrainingSummary = (
  variables: TrainingReportGetGroupTrainingSummaryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingReportGetGroupTrainingSummaryResponse,
    TrainingReportGetGroupTrainingSummaryError,
    undefined,
    TrainingReportGetGroupTrainingSummaryHeaders,
    {},
    TrainingReportGetGroupTrainingSummaryPathParams
  >({
    url: '/api/reports/training/physicianGroup/{physicianGroupOrgHierarchyId}',
    method: 'get',
    ...variables,
    signal
  });

export const useTrainingReportGetGroupTrainingSummary = <TData = TrainingReportGetGroupTrainingSummaryResponse>(
  variables: TrainingReportGetGroupTrainingSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingReportGetGroupTrainingSummaryResponse,
      TrainingReportGetGroupTrainingSummaryError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingReportGetGroupTrainingSummaryResponse,
    TrainingReportGetGroupTrainingSummaryError,
    TData
  >(
    queryKeyFn({
      path: '/api/reports/training/physicianGroup/{physicianGroupOrgHierarchyId}',
      operationId: 'trainingReportGetGroupTrainingSummary',
      variables
    }),
    ({ signal }) => fetchTrainingReportGetGroupTrainingSummary({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportGetHospitalSummaryReporPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
};

export type TrainingReportGetHospitalSummaryReporHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportGetHospitalSummaryReporError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingReportGetHospitalSummaryReporResponse = Schemas.TrainingGroupReportTrainingSummary[];

export type TrainingReportGetHospitalSummaryReporVariables = {
  headers?: TrainingReportGetHospitalSummaryReporHeaders;
  pathParams: TrainingReportGetHospitalSummaryReporPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportGetHospitalSummaryRepor = (
  variables: TrainingReportGetHospitalSummaryReporVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingReportGetHospitalSummaryReporResponse,
    TrainingReportGetHospitalSummaryReporError,
    undefined,
    TrainingReportGetHospitalSummaryReporHeaders,
    {},
    TrainingReportGetHospitalSummaryReporPathParams
  >({ url: '/api/reports/training/hospital/{hospitalOrgHierarchyId}', method: 'get', ...variables, signal });

export const useTrainingReportGetHospitalSummaryRepor = <TData = TrainingReportGetHospitalSummaryReporResponse>(
  variables: TrainingReportGetHospitalSummaryReporVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingReportGetHospitalSummaryReporResponse,
      TrainingReportGetHospitalSummaryReporError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingReportGetHospitalSummaryReporResponse,
    TrainingReportGetHospitalSummaryReporError,
    TData
  >(
    queryKeyFn({
      path: '/api/reports/training/hospital/{hospitalOrgHierarchyId}',
      operationId: 'trainingReportGetHospitalSummaryRepor',
      variables
    }),
    ({ signal }) => fetchTrainingReportGetHospitalSummaryRepor({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportGetPhysicianTrainingSummaryPathParams = {
  /**
   * @format int32
   */
  physicianOrgHierarchyId: number;
};

export type TrainingReportGetPhysicianTrainingSummaryHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportGetPhysicianTrainingSummaryError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingReportGetPhysicianTrainingSummaryResponse = Schemas.TrainingProviderConditionTrainingSummary[];

export type TrainingReportGetPhysicianTrainingSummaryVariables = {
  headers?: TrainingReportGetPhysicianTrainingSummaryHeaders;
  pathParams: TrainingReportGetPhysicianTrainingSummaryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportGetPhysicianTrainingSummary = (
  variables: TrainingReportGetPhysicianTrainingSummaryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingReportGetPhysicianTrainingSummaryResponse,
    TrainingReportGetPhysicianTrainingSummaryError,
    undefined,
    TrainingReportGetPhysicianTrainingSummaryHeaders,
    {},
    TrainingReportGetPhysicianTrainingSummaryPathParams
  >({ url: '/api/reports/training/physician/{physicianOrgHierarchyId}', method: 'get', ...variables, signal });

export const useTrainingReportGetPhysicianTrainingSummary = <TData = TrainingReportGetPhysicianTrainingSummaryResponse>(
  variables: TrainingReportGetPhysicianTrainingSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingReportGetPhysicianTrainingSummaryResponse,
      TrainingReportGetPhysicianTrainingSummaryError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingReportGetPhysicianTrainingSummaryResponse,
    TrainingReportGetPhysicianTrainingSummaryError,
    TData
  >(
    queryKeyFn({
      path: '/api/reports/training/physician/{physicianOrgHierarchyId}',
      operationId: 'trainingReportGetPhysicianTrainingSummary',
      variables
    }),
    ({ signal }) => fetchTrainingReportGetPhysicianTrainingSummary({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingReportGetSystemSummaryReporPathParams = {
  /**
   * @format int32
   */
  systemOrgHierarchyId: number;
};

export type TrainingReportGetSystemSummaryReporHeaders = {
  ['x-api-version']?: string;
};

export type TrainingReportGetSystemSummaryReporError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingReportGetSystemSummaryReporResponse = Schemas.TrainingGroupReportTrainingSummary[];

export type TrainingReportGetSystemSummaryReporVariables = {
  headers?: TrainingReportGetSystemSummaryReporHeaders;
  pathParams: TrainingReportGetSystemSummaryReporPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingReportGetSystemSummaryRepor = (
  variables: TrainingReportGetSystemSummaryReporVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingReportGetSystemSummaryReporResponse,
    TrainingReportGetSystemSummaryReporError,
    undefined,
    TrainingReportGetSystemSummaryReporHeaders,
    {},
    TrainingReportGetSystemSummaryReporPathParams
  >({ url: '/api/reports/training/system/{systemOrgHierarchyId}', method: 'get', ...variables, signal });

export const useTrainingReportGetSystemSummaryRepor = <TData = TrainingReportGetSystemSummaryReporResponse>(
  variables: TrainingReportGetSystemSummaryReporVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingReportGetSystemSummaryReporResponse,
      TrainingReportGetSystemSummaryReporError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingReportGetSystemSummaryReporResponse,
    TrainingReportGetSystemSummaryReporError,
    TData
  >(
    queryKeyFn({
      path: '/api/reports/training/system/{systemOrgHierarchyId}',
      operationId: 'trainingReportGetSystemSummaryRepor',
      variables
    }),
    ({ signal }) => fetchTrainingReportGetSystemSummaryRepor({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingTestGetNextSequenceQueryParams = {
  /**
   * Org Hierarchy Id - for admin
   *
   * @format int32
   */
  orgId?: number;
  /**
   * optional query param for mobile-specific response behavior
   *
   * @default false
   */
  mobile?: boolean;
};

export type TrainingTestGetNextSequenceHeaders = {
  ['x-api-version']?: string;
};

export type TrainingTestGetNextSequenceError = Fetcher.ErrorWrapper<undefined>;

export type TrainingTestGetNextSequenceVariables = {
  headers?: TrainingTestGetNextSequenceHeaders;
  queryParams?: TrainingTestGetNextSequenceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingTestGetNextSequence = (
  variables: TrainingTestGetNextSequenceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTestContent,
    TrainingTestGetNextSequenceError,
    undefined,
    TrainingTestGetNextSequenceHeaders,
    TrainingTestGetNextSequenceQueryParams,
    {}
  >({ url: '/api/training/test/nextsequence', method: 'get', ...variables, signal });

export const useTrainingTestGetNextSequence = <TData = Schemas.TrainingTestContent>(
  variables: TrainingTestGetNextSequenceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingTestContent, TrainingTestGetNextSequenceError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingTestContent, TrainingTestGetNextSequenceError, TData>(
    queryKeyFn({ path: '/api/training/test/nextsequence', operationId: 'trainingTestGetNextSequence', variables }),
    ({ signal }) => fetchTrainingTestGetNextSequence({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingTestGetTrainingStatusSummaryHeaders = {
  ['x-api-version']?: string;
};

export type TrainingTestGetTrainingStatusSummaryError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingTestGetTrainingStatusSummaryVariables = {
  headers?: TrainingTestGetTrainingStatusSummaryHeaders;
} & ApiContext['fetcherOptions'];

/**
 * Can be used by dashboard to preview training tile.
 */
export const fetchTrainingTestGetTrainingStatusSummary = (
  variables: TrainingTestGetTrainingStatusSummaryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingProviderTrainingSummary,
    TrainingTestGetTrainingStatusSummaryError,
    undefined,
    TrainingTestGetTrainingStatusSummaryHeaders,
    {},
    {}
  >({ url: '/api/training/test/progress', method: 'get', ...variables, signal });

/**
 * Can be used by dashboard to preview training tile.
 */
export const useTrainingTestGetTrainingStatusSummary = <TData = Schemas.TrainingProviderTrainingSummary>(
  variables: TrainingTestGetTrainingStatusSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingProviderTrainingSummary,
      TrainingTestGetTrainingStatusSummaryError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingProviderTrainingSummary, TrainingTestGetTrainingStatusSummaryError, TData>(
    queryKeyFn({ path: '/api/training/test/progress', operationId: 'trainingTestGetTrainingStatusSummary', variables }),
    ({ signal }) => fetchTrainingTestGetTrainingStatusSummary({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingTestLogAnswerHeaders = {
  ['x-api-version']?: string;
};

export type TrainingTestLogAnswerError = Fetcher.ErrorWrapper<
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingTestLogAnswerVariables = {
  body: Schemas.TrainingLogAnswerRequest;
  headers?: TrainingTestLogAnswerHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingTestLogAnswer = (variables: TrainingTestLogAnswerVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.TrainingLogAnswerRespose,
    TrainingTestLogAnswerError,
    Schemas.TrainingLogAnswerRequest,
    TrainingTestLogAnswerHeaders,
    {},
    {}
  >({ url: '/api/training/test/log/answer', method: 'post', ...variables, signal });

export const useTrainingTestLogAnswer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TrainingLogAnswerRespose,
      TrainingTestLogAnswerError,
      TrainingTestLogAnswerVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.TrainingLogAnswerRespose,
    TrainingTestLogAnswerError,
    TrainingTestLogAnswerVariables
  >(
    (variables: TrainingTestLogAnswerVariables) => fetchTrainingTestLogAnswer({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingTestLogVideoHeaders = {
  ['x-api-version']?: string;
};

export type TrainingTestLogVideoError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingTestLogVideoVariables = {
  body: Schemas.TrainingLogVideoRequest;
  headers?: TrainingTestLogVideoHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingTestLogVideo = (variables: TrainingTestLogVideoVariables, signal?: AbortSignal) =>
  apiFetch<string, TrainingTestLogVideoError, Schemas.TrainingLogVideoRequest, TrainingTestLogVideoHeaders, {}, {}>({
    url: '/api/training/test/log/video',
    method: 'post',
    ...variables,
    signal
  });

export const useTrainingTestLogVideo = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, TrainingTestLogVideoError, TrainingTestLogVideoVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<string, TrainingTestLogVideoError, TrainingTestLogVideoVariables>(
    (variables: TrainingTestLogVideoVariables) => fetchTrainingTestLogVideo({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingTestRetakeTrainingQueryParams = {
  /**
   * Org Hierarchy Id
   *
   * @format int32
   */
  orgId?: number;
};

export type TrainingTestRetakeTrainingHeaders = {
  ['x-api-version']?: string;
};

export type TrainingTestRetakeTrainingError = Fetcher.ErrorWrapper<
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingTestRetakeTrainingVariables = {
  headers?: TrainingTestRetakeTrainingHeaders;
  queryParams?: TrainingTestRetakeTrainingQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * Can be used by dashboard to preview training tile.
 */
export const fetchTrainingTestRetakeTraining = (variables: TrainingTestRetakeTrainingVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.TrainingProviderTrainingSummary,
    TrainingTestRetakeTrainingError,
    undefined,
    TrainingTestRetakeTrainingHeaders,
    TrainingTestRetakeTrainingQueryParams,
    {}
  >({ url: '/api/training/test/retake', method: 'put', ...variables, signal });

/**
 * Can be used by dashboard to preview training tile.
 */
export const useTrainingTestRetakeTraining = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TrainingProviderTrainingSummary,
      TrainingTestRetakeTrainingError,
      TrainingTestRetakeTrainingVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.TrainingProviderTrainingSummary,
    TrainingTestRetakeTrainingError,
    TrainingTestRetakeTrainingVariables
  >(
    (variables: TrainingTestRetakeTrainingVariables) =>
      fetchTrainingTestRetakeTraining({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingTestSendTrainingCompletionEmailHeaders = {
  ['x-api-version']?: string;
};

export type TrainingTestSendTrainingCompletionEmailError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingTestSendTrainingCompletionEmailVariables = {
  body?: Schemas.TrainingTrainingCompletionEmailRequest;
  headers?: TrainingTestSendTrainingCompletionEmailHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingTestSendTrainingCompletionEmail = (
  variables: TrainingTestSendTrainingCompletionEmailVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingTestSendTrainingCompletionEmailError,
    Schemas.TrainingTrainingCompletionEmailRequest,
    TrainingTestSendTrainingCompletionEmailHeaders,
    {},
    {}
  >({ url: '/api/training/test/sendTrainingCompletionEmail', method: 'post', ...variables, signal });

export const useTrainingTestSendTrainingCompletionEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingTestSendTrainingCompletionEmailError,
      TrainingTestSendTrainingCompletionEmailVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingTestSendTrainingCompletionEmailError,
    TrainingTestSendTrainingCompletionEmailVariables
  >(
    (variables: TrainingTestSendTrainingCompletionEmailVariables) =>
      fetchTrainingTestSendTrainingCompletionEmail({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersAddUserHeaders = {
  ['x-api-version']?: string;
};

export type UsersAddUserError = Fetcher.ErrorWrapper<undefined>;

export type UsersAddUserVariables = {
  body: Schemas.AddUserDto;
  headers?: UsersAddUserHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersAddUser = (variables: UsersAddUserVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersAddUserError, Schemas.AddUserDto, UsersAddUserHeaders, {}, {}>({
    url: '/api/Users',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersAddUser = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, UsersAddUserError, UsersAddUserVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersAddUserError, UsersAddUserVariables>(
    (variables: UsersAddUserVariables) => fetchUsersAddUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersGetAllUsersHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetAllUsersError = Fetcher.ErrorWrapper<undefined>;

export type UsersGetAllUsersVariables = {
  headers?: UsersGetAllUsersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersGetAllUsers = (variables: UsersGetAllUsersVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersGetAllUsersError, undefined, UsersGetAllUsersHeaders, {}, {}>({
    url: '/api/Users',
    method: 'get',
    ...variables,
    signal
  });

export const useUsersGetAllUsers = <TData = undefined>(
  variables: UsersGetAllUsersVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersGetAllUsersError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersGetAllUsersError, TData>(
    queryKeyFn({ path: '/api/Users', operationId: 'usersGetAllUsers', variables }),
    ({ signal }) => fetchUsersGetAllUsers({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersDeleteUserPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type UsersDeleteUserHeaders = {
  ['x-api-version']?: string;
};

export type UsersDeleteUserError = Fetcher.ErrorWrapper<undefined>;

export type UsersDeleteUserVariables = {
  headers?: UsersDeleteUserHeaders;
  pathParams: UsersDeleteUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersDeleteUser = (variables: UsersDeleteUserVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersDeleteUserError, undefined, UsersDeleteUserHeaders, {}, UsersDeleteUserPathParams>({
    url: '/api/Users/{userId}',
    method: 'delete',
    ...variables,
    signal
  });

export const useUsersDeleteUser = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, UsersDeleteUserError, UsersDeleteUserVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersDeleteUserError, UsersDeleteUserVariables>(
    (variables: UsersDeleteUserVariables) => fetchUsersDeleteUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersGetUserPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type UsersGetUserHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetUserError = Fetcher.ErrorWrapper<undefined>;

export type UsersGetUserVariables = {
  headers?: UsersGetUserHeaders;
  pathParams: UsersGetUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersGetUser = (variables: UsersGetUserVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersGetUserError, undefined, UsersGetUserHeaders, {}, UsersGetUserPathParams>({
    url: '/api/Users/{userId}',
    method: 'get',
    ...variables,
    signal
  });

export const useUsersGetUser = <TData = undefined>(
  variables: UsersGetUserVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersGetUserError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersGetUserError, TData>(
    queryKeyFn({ path: '/api/Users/{userId}', operationId: 'usersGetUser', variables }),
    ({ signal }) => fetchUsersGetUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersUpdateUserPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type UsersUpdateUserHeaders = {
  ['x-api-version']?: string;
};

export type UsersUpdateUserError = Fetcher.ErrorWrapper<undefined>;

export type UsersUpdateUserVariables = {
  body: Schemas.UserUpdateDto;
  headers?: UsersUpdateUserHeaders;
  pathParams: UsersUpdateUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersUpdateUser = (variables: UsersUpdateUserVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersUpdateUserError,
    Schemas.UserUpdateDto,
    UsersUpdateUserHeaders,
    {},
    UsersUpdateUserPathParams
  >({ url: '/api/Users/{userId}', method: 'put', ...variables, signal });

export const useUsersUpdateUser = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, UsersUpdateUserError, UsersUpdateUserVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersUpdateUserError, UsersUpdateUserVariables>(
    (variables: UsersUpdateUserVariables) => fetchUsersUpdateUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersDeleteUsersHeaders = {
  ['x-api-version']?: string;
};

export type UsersDeleteUsersError = Fetcher.ErrorWrapper<undefined>;

export type UsersDeleteUsersRequestBody = number[];

export type UsersDeleteUsersVariables = {
  body?: UsersDeleteUsersRequestBody;
  headers?: UsersDeleteUsersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersDeleteUsers = (variables: UsersDeleteUsersVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersDeleteUsersError, UsersDeleteUsersRequestBody, UsersDeleteUsersHeaders, {}, {}>({
    url: '/api/Users/DeleteUsers',
    method: 'delete',
    ...variables,
    signal
  });

export const useUsersDeleteUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersDeleteUsersError, UsersDeleteUsersVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersDeleteUsersError, UsersDeleteUsersVariables>(
    (variables: UsersDeleteUsersVariables) => fetchUsersDeleteUsers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersIsNpiTakenQueryParams = {
  npi?: string;
};

export type UsersIsNpiTakenHeaders = {
  ['x-api-version']?: string;
};

export type UsersIsNpiTakenError = Fetcher.ErrorWrapper<undefined>;

export type UsersIsNpiTakenVariables = {
  headers?: UsersIsNpiTakenHeaders;
  queryParams?: UsersIsNpiTakenQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersIsNpiTaken = (variables: UsersIsNpiTakenVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersIsNpiTakenError, undefined, UsersIsNpiTakenHeaders, UsersIsNpiTakenQueryParams, {}>({
    url: '/api/Users/isNPITaken',
    method: 'get',
    ...variables,
    signal
  });

export const useUsersIsNpiTaken = <TData = undefined>(
  variables: UsersIsNpiTakenVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersIsNpiTakenError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersIsNpiTakenError, TData>(
    queryKeyFn({ path: '/api/Users/isNPITaken', operationId: 'usersIsNpiTaken', variables }),
    ({ signal }) => fetchUsersIsNpiTaken({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersManageBlockUserHeaders = {
  ['x-api-version']?: string;
};

export type UsersManageBlockUserError = Fetcher.ErrorWrapper<undefined>;

export type UsersManageBlockUserVariables = {
  body?: Schemas.UserBlockRequestDto;
  headers?: UsersManageBlockUserHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersManageBlockUser = (variables: UsersManageBlockUserVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersManageBlockUserError, Schemas.UserBlockRequestDto, UsersManageBlockUserHeaders, {}, {}>({
    url: '/api/Users/BlockUsers',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersManageBlockUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersManageBlockUserError, UsersManageBlockUserVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersManageBlockUserError, UsersManageBlockUserVariables>(
    (variables: UsersManageBlockUserVariables) => fetchUsersManageBlockUser({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersPasswordResetRequestHeaders = {
  ['x-api-version']?: string;
};

export type UsersPasswordResetRequestError = Fetcher.ErrorWrapper<undefined>;

export type UsersPasswordResetRequestVariables = {
  body: Schemas.ResetPasswordDto;
  headers?: UsersPasswordResetRequestHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPasswordResetRequest = (variables: UsersPasswordResetRequestVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersPasswordResetRequestError,
    Schemas.ResetPasswordDto,
    UsersPasswordResetRequestHeaders,
    {},
    {}
  >({ url: '/api/Users/passresreq', method: 'post', ...variables, signal });

export const useUsersPasswordResetRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersPasswordResetRequestError, UsersPasswordResetRequestVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPasswordResetRequestError, UsersPasswordResetRequestVariables>(
    (variables: UsersPasswordResetRequestVariables) =>
      fetchUsersPasswordResetRequest({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersResetDashboardsHeaders = {
  ['x-api-version']?: string;
};

export type UsersResetDashboardsError = Fetcher.ErrorWrapper<undefined>;

export type UsersResetDashboardsRequestBody = number[];

export type UsersResetDashboardsVariables = {
  body?: UsersResetDashboardsRequestBody;
  headers?: UsersResetDashboardsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersResetDashboards = (variables: UsersResetDashboardsVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersResetDashboardsError, UsersResetDashboardsRequestBody, UsersResetDashboardsHeaders, {}, {}>({
    url: '/api/Users/ResetDashboards',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersResetDashboards = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersResetDashboardsError, UsersResetDashboardsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersResetDashboardsError, UsersResetDashboardsVariables>(
    (variables: UsersResetDashboardsVariables) => fetchUsersResetDashboards({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersResetPasswordsHeaders = {
  ['x-api-version']?: string;
};

export type UsersResetPasswordsError = Fetcher.ErrorWrapper<undefined>;

export type UsersResetPasswordsRequestBody = number[];

export type UsersResetPasswordsVariables = {
  body?: UsersResetPasswordsRequestBody;
  headers?: UsersResetPasswordsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersResetPasswords = (variables: UsersResetPasswordsVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersResetPasswordsError, UsersResetPasswordsRequestBody, UsersResetPasswordsHeaders, {}, {}>({
    url: '/api/Users/ResetPasswords',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersResetPasswords = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersResetPasswordsError, UsersResetPasswordsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersResetPasswordsError, UsersResetPasswordsVariables>(
    (variables: UsersResetPasswordsVariables) => fetchUsersResetPasswords({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersSendEmailHeaders = {
  ['x-api-version']?: string;
};

export type UsersSendEmailError = Fetcher.ErrorWrapper<undefined>;

export type UsersSendEmailRequestBody = number[];

export type UsersSendEmailVariables = {
  body?: UsersSendEmailRequestBody;
  headers?: UsersSendEmailHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersSendEmail = (variables: UsersSendEmailVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersSendEmailError, UsersSendEmailRequestBody, UsersSendEmailHeaders, {}, {}>({
    url: '/api/Users/SendEmail',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersSendEmail = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, UsersSendEmailError, UsersSendEmailVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersSendEmailError, UsersSendEmailVariables>(
    (variables: UsersSendEmailVariables) => fetchUsersSendEmail({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersUploadUsersHeaders = {
  ['x-api-version']?: string;
};

export type UsersUploadUsersError = Fetcher.ErrorWrapper<undefined>;

export type UsersUploadUsersRequestBody = {
  /**
   * @format binary
   */
  UserUploadFile: Blob;
};

export type UsersUploadUsersVariables = {
  body: UsersUploadUsersRequestBody;
  headers?: UsersUploadUsersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersUploadUsers = (variables: UsersUploadUsersVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersUploadUsersError, UsersUploadUsersRequestBody, UsersUploadUsersHeaders, {}, {}>({
    url: '/api/Users/Upload',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersUploadUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersUploadUsersError, UsersUploadUsersVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersUploadUsersError, UsersUploadUsersVariables>(
    (variables: UsersUploadUsersVariables) => fetchUsersUploadUsers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersValidateNpiQueryParams = {
  npi?: string;
};

export type UsersValidateNpiHeaders = {
  ['x-api-version']?: string;
};

export type UsersValidateNpiError = Fetcher.ErrorWrapper<undefined>;

export type UsersValidateNpiVariables = {
  headers?: UsersValidateNpiHeaders;
  queryParams?: UsersValidateNpiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersValidateNpi = (variables: UsersValidateNpiVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersValidateNpiError, undefined, UsersValidateNpiHeaders, UsersValidateNpiQueryParams, {}>({
    url: '/api/Users/ValidateNPI',
    method: 'get',
    ...variables,
    signal
  });

export const useUsersValidateNpi = <TData = undefined>(
  variables: UsersValidateNpiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersValidateNpiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersValidateNpiError, TData>(
    queryKeyFn({ path: '/api/Users/ValidateNPI', operationId: 'usersValidateNpi', variables }),
    ({ signal }) => fetchUsersValidateNpi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersGetClinicalLeadersHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetClinicalLeadersError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: Schemas.MvcProblemDetails;
}>;

export type UsersGetClinicalLeadersResponse = Schemas.UserAccountDto[];

export type UsersGetClinicalLeadersVariables = {
  headers?: UsersGetClinicalLeadersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersGetClinicalLeaders = (variables: UsersGetClinicalLeadersVariables, signal?: AbortSignal) =>
  apiFetch<
    UsersGetClinicalLeadersResponse,
    UsersGetClinicalLeadersError,
    undefined,
    UsersGetClinicalLeadersHeaders,
    {},
    {}
  >({ url: '/api/Users/ClinicalLeaders', method: 'get', ...variables, signal });

export const useUsersGetClinicalLeaders = <TData = UsersGetClinicalLeadersResponse>(
  variables: UsersGetClinicalLeadersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<UsersGetClinicalLeadersResponse, UsersGetClinicalLeadersError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<UsersGetClinicalLeadersResponse, UsersGetClinicalLeadersError, TData>(
    queryKeyFn({ path: '/api/Users/ClinicalLeaders', operationId: 'usersGetClinicalLeaders', variables }),
    ({ signal }) => fetchUsersGetClinicalLeaders({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type VersionGetVersionHeaders = {
  ['x-api-version']?: string;
};

export type VersionGetVersionError = Fetcher.ErrorWrapper<undefined>;

export type VersionGetVersionVariables = {
  headers?: VersionGetVersionHeaders;
} & ApiContext['fetcherOptions'];

export const fetchVersionGetVersion = (variables: VersionGetVersionVariables, signal?: AbortSignal) =>
  apiFetch<undefined, VersionGetVersionError, undefined, VersionGetVersionHeaders, {}, {}>({
    url: '/api/Version',
    method: 'get',
    ...variables,
    signal
  });

export const useVersionGetVersion = <TData = undefined>(
  variables: VersionGetVersionVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, VersionGetVersionError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, VersionGetVersionError, TData>(
    queryKeyFn({ path: '/api/Version', operationId: 'versionGetVersion', variables }),
    ({ signal }) => fetchVersionGetVersion({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type QueryOperation =
  | {
      path: '/api/Auth/identities';
      operationId: 'authGetIdentities';
      variables: AuthGetIdentitiesVariables;
    }
  | {
      path: '/api/Auth/user';
      operationId: 'authGetUser';
      variables: AuthGetUserVariables;
    }
  | {
      path: '/api/CIUniversity/groups';
      operationId: 'cIUniversityGetLearningGroups';
      variables: CIUniversityGetLearningGroupsVariables;
    }
  | {
      path: '/api/reports/CmiAnalysis/widgetdates/{orgId}';
      operationId: 'cmiAnalysisGetCmiAnalysisWidget';
      variables: CmiAnalysisGetCmiAnalysisWidgetVariables;
    }
  | {
      path: '/api/reports/CmiAnalysis/dates/{orgId}';
      operationId: 'cmiAnalysisGetDates';
      variables: CmiAnalysisGetDatesVariables;
    }
  | {
      path: '/api/Conditions';
      operationId: 'conditionsGet';
      variables: ConditionsGetVariables;
    }
  | {
      path: '/api/Conditions/icd10codes/{id}';
      operationId: 'conditionsGetIcd10Codes';
      variables: ConditionsGetIcd10CodesVariables;
    }
  | {
      path: '/api/Conditions/icd10codes/download/{id}';
      operationId: 'conditionsGetIcd10CodesDownload';
      variables: ConditionsGetIcd10CodesDownloadVariables;
    }
  | {
      path: '/api/Dashboard/{userId}';
      operationId: 'dashboardGet';
      variables: DashboardGetVariables;
    }
  | {
      path: '/api/DefaultDates/{orgId}';
      operationId: 'defaultDatesGetDefaultDates';
      variables: DefaultDatesGetDefaultDatesVariables;
    }
  | {
      path: '/api/DocScoreGoal/history';
      operationId: 'docScoreGoalGetAllDocScoreGoalsHistory';
      variables: DocScoreGoalGetAllDocScoreGoalsHistoryVariables;
    }
  | {
      path: '/api/DocScoreGoal/history/download';
      operationId: 'docScoreGoalGetAllDocScoreGoalsHistoryExcel';
      variables: DocScoreGoalGetAllDocScoreGoalsHistoryExcelVariables;
    }
  | {
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}';
      operationId: 'docScoreGoalGetDocScoreGoalsForHospital';
      variables: DocScoreGoalGetDocScoreGoalsForHospitalVariables;
    }
  | {
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}';
      operationId: 'docScoreGoalGetDocScoreGoalsForPhysicianGroup';
      variables: DocScoreGoalGetDocScoreGoalsForPhysicianGroupVariables;
    }
  | {
      path: '/api/DocScoreGoal';
      operationId: 'docScoreGoalGetDocScoreGoalsForPhysicianGroups';
      variables: DocScoreGoalGetDocScoreGoalsForPhysicianGroupsVariables;
    }
  | {
      path: '/api/DocScoreGoal/systemsspecialtygroup';
      operationId: 'docScoreGoalGetDocScoreGoalsForSystem';
      variables: DocScoreGoalGetDocScoreGoalsForSystemVariables;
    }
  | {
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}/history';
      operationId: 'docScoreGoalGetDocScoreGoalsHistory';
      variables: DocScoreGoalGetDocScoreGoalsHistoryVariables;
    }
  | {
      path: '/api/DocScoreGoal/smartgoalsmodal/{orgId}';
      operationId: 'docScoreGoalGetSmartGoalsModal';
      variables: DocScoreGoalGetSmartGoalsModalVariables;
    }
  | {
      path: '/api/DocScoreGoal/smartgoalswidget/{orgId}';
      operationId: 'docScoreGoalGetSmartGoalsWidget';
      variables: DocScoreGoalGetSmartGoalsWidgetVariables;
    }
  | {
      path: '/api/DocScoreGoal/targetedconditionstable/{orgId}';
      operationId: 'docScoreGoalGetTargetedConditionsPerformanceTable';
      variables: DocScoreGoalGetTargetedConditionsPerformanceTableVariables;
    }
  | {
      path: '/api/DocScoreGoal/targetedconditionswidget/{orgId}';
      operationId: 'docScoreGoalGetTargetedConditionsPerformanceWidget';
      variables: DocScoreGoalGetTargetedConditionsPerformanceWidgetVariables;
    }
  | {
      path: '/api/Features';
      operationId: 'featuresGetAllFeatures';
      variables: FeaturesGetAllFeaturesVariables;
    }
  | {
      path: '/api/Features/{id}';
      operationId: 'featuresGetFeature';
      variables: FeaturesGetFeatureVariables;
    }
  | {
      path: '/api/financialtracker';
      operationId: 'financialTrackerGetTracker';
      variables: FinancialTrackerGetTrackerVariables;
    }
  | {
      path: '/api/Graph/cmi/{orgId}';
      operationId: 'graphGetCmi';
      variables: GraphGetCmiVariables;
    }
  | {
      path: '/api/Graph/conditions/{orgId}';
      operationId: 'graphGetConditionsForOrg';
      variables: GraphGetConditionsForOrgVariables;
    }
  | {
      path: '/api/Graph/conditions';
      operationId: 'graphGetConditionsForOrgs';
      variables: GraphGetConditionsForOrgsVariables;
    }
  | {
      path: '/api/Graph/conditionssmartmodal/{orgId}';
      operationId: 'graphGetConditionsSmartModal';
      variables: GraphGetConditionsSmartModalVariables;
    }
  | {
      path: '/api/Graph/docscore/{orgId}';
      operationId: 'graphGetDocScoreForOrg';
      variables: GraphGetDocScoreForOrgVariables;
    }
  | {
      path: '/api/Graph/docscore';
      operationId: 'graphGetDocScoresForOrgs';
      variables: GraphGetDocScoresForOrgsVariables;
    }
  | {
      path: '/api/Graph/elixhausermortality/{orgId}';
      operationId: 'graphGetElixhauserMortality';
      variables: GraphGetElixhauserMortalityVariables;
    }
  | {
      path: '/api/Graph/elixhauserreadmission/{orgId}';
      operationId: 'graphGetElixhauserReadmission';
      variables: GraphGetElixhauserReadmissionVariables;
    }
  | {
      path: '/api/Graph/hcupcmi/{orgId}';
      operationId: 'graphGetHcupCmi';
      variables: GraphGetHcupCmiVariables;
    }
  | {
      path: '/api/Graph/los/{orgId}';
      operationId: 'graphGetLos';
      variables: GraphGetLosVariables;
    }
  | {
      path: '/api/Graph/psi/{orgId}';
      operationId: 'graphGetPsi';
      variables: GraphGetPsiVariables;
    }
  | {
      path: '/api/Graph/raf/{orgId}';
      operationId: 'graphGetRaf';
      variables: GraphGetRafVariables;
    }
  | {
      path: '/api/Graph/severitycmi/{orgId}';
      operationId: 'graphGetSeveritycmi';
      variables: GraphGetSeveritycmiVariables;
    }
  | {
      path: '/api/Graph/speedometer';
      operationId: 'graphGetSpeedometer';
      variables: GraphGetSpeedometerVariables;
    }
  | {
      path: '/api/Download/Graph/{orgId}';
      operationId: 'graphDataDownloadGetAll';
      variables: GraphDataDownloadGetAllVariables;
    }
  | {
      path: '/api/Download/Graph/conditions/{orgId}';
      operationId: 'graphDataDownloadGetConditions';
      variables: GraphDataDownloadGetConditionsVariables;
    }
  | {
      path: '/api/reports/group/physiciangroup/{id}';
      operationId: 'groupReportGetAll';
      variables: GroupReportGetAllVariables;
    }
  | {
      path: '/api/reports/group/{reportId}';
      operationId: 'groupReportGetReport';
      variables: GroupReportGetReportVariables;
    }
  | {
      path: '/api/Health';
      operationId: 'healthGetHealth';
      variables: HealthGetHealthVariables;
    }
  | {
      path: '/api/Hospitals';
      operationId: 'hospitalsGetAll';
      variables: HospitalsGetAllVariables;
    }
  | {
      path: '/api/Hospitals/BDSystemHospitals/{orgId}';
      operationId: 'hospitalsGetBDSystemHospitals';
      variables: HospitalsGetBDSystemHospitalsVariables;
    }
  | {
      path: '/api/Hospitals/hospitalsWithSystem';
      operationId: 'hospitalsGetAllPlusSystem';
      variables: HospitalsGetAllPlusSystemVariables;
    }
  | {
      path: '/api/Hospitals/{id}';
      operationId: 'hospitalsGetSpecific';
      variables: HospitalsGetSpecificVariables;
    }
  | {
      path: '/api/Mdc';
      operationId: 'mdcGetAllMdc';
      variables: MdcGetAllMdcVariables;
    }
  | {
      path: '/api/Mdc/{mdcId}';
      operationId: 'mdcGetSpecificMdc';
      variables: MdcGetSpecificMdcVariables;
    }
  | {
      path: '/api/Metrics/cmi/{orgId}';
      operationId: 'metricsGetCmi';
      variables: MetricsGetCmiVariables;
    }
  | {
      path: '/api/Metrics/conditions/{orgId}';
      operationId: 'metricsGetConditionsForOrg';
      variables: MetricsGetConditionsForOrgVariables;
    }
  | {
      path: '/api/Metrics/conditions';
      operationId: 'metricsGetConditionsForOrgs';
      variables: MetricsGetConditionsForOrgsVariables;
    }
  | {
      path: '/api/Metrics/docscore/{orgId}';
      operationId: 'metricsGetDocScore';
      variables: MetricsGetDocScoreVariables;
    }
  | {
      path: '/api/Metrics/drgmix/{orgId}';
      operationId: 'metricsGetDrgMix';
      variables: MetricsGetDrgMixVariables;
    }
  | {
      path: '/api/Metrics/drgmixcomparison/{orgId}';
      operationId: 'metricsGetDrgMixComparison';
      variables: MetricsGetDrgMixComparisonVariables;
    }
  | {
      path: '/api/Metrics/elixhausermortality/{orgId}';
      operationId: 'metricsGetElixhauserMortality';
      variables: MetricsGetElixhauserMortalityVariables;
    }
  | {
      path: '/api/Metrics/elixhauserreadmission/{orgId}';
      operationId: 'metricsGetElixhauserReadmission';
      variables: MetricsGetElixhauserReadmissionVariables;
    }
  | {
      path: '/api/Metrics/los/{orgId}';
      operationId: 'metricsGetLos';
      variables: MetricsGetLosVariables;
    }
  | {
      path: '/api/Metrics/conditions/shares/{orgId}';
      operationId: 'metricsGetOpportunityShareForOrg';
      variables: MetricsGetOpportunityShareForOrgVariables;
    }
  | {
      path: '/api/Metrics/conditions/shares';
      operationId: 'metricsGetOpportunityShareForSystem';
      variables: MetricsGetOpportunityShareForSystemVariables;
    }
  | {
      path: '/api/Metrics/overallperformance';
      operationId: 'metricsGetOverallPerformance';
      variables: MetricsGetOverallPerformanceVariables;
    }
  | {
      path: '/api/Metrics/psi/{orgId}';
      operationId: 'metricsGetPsi';
      variables: MetricsGetPsiVariables;
    }
  | {
      path: '/api/Metrics/psipies/{orgId}';
      operationId: 'metricsGetPsiPies';
      variables: MetricsGetPsiPiesVariables;
    }
  | {
      path: '/api/Metrics/raf/{orgId}';
      operationId: 'metricsGetRaf';
      variables: MetricsGetRafVariables;
    }
  | {
      path: '/api/Metrics/severitycmi/{orgId}';
      operationId: 'metricsGetSeverityCmi';
      variables: MetricsGetSeverityCmiVariables;
    }
  | {
      path: '/api/Metrics/severityCmiConditions/{orgId}';
      operationId: 'metricsGetSeverityCmiConditionImpact';
      variables: MetricsGetSeverityCmiConditionImpactVariables;
    }
  | {
      path: '/api/Metrics/severitycmicomparison/{orgId}';
      operationId: 'metricsSeverityCmiComparison';
      variables: MetricsSeverityCmiComparisonVariables;
    }
  | {
      path: '/api/Download/Metrics/patientmixanalysisreport/{orgId}';
      operationId: 'metricsDataDownloadGenerateCmiAnalysisReport';
      variables: MetricsDataDownloadGenerateCmiAnalysisReportVariables;
    }
  | {
      path: '/api/Download/Metrics/cmicomparisonreport/{orgId}/{compareToOrgId}';
      operationId: 'metricsDataDownloadGenerateCmiComparisonReport';
      variables: MetricsDataDownloadGenerateCmiComparisonReportVariables;
    }
  | {
      path: '/api/Download/Metrics/cmi/{id}';
      operationId: 'metricsDataDownloadGetCmi';
      variables: MetricsDataDownloadGetCmiVariables;
    }
  | {
      path: '/api/Download/Metrics/conditions/{id}';
      operationId: 'metricsDataDownloadGetConditions';
      variables: MetricsDataDownloadGetConditionsVariables;
    }
  | {
      path: '/api/Download/Metrics/docscore/{id}';
      operationId: 'metricsDataDownloadGetDocScore';
      variables: MetricsDataDownloadGetDocScoreVariables;
    }
  | {
      path: '/api/Download/Metrics/drgmixcomparison/{id}';
      operationId: 'metricsDataDownloadGetDrgMixComparison';
      variables: MetricsDataDownloadGetDrgMixComparisonVariables;
    }
  | {
      path: '/api/Download/Metrics/elixhausermortality/{id}';
      operationId: 'metricsDataDownloadGetElixhauserMortality';
      variables: MetricsDataDownloadGetElixhauserMortalityVariables;
    }
  | {
      path: '/api/Download/Metrics/elixhauserreadmission/{id}';
      operationId: 'metricsDataDownloadGetElixhauserReadmission';
      variables: MetricsDataDownloadGetElixhauserReadmissionVariables;
    }
  | {
      path: '/api/Download/Metrics/los/{id}';
      operationId: 'metricsDataDownloadGetLos';
      variables: MetricsDataDownloadGetLosVariables;
    }
  | {
      path: '/api/Download/Metrics/patientmixanalysis/{id}';
      operationId: 'metricsDataDownloadGetPatientMixAnalysis';
      variables: MetricsDataDownloadGetPatientMixAnalysisVariables;
    }
  | {
      path: '/api/Download/Metrics/psi/{id}';
      operationId: 'metricsDataDownloadGetPsi';
      variables: MetricsDataDownloadGetPsiVariables;
    }
  | {
      path: '/api/Download/Metrics/raf/{id}';
      operationId: 'metricsDataDownloadGetRaf';
      variables: MetricsDataDownloadGetRafVariables;
    }
  | {
      path: '/api/Download/Metrics/severitycmi/{id}';
      operationId: 'metricsDataDownloadGetSeverityCmi';
      variables: MetricsDataDownloadGetSeverityCmiVariables;
    }
  | {
      path: '/api/Download/Metrics/severityCmiConditionImpact/{id}';
      operationId: 'metricsDataDownloadGetSeverityCmiConditionImpact';
      variables: MetricsDataDownloadGetSeverityCmiConditionImpactVariables;
    }
  | {
      path: '/api/Org/{orgId}';
      operationId: 'orgGet';
      variables: OrgGetVariables;
    }
  | {
      path: '/api/Org/BDSystemOrgs';
      operationId: 'orgGetBDSystemOrgs';
      variables: OrgGetBDSystemOrgsVariables;
    }
  | {
      path: '/api/Org/FundamentalGroups';
      operationId: 'orgGetFundamentalSpecialtyGroups';
      variables: OrgGetFundamentalSpecialtyGroupsVariables;
    }
  | {
      path: '/api/Org/SystemView/{id}';
      operationId: 'orgGetSystemView';
      variables: OrgGetSystemViewVariables;
    }
  | {
      path: '/api/Org';
      operationId: 'orgSearch';
      variables: OrgSearchVariables;
    }
  | {
      path: '/api/OrgChangeRequests/hierarchy';
      operationId: 'orgChangeRequestsDownloadOrgHierarchySpreadsheet';
      variables: OrgChangeRequestsDownloadOrgHierarchySpreadsheetVariables;
    }
  | {
      path: '/api/OrgChangeRequests';
      operationId: 'orgChangeRequestsGetOrgChangeRequestQueue';
      variables: OrgChangeRequestsGetOrgChangeRequestQueueVariables;
    }
  | {
      path: '/api/OrgChangeRequests/UserHierarchy';
      operationId: 'orgChangeRequestsDownloadUserOrgHierarchySpreadsheet';
      variables: OrgChangeRequestsDownloadUserOrgHierarchySpreadsheetVariables;
    }
  | {
      path: '/api/OrgChangeRequests/template';
      operationId: 'orgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheet';
      variables: OrgChangeRequestsDownloadOrgHierarchyTemplateSpreadsheetVariables;
    }
  | {
      path: '/api/OrgChangeRequests/history';
      operationId: 'orgChangeRequestsDownloadOrgChangeRequest';
      variables: OrgChangeRequestsDownloadOrgChangeRequestVariables;
    }
  | {
      path: '/api/PhysicianGroups';
      operationId: 'physicianGroupsGetPhysicianGroups';
      variables: PhysicianGroupsGetPhysicianGroupsVariables;
    }
  | {
      path: '/api/Provider';
      operationId: 'providerGetProvidersBySearch';
      variables: ProviderGetProvidersBySearchVariables;
    }
  | {
      path: '/api/reports/provider/{providerOrgId}';
      operationId: 'providerReportGetProviderReport';
      variables: ProviderReportGetProviderReportVariables;
    }
  | {
      path: '/api/reports/provider/log/PhysicianGroup/{physicianGroupOrgId}';
      operationId: 'providerReportGetProviderReportLog';
      variables: ProviderReportGetProviderReportLogVariables;
    }
  | {
      path: '/api/reports/provider/generate';
      operationId: 'providerReportGenerateProviderReports';
      variables: ProviderReportGenerateProviderReportsVariables;
    }
  | {
      path: '/api/Roles';
      operationId: 'rolesGetAllRoles';
      variables: RolesGetAllRolesVariables;
    }
  | {
      path: '/api/Roles/{id}';
      operationId: 'rolesGetRole';
      variables: RolesGetRoleVariables;
    }
  | {
      path: '/api/saf/conditions';
      operationId: 'safConditionsGetConditonsForOrg';
      variables: SafConditionsGetConditonsForOrgVariables;
    }
  | {
      path: '/api/saf/graph/cmi/{id}';
      operationId: 'safGraphGetCmi';
      variables: SafGraphGetCmiVariables;
    }
  | {
      path: '/api/saf/graph/docscore/{id}';
      operationId: 'safGraphGetDocScore';
      variables: SafGraphGetDocScoreVariables;
    }
  | {
      path: '/api/saf/graph/hcupcmi/{id}';
      operationId: 'safGraphGetHcupCmi';
      variables: SafGraphGetHcupCmiVariables;
    }
  | {
      path: '/api/saf/histogram/safHistogramReport';
      operationId: 'safHistogramGenerateSafHistogramReport';
      variables: SafHistogramGenerateSafHistogramReportVariables;
    }
  | {
      path: '/api/saf/histogram/filters';
      operationId: 'safHistogramGetFilters';
      variables: SafHistogramGetFiltersVariables;
    }
  | {
      path: '/api/saf/histogram/filters/{orgId}';
      operationId: 'safHistogramGetFiltersForOrg';
      variables: SafHistogramGetFiltersForOrgVariables;
    }
  | {
      path: '/api/saf/histogram';
      operationId: 'safHistogramGetHistograms';
      variables: SafHistogramGetHistogramsVariables;
    }
  | {
      path: '/api/saf/histogram/metricTypes';
      operationId: 'safHistogramGetMetricTypes';
      variables: SafHistogramGetMetricTypesVariables;
    }
  | {
      path: '/api/saf/metrics/conditions';
      operationId: 'safMetricsGetAllConditions';
      variables: SafMetricsGetAllConditionsVariables;
    }
  | {
      path: '/api/saf/metrics';
      operationId: 'safMetricsGetAllMetrics';
      variables: SafMetricsGetAllMetricsVariables;
    }
  | {
      path: '/api/saf/metrics/drgmixcomparison/{id}';
      operationId: 'safMetricsGetDrgMixComparison';
      variables: SafMetricsGetDrgMixComparisonVariables;
    }
  | {
      path: '/api/saf/metrics/severitycmicomparison/{id}';
      operationId: 'safMetricsSeverityCmiComparison';
      variables: SafMetricsSeverityCmiComparisonVariables;
    }
  | {
      path: '/api/saf/download/metrics/cmicomparisonreport/{orgId}/{compareToOrgId}';
      operationId: 'safMetricsDataDownloadGenerateCmiComparisonReport';
      variables: SafMetricsDataDownloadGenerateCmiComparisonReportVariables;
    }
  | {
      path: '/api/saf/download/metrics/drgmixcomparison/{id}';
      operationId: 'safMetricsDataDownloadGetDrgMixComparison';
      variables: SafMetricsDataDownloadGetDrgMixComparisonVariables;
    }
  | {
      path: '/api/SAF/org/defaultdates/{orgId}';
      operationId: 'safOrgGetDefaultDates';
      variables: SafOrgGetDefaultDatesVariables;
    }
  | {
      path: '/api/SAF/org/{id}';
      operationId: 'safOrgGetOrg';
      variables: SafOrgGetOrgVariables;
    }
  | {
      path: '/api/SAF/org';
      operationId: 'safOrgSearch';
      variables: SafOrgSearchVariables;
    }
  | {
      path: '/api/schedule/reports';
      operationId: 'scheduleReportGetSchedules';
      variables: ScheduleReportGetSchedulesVariables;
    }
  | {
      path: '/api/schedule/reports/{id}';
      operationId: 'scheduleReportGetSchedule';
      variables: ScheduleReportGetScheduleVariables;
    }
  | {
      path: '/api/schedule/reports/download/{id}';
      operationId: 'scheduleReportDownloadReport';
      variables: ScheduleReportDownloadReportVariables;
    }
  | {
      path: '/api/schedule/reports/reportTypes';
      operationId: 'scheduleReportGetReportTypes';
      variables: ScheduleReportGetReportTypesVariables;
    }
  | {
      path: '/api/schedule/reports/frequencies';
      operationId: 'scheduleReportGetScheduleFrequencies';
      variables: ScheduleReportGetScheduleFrequenciesVariables;
    }
  | {
      path: '/api/Schema/AuthorizedUser';
      operationId: 'schemaGetAuthorizedUser';
      variables: SchemaGetAuthorizedUserVariables;
    }
  | {
      path: '/api/Schema/Cmi';
      operationId: 'schemaGetCmi';
      variables: SchemaGetCmiVariables;
    }
  | {
      path: '/api/Schema/Condition';
      operationId: 'schemaGetConditionMetric';
      variables: SchemaGetConditionMetricVariables;
    }
  | {
      path: '/api/Schema/DefaultDates';
      operationId: 'schemaGetDefaultDates';
      variables: SchemaGetDefaultDatesVariables;
    }
  | {
      path: '/api/Schema/DocScore';
      operationId: 'schemaGetDocScore';
      variables: SchemaGetDocScoreVariables;
    }
  | {
      path: '/api/Schema/ElixhauserMortality';
      operationId: 'schemaGetElixhauserMortality';
      variables: SchemaGetElixhauserMortalityVariables;
    }
  | {
      path: '/api/Schema/ElixhauserReadmission';
      operationId: 'schemaGetElixhauserReadmission';
      variables: SchemaGetElixhauserReadmissionVariables;
    }
  | {
      path: '/api/Schema/Los';
      operationId: 'schemaGetLos';
      variables: SchemaGetLosVariables;
    }
  | {
      path: '/api/Schema/overallperformance';
      operationId: 'schemaGetOverallPerformance';
      variables: SchemaGetOverallPerformanceVariables;
    }
  | {
      path: '/api/Schema/Psi';
      operationId: 'schemaGetPsi';
      variables: SchemaGetPsiVariables;
    }
  | {
      path: '/api/Schema/Raf';
      operationId: 'schemaGetRaf';
      variables: SchemaGetRafVariables;
    }
  | {
      path: '/api/Schema/SeverityCmi';
      operationId: 'schemaGetSeverityCmi';
      variables: SchemaGetSeverityCmiVariables;
    }
  | {
      path: '/api/Schema/UserTestDTO';
      operationId: 'schemaGetUserTestDto';
      variables: SchemaGetUserTestDtoVariables;
    }
  | {
      path: '/api/settings/{userId}/histogram/filters/{id}';
      operationId: 'settingsGetFilter';
      variables: SettingsGetFilterVariables;
    }
  | {
      path: '/api/settings/{userId}/histogram/filters';
      operationId: 'settingsGetFilters';
      variables: SettingsGetFiltersVariables;
    }
  | {
      path: '/api/reports/system/executivesummary';
      operationId: 'systemReportGenerateExecutiveSummaryReport';
      variables: SystemReportGenerateExecutiveSummaryReportVariables;
    }
  | {
      path: '/api/TenantSettings/authConfig';
      operationId: 'tenantSettingsGetAuthConfig';
      variables: TenantSettingsGetAuthConfigVariables;
    }
  | {
      path: '/api/training/assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}';
      operationId: 'trainingAssignmentGetProviderTraining';
      variables: TrainingAssignmentGetProviderTrainingVariables;
    }
  | {
      path: '/api/training/assignment/defaultcreditmonths';
      operationId: 'trainingAssignmentGetDefaultCreditMonths';
      variables: TrainingAssignmentGetDefaultCreditMonthsVariables;
    }
  | {
      path: '/api/training/assignment/{physicianGroupOrgHierarchyId}';
      operationId: 'trainingAssignmentGetGroupTrainingConfig';
      variables: TrainingAssignmentGetGroupTrainingConfigVariables;
    }
  | {
      path: '/api/training/ConditionPerformance';
      operationId: 'trainingConditionPerformanceGetConditionPerformance';
      variables: TrainingConditionPerformanceGetConditionPerformanceVariables;
    }
  | {
      path: '/api/training/content/specialtybaskets';
      operationId: 'trainingContentGetSpecialtyBaskets';
      variables: TrainingContentGetSpecialtyBasketsVariables;
    }
  | {
      path: '/api/training/content/conditions/{conditionId}';
      operationId: 'trainingContentGetConditionReference';
      variables: TrainingContentGetConditionReferenceVariables;
    }
  | {
      path: '/api/training/content/contentconditions';
      operationId: 'trainingContentGetContentConditions';
      variables: TrainingContentGetContentConditionsVariables;
    }
  | {
      path: '/api/training/content/contentconditionswithtrainingcontent';
      operationId: 'trainingContentGetContentConditionsWithTrainingContent';
      variables: TrainingContentGetContentConditionsWithTrainingContentVariables;
    }
  | {
      path: '/api/training/content/fundamentalspecialties';
      operationId: 'trainingContentGetFundamentalSpecialties';
      variables: TrainingContentGetFundamentalSpecialtiesVariables;
    }
  | {
      path: '/api/training/content/pocketcards/{pocketCardId}';
      operationId: 'trainingContentGetPocketCard';
      variables: TrainingContentGetPocketCardVariables;
    }
  | {
      path: '/api/training/content/questions/{questionId}';
      operationId: 'trainingContentGetQuestion';
      variables: TrainingContentGetQuestionVariables;
    }
  | {
      path: '/api/training/content/specialtybaskets/{specialtyBasketId}';
      operationId: 'trainingContentGetSpecialtyBasket';
      variables: TrainingContentGetSpecialtyBasketVariables;
    }
  | {
      path: '/api/training/content/specialtybasketsforcontentcondition/{contentConditionId}';
      operationId: 'trainingContentGetSpecialtyBasketsForContentCondition';
      variables: TrainingContentGetSpecialtyBasketsForContentConditionVariables;
    }
  | {
      path: '/api/training/content/{contentConditionId}';
      operationId: 'trainingContentGetTrainingContent';
      variables: TrainingContentGetTrainingContentVariables;
    }
  | {
      path: '/api/training/content/trainingvideos/{trainingVideoId}';
      operationId: 'trainingContentGetTrainingVideo';
      variables: TrainingContentGetTrainingVideoVariables;
    }
  | {
      path: '/api/training/content/videolinktypes';
      operationId: 'trainingContentGetVideoLinkTypes';
      variables: TrainingContentGetVideoLinkTypesVariables;
    }
  | {
      path: '/api/training/management/approvaltrainingcontent';
      operationId: 'trainingManagementGetTrainingApprovalContentConditions';
      variables: TrainingManagementGetTrainingApprovalContentConditionsVariables;
    }
  | {
      path: '/api/training/management/content/{conditionId}';
      operationId: 'trainingManagementGetTrainingContentWithApproval';
      variables: TrainingManagementGetTrainingContentWithApprovalVariables;
    }
  | {
      path: '/api/reports/training/physicianGroup/{physicianGroupOrgHierarchyId}/download';
      operationId: 'trainingReportDownloadGroupTrainingSummary';
      variables: TrainingReportDownloadGroupTrainingSummaryVariables;
    }
  | {
      path: '/api/reports/training/hospital/{hospitalOrgHierarchyId}/download';
      operationId: 'trainingReportDownloadHospitalSummaryRepor';
      variables: TrainingReportDownloadHospitalSummaryReporVariables;
    }
  | {
      path: '/api/reports/training/physician/{physicianOrgHierarchyId}/download';
      operationId: 'trainingReportDownloadPhysicianTrainingSummary';
      variables: TrainingReportDownloadPhysicianTrainingSummaryVariables;
    }
  | {
      path: '/api/reports/training/system/{systemOrgHierarchyId}/download';
      operationId: 'trainingReportDownloadSystemSummaryRepor';
      variables: TrainingReportDownloadSystemSummaryReporVariables;
    }
  | {
      path: '/api/reports/training/physicianGroup/{physicianGroupOrgHierarchyId}';
      operationId: 'trainingReportGetGroupTrainingSummary';
      variables: TrainingReportGetGroupTrainingSummaryVariables;
    }
  | {
      path: '/api/reports/training/hospital/{hospitalOrgHierarchyId}';
      operationId: 'trainingReportGetHospitalSummaryRepor';
      variables: TrainingReportGetHospitalSummaryReporVariables;
    }
  | {
      path: '/api/reports/training/physician/{physicianOrgHierarchyId}';
      operationId: 'trainingReportGetPhysicianTrainingSummary';
      variables: TrainingReportGetPhysicianTrainingSummaryVariables;
    }
  | {
      path: '/api/reports/training/system/{systemOrgHierarchyId}';
      operationId: 'trainingReportGetSystemSummaryRepor';
      variables: TrainingReportGetSystemSummaryReporVariables;
    }
  | {
      path: '/api/training/test/nextsequence';
      operationId: 'trainingTestGetNextSequence';
      variables: TrainingTestGetNextSequenceVariables;
    }
  | {
      path: '/api/training/test/progress';
      operationId: 'trainingTestGetTrainingStatusSummary';
      variables: TrainingTestGetTrainingStatusSummaryVariables;
    }
  | {
      path: '/api/Users';
      operationId: 'usersGetAllUsers';
      variables: UsersGetAllUsersVariables;
    }
  | {
      path: '/api/Users/{userId}';
      operationId: 'usersGetUser';
      variables: UsersGetUserVariables;
    }
  | {
      path: '/api/Users/isNPITaken';
      operationId: 'usersIsNpiTaken';
      variables: UsersIsNpiTakenVariables;
    }
  | {
      path: '/api/Users/ValidateNPI';
      operationId: 'usersValidateNpi';
      variables: UsersValidateNpiVariables;
    }
  | {
      path: '/api/Users/ClinicalLeaders';
      operationId: 'usersGetClinicalLeaders';
      variables: UsersGetClinicalLeadersVariables;
    }
  | {
      path: '/api/Version';
      operationId: 'versionGetVersion';
      variables: VersionGetVersionVariables;
    };
