import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import Table from '@clinintell/components/Table';
import { useDefaultDates, useDefaultDatesDispatch } from '@clinintell/modules/store';
import { ProviderTrainingAssignmentState } from '@clinintell/modules/trainingAssignment';
import { parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { Box, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useReportDownloadStatus } from '../context/ReportDownloadContext';
import GenerateGroupTrainingReportTableProps from '../logic/generateGroupTrainingReportTableProps';
import { OrgTableProps } from '../TrainingStatusReport';

const GroupGeneratedReport: React.FC<OrgTableProps> = ({ orgId, onSort, rowsPerPageValues, onRowsPerPageChanged }) => {
  const { isLoading, output } = useGetAPICAll<ProviderTrainingAssignmentState[]>({
    endpoint: `reports/training/physicianGroup/${orgId}`,
    isWaiting: false
  });

  const defaultDates = useDefaultDates();
  const defaultDatesDispatch = useDefaultDatesDispatch();

  const { enableDownload, disableDownload } = useReportDownloadStatus();

  useEffect(() => {
    if (defaultDates.byId[orgId]) {
      return;
    }

    defaultDatesDispatch({ type: 'DEFAULT_DATES_FETCH_REQUESTED', payload: orgId.toString() });
  }, [defaultDates.byId, defaultDatesDispatch, orgId]);

  const groupDates = defaultDates.byId[orgId];

  const headerTooltipText = groupDates
    ? `The percentage of CC/MCC severity value reported versus expected.  Current period = ${parseDateIntoPeriod(
        new Date(groupDates.currentMinMonth as string)
      )} - ${parseDateIntoPeriod(new Date(groupDates.currentMaxMonth as string))}`
    : '';

  const theme = useTheme();

  if (isLoading) {
    return <ClinIntellSkeleton variant="rectangular" height="25rem" width="100%" />;
  }

  if (output?.status === 204) {
    disableDownload();
    return (
      <Box mt={5} ml={1} component="span" color={theme.palette.red[500]}>
        None of the providers in this group are enrolled in ClinIntell's online training.
      </Box>
    );
  } else {
    enableDownload();
  }

  return (
    <Table
      tableId="trainingStatusGroup"
      onRowsPerPageChange={onRowsPerPageChanged}
      onSort={onSort}
      {...GenerateGroupTrainingReportTableProps(output && output.data ? output.data : [], rowsPerPageValues)}
      headerTooltipColumn="documentationScore"
      headerTooltipText={headerTooltipText}
    />
  );
};

export default GroupGeneratedReport;
