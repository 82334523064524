import React, { useContext, useState, useEffect } from 'react';
import {
  IconButton,
  TableContainer,
  Table as TableComponent,
  TableCell,
  TableHead,
  Paper,
  TableRow,
  TableBody,
  TableSortLabel,
  TablePagination,
  Box,
  Typography,
  useTheme
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTableState } from '@clinintell/modules/store';
import { isSafari, isIE, browserVersion } from 'react-device-detect';
import CircleLoadingIndicator from '@clinintell/components/CircleLoadingIndicator';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import clsx from 'clsx';
import Footnotes from '@clinintell/components/Footnotes';
import CheckBox from '@mui/material/Checkbox';
import { ChartDataSetType } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { ApplicationAPI } from '@clinintell/utils/api';
import { WindowSizeContext } from '@clinintell/modules/windowSizeContext';
import TableHeaderFilter from '@clinintell/components/TableHeaderFilter';
import ColumnResizer from '@clinintell/components/ColumnResizer';
import StyledTableCell, { ColumnDataType } from '@clinintell/components/StyledTableCell';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { SelectOptionType } from '@clinintell/types/common';
import Tooltip from './tooltip/Tooltip';
import TextInput from './textInput/TextInput';
import Button from './button/Button';

export type ColumnDefinition = {
  type: ColumnDataType;
  prefix?: string;
  postfix?: string;
  title: string;
  maxCharacters?: number;
  filter?: boolean;
  onFilter?: (column: string, value: string | string[] | number | number[]) => void;
  filterType?: 'text' | 'type';
  filterTypeList?: SelectOptionType[];
  defaultOptions?: SelectOptionType[];
  isTargetCondition?: boolean;
};

export type RowValue = {
  component?: typeof StyledTableCell | JSX.Element;
  rawValue: string | number | number[] | boolean;
  uId: number;
  condition?: number;
  isSelected?: boolean;
};

export interface Row {
  [key: string]: RowValue;
}

export interface RowDefinition {
  text: string;
  toggleable: boolean;
  isToggled?: boolean;
  id: ChartDataSetType;
  className: string;
  cells: string[];
  tooltip?: string;
}

export interface ChartTableDefinition {
  columns: string[];
  rows: RowDefinition[];
}

export type RowsPerPageValues = 10 | 25 | 50 | 100;

export interface ActionOptions {
  title: string;
  action: 'view' | 'edit' | 'delete' | 'copy';
  actionUrl?: string;
  fileType?: string;
  viewFn?: (buffer: ArrayBuffer, name: string) => void;
  actionFn?: (id: number) => void;
}

export interface TableProps {
  tableId?: string;
  rows?: Row[];
  columns?: Record<string, ColumnDefinition>;
  footerRow?: Row;
  // maps to Column field interface
  defaultSortColumn: string;
  sortDirection?: Order;
  disablePaging: boolean;
  disableSorting?: boolean;
  renderChartTable?: boolean;
  chartTableRows?: RowDefinition[];
  chartTableColumns?: string[];
  footnotes?: string[] | [];
  onToggle?: (id: ChartDataSetType) => void;
  onRowsPerPageChange?: (rowsPerPage: RowsPerPageValues) => void;
  rowsPerPage: RowsPerPageValues;
  disableToggling?: boolean;
  actionOptions?: ActionOptions[];
  columnIdentifier?: string;
  editColumnName?: string;
  onEditColumnValidation?: (value: string) => boolean;
  onEditColumnUpdate?: (id: number, value: string) => void;
  onUpdateSelectedRows?: (rows: number[]) => void;
  initialSelectedRows?: number[];
  headerTooltipColumn?: string;
  headerTooltipText?: string;
  maxHeight?: string;
  tableLayout?: 'auto' | 'fixed';
  isDashboard?: boolean;
  onSort?: (column: string, isDesc: boolean) => void;
  isFilterUpdating?: boolean;
  onViewDidLoad?: () => void;
  testID?: string;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    '& table .MuiTableHead-root > tr > th': {
      borderBottom: 'none'
    },
    '& table .MuiTableCell-sizeSmall': {
      padding: '0 16px 0 12px',
      height: 38,
      display: 'table-cell',
      '&:first-child': {
        paddingLeft: theme.spacing(2)
      }
    },
    '& table': {
      borderCollapse: 'collapse'
    }
  },
  containerSorting: {
    '& table .MuiTableCell-sizeSmall': {
      padding: '0 !important',
      '&:first-child': {
        paddingLeft: '0 !important'
      }
    }
  },
  headerCell: {
    background: theme.palette.blue.main,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: theme.typography.p1.fontSize,
    whiteSpace: 'nowrap'
  },
  headerTooltip: {
    marginLeft: 5,
    top: 3,
    position: 'relative'
  },
  headerTitleHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  headerCellWrapper: {
    display: 'inline-block'
  },
  headerCellWrapperCentered: {
    justifyContent: 'center'
  },
  headerIcon: {
    '& > svg': {
      fill: theme.palette.common.white,
      marginTop: '4px'
    }
  },
  bodyRow: {
    height: '38px',
    flex: 1,
    verticalAlign: 'top',
    '&:hover': {
      backgroundColor: `${theme.palette.grey[50]} !important`
    },
    '& .ellipse': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '& .MuiTableCell-root': {
      borderBottom: `solid 1px ${theme.palette.grey[100]}`
    }
  },
  graphDataTable: {
    '& tr': {
      verticalAlign: 'middle'
    },
    '& tr:last-child > td': {
      borderBottom: 'none'
    }
  },
  footerRowClass: {
    color: theme.palette.common.black,
    background: theme.palette.blue.light5,
    '& > td': {
      fontWeight: 700,
      borderTop: `2px solid ${theme.palette.blue.main}`,
      '& div': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    }
  },
  compact: {
    padding: theme.spacing(1)
  },
  editInput: {
    margin: '4px auto',
    '& > .MuiOutlinedInput-root': {
      fontSize: '0.9rem',
      height: 30,
      '& > input': {
        width: 200
      }
    }
  },
  editLabel: {
    position: 'relative',
    textAlign: 'center',
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
    padding: `5px 14px`,
    margin: `4px auto`,
    width: 200,
    minHeight: theme.spacing(2),
    height: 18,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  columnFilter: {
    border: `solid 2px ${theme.palette.grey[300]}`,
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white
  }
}));

export type Order = 'asc' | 'desc';

const compareDescending = (a: RowValue, b: RowValue): number => {
  let aRawValue = a.rawValue;
  let bRawValue = b.rawValue;

  if (typeof aRawValue === 'string' && typeof bRawValue === 'string') {
    aRawValue = aRawValue.toLowerCase();
    bRawValue = bRawValue.toLowerCase();
  }

  if (aRawValue < bRawValue) {
    return 1;
  }

  if (aRawValue > bRawValue) {
    return -1;
  }

  return 0;
};

const sort = (
  array: Row[],
  column: string,
  order: Order,
  selectedRows?: number[],
  columnIdentifier = 'name'
): Row[] => {
  if (!array.length) return [];

  const newArray = [...array];
  if (!newArray[0].hasOwnProperty(column)) {
    return newArray;
  }

  if (selectedRows && selectedRows.length && (column === 'isTargetCondition' || column === 'selectAll')) {
    const selected = newArray.filter(rec => {
      const rowId = rec[columnIdentifier].condition
        ? Number(rec[columnIdentifier].condition)
        : Number(rec[columnIdentifier].uId);
      return selectedRows.some(x => x === rowId);
    });
    const unselected = newArray.filter(rec => {
      const rowId = rec[columnIdentifier].condition
        ? Number(rec[columnIdentifier].condition)
        : Number(rec[columnIdentifier].uId);
      return !selectedRows.some(x => x === rowId);
    });
    return order === 'desc' ? [...selected, ...unselected] : [...unselected, ...selected];
  } else {
    newArray.sort((a, b) => {
      const ordering =
        order === 'desc' ? compareDescending(a[column], b[column]) : -compareDescending(a[column], b[column]);

      return ordering;
    });
  }

  return newArray;
};

interface FieldValidation {
  id: number;
  value: string;
  valid: boolean;
  edit: boolean;
}

const Table: React.FC<TableProps> = ({
  tableId = 'tbl',
  columns,
  rows,
  footerRow,
  defaultSortColumn,
  sortDirection = 'desc',
  rowsPerPage,
  disablePaging,
  disableSorting = false,
  footnotes,
  renderChartTable,
  chartTableRows,
  chartTableColumns,
  onToggle,
  onRowsPerPageChange,
  disableToggling,
  actionOptions,
  columnIdentifier = 'name',
  editColumnName,
  onEditColumnValidation,
  onEditColumnUpdate,
  onUpdateSelectedRows,
  initialSelectedRows = [],
  headerTooltipColumn,
  headerTooltipText,
  maxHeight = '65rem',
  tableLayout = 'fixed',
  onSort,
  isDashboard = false,
  isFilterUpdating = false,
  onViewDidLoad,
  testID = ''
}) => {
  const { tableColumnSizes } = useTableState();
  const [allRowsSelected, setAllRowsSelected] = useState<number[]>(initialSelectedRows);
  const [order, setOrder] = useState<Order>(sortDirection);
  const [orderBy, setOrderBy] = useState<string>(defaultSortColumn);
  const [page, setPage] = useState<number>(0);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [isDownloading, setIsDownloading] = useState({
    downloading: false,
    id: -1,
    name: ''
  });
  let sortedRows = sort(rows ?? [], orderBy, order, allRowsSelected, columnIdentifier);
  const [isSorting, setIsSorting] = useState(false);
  const isIE11 = isIE && browserVersion === '11';
  const initInputValidation: FieldValidation[] = [];
  if (rows && editColumnName) {
    rows.forEach((row: Row) =>
      initInputValidation.push({
        id: Number(row[columnIdentifier].uId),
        value: row[editColumnName].rawValue.toString(),
        valid: true,
        edit: false
      })
    );
  }
  const [inputValidation, setInputValidation] = useState<FieldValidation[]>(initInputValidation);
  const {
    container,
    containerSorting,
    headerCell,
    headerTitleHover,
    bodyRow,
    graphDataTable,
    headerIcon,
    headerCellWrapper,
    headerCellWrapperCentered,
    headerTooltip,
    footerRowClass,
    editLabel
  } = useStyles();

  const theme = useTheme();
  const { width } = useContext(WindowSizeContext);

  useEffect(() => {
    setOrderBy(defaultSortColumn);
  }, [defaultSortColumn]);

  useEffect(() => {
    const { downloading, id, name } = isDownloading;
    if (!downloading) {
      return;
    }

    const viewAction = actionOptions && actionOptions.find(action => action.action === 'view');
    const endpoint =
      viewAction && viewAction.actionUrl
        ? viewAction.actionUrl.replace('$id', id.toString())
        : `reports/Provider/${id}`;
    ApplicationAPI.download(endpoint, viewAction && viewAction.fileType ? viewAction.fileType : 'pdf')
      .then(response => {
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.error);
        }
      })
      .then(buffer => {
        if (viewAction && viewAction.viewFn) {
          viewAction.viewFn(buffer, name);
        }
      })
      .catch(error => {
        throw new Error(`Error with the view request: ${error}`);
      })
      .finally(() => {
        setIsDownloading({
          downloading: false,
          id: -1,
          name: ''
        });
      });
  }, [isDownloading, actionOptions]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    sortedRows = sort(rows ?? [], orderBy, order, allRowsSelected, columnIdentifier);
    setIsSorting(false);
    onViewDidLoad && onViewDidLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order, allRowsSelected]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string): void => {
    const isAsc = orderBy === property && order === 'asc';

    if (onSort) {
      onSort(property, isAsc);
    }

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(parseInt(event.target.value) as RowsPerPageValues);
    }

    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>): void => {
    handleRequestSort(event, property);
    setIsSorting(true);
  };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      if (rows) {
        const selectedRows = rows
          .filter((row: Row) => Number(row[columnIdentifier].condition ?? row[columnIdentifier].uId) !== -1)
          .map((row: Row) => Number(row[columnIdentifier].condition ?? row[columnIdentifier].uId));
        setAllRowsSelected(selectedRows);
        if (onUpdateSelectedRows) {
          onUpdateSelectedRows(selectedRows);
        }
      }
      return;
    }
    if (onUpdateSelectedRows) {
      onUpdateSelectedRows([]);
    }
    setAllRowsSelected([]);
  };

  const handleRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>,
    uId: number
  ): void => {
    const selectedIndex = allRowsSelected.indexOf(uId);
    let newSelected: number[] = [];

    const editIx = inputValidation.findIndex(rec => rec.id === uId);
    if (editIx !== -1 && inputValidation[editIx].edit) {
      return;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(allRowsSelected, uId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(allRowsSelected.slice(1));
    } else if (selectedIndex === allRowsSelected.length - 1) {
      newSelected = newSelected.concat(allRowsSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        allRowsSelected.slice(0, selectedIndex),
        allRowsSelected.slice(selectedIndex + 1)
      );
    }

    setAllRowsSelected(newSelected);
    if (onUpdateSelectedRows) {
      onUpdateSelectedRows(newSelected);
    }
  };

  const handleInlineEditToggle = (id: number): void => {
    const inputValidationClone = Array.from(inputValidation);
    const ix = inputValidationClone.findIndex(rec => rec.id === id);
    if (ix !== -1) {
      inputValidationClone[ix].edit = !inputValidationClone[ix].edit;
    }
    setInputValidation(inputValidationClone);
  };

  const handleFieldValidation = (id: number, value: string): void => {
    const inputValidationClone = Array.from(inputValidation);
    const ix = inputValidationClone.findIndex(rec => rec.id === id);
    if (ix !== -1) {
      if (value.length > 0) {
        inputValidationClone[ix].value = value;
        inputValidationClone[ix].valid = onEditColumnValidation ? onEditColumnValidation(value) : true;
      }
      inputValidationClone[ix].edit = false;
    }
    setInputValidation(inputValidationClone);
    if (onUpdateSelectedRows) {
      onUpdateSelectedRows(allRowsSelected);
    }
  };

  if (!isSorting && isFilterUpdating) {
    onViewDidLoad && onViewDidLoad();
  }

  if (isFilterUpdating) {
    return <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />;
  }

  return (
    <Paper>
      <TableContainer
        data-cy={testID}
        className={
          isSorting && Object.keys(columns ?? []).some(key => key === 'isTargetCondition')
            ? clsx(container, containerSorting)
            : container
        }
        style={{ maxHeight: maxHeight }}
      >
        <TableComponent
          stickyHeader
          size="small"
          style={
            renderChartTable || actionOptions
              ? { tableLayout: 'auto' }
              : { tableLayout: width <= 480 ? 'auto' : tableLayout }
          }
        >
          {renderChartTable ? (
            <>
              <TableHead>
                <TableRow>
                  {chartTableColumns &&
                    chartTableColumns.map((column, index) => {
                      return (
                        <TableCell
                          id={`col-${index}`}
                          key={`${column}-${index}`}
                          className={headerCell}
                          align={index === 0 ? 'left' : 'right'}
                        >
                          <div className={clsx(headerCellWrapper)}>{column}</div>
                          {!isSafari && !isIE11 && <ColumnResizer />}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody className={graphDataTable}>
                {chartTableRows &&
                  chartTableRows.map((row, index) => (
                    <TableRow key={index} className={bodyRow}>
                      <TableCell key={index} align="left" className={row.className} style={{ whiteSpace: 'nowrap' }}>
                        {row.toggleable ? (
                          <Box display="flex" alignItems="center">
                            <CheckBox
                              disabled={disableToggling && row.isToggled}
                              disableRipple
                              checked={row.isToggled}
                              onChange={onToggle ? (): void => onToggle(row.id) : undefined}
                              style={{ padding: '0 9px', color: theme.palette.grey[400] }}
                            />
                            {row.text}
                            {row.tooltip && (
                              <Tooltip content={row.tooltip}>
                                <HelpOutlineIcon
                                  style={{ marginLeft: 8, width: 18, height: 18, color: theme.palette.grey[600] }}
                                />
                              </Tooltip>
                            )}
                          </Box>
                        ) : chartTableRows.some(row => row.toggleable) ? (
                          <div style={{ paddingLeft: '40px' }}>{row.text}</div>
                        ) : (
                          <div>{row.text}</div>
                        )}
                      </TableCell>
                      {row.cells.map((cell, index) => (
                        <TableCell
                          style={{ fontFamily: theme.typography.fontFamilyMonospaced }}
                          key={index}
                          align="right"
                          className={row.className}
                        >
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </>
          ) : (
            <>
              <TableHead>
                <TableRow>
                  {columns &&
                    Object.keys(columns).map((key, index) => {
                      const thId = `${tableId}-col-${key}-${index}`;
                      const col = tableColumnSizes && tableColumnSizes.find(c => c.id === thId);
                      if (key === 'selectAll' || key === 'isTargetCondition') {
                        return (
                          <React.Fragment key={index}>
                            <TableCell key={key} className={headerCell} style={{ width: 48, padding: 0 }}>
                              <Box
                                width="100%"
                                height="100%"
                                display="flex"
                                className={headerTitleHover}
                                justifyContent="center"
                                alignItems="center"
                                onClick={!disableSorting ? createSortHandler(key) : undefined}
                              >
                                {!disableSorting ? (
                                  <TableSortLabel
                                    active={orderBy === key}
                                    direction={orderBy === key ? order : 'asc'}
                                    onClick={createSortHandler(key)}
                                    className={headerIcon}
                                    style={orderBy === key ? { display: 'inline', top: 2 } : { display: 'none' }}
                                  />
                                ) : null}
                                <CheckBox
                                  indeterminate={
                                    rows !== undefined &&
                                    allRowsSelected.length > 0 &&
                                    allRowsSelected.length < rows.length
                                  }
                                  checked={
                                    rows !== undefined && rows.length > 0 && allRowsSelected.length === rows?.length
                                  }
                                  onChange={handleCheckAll}
                                  style={{ color: theme.palette.common.white, height: 22, padding: 0 }}
                                />
                              </Box>
                            </TableCell>
                            {actionOptions
                              ? actionOptions.map((action, index) => {
                                  if (action.action === 'view') {
                                    return (
                                      <TableCell
                                        id={`viewEditcol-${index}`}
                                        key={`viewEdit${index}`}
                                        className={headerCell}
                                        style={{ width: '50px' }}
                                      ></TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell
                                      id={`viewDeletecol-${index}`}
                                      key={`editDelete${index}`}
                                      className={headerCell}
                                      style={{ width: '50px' }}
                                    ></TableCell>
                                  );
                                })
                              : null}
                          </React.Fragment>
                        );
                      } else {
                        let textAlignment: 'left' | 'right' | 'center' = 'left';
                        const selectAllPresent =
                          Object.keys(columns).includes('selectAll') ||
                          Object.keys(columns).includes('isTargetCondition');
                        const hasTooltip = key === headerTooltipColumn;
                        const tooltipText = hasTooltip && headerTooltipText ? headerTooltipText : '';

                        if ((!selectAllPresent && index === 0) || (selectAllPresent && index === 1)) {
                          textAlignment = 'left';
                        } else if ((!selectAllPresent && index > 0) || (selectAllPresent && index > 1)) {
                          textAlignment = 'center';
                        }
                        if (key === 'name' || key === 'description') {
                          textAlignment = 'left';
                        }
                        if (key === 'code') {
                          textAlignment = 'right';
                        }

                        return (
                          <React.Fragment key={index}>
                            {index === 0 && actionOptions
                              ? actionOptions.map((action, index) => {
                                  if (action.action === 'view') {
                                    return (
                                      <TableCell
                                        id={`viewEditcol-${index}`}
                                        key={`viewEdit${index}`}
                                        className={headerCell}
                                        style={{ width: '50px' }}
                                      ></TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell
                                      id={`viewDeletecol-${index}`}
                                      key={`editDelete${index}`}
                                      className={headerCell}
                                      style={{ width: '50px' }}
                                    ></TableCell>
                                  );
                                })
                              : null}
                            <TableCell
                              data-cy={key}
                              id={thId}
                              key={key}
                              className={headerCell}
                              align={textAlignment}
                              style={
                                col
                                  ? { width: col.width }
                                  : key === 'conditionTypeId'
                                  ? { width: 50 }
                                  : key === 'name' && isDashboard && columns[key].title !== 'Clinical Condition'
                                  ? { width: 200 }
                                  : key === 'name' && !isDashboard && columns[key].title !== 'Clinical Condition'
                                  ? { width: 500 }
                                  : key === 'name' && columns[key].title === 'Clinical Condition'
                                  ? { width: 400 }
                                  : key === 'mdcName'
                                  ? { width: 500 }
                                  : key === 'description'
                                  ? { width: 700 }
                                  : key === 'code'
                                  ? { width: 75 }
                                  : key === 'drgValue'
                                  ? { width: 100 }
                                  : undefined
                              }
                            >
                              <Box
                                onClick={!disableSorting ? createSortHandler(key) : undefined}
                                className={clsx(
                                  headerCellWrapper,
                                  {
                                    [headerCellWrapperCentered]: index > 0
                                  },
                                  headerTitleHover
                                )}
                              >
                                {!disableSorting ? (
                                  <TableSortLabel
                                    active={orderBy === key}
                                    direction={orderBy === key ? order : 'asc'}
                                    onClick={createSortHandler(key)}
                                    className={headerIcon}
                                    style={
                                      orderBy === key
                                        ? { display: 'inline', top: hasTooltip ? -1 : 2 }
                                        : { display: 'none' }
                                    }
                                  />
                                ) : null}
                                {hasTooltip ? (
                                  <>
                                    <span
                                      style={
                                        !disableSorting && orderBy === key
                                          ? { top: -1, position: 'relative' }
                                          : { top: -3, position: 'relative' }
                                      }
                                    >
                                      {columns[key].title}
                                    </span>
                                    <Tooltip content={tooltipText}>
                                      <span className={headerTooltip}>
                                        <HelpOutlineIcon
                                          component="svg"
                                          style={{
                                            position: 'relative',
                                            top: orderBy === key ? 2 : -1,
                                            color: theme.palette.common.white
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <span
                                    style={
                                      !disableSorting && orderBy === key ? { position: 'relative', top: 1 } : undefined
                                    }
                                  >
                                    {columns[key].title}
                                  </span>
                                )}
                              </Box>
                              {columns[key].filter ? (
                                <>
                                  <IconButton
                                    id={`columnFilterButton-${key}`}
                                    value={key}
                                    onClick={(
                                      event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
                                    ): void => setFilterAnchorEl(event.currentTarget)}
                                    style={{ height: 28 }}
                                    size="large"
                                  >
                                    <FilterListIcon style={{ color: theme.palette.common.white }} />
                                  </IconButton>
                                  <TableHeaderFilter
                                    id={key}
                                    type={columns[key].filterType ?? 'text'}
                                    anchorEl={filterAnchorEl}
                                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                                    open={Boolean(filterAnchorEl)}
                                    onClose={(): void => setFilterAnchorEl(null)}
                                    onFilter={(value): void => {
                                      const filterMethod = columns[key].onFilter;
                                      if (filterMethod) {
                                        filterMethod(key, value);
                                      }
                                    }}
                                    defaultOptions={columns[key].defaultOptions}
                                    selectOptions={columns[key].filterTypeList}
                                  />
                                </>
                              ) : null}
                              {!isSafari && !isIE11 && <ColumnResizer />}
                            </TableCell>
                          </React.Fragment>
                        );
                      }
                    })}
                </TableRow>
              </TableHead>
              {isSorting && Object.keys(columns ?? []).some(key => key === 'isTargetCondition') ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={Object.keys(columns ?? []).length}>
                      <ClinIntellSkeleton
                        variant="rectangular"
                        height={rows ? `${rows.length < rowsPerPage ? rows.length : rowsPerPage * 38}px` : '38px'}
                        width="100%"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {sortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: Row, index: number) => {
                      const rowId = row[columnIdentifier].condition
                        ? Number(row[columnIdentifier].condition)
                        : Number(row[columnIdentifier].uId);
                      let isRowSelected = false;
                      try {
                        isRowSelected = allRowsSelected.indexOf(rowId) !== -1;
                      } catch (e) {
                        isRowSelected = false;
                      }
                      return (
                        <TableRow
                          id={`${rowId}`}
                          key={`${rowId}-${index}`}
                          hover
                          tabIndex={-1}
                          role="checkbox"
                          className={bodyRow}
                          selected={isRowSelected}
                          aria-checked={isRowSelected}
                        >
                          {columns &&
                            Object.keys(columns).map((key: string, index: number) => {
                              if (key === 'selectAll' || key === 'isTargetCondition') {
                                return (
                                  <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <TableCell
                                      key={`${rowId}${key}${index}`}
                                      align="center"
                                      style={orderBy === key ? { paddingLeft: 36 } : { padding: 0 }}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        height="100%"
                                        width="100%"
                                      >
                                        <CheckBox
                                          id={`chk-${rowId}`}
                                          checked={isRowSelected}
                                          color="primary"
                                          style={{
                                            color: theme.palette.blue.main,
                                            height: 22,
                                            padding: 0,
                                            marginLeft: 1
                                          }}
                                          onChange={(event): void => handleRowClick(event, rowId)}
                                        />
                                      </Box>
                                    </TableCell>
                                    {actionOptions
                                      ? actionOptions.map((action, index) => {
                                          if (action.action === 'view') {
                                            return (
                                              <TableCell
                                                key={`viewEdit${index}`}
                                                align="center"
                                                // vAlign prop doesn't work
                                                style={{ verticalAlign: 'middle' }}
                                              >
                                                {(!isDownloading.downloading &&
                                                  isDownloading.id !== row[columnIdentifier].uId) ||
                                                (isDownloading.downloading &&
                                                  isDownloading.id !== row[columnIdentifier].uId) ? (
                                                  <Button
                                                    label={action.title}
                                                    variant="outlined"
                                                    onClick={(event: React.MouseEvent): void => {
                                                      event.stopPropagation();
                                                      setIsDownloading({
                                                        downloading: true,
                                                        id: row[columnIdentifier].uId,
                                                        name: row[columnIdentifier].rawValue.toString()
                                                      });
                                                    }}
                                                    sx={{ height: '30px' }}
                                                  />
                                                ) : (
                                                  <Box marginTop={theme.spacing(1)}>
                                                    <CircleLoadingIndicator loadingIndicatorSize={24} />
                                                  </Box>
                                                )}
                                              </TableCell>
                                            );
                                          } else {
                                            return (
                                              <TableCell
                                                key={`editDelete${index}`}
                                                align="center"
                                                // vAlign prop doesn't work
                                                style={{ verticalAlign: 'middle' }}
                                              >
                                                <Button
                                                  sx={{ height: '30px' }}
                                                  label={action.title}
                                                  variant="outlined"
                                                  onClick={(event: React.MouseEvent): void => {
                                                    event.stopPropagation();
                                                    if (action.actionFn) action.actionFn(row[columnIdentifier].uId);
                                                  }}
                                                />
                                              </TableCell>
                                            );
                                          }
                                        })
                                      : null}
                                  </Box>
                                );
                              } else if (editColumnName && key === editColumnName) {
                                const ix = inputValidation.findIndex(rec => rec.id === rowId);
                                const inputFieldValidation = ix !== -1 ? inputValidation[ix] : null;
                                const isInputInvalid =
                                  inputFieldValidation !== null ? !inputFieldValidation.valid : false;

                                if (inputFieldValidation) {
                                  return (
                                    <TableCell key={`${key}${index}`} align="center">
                                      {inputFieldValidation.edit ? (
                                        <TextInput
                                          // eslint-disable-next-line jsx-a11y/no-autofocus
                                          autoFocus={true}
                                          error={isInputInvalid}
                                          role="input"
                                          key={`${key}${index}`}
                                          defaultValue={row[editColumnName].rawValue}
                                          onClick={(event): void => event.stopPropagation()}
                                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                          onBlur={(event: any): void => {
                                            handleFieldValidation(rowId, event.target.value);
                                            if (onEditColumnUpdate) {
                                              onEditColumnUpdate(rowId, event.target.value);
                                            }
                                          }}
                                          onKeyUp={(event: React.KeyboardEvent): void => {
                                            if (event.key === 'Enter' && onEditColumnUpdate) {
                                              const input = event.currentTarget
                                                .getElementsByTagName('input')
                                                .item(0) as HTMLInputElement;
                                              if (input && input.value) {
                                                onEditColumnUpdate(rowId, input.value);
                                                handleFieldValidation(rowId, input.value);
                                              }
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Typography
                                          variant="p2"
                                          component="div"
                                          className={editLabel}
                                          onClick={(event: React.MouseEvent): void => {
                                            event.stopPropagation();
                                            handleInlineEditToggle(inputFieldValidation.id);
                                          }}
                                          style={
                                            !inputFieldValidation.valid
                                              ? { borderColor: theme.palette.red[500] }
                                              : undefined
                                          }
                                        >
                                          {row[editColumnName].rawValue}
                                        </Typography>
                                      )}
                                    </TableCell>
                                  );
                                } else {
                                  return null;
                                }
                              } else {
                                return row[key] ? (
                                  <React.Fragment key={index}>
                                    {index === 0 && actionOptions
                                      ? actionOptions.map((action, index) => {
                                          if (action.action === 'view') {
                                            return (
                                              <TableCell
                                                key={`viewEdit${index}`}
                                                align="center"
                                                style={{ verticalAlign: 'middle' }}
                                              >
                                                {(!isDownloading.downloading &&
                                                  isDownloading.id !== row[columnIdentifier].uId) ||
                                                (isDownloading.downloading &&
                                                  isDownloading.id !== row[columnIdentifier].uId) ? (
                                                  <Button
                                                    sx={{ height: '30px' }}
                                                    label={action.title}
                                                    variant="outlined"
                                                    onClick={(event: React.MouseEvent): void => {
                                                      event.stopPropagation();
                                                      setIsDownloading({
                                                        downloading: true,
                                                        id: row[columnIdentifier].uId,
                                                        name: row[columnIdentifier].rawValue.toString()
                                                      });
                                                    }}
                                                  />
                                                ) : (
                                                  <Box marginTop={theme.spacing(1)}>
                                                    <CircleLoadingIndicator loadingIndicatorSize={24} />
                                                  </Box>
                                                )}
                                              </TableCell>
                                            );
                                          } else {
                                            return (
                                              <TableCell
                                                key={`editDelete${index}`}
                                                align="center"
                                                style={{ verticalAlign: 'middle' }}
                                              >
                                                <Button
                                                  sx={{ height: '30px' }}
                                                  label={action.title}
                                                  variant="outlined"
                                                  onClick={(event: React.MouseEvent): void => {
                                                    event.stopPropagation();
                                                    if (action.actionFn) action.actionFn(row[columnIdentifier].uId);
                                                  }}
                                                />
                                              </TableCell>
                                            );
                                          }
                                        })
                                      : null}
                                    {row[key].component}
                                  </React.Fragment>
                                ) : (
                                  <TableCell key={`${key}-${index}`}></TableCell>
                                );
                              }
                            })}
                        </TableRow>
                      );
                    })}
                  {footerRow ? (
                    <TableRow className={footerRowClass}>
                      {columns &&
                        Object.keys(columns).map((key: string, index: number) =>
                          footerRow[key] ? (
                            <React.Fragment key={index}>{footerRow[key].component}</React.Fragment>
                          ) : key === 'selectAll' ? (
                            <TableCell key={`${key}${index}}`} />
                          ) : (
                            <TableCell key={`${key}${index}}`} />
                          )
                        )}
                      {actionOptions ? <TableCell /> : null}
                    </TableRow>
                  ) : null}
                </TableBody>
              )}
            </>
          )}
        </TableComponent>
      </TableContainer>
      {!disablePaging ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={rows ? rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
        />
      ) : null}
      <Footnotes footnotes={footnotes} />
    </Paper>
  );
};

export default Table;
