import { RowsPerPageValues } from '@clinintell/components/Table';
import useTableFilters from '@clinintell/components/Table/useTableFilters';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import GroupGeneratedReport from './sections/GroupGeneratedReport';
import HospitalGeneratedReport from './sections/HospitalGeneratedReport';
import ProviderGeneratedReport from './sections/ProviderGeneratedReport';
import SystemGeneratedReport from './sections/SystemGeneratedReport';
import { TrainingProgressEntity } from '../reports/types/routeProps';
import { useReportDownloadStatus } from './context/ReportDownloadContext';
import { useUser } from '@clinintell/modules/store';

export type ReportTypes = 'System' | 'Hospital' | 'Group' | 'Provider' | 'Unknown';

export type OrgTableProps = {
  orgId: number;
  onSort?: (column: string, isDescending: boolean) => void;
  rowsPerPageValues: RowsPerPageValues;
  onRowsPerPageChanged?: (val: RowsPerPageValues) => void;
};

type ReportTypeProps = {
  hospital?: TrainingProgressEntity;
  physician?: TrainingProgressEntity;
  group?: TrainingProgressEntity;
};

// Helper functions
export const getReportType = ({ hospital, physician, group }: ReportTypeProps): ReportTypes => {
  if (physician && physician.name !== 'All' && physician.name !== '') {
    return 'Provider';
  } else if (group && group.name !== 'All' && group.name !== '') {
    return 'Group';
  } else if (hospital && hospital.name !== 'All Hospitals' && hospital.name !== '') {
    return 'Hospital';
  } else if (hospital) {
    return 'System';
  } else {
    return 'Unknown';
  }
};

type TrainingStatusReportProps = {
  hospital?: TrainingProgressEntity;
  group?: TrainingProgressEntity;
  physician?: TrainingProgressEntity;
};

const TrainingStatusReport: React.FC<TrainingStatusReportProps> = ({ hospital, group, physician }) => {
  const { rootId } = useUser();
  const { sortColumn, sortIsDescending, onSortChange, rowsPerPage, onRowsPerPageChange } = useTableFilters({
    defaultSortColumn: 'progress',
    defaultSortIsDescending: true,
    defaultRowsPerPage: 50
  });

  const { changeSortColumn, toggleSortDescending } = useReportDownloadStatus();

  useEffect(() => {
    changeSortColumn(sortColumn);
  }, [changeSortColumn, sortColumn]);

  useEffect(() => {
    toggleSortDescending(Boolean(sortIsDescending));
  }, [sortIsDescending, toggleSortDescending]);

  let report;
  switch (getReportType({ hospital, group, physician })) {
    case 'Provider':
      report = (
        <ProviderGeneratedReport
          orgId={Number(physician?.id)}
          onSort={onSortChange}
          rowsPerPageValues={rowsPerPage}
          onRowsPerPageChanged={onRowsPerPageChange}
        />
      );
      break;
    case 'Group':
      report = (
        <GroupGeneratedReport
          orgId={Number(group?.id)}
          onSort={onSortChange}
          rowsPerPageValues={rowsPerPage}
          onRowsPerPageChanged={onRowsPerPageChange}
        />
      );
      break;
    case 'Hospital':
      report = (
        <HospitalGeneratedReport
          orgId={Number(hospital?.id)}
          onSort={onSortChange}
          rowsPerPageValues={rowsPerPage}
          onRowsPerPageChanged={onRowsPerPageChange}
        />
      );
      break;
    case 'System':
      if (hospital && hospital.id) {
        report = (
          <SystemGeneratedReport
            rowsPerPageValues={rowsPerPage}
            onRowsPerPageChanged={onRowsPerPageChange}
            orgId={hospital && hospital.id !== -1 ? hospital.id : rootId}
            onSort={onSortChange}
          />
        );
      } else {
        report = null;
      }
      break;
    case 'Unknown':
    default:
      report = null;
      break;
  }

  return <Box marginTop={4}>{report}</Box>;
};

export default TrainingStatusReport;
