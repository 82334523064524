import React, { useState } from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import clsx from 'clsx';

import { IconButton, Typography, useTheme, Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  headerTooltip: {
    marginLeft: 5,
    top: 3,
    position: 'relative'
  },
  overlay: {
    borderRadius: '1rem',
    fontWeight: 700,
    maxWidth: '5rem',
    margin: '10px auto'
  },
  red: {
    backgroundColor: theme.palette.bubble.red.light,
    color: theme.palette.bubble.red.main
  },
  green: {
    backgroundColor: theme.palette.bubble.green.light,
    color: theme.palette.bubble.green.main
  },
  yellow: {
    backgroundColor: theme.palette.bubble.yellow.light,
    color: theme.palette.bubble.yellow.main
  },
  clickable: {
    color: theme.palette.blue.main,
    cursor: 'pointer'
  },
  plain: {
    listStyleType: 'none',
    margin: '0px',
    '& > li': {
      '&:hover': {
        cursor: 'auto'
      }
    }
  },
  tdroot: {
    position: 'relative'
  },
  expanded: {
    display: 'block',
    paddingBottom: '12px',
    paddingLeft: '20px'
  },
  closed: {
    display: 'none'
  },
  verysmall: {
    width: '1rem',
    height: '1rem',
    fontSize: '1rem',
    padding: 0,
    marginTop: '-1px'
  }
}));

interface Props {
  openOthers?: (height: number, tableHeightTmp: number) => void;
  otherChildren: string[];
}

const OthersCell: React.FC<Props> = ({ children, otherChildren, openOthers }) => {
  const { plain, expanded, closed, verysmall } = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const tsCompliantChildren: string[] = [...otherChildren];
  const toggleIsExpanded = function(e: React.MouseEvent<unknown>): void {
    e.stopPropagation();
    if (isExpanded) {
      setIsExpanded(false);
      if (!!openOthers) openOthers(38, 128);
    } else {
      setIsExpanded(true);
      if (!!openOthers) openOthers(otherChildren.length * 34.5 + 60, otherChildren.length * 34.5 + 145);
    }
  };

  return (
    <Box style={{ textAlign: 'left' }}>
      <Box style={isExpanded ? { marginTop: 10 } : undefined}>
        <IconButton
          size="small"
          classes={{ root: verysmall, sizeSmall: verysmall }}
          color="primary"
          aria-label="expand"
          onClick={toggleIsExpanded}
        >
          {isExpanded ? (
            <RemoveCircleRoundedIcon
              fontSize="small"
              classes={{ root: verysmall, fontSizeSmall: verysmall }}
            ></RemoveCircleRoundedIcon>
          ) : (
            <AddCircleRoundedIcon
              fontSize="small"
              classes={{ root: verysmall, fontSizeSmall: verysmall }}
            ></AddCircleRoundedIcon>
          )}
        </IconButton>
        <Box className="ellipse" component="span" ml={1} style={{ marginLeft: '5px' }}>
          {children}
        </Box>
      </Box>
      <ul
        className={clsx('classes', {
          [plain]: true,
          [expanded]: isExpanded,
          [closed]: !isExpanded
        })}
      >
        {tsCompliantChildren.map((c, idx) => {
          return (
            <li
              style={{
                marginTop: idx === 0 ? theme.spacing(1) : 0
              }}
              key={idx}
            >
              <Typography color="textPrimary" variant="p2">
                {c}
              </Typography>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default OthersCell;
