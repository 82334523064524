import React, { useState, useEffect } from 'react';
import AgGrid from '@clinintell/components/agGrid';
import { cellFormat } from '@clinintell/containers/metrics/sections/metricsAgGridTable';
import { Row, ColumnDefinition, RowValue } from '@clinintell/components/agGrid/tablesTypes';
import { ValueGetterParams, ColDef, ICellRendererParams } from 'ag-grid-community/dist/lib/main';

export type ReportTableProps = {
  rows: Row[];
  columns: Record<string, ColumnDefinition>;
  defaultSortColumn: string;
  columnIdentifier: string;
};

const nameFilterParams = {
  applyMiniFilterWhileTyping: true,
  valueGetter: (params: ValueGetterParams): number | string => {
    return params.data.name.rawValue;
  },
  comparator: (a: string, b: string): number => {
    return a.localeCompare(b);
  }
};

const ScheduleReportTable: React.FC<ReportTableProps> = ({ rows, columns, defaultSortColumn, columnIdentifier }) => {
  const [rowData, setRowData] = useState<Row[]>([]);
  const [tableHeight, setTableHeight] = useState(128 /*+15*/);

  const defaultColDef = {
    sortable: true,
    resizable: true,
    flex: 1,
    filterParams: nameFilterParams,
    checkboxSelection: false,
    headerCheckboxSelection: false,
    cellRenderer: 'cellRender'
  };

  const ProcessColumns = (): ColDef[] => {
    const cols: ColDef[] = [];

    Object.keys(columns).forEach((key: string) => {
      cols.push({
        field: key,
        headerName: columns[key].title,
        sort: defaultSortColumn === key ? 'asc' : '',
        minWidth:
          key === 'name' ? 350 : key === 'action' ? 100 : key === 'frequency' ? 150 : key === 'recipients' ? 250 : 200,
        maxWidth: key === 'action' ? 100 : key === 'frequency' ? 150 : key === 'reportType' ? 200 : 500,
        menuTabs: key === 'name' ? ['filterMenuTab'] : [],
        filter: key === 'name',
        headerClass: `resizable-header column-header-style${key === 'name' ? '' : ' centered-header'}`,
        comparator: (valueA: RowValue, valueB: RowValue) => {
          if (!valueA && !valueB) return 0;
          let rawValueA = valueA.rawValue;
          let rawValueB = valueB.rawValue;
          if (typeof rawValueA === 'string' && typeof rawValueB === 'string') {
            rawValueA = rawValueA.toLowerCase();
            rawValueB = rawValueB.toLowerCase();
          }

          return rawValueA === rawValueB ? 0 : rawValueA < rawValueB ? -1 : 1;
        }
      });
    });

    return cols;
  };

  const ProcessRows = (): void => {
    const tmpRowData: Row[] = [];

    if (rows && rows.length > 0) {
      let rowObject = {};
      rows.forEach((row: Row) => {
        const rowId = (row[columnIdentifier] as RowValue).uId;

        Object.keys(columns).forEach((key: string) => {
          const { prefix = '', postfix = '' } = columns[key];
          rowObject = {
            ...rowObject,
            key,
            [key]: {
              key,
              rowId,
              rawValue: (row[key] as RowValue).rawValue,
              prefix,
              postfix,
              restProps: (row[key] as RowValue).restProps,
              component: (row[key] as RowValue).component
            }
          };
        });

        tmpRowData.push(rowObject);
      });
    }

    setRowData(tmpRowData);
  };

  type CellRenderObject = {
    restProps: unknown;
    value: unknown;
    styledValue: string;
    key: unknown;
    column: unknown;
    component: unknown;
  };

  const CellRenderer = (params: ICellRendererParams): CellRenderObject => {
    const { key, rawValue, prefix, postfix, restProps, component } = params.value;
    const type = !!restProps ? restProps.type : undefined;
    const styledValue = cellFormat(rawValue, prefix, postfix, type);

    return {
      restProps: { ...restProps, rawValue },
      value: rawValue,
      styledValue,
      key,
      column: key,
      component
    };
  };

  useEffect(() => {
    ProcessRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AgGrid
      headerHeight={38}
      columnDefs={ProcessColumns()}
      rowData={rowData}
      getRenderCells={CellRenderer}
      defaultColDef={defaultColDef}
      columnIdentifier={columnIdentifier}
      pagination={true}
      rowsPerPage={50}
      tableFinalHeight={rowData.length * 38 + (tableHeight - 39)}
      setTableHeight={setTableHeight}
    />
  );
};

export default ScheduleReportTable;
