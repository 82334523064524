import React, { Suspense, lazy } from 'react';
import Layout from '@clinintell/containers/layout/Layout';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import { Routes, Route, Navigate } from 'react-router-dom';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import ReportRoutes from '@clinintell/routes/reports/ReportRoutes';
import AnalysisRoutes from '@clinintell/routes/analysis/AnalysisRoutes';
import GoalRoutes from '@clinintell/routes/goals/GoalRoutes';
import TrainingRoutes from '@clinintell/routes/training/TrainingRoutes';
import ToolRoutes from '@clinintell/routes/tools/ToolRoutes';
import { SnackbarProvider } from 'notistack';
import { TargetedConditionsProvider } from '@clinintell/containers/metrics/logic/TargetedConditionsContext';
import useClearBreadcrumbNavigationOnRouteChange from './logic/useClearBreadcrumbNavigationOnRouteChange';
import { MetricsDataProvider } from '@clinintell/containers/metrics/logic/MetricsDataContext';

const Metrics = lazy(() => import(/* webpackChunkName: 'metrics' */ '@clinintell/containers/metrics/Metrics'));

const DashboardController = lazy(() =>
  import(/* webpackChunkName: 'dashboardController' */ '@clinintell/containers/dashboard/Dashboard')
);

const SupportPage = lazy(() => import(/* webpackChunkName: 'support' */ '@clinintell/containers/support/Support'));

const AuthenticatedRoutes: React.FC = () => {
  useClearBreadcrumbNavigationOnRouteChange();

  return (
    <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Layout>
        <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
          <Routes>
            <Route
              path="metrics"
              element={
                <MetricsDataProvider>
                  <TargetedConditionsProvider>
                    <Metrics />
                  </TargetedConditionsProvider>
                </MetricsDataProvider>
              }
            />
            <Route path="goals/*" element={<GoalRoutes />} />
            <Route path="analytics/*" element={<AnalysisRoutes />} />
            <Route path="reports/*" element={<ReportRoutes />} />
            <Route path="training/*" element={<TrainingRoutes />} />
            <Route path="tools/*" element={<ToolRoutes />} />
            <Route path="support" element={<SupportPage />} />
            <Route path="callback" element={<Navigate replace to="/" />} />
            <Route path="signin" element={<Navigate replace to="/" />} />
            <Route path="signup" element={<Navigate replace to="/" />} />
            <Route path="index.html" element={<Navigate replace to="/" />} />
            <Route index path="/" element={<DashboardController />} />
            <Route path="*" element={<RouteErrorPage error={new Error('404')} />} />
          </Routes>
        </Suspense>
      </Layout>
    </SnackbarProvider>
  );
};

export default AuthenticatedRoutes;
