import { ColumnDefinition } from '@clinintell/components/Table';

export type NodeType = 'Provider' | 'Conditions' | 'Resource';
export type NodeTypeLabel = 'Provider Name' | 'Clinical Conditions' | 'Resource';

export interface ActionRecordJSON {
  [index: string]: string | number | boolean | null;
  id: number;
  providerName: string;
  videosWatched: string;
  questionsAnswered: string;
  testScores: number;
  email: string | null;
  docScore: number;
  sentTo: string | null;
  sentDate: string;
  nodeTypeId: number;
  nodeType: NodeType;
}

export interface GroupReportRecordJSON {
  [index: string]: string | number | Date | null;
  reportId: number;
  fileName: string;
  reportDate: Date;
  reportTypeName: string;
  reportTypeId: number;
  title: string;
}

export interface ResourceRecordJSON {
  [index: string]: string | number | ResourceCategoryRecordJSON | FeatureRecordJSON[] | null | undefined;
  id: number;
  title: string;
  slug: string;
  imageLink?: string;
  category: ResourceCategoryRecordJSON;
  html: string;
  features: FeatureRecordJSON[];
  dateCreated: string;
  dateModified?: string;
}

export interface ResourceCategoryRecordJSON {
  [index: string]: number | string;
  id: number;
  category: string;
  description: string;
}

export interface FeatureRecordJSON {
  id: number;
  featureName: string;
  displayName: string;
  description: string;
}

export interface ActionSchema {
  type: string;
  properties: Record<string, ColumnDefinition>;
}

export interface ConditionJSON {
  id: number;
  conditionName: string;
  conditionDescription: string;
}

export enum ReportType {
  Provider = 'provider',
  Group = 'group',
  Quarterly = 'quarterly',
  Training = 'trainingprogress',
  System = 'system',
  Executive = 'executive'
}
