import React from 'react';
import { Box } from '@mui/material';

import DRG from '@clinintell/assets/images/DRG.svg';
import HCC from '@clinintell/assets/images/HCC.svg';
import Ex from '@clinintell/assets/images/Ex.svg';

interface Props {
  rawValue: string | number;
}

const ConditionCell: React.FC<Props> = ({ children, rawValue }) => {
  return (
    <Box style={{ width: '100%', marginTop: 9 }}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <img
          src={rawValue === 1 ? DRG : rawValue === 2 ? HCC : Ex}
          alt={rawValue === 1 ? 'DRG' : rawValue === 2 ? 'HCC' : 'Ex'}
        />
      </Box>
    </Box>
  );
};

export default ConditionCell;
