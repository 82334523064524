/* eslint-disable @typescript-eslint/no-empty-function */
import { ActionSchema } from '@clinintell/containers/reports/types/actionTypes';

export type ColumnName =
  | 'id'
  | 'providerName'
  | 'videosWatched'
  | 'questionsAnswered'
  | 'testScores'
  | 'clinicalCondition'
  | 'lastVideosWatched'
  | 'lastTestTaken'
  | 'email'
  | 'docScore'
  | 'sentTo'
  | 'sentDate'
  | 'groupReport'
  | 'title'
  | 'slug'
  | 'imageLink'
  | 'html'
  | 'category'
  | 'features'
  | 'dateCreated'
  | 'dateModified'
  | 'description'
  | 'nodeTypeId'
  | 'nodeType'
  | 'reportDate';

export type ColumnView = {
  columns: ColumnName[];
  defaultSort: ColumnName;
};

export enum Views {
  providerReportViewSend = 'providerReportViewSend',
  providerReportView = 'providerReportView',
  groupReportView = 'groupReportView',
  trainingConfiguration = 'trainingConfiguration',
  providerTraining = 'providerTraining',
  resourcePageView = 'resourcePageView',
  resourceCategoryView = 'resourceCategoryView'
}

type ViewLabels = {
  [key in Views]: string;
};

export const viewLabels: ViewLabels = {
  providerReportViewSend: 'Provider Report',
  providerReportView: 'Provider Report',
  groupReportView: 'Group Report',
  trainingConfiguration: 'Training Configuration',
  providerTraining: 'Provider',
  resourcePageView: 'Resources',
  resourceCategoryView: 'Resource Categories'
};

type CommonViews = {
  [key in Views]: ColumnView;
};

export const commonConfigurations: CommonViews = {
  providerReportViewSend: {
    columns: ['providerName', 'email', 'docScore', 'sentTo', 'sentDate'],
    defaultSort: 'docScore'
  },
  providerReportView: {
    columns: ['providerName', 'docScore'],
    defaultSort: 'providerName'
  },
  groupReportView: {
    columns: ['title'],
    defaultSort: 'title'
  },
  trainingConfiguration: {
    columns: ['providerName', 'videosWatched', 'questionsAnswered', 'testScores'],
    defaultSort: 'providerName'
  },
  providerTraining: {
    columns: ['clinicalCondition', 'lastVideosWatched', 'lastTestTaken'],
    defaultSort: 'lastTestTaken'
  },
  resourcePageView: {
    columns: ['title', 'slug', 'category', 'features', 'dateCreated', 'dateModified'],
    defaultSort: 'dateCreated'
  },
  resourceCategoryView: {
    columns: ['category', 'description'],
    defaultSort: 'category'
  }
};

export const providerReportSchema: ActionSchema = {
  type: 'object',
  properties: {
    Id: {
      title: 'Id',
      type: 'integer',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    ProviderName: {
      title: 'Provider Name',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    VideosWatched: {
      title: 'Videos Watched',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    QuestionsAnswered: {
      title: 'Questions Answered',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    TestScores: {
      title: 'Test Scores',
      type: 'number',
      postfix: '%',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Email: {
      title: 'Email',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    DocScore: {
      title: 'Documentation Score',
      type: 'integer',
      postfix: '%',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    SentTo: {
      title: 'Last Sent To',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    SentDate: {
      title: 'Last Sent Date',
      type: 'date',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    }
  }
};

export const groupReportSchema: ActionSchema = {
  type: 'object',
  properties: {
    ReportId: {
      title: 'ReportId',
      type: 'integer',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Title: {
      title: 'Group Report',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    }
  }
};

export const resourcePageSchema: ActionSchema = {
  type: 'object',
  properties: {
    Id: {
      title: 'Id',
      type: 'integer',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Title: {
      title: 'Title',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Slug: {
      title: 'Slug',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    ImageLink: {
      title: 'Image',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Category: {
      title: 'Category',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Features: {
      title: 'Features',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    DateCreated: {
      title: 'Date Created',
      type: 'date',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    DateModified: {
      title: 'Date Modified',
      type: 'date',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    }
  }
};

export const resourceCategorySchema: ActionSchema = {
  type: 'object',
  properties: {
    Id: {
      title: 'Id',
      type: 'integer',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Category: {
      title: 'Category',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    },
    Description: {
      title: 'Description',
      type: 'string',
      onFilter: (column: string, value: string | string[] | number | number[]): void => {}
    }
  }
};
