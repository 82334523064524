import React from 'react';
import { Box, useTheme } from '@mui/material';
import Tooltip from '@clinintell/components/tooltip/Tooltip';

interface Props {
  column: string;
  type: string;
  align?: 'center' | 'left' | 'right';
  tooltipTitle?: string;
  tooltipContent?: string | JSX.Element;
}

const DefaultCell: React.FC<Props> = ({ children, column, type, align, tooltipTitle, tooltipContent }) => {
  const theme = useTheme();

  function cellStyle(column: string, type: string, styledValue: string): React.CSSProperties {
    const isBubble = column === 'gapVsCe' || column === 'change' || column === 'otoEGapVsCe' || column === 'otoEChange';
    const cellProps: React.CSSProperties = {
      textAlign: !!align ? align : isBubble || type === 'date' ? 'center' : type === 'string' ? 'left' : 'right',
      width: '100%',
      fontSize: '0.9rem',
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: theme.typography.fontFamily
    };

    if ((type === 'string' || type === 'integer') && styledValue === '-') cellProps.textAlign = 'center';

    if (type !== 'string' && type !== 'date' && !isBubble) {
      cellProps.fontFamily = theme.typography.fontFamilyMonospaced;
      cellProps.width = !!align ? '100%' : 'calc(50% + 17px)';
    }

    if (styledValue === '-' && type !== 'date') {
      cellProps.textAlign = 'right';
      cellProps.width = 'calc(50%)';
    }

    if (type === 'string') cellProps.fontFamily = theme.typography.fontFamily;

    return cellProps;
  }

  const styledValue = children as string;
  const cellProps = cellStyle(column, type, styledValue);
  return (
    <Tooltip title={tooltipTitle} content={tooltipContent} position="right">
      <Box style={{ ...cellProps }}>{children}</Box>
    </Tooltip>
  );
};

export default DefaultCell;
