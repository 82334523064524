import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import AgGrid from '@clinintell/components/agGrid';
import { ColDef, ICellRendererParams } from 'ag-grid-community/dist/lib/main';
import { Row } from '@clinintell/components/agGrid/tablesTypes';
import * as Cells from '@clinintell/components/agGrid/cells';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { format } from 'date-fns';
import { sortingComparator } from '@clinintell/containers/metrics/sections/metricsAgGridTable';
import { Timeline } from '@mui/icons-material';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import Footnotes from '@clinintell/components/Footnotes';
import useInitializeMetrics from '@clinintell/containers/metrics/logic/useInitializeMetrics';
import { useDashboard2 } from '@clinintell/modules/store';
import { TargetedConditionsTableType } from '@clinintell/modules/dashboard2';

type Props = {
  onGraphClick: (conditionId: number, conditionDescription: string) => void;
};

const TargetedConditionsWidgetTable: React.FC<Props> = ({ onGraphClick }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { targetedConditionsWidget } = useDashboard2();
  const { modalTableData } = targetedConditionsWidget;
  const commonFieldProps = {
    suppressMenu: true,
    sort: null,
    cellRenderer: 'cellRender',
    comparator: sortingComparator
  };
  const baseHeaderClass = 'resizable-header column-header-style';

  const columnDefs: Array<ColDef> = [
    {
      ...commonFieldProps,
      field: 'conditionDescription',
      headerName: 'Clinical Condition',
      headerClass: `condition ${baseHeaderClass} left-header`,
      cellStyle: {
        textAlign: 'left'
      },
      headerComponentParams: {
        align: 'flex-start'
      },
      width: 450
    },
    {
      ...commonFieldProps,
      field: 'action',
      headerName: 'Action',
      headerClass: `action ${baseHeaderClass} centered-header`,
      checkboxSelection: false,
      headerCheckboxSelection: false,
      suppressMenu: true,
      sortable: false,
      sort: null,
      width: 100
    },
    {
      ...commonFieldProps,
      field: 'historicalOtoCE',
      headerName: 'Historical O/CE',
      headerClass: `historicalOtoCE ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'currentOtoCE',
      headerName: 'Current O/CE',
      headerClass: `currentOtoCE ${baseHeaderClass} centered-header`
    },
    {
      ...commonFieldProps,
      field: 'status',
      headerName: 'Status',
      headerClass: `status ${baseHeaderClass} centered-header`,
      headerComponentParams: {
        tooltipMessage: (
          <Box>
            <p>Improving: The positive change is statistically significant.</p>
            <p>No Change: The positive or negative change is not statistically significant.</p>
            <p>Declining: The negative change is statistically significant.</p>
          </Box>
        ),
        align: 'center'
      }
    }
  ].filter(col => (isMobileView ? ['conditionDescription', 'action', 'status'].includes(col.field as string) : true));

  const formatStatusText = (text: string): string => {
    const words = text.split(' ');
    words.forEach((word, ix) => {
      words[ix] = word[0].toUpperCase() + word.substring(1);
    });
    return words.join(' ');
  };

  const getStatusColor = (text: string): string => {
    if (text === 'Improving') return theme.palette.teal.light4;
    if (text === 'Declining') return theme.palette.red[500];
    return theme.palette.grey[500];
  };

  const {
    historicalStart,
    historicalEnd,
    currentStart,
    currentEnd,
    table
  } = modalTableData as TargetedConditionsTableType;

  let agGridRows: Row[] = [];
  try {
    agGridRows = table.map(
      (row): Row => {
        return {
          conditionDescription: {
            rawValue: row.conditionDescription,
            component: (
              <Box
                fontSize={theme.typography.p2.fontSize}
                fontFamily={theme.typography.fontFamily}
                width="100%"
                textAlign="left"
                component="span"
              >
                {row.conditionDescription}
              </Box>
            )
          },
          action: {
            rawValue: row.conditionId,
            component: (
              <Box
                onClick={(): void => onGraphClick(row.conditionId, row.conditionDescription)}
                onTouchEnd={(): void => onGraphClick(row.conditionId, row.conditionDescription)}
                sx={{ marginTop: 1, cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%' }}
              >
                <Tooltip content="View Graph" position="right">
                  <Timeline />
                </Tooltip>
              </Box>
            )
          },
          historicalOtoCE: {
            rawValue: row.historicalOtoCE,
            component: (
              <Box width="100%" textAlign="center" component="span">
                {`${formatToDecimalPlaces(row.historicalOtoCE, 0)}%`}
              </Box>
            )
          },
          currentOtoCE: {
            rawValue: row.currentOtoCE,
            component: (
              <Box width="100%" textAlign="center" component="span">
                {`${formatToDecimalPlaces(row.currentOtoCE, 0)}%`}
              </Box>
            )
          },
          status: {
            rawValue: row.status,
            component: (
              <Box
                component="span"
                sx={{ width: '100%', textAlign: 'center', fontWeight: 'bold', color: getStatusColor(row.status) }}
              >
                {formatStatusText(row.status)}
              </Box>
            )
          }
        };
      }
    );
  } catch {}

  const getRenderCells = (params: ICellRendererParams): unknown => {
    const { key, rawValue, rowId, component } = params.value;

    return {
      [key]: key === 'action' ? Cells['ActionCell'] : Cells['DefaultCell'],
      restProps: { rawValue },
      value: rawValue,
      key,
      rowId,
      component
    };
  };

  const footnotes: string[] = [
    `Historical O/CE: ${format(new Date(historicalStart), 'MMMyy')}-${format(new Date(historicalEnd), 'MMMyy')}`,
    `Current O/CE: ${format(new Date(currentStart), 'MMMyy')}-${format(new Date(currentEnd), 'MMMyy')}`
  ];

  useInitializeMetrics();

  return (
    <Box>
      <Typography variant={'h5'} style={{ color: theme.palette.grey[700], fontWeight: 'bold' }}>
        Condition Performance Table
      </Typography>
      <Box mt={2}>
        <AgGrid
          headerHeight={38}
          getRenderCells={getRenderCells}
          rowData={agGridRows}
          columnDefs={columnDefs}
          rowsPerPage={10}
          tableFinalHeight={10 * 38 + 128}
          defaultColDef={{
            sortable: true,
            resizable: true,
            flex: 1,
            sortingOrder: ['asc', 'desc']
          }}
        />
        <Box mt={1} ml={-2}>
          <Footnotes footnotes={footnotes} />
        </Box>
      </Box>
    </Box>
  );
};

export default TargetedConditionsWidgetTable;
