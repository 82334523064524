import React, { useState } from 'react';
import { ApplicationAPI } from '@clinintell/utils/api';
import { ScheduledReportActionTypes, ScheduledReportItem } from '@clinintell/modules/reports';
import { useScheduledReportState, useScheduledReportDispatch } from '@clinintell/modules/store';
import { validateEmail } from '@clinintell/utils/validators';
import useAlert from '@clinintell/components/alert/logic/useAlert';

type SearchReport = {
  exists: boolean;
  report?: ScheduledReportItem;
};

type ScheduleReportOutput = {
  isUpdating: boolean;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  reloadReports: () => void;
  reportExist: (id: number) => SearchReport;
  downloadReport: (id: number) => Promise<void>;
  validateEmails: (emailList: string[]) => Set<string>;
  deleteReport: (id: number) => void;
};

const useScheduleReport = (): ScheduleReportOutput => {
  const reportDispatch = useScheduledReportDispatch();
  const { reportList } = useScheduledReportState();
  const [isUpdating, setIsUpdating] = useState(false);
  const { pushAlert } = useAlert();

  const reloadReports = (): void => {
    reportDispatch({ type: ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS });
  };

  const reportExist = (id: number): SearchReport => {
    if (reportList.length === 0) return { exists: false };
    const report = reportList.find(r => r.id === id);
    if (!report) {
      pushAlert({ message: `Report not found, reloading table`, variant: `error` });
      reloadReports();
      return { exists: false };
    }
    return { exists: true, report };
  };

  const downloadReport = async (id: number): Promise<void> => {
    setIsUpdating(value => (value = true));
    const endpoint = `schedule/reports/download/${id}`;

    const { exists, report } = reportExist(id);
    if (exists && report) {
      ApplicationAPI.download(endpoint, 'pdf')
        .then(response => {
          if (response.data) {
            return response.data;
          } else {
            pushAlert({ message: `${response.error}`, variant: `error` });
            throw new Error(response.error);
          }
        })
        .then(buffer => {
          const blob = new Blob([buffer], {
            type: 'application/pdf'
          });
          const filename = report?.reportName;
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
            return;
          }
          const link = document.createElement('a');
          link.download = filename;
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          throw new Error(`Error with the download request: ${error}`);
          // Update global error boundary state to display error boundary
        })
        .finally(() => {
          setIsUpdating(false);
        });
    } else {
      setIsUpdating(value => (value = false));
    }
  };

  const validateEmails = (emailList: string[]): Set<string> => {
    const badList: Set<string> = new Set();

    emailList.forEach(email => {
      if (!validateEmail(email)) {
        badList.add(email);
      }
    });
    return badList;
  };

  const deleteReport = (id: number): void => {
    setIsUpdating(value => (value = true));
    const endpoint = `schedule/reports/${id}`;

    if (reportExist(id).exists) {
      ApplicationAPI.delete({ endpoint })
        .then(response => {
          if (response.status === 200) reloadReports();
          if (response.error) pushAlert({ message: response.error, variant: `error` });
        })
        .catch(error => {
          throw new Error(`Error deleting a scheduled report: ${error}`);
        })
        .finally(() => setIsUpdating(false));
    }
  };

  return {
    isUpdating,
    setIsUpdating,
    reloadReports,
    reportExist,
    downloadReport,
    validateEmails,
    deleteReport
  };
};

export default useScheduleReport;
