import React from 'react';
import { Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Tooltip from '@clinintell/components/tooltip/Tooltip';

const useStyles = makeStyles(theme => ({
  headerTooltip: {
    marginLeft: 5,
    top: 3,
    position: 'relative'
  },
  overlay: {
    borderRadius: '1rem',
    fontWeight: 700,
    maxWidth: '5rem',
    margin: '10px auto'
  },
  red: {
    backgroundColor: theme.palette.bubble.red.light,
    color: theme.palette.bubble.red.main
  },
  green: {
    backgroundColor: theme.palette.bubble.green.light,
    color: theme.palette.bubble.green.main
  },
  yellow: {
    backgroundColor: theme.palette.bubble.yellow.light,
    color: theme.palette.bubble.yellow.main
  }
}));

interface Props {
  overlayStyle: string;
  tooltipTitle?: string;
  tooltipContent?: string | JSX.Element;
}

const BubbleCell: React.FC<Props> = ({ children, overlayStyle, tooltipTitle, tooltipContent }) => {
  const theme = useTheme();
  const { overlay, red, green, yellow } = useStyles();
  return (
    <Tooltip title={tooltipTitle} content={tooltipContent} position="right">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          fontSize: '0.9rem',
          fontFamily: theme.typography.fontFamilyMonospaced,
          height: !!overlayStyle ? 18 : 'auto',
          width: !!overlayStyle ? 72 : '100%'
        }}
        className={clsx({
          [overlay]: overlayStyle !== undefined,
          [red]: overlayStyle === 'red',
          [green]: overlayStyle === 'green',
          [yellow]: overlayStyle === 'yellow'
        })}
        component="div"
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default BubbleCell;
