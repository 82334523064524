import { useState } from 'react';
import { RowsPerPageValues, TableProps } from '../Table';

type TableFiltersProps = {
  defaultRowsPerPage: RowsPerPageValues;
  defaultSortColumn?: string;
  defaultSortIsDescending?: boolean;
};

type TableFiltersOutput = {
  rowsPerPage: RowsPerPageValues;
  onRowsPerPageChange: TableProps['onRowsPerPageChange'];
  sortColumn?: string;
  onSortChange: TableProps['onSort'];
  sortIsDescending?: boolean;
};

const useTableFilters = ({
  defaultRowsPerPage,
  defaultSortColumn,
  defaultSortIsDescending
}: TableFiltersProps): TableFiltersOutput => {
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortIsDescending, setSortIsDescending] = useState(defaultSortIsDescending);
  const [rowsPerPage, setRowsPerPage] = useState<RowsPerPageValues>(defaultRowsPerPage);

  return {
    rowsPerPage,
    onRowsPerPageChange: setRowsPerPage,
    sortColumn,
    sortIsDescending,
    onSortChange: (sortColumn: string, isDesc: boolean): void => {
      setSortColumn(sortColumn);
      setSortIsDescending(isDesc);
    }
  };
};

export default useTableFilters;
