import React from 'react';
import { TableProps, Row, RowsPerPageValues } from '@clinintell/components/Table';
import StyledTableCell from '@clinintell/components/StyledTableCell';
import { GeneratedHospitalReportRecord } from '../types/generatedReportDataTypes';

const mapRow = (group: GeneratedHospitalReportRecord): Row => ({
  groupName: {
    rawValue: group.name,
    uId: group.orgId,
    component: <StyledTableCell isBubble={false} value={group.name} type="string" align="left" />
  },
  numberOfCompletedTraining: {
    rawValue: group.totalCompletedTraining,
    uId: group.orgId,
    component: <StyledTableCell isBubble={false} value={group.totalCompletedTraining} type="integer" align="center" />
  },
  numberOfAssignedTraining: {
    rawValue: group.totalInvitationSent,
    uId: group.orgId,
    component: <StyledTableCell isBubble={false} value={group.totalInvitationSent} type="integer" align="center" />
  },
  progress: {
    rawValue: group.trainingProgressPercent,
    uId: group.orgId,
    component: (
      <StyledTableCell
        isBubble={false}
        value={group.trainingProgressPercent}
        type="integer"
        postfix="%"
        align="center"
      />
    )
  }
});

const generateGroupTrainingReportTableProps = (
  groups: GeneratedHospitalReportRecord[],
  rowsPerPage: RowsPerPageValues
): TableProps => {
  let footerRow;
  const rows = groups.filter(group => !group.isFooter).map(group => mapRow(group));

  const footerRecord = groups.find(group => group.isFooter);
  if (footerRecord) {
    footerRow = mapRow(footerRecord);
  }

  return {
    rows,
    footerRow: footerRow,
    columns: {
      groupName: {
        type: 'string',
        title: 'Physician Group'
      },
      numberOfAssignedTraining: {
        type: 'integer',
        title: 'Provider Count (Assigned)'
      },
      numberOfCompletedTraining: {
        type: 'integer',
        title: 'Provider Count (Completed)'
      },
      progress: {
        type: 'integer',
        postfix: '%',
        title: 'Training Progress'
      }
    },
    defaultSortColumn: 'progress',
    disablePaging: false,
    rowsPerPage,
    columnIdentifier: 'groupName'
  };
};

export default generateGroupTrainingReportTableProps;
