import { useEffect, useState } from 'react';
import _ from 'lodash';
import { GridApi } from 'ag-grid-community/dist/lib/main';
import { UpdatedGridApi } from '../components/customStatusBar';
import useIsMobileView from '@clinintell/utils/useIsMobileView';

type PageNavigation = {
  totalPages: number;
  currentPage: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  currentRange: number[];
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (page: number) => void;
  nextRange: () => void;
  prevRange: () => void;
  displayFirstPage: boolean;
  displayLastPage: boolean;
};

const usePageNavigation = (api: GridApi): PageNavigation => {
  const [gridApi, setGridApi] = useState(api);
  const [currentPage, setCurrentPage] = useState(api.paginationGetCurrentPage());
  const [totalPages, setTotalPages] = useState(0);
  const [totalRange, setTotalRange] = useState<number[]>([]);
  const [currentRange, setCurrentRange] = useState<number[]>([]);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [displayFirstPage, setDisplayFirstPage] = useState(false);
  const [displayLastPage, setDisplayLastPage] = useState(false);

  const isMobile = useIsMobileView();
  const NUMBERPAGESTORENDER = isMobile ? 3 : 5;

  useEffect(() => {
    if (!api) return;
    setGridApi(api);
    api.addEventListener('filterChanged', updatePageNavigation);
    setCurrentPage(api.paginationGetCurrentPage());
    const totalPageCount = api.paginationGetTotalPages();
    setTotalPages(totalPageCount);
    const range = _.range(0, totalPageCount);
    setTotalRange(range);
    setCurrentRange(_.take(range, NUMBERPAGESTORENDER));
    setIsFirstPage(true);
    setDisplayLastPage(totalPageCount > NUMBERPAGESTORENDER);

    return () => {
      api.removeEventListener('filterChanged', updatePageNavigation);
    };
    // Adding updatePageNavigation as a dependency stops the navigation
    // from updating after the filter changed - need to find a better solution
    // eslint-disable-next-line
  }, [api, NUMBERPAGESTORENDER]);

  const updatePageNavigation = (updatedApi: UpdatedGridApi): void => {
    if (!updatedApi) return;
    setGridApi(updatedApi.api);
    goToPage(0);
    const totalPageCount = updatedApi.api.paginationGetTotalPages();
    setTotalPages(totalPageCount);
    const range = _.range(0, totalPageCount);
    setTotalRange(range);
    setCurrentRange(_.take(range, NUMBERPAGESTORENDER));
  };

  const updateFirstLastPage = (page: number, range: number[]) => {
    setIsFirstPage(page === 0);
    setIsLastPage(page === totalPages - 1);
    if (isMobile) {
      setDisplayFirstPage(page >= NUMBERPAGESTORENDER);
      setDisplayLastPage(page < totalPages - NUMBERPAGESTORENDER);
    } else {
      setDisplayFirstPage(range.indexOf(0) === -1);
      setDisplayLastPage(range.indexOf(totalPages - 1) === -1);
    }
  };

  const nextPage = () => {
    gridApi.paginationGoToNextPage();
    let newPageNumber = currentPage + 1;
    let range = currentRange;
    if (newPageNumber > totalPages) newPageNumber--;
    if (newPageNumber > currentRange[currentRange.length - 1]) {
      range = nextRange();
    } else {
      setCurrentPage(newPageNumber);
    }
    updateFirstLastPage(newPageNumber, range);
  };

  const prevPage = () => {
    gridApi.paginationGoToPreviousPage();
    let newPageNumber = currentPage - 1;
    let range = currentRange;
    if (newPageNumber < 0) newPageNumber = 0;
    if (newPageNumber === 0 && range[0] !== 0) {
      range = _.take(totalRange, NUMBERPAGESTORENDER);
      setCurrentRange(range);
    }
    if (newPageNumber < range[0]) {
      range = prevRange();
    } else {
      setCurrentPage(newPageNumber);
    }
    updateFirstLastPage(newPageNumber, range);
  };

  const goToPage = (page: number) => {
    let range = currentRange;
    if (page === 0) {
      range = _.take(totalRange, NUMBERPAGESTORENDER);
      setCurrentRange(range);
    }
    if (page === totalPages - 1 && currentRange.indexOf(page) === -1) {
      range = totalRange.slice(totalRange.length - NUMBERPAGESTORENDER, totalRange.length);
      setCurrentRange(range);
    }
    gridApi.paginationGoToPage(page);
    setCurrentPage(page);
    updateFirstLastPage(page, range);
  };

  const nextRange = (): number[] => {
    const lastPageInRange = currentRange[currentRange.length - 1];
    const range = totalRange.slice(lastPageInRange + 1, lastPageInRange + NUMBERPAGESTORENDER + 1);
    setCurrentRange(range);
    goToPage(lastPageInRange + 1);
    return range;
  };

  const prevRange = (): number[] => {
    const firstPageInRange = currentRange[0];
    const range = totalRange.slice(firstPageInRange - NUMBERPAGESTORENDER, firstPageInRange);
    setCurrentRange(range);
    goToPage(firstPageInRange - 1);
    return range;
  };

  return {
    totalPages,
    currentPage,
    isFirstPage,
    isLastPage,
    currentRange,
    nextPage,
    prevPage,
    goToPage,
    nextRange,
    prevRange,
    displayFirstPage,
    displayLastPage
  };
};

export default usePageNavigation;
