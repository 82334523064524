import { BestFitDates } from '@clinintell/containers/cmiAnalysis/types/BestFitDates';
import { PatientMixAnalysisJSON } from '@clinintell/containers/cmiAnalysis/types/DRGGroup';
import { SeverityCMIAnalysisJSON } from '@clinintell/containers/cmiAnalysis/types/SevCMIGroup';
import { CMIMetricRecordJSON } from '@clinintell/containers/metrics/typings/metricTypes';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { CMIAnalysisViews } from './cmiAnalysisSelections';
import { CombinedState } from './store';

export type AnalysisType = 'cmi' | 'drgmix' | 'severitycapture';
export type SummaryRecord = {
  historical: number | null;
  current: number | null;
};

export type CMIAnalysis = {
  isInitialized: boolean;
  chartMinDate: Date | undefined;
  chartMaxDate: Date | undefined;
  currentMinDate: Date | undefined;
  currentMaxDate: Date | undefined;
  historicalMinDate: Date | undefined;
  historicalMaxDate: Date | undefined;
  cmiSummary: SummaryRecord | undefined;
  ceCMISummary: SummaryRecord | undefined;
  severityCMISummary: SummaryRecord | undefined;
  severityCMIAnalysis: SeverityCMIAnalysisJSON | undefined;
  drgMixSummary: SummaryRecord | undefined;
  drgAnalysis: PatientMixAnalysisJSON | undefined;
  isLoadingBestFit: boolean;
  isLoadingSummaryRecords: boolean;
  error?: string;
  noSummaryData: boolean;
  noBestFit: boolean;
  entityId: number | undefined;
  entityName: string | undefined;
  analysisView: CMIAnalysisViews;
};

/* Set best fit actions */
const SET_BEST_FIT = 'SET_BEST_FIT';
const SET_BEST_FIT_BEGIN = 'SET_BEST_FIT_BEGIN';
const SET_BEST_FIT_SUCCESSFUL = 'SET_BEST_FIT_SUCCESSFUL';
const SET_BEST_FIT_FAILED = 'SET_BEST_FIT_FAILED';

export type SetBestFitArgs = {
  orgId: number;
  orgName: string;
  startDate?: Date;
  endDate?: Date;
  recalculate?: boolean;
  metric: string;
};

export type SetBestFitPayload = {
  currentMinDate: Date;
  currentMaxDate: Date;
  historicalMinDate: Date;
  historicalMaxDate: Date;
  chartMinDate: Date;
  chartMaxDate: Date;
};

export type SetBestFitFailedPayload = {
  message: string;
  chartMinDate: Date;
  chartMaxDate: Date;
  orgId: number;
  orgName: string;
};

interface SetBestFitAction {
  type: typeof SET_BEST_FIT;
  payload: SetBestFitArgs;
}

interface SetBestFitActionBegin {
  type: typeof SET_BEST_FIT_BEGIN;
}

interface SetBestFitActionSuccessful {
  type: typeof SET_BEST_FIT_SUCCESSFUL;
  payload: SetBestFitPayload;
}

interface SetBestFitFailed {
  type: typeof SET_BEST_FIT_FAILED;
  payload: SetBestFitFailedPayload;
}

/* Set current and historical period actions */
const SET_CURRENT_AND_HISTORICAL_PERIOD = 'SET_CURRENT_AND_HISTORICAL_PERIOD';
const SET_CURRENT_AND_HISTORICAL_PERIOD_BEGIN = 'SET_CURRENT_AND_HISTORICAL_PERIOD_BEGIN';
const SET_CURRENT_AND_HISTORICAL_PERIOD_SUCCESSFUL = 'SET_CURRENT_AND_HISTORICAL_PERIOD_SUCCESSFUL';
const SET_CURRENT_AND_HISTORICAL_PERIOD_ERROR = 'SET_CURRENT_AND_HISTORICAL_PERIOD_ERROR';

export type SetCurrentAndHistoricalPeriodArgs = {
  currentMinDate?: Date;
  currentMaxDate?: Date;
  historicalMinDate?: Date;
  historicalMaxDate?: Date;
  orgId: number;
  orgName: string;
};

interface SetCurrentAndHistoricalPeriod {
  type: typeof SET_CURRENT_AND_HISTORICAL_PERIOD;
  payload: SetCurrentAndHistoricalPeriodArgs;
}

interface SetCurrentAndHistoricalPeriodBegin {
  type: typeof SET_CURRENT_AND_HISTORICAL_PERIOD_BEGIN;
}

interface SetCurrentAndHistoricalPeriodSuccessful {
  type: typeof SET_CURRENT_AND_HISTORICAL_PERIOD_SUCCESSFUL;
  payload: SetCurrentAndHistoricalPeriodArgs;
}

interface SetCurrentAndHistoricalPeriodError {
  type: typeof SET_CURRENT_AND_HISTORICAL_PERIOD_ERROR;
  payload: string;
}

/* Set Summary Records Actions */
const SET_SUMMARY_RECORDS = 'SET_SUMMARY_RECORDS';
const SET_SUMMARY_RECORDS_BEGIN = 'SET_SUMMARY_RECORDS_BEGIN';
const SET_SUMMARY_RECORDS_SUCCESSFUL = 'SET_SUMMARY_RECORDS_SUCCESSFUL';
const SET_SUMMARY_RECORDS_ERRORS = 'SET_SUMMARY_RECORDS_ERRORS';

export type SetSummaryRecordsArgs = {
  historicalMin: Date;
  historicalMax: Date;
  currentMin: Date;
  currentMax: Date;
  orgId: number;
  orgName: string;
};

export type SetSummaryRecordsPayload = {
  cmiSummary: SummaryRecord | undefined;
  ceCMISummary: SummaryRecord | undefined;
  severityCMISummary: SummaryRecord | undefined;
  severityCMIAnalysis: SeverityCMIAnalysisJSON | undefined;
  drgMixSummary: SummaryRecord | undefined;
  drgAnalysis: PatientMixAnalysisJSON | undefined;
  noSummaryData: boolean;
};

interface SetSummaryRecords {
  type: typeof SET_SUMMARY_RECORDS;
  payload: SetSummaryRecordsArgs;
}

interface SetSummaryRecordsBegin {
  type: typeof SET_SUMMARY_RECORDS_BEGIN;
}

interface SetSummaryRecordsSuccessful {
  type: typeof SET_SUMMARY_RECORDS_SUCCESSFUL;
  payload: SetSummaryRecordsPayload;
}

interface SetSummaryRecordsErrors {
  type: typeof SET_SUMMARY_RECORDS_ERRORS;
  payload: string;
}

/* Set Analysis View */
const SET_ANALYSIS_VIEW = 'SET_ANALYSIS_VIEW';

type SetAnalysisViewPayload = {
  analysisView: CMIAnalysisViews;
};

interface SetAnalysisView {
  type: typeof SET_ANALYSIS_VIEW;
  payload: SetAnalysisViewPayload;
}

/* Reset Analysis View */
const RESET_ANALYSIS = 'RESET_ANALYSIS';

interface ResetAnalysis {
  type: typeof RESET_ANALYSIS;
}

export type CMIAnalysisActionTypes =
  | SetBestFitAction
  | SetBestFitActionBegin
  | SetBestFitActionSuccessful
  | SetBestFitFailed
  | SetCurrentAndHistoricalPeriod
  | SetCurrentAndHistoricalPeriodBegin
  | SetCurrentAndHistoricalPeriodSuccessful
  | SetCurrentAndHistoricalPeriodError
  | SetSummaryRecords
  | SetSummaryRecordsBegin
  | SetSummaryRecordsSuccessful
  | SetSummaryRecordsErrors
  | SetAnalysisView
  | ResetAnalysis;

export const setCMIAnalysisBestFit = (args: SetBestFitArgs): CMIAnalysisActionTypes => ({
  type: 'SET_BEST_FIT',
  payload: args
});

export const setCMIAnalysisDates = (args: SetCurrentAndHistoricalPeriodArgs): CMIAnalysisActionTypes => ({
  type: 'SET_CURRENT_AND_HISTORICAL_PERIOD',
  payload: args
});

export const setSummaryRecords = (args: SetSummaryRecordsArgs): CMIAnalysisActionTypes => ({
  type: 'SET_SUMMARY_RECORDS',
  payload: args
});

export const setCMIAnalysisView = (args: SetAnalysisViewPayload): CMIAnalysisActionTypes => ({
  type: 'SET_ANALYSIS_VIEW',
  payload: args
});

export const resetCMIAnalysis = (): CMIAnalysisActionTypes => ({
  type: 'RESET_ANALYSIS'
});

export const initialState: CMIAnalysis = {
  isInitialized: false,
  chartMinDate: undefined,
  chartMaxDate: undefined,
  currentMinDate: undefined,
  currentMaxDate: undefined,
  historicalMinDate: undefined,
  historicalMaxDate: undefined,
  isLoadingBestFit: false,
  isLoadingSummaryRecords: false,
  cmiSummary: undefined,
  severityCMISummary: undefined,
  severityCMIAnalysis: undefined,
  drgMixSummary: undefined,
  ceCMISummary: undefined,
  drgAnalysis: undefined,
  noSummaryData: false,
  noBestFit: false,
  entityId: undefined,
  entityName: undefined,
  analysisView: 'cmi'
};

// Reducer
const reducer = (state: CMIAnalysis = initialState, action: CMIAnalysisActionTypes): CMIAnalysis => {
  switch (action.type) {
    case 'SET_BEST_FIT_BEGIN': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState,
        isLoadingBestFit: true,
        noBestFit: false
      };
    }
    case 'SET_BEST_FIT_SUCCESSFUL': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState,
        ...action.payload,
        isLoadingBestFit: false,
        noBestFit: false
      };
    }
    case 'SET_BEST_FIT_FAILED': {
      return {
        ...state,
        error: action.payload.message,
        chartMinDate: action.payload.chartMinDate,
        chartMaxDate: action.payload.chartMaxDate,
        isLoadingBestFit: false,
        noBestFit: true,
        entityId: action.payload.orgId,
        entityName: action.payload.orgName
      };
    }
    case 'SET_CURRENT_AND_HISTORICAL_PERIOD_BEGIN': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState
      };
    }
    case 'SET_CURRENT_AND_HISTORICAL_PERIOD_SUCCESSFUL': {
      const { error, ...errorlessState } = state;
      const { orgId, ...requiredPayload } = action.payload;

      return {
        ...errorlessState,
        ...requiredPayload
      };
    }
    case 'SET_CURRENT_AND_HISTORICAL_PERIOD_ERROR': {
      return {
        ...state,
        error: action.payload
      };
    }
    case 'SET_SUMMARY_RECORDS_BEGIN': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState,
        isLoadingSummaryRecords: true,
        noSummaryData: false
      };
    }
    case 'SET_SUMMARY_RECORDS_SUCCESSFUL': {
      const { error, ...errorlessState } = state;

      return {
        ...errorlessState,
        ...action.payload,
        isLoadingSummaryRecords: false
      };
    }
    case 'SET_SUMMARY_RECORDS_ERRORS': {
      return {
        ...state,
        isLoadingSummaryRecords: false,
        error: action.payload
      };
    }
    case 'SET_ANALYSIS_VIEW': {
      return {
        ...state,
        analysisView: action.payload.analysisView
      };
    }
    case 'RESET_ANALYSIS': {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

const hasComparisonData = (record: SummaryRecord | undefined): boolean => {
  if (!record) {
    return false;
  }

  if (!record.current || !record.historical) {
    return false;
  }

  return true;
};

// Sagas
export function* fetchCMIAnalysisSummaryRecords({ payload }: SetSummaryRecords): SagaIterator {
  yield put({
    type: SET_SUMMARY_RECORDS_BEGIN
  });

  const { historicalMin, historicalMax, currentMin, currentMax, orgId, orgName } = payload;

  const querystring = `?historicalStart=${formatDateForAPI(historicalMin)}&historicalEnd=${formatDateForAPI(
    historicalMax
  )}&currentStart=${formatDateForAPI(currentMin)}&currentEnd=${formatDateForAPI(currentMax)}`;

  const results: [
    AsyncOutput<CMIMetricRecordJSON[]>,
    AsyncOutput<CMIMetricRecordJSON[]>,
    AsyncOutput<PatientMixAnalysisJSON>,
    AsyncOutput<SeverityCMIAnalysisJSON>
  ] = yield all([
    call(ApplicationAPI.get, { endpoint: `metrics/cmi/${orgId}${querystring}` }),
    call(ApplicationAPI.get, {
      endpoint: `metrics/severitycmi/${orgId}${querystring}`
    }),
    call(ApplicationAPI.get, {
      endpoint: `metrics/drgmix/${orgId}${querystring}`
    }),
    call(ApplicationAPI.get, {
      endpoint: `metrics/severitycmiconditions/${orgId}${querystring}`
    })
  ]);

  const errors: string[] = [];
  const summaryState = results.reduce((obj, result, index) => {
    let metricLabel;
    switch (index) {
      case 0:
        metricLabel = 'CMI';
        break;
      case 1:
      case 3:
        metricLabel = 'Severity CMI';
        break;
      case 2:
        metricLabel = 'DRG Mix';
        break;
      default:
        metricLabel = 'N/A';
        break;
    }

    if (result.error) {
      errors.push(`Error fetching ${metricLabel} summary records - ${result.error}`);
      return {
        ...obj
      };
    }

    if (index !== 2 && index !== 3) {
      // Get the group record from the output
      const group = (result.data as CMIMetricRecordJSON[]).find(record => record.orgId === orgId);
      if (!group) {
        if (index === 0) {
          return {
            ...obj,
            cmiSummary: undefined,
            ceCMISummary: undefined
          };
        }

        return {
          ...obj,
          severityCMISummary: undefined
        };
      }

      const historical = group.historical;
      const current = group.current;

      if (index === 0) {
        const currentCE = group.clinicallyExpected;
        const historicalCE = group.historicalClinicallyExpected;

        return {
          ...obj,
          cmiSummary: {
            historical,
            current
          },
          ceCMISummary: {
            historical: historicalCE,
            current: currentCE
          }
        };
      } else if (index === 1) {
        return {
          ...obj,
          severityCMISummary: {
            historical,
            current
          }
        };
      }

      return {
        ...obj
      };
    } else if (index === 2) {
      // Catch cases where there is no data returned
      if (!result.data && result.status === 204) {
        return {
          ...obj,
          drgMixSummary: undefined,
          drgAnalysis: undefined
        };
      }

      const { historicalHcupCmi, currentHcupCmi } = result.data as PatientMixAnalysisJSON;
      return {
        ...obj,
        drgMixSummary: {
          historical: historicalHcupCmi,
          current: currentHcupCmi
        },
        drgAnalysis: result.data as PatientMixAnalysisJSON
      };
    } else if (index === 3) {
      return {
        ...obj,
        severityCMIAnalysis: result.data as SeverityCMIAnalysisJSON
      };
    } else {
      return { ...obj };
    }
  }, {} as SetSummaryRecordsPayload);
  if (errors.length > 0) {
    yield put({
      type: SET_SUMMARY_RECORDS_ERRORS,
      payload: errors.join(' / ')
    });
  }

  yield put({
    type: SET_SUMMARY_RECORDS_SUCCESSFUL,
    payload: {
      ...summaryState,
      entityId: orgId,
      entityName: orgName,
      noSummaryData:
        !hasComparisonData(summaryState.ceCMISummary) ||
        !hasComparisonData(summaryState.cmiSummary) ||
        !hasComparisonData(summaryState.drgMixSummary) ||
        !hasComparisonData(summaryState.severityCMISummary) ||
        !summaryState.drgAnalysis
    }
  });
}

export function* fetchCMIAnalysisBestFit({ payload }: SetBestFitAction): SagaIterator {
  yield put({
    type: SET_BEST_FIT_BEGIN
  });

  const { orgId, startDate, endDate, recalculate, metric, orgName } = payload;

  let bestFit;
  switch (metric) {
    case 'cmi': {
      bestFit = 'Cmi';
      break;
    }
    case 'severity': {
      bestFit = 'SeverityCmi';
      break;
    }
    case 'drgmix': {
      bestFit = 'HcupCmi';
      break;
    }
    default: {
      bestFit = 'Cmi';
      break;
    }
  }

  let endpoint = `reports/cmianalysis/dates/${orgId}?bestFit=${bestFit}`;
  if (startDate && endDate && recalculate) {
    endpoint += `&startDate=${formatDateForAPI(startDate)}&endDate=${formatDateForAPI(
      endDate
    )}&recalculate=${recalculate}`;
  }

  const result: AsyncOutput<BestFitDates> = yield call(ApplicationAPI.get, {
    endpoint
  });

  if (result.data) {
    yield put({
      type: SET_BEST_FIT_SUCCESSFUL,
      payload: {
        currentMinDate: new Date(result.data.bestFitCurrentStart),
        currentMaxDate: new Date(result.data.bestFitCurrentEnd),
        historicalMinDate: new Date(result.data.bestFitHistoricalStart),
        historicalMaxDate: new Date(result.data.bestFitHistoricalEnd),
        chartMinDate: new Date(startDate || result.data.dataStart),
        chartMaxDate: new Date(endDate || result.data.dataEnd)
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield call(fetchCMIAnalysisSummaryRecords, {
      payload: {
        historicalMin: new Date(result.data.bestFitHistoricalStart),
        historicalMax: new Date(result.data.bestFitHistoricalEnd),
        currentMin: new Date(result.data.bestFitCurrentStart),
        currentMax: new Date(result.data.bestFitCurrentEnd),
        orgId,
        orgName
      }
    });
  } else if (result.error) {
    const errorMessage = result.error.includes('404')
      ? 'Please select an entity/period range with at least 4 months of data'
      : `Error setting best fit date selections - ${result.error}`;

    yield put({
      type: SET_BEST_FIT_FAILED,
      payload: {
        message: errorMessage,
        chartMinDate: startDate,
        chartMaxDate: endDate,
        orgId,
        orgName
      }
    });
  }
}

export const cmiAnalysisSelector = (state: CombinedState): CMIAnalysis => state.cmiAnalysis;

export function* putCMIAnalysisSelectedDates({ payload }: SetCurrentAndHistoricalPeriod): SagaIterator {
  yield put({
    type: SET_CURRENT_AND_HISTORICAL_PERIOD_BEGIN
  });

  const { orgId, orgName, ...dates } = payload;
  const {
    currentMinDate,
    currentMaxDate,
    historicalMinDate,
    historicalMaxDate,
    chartMinDate,
    chartMaxDate
  } = yield select(cmiAnalysisSelector);

  const datePayload = {
    currentMinDate,
    currentMaxDate,
    historicalMinDate,
    historicalMaxDate,
    chartMinDate,
    chartMaxDate,
    ...dates
  };

  yield put({
    type: SET_CURRENT_AND_HISTORICAL_PERIOD_SUCCESSFUL,
    payload: datePayload
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  yield call(fetchCMIAnalysisSummaryRecords, {
    payload: {
      historicalMin: new Date(datePayload.historicalMinDate),
      historicalMax: new Date(datePayload.historicalMaxDate),
      currentMin: new Date(datePayload.currentMinDate),
      currentMax: new Date(datePayload.currentMaxDate),
      orgId,
      orgName
    }
  });

  // Call this PUT request to save user date settings after updating the dates state, as we don't want to hold up the re-rendering and the UI doesn't depend on this
  const result = yield call(ApplicationAPI.put, {
    endpoint: `reports/cmianalysis/dates/${payload.orgId}`,
    data: {
      dataStart: formatDateForAPI(chartMinDate),
      dataEnd: formatDateForAPI(chartMaxDate),
      bestFitHistoricalStart: formatDateForAPI(datePayload.historicalMinDate),
      bestFitHistoricalEnd: formatDateForAPI(datePayload.historicalMaxDate),
      bestFitcurrentStart: formatDateForAPI(datePayload.currentMinDate),
      bestFitcurrentEnd: formatDateForAPI(datePayload.currentMaxDate)
    }
  });

  // No need to do anything if PUT call was successful, but do catch errors
  if (result.error) {
    yield put({
      type: SET_CURRENT_AND_HISTORICAL_PERIOD_ERROR,
      payload: `Error saving date selections - ${result.error}`
    });
  }
}

export function* cmiAnalysisBestFitSaga(): SagaIterator {
  yield takeLatest('SET_BEST_FIT', fetchCMIAnalysisBestFit);
}

export function* cmiAnalysisSelectedDatesSaga(): SagaIterator {
  yield takeLeading('SET_CURRENT_AND_HISTORICAL_PERIOD', putCMIAnalysisSelectedDates);
}

export function* cmiAnalysisSummaryRecordsSaga(): SagaIterator {
  yield takeLatest('SET_SUMMARY_RECORDS', fetchCMIAnalysisSummaryRecords);
}
