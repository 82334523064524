import React, { useState, createContext, FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useDownloadReport from '@clinintell/containers/systemReport/logic/useDownloadReport';
import useExecutiveSummaryReport from '@clinintell/containers/systemReport/logic/useExecutiveSummaryReport';
import { EntityReportRouteProps, ReportType, TrainingProgressRouteProps } from '../../types';

interface IReportContext {
  systemId?: number;
  hospitalId: number;
  groupId: number;
  physicianId: number;
  trainingGroupData: TrainingProgressRouteProps;
  reportDate: Date | string;
  reportBtnDisabled: boolean;
  isCreating?: boolean;
  isCreatingExecutiveReport?: boolean;
  setReportSystemId: (id: number | null) => void;
  setReportHospitalId: (id: number | null) => void;
  setReportGrouplId: (id: number | null) => void;
  setReportPhysicianlId: (id: number | null) => void;
  setReportTrainingGroupHandler: (data: TrainingProgressRouteProps) => void;
  setReportDateHandler: (date: string) => void;
  reportResetFilters: () => void;
  routeToReport: <T>(route: string, state: T) => void;
  reportSubmitHandler: (report: ReportType) => void;
  setReportBtnDisabled: (value: boolean) => void;
}

const defaultState = {
  systemId: -1,
  hospitalId: -1,
  groupId: -1,
  physicianId: -1,
  trainingGroupData: { hospital: { name: '' } },
  reportDate: '0',
  reportBtnDisabled: true,
  isCreating: false,
  isCreatingExecutiveReport: false,
  setReportSystemId: (id: number | null) => 0,
  setReportHospitalId: (id: number | null) => 0,
  setReportGrouplId: (id: number | null) => 0,
  setReportPhysicianlId: (id: number | null) => 0,
  setReportTrainingGroupHandler: (data: TrainingProgressRouteProps) => 0,
  setReportDateHandler: (date: string) => 0,
  reportResetFilters: () => 0,
  routeToReport: (route: string) => 0,
  reportSubmitHandler: (report: ReportType) => 0,
  setReportBtnDisabled: (value: boolean) => 0
};

export const ReportsContext = createContext<IReportContext>(defaultState);

export const ReportsContextProvider: FC = ({ children }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [systemId, setSystemId] = useState(defaultState.systemId);
  const [hospitalId, setHospitalId] = useState(defaultState.hospitalId);
  const [groupId, setGroupId] = useState(defaultState.groupId);
  const [physicianId, setPhysicianId] = useState(defaultState.physicianId);
  const [trainingGroupData, setTrainingGroupData] = useState(defaultState.trainingGroupData);
  const [reportDate, setReportDate] = useState(defaultState.reportDate);
  const [reportBtnDisabled, setReportBtnDisabled] = useState(defaultState.reportBtnDisabled);
  const { isCreating, handleCreateReport } = useDownloadReport();
  const { isCreatingExecutiveReport, handleCreateExecutiveReport } = useExecutiveSummaryReport();

  const setReportSystemId = (id: number | null) => {
    setSystemId(id as number);
    setHospitalId(-1);
    setGroupId(-1);
    setPhysicianId(-1);
  };

  const setReportHospitalId = (id: number | null) => {
    setHospitalId(id as number);
    setGroupId(-1);
    setPhysicianId(-1);
  };
  const setReportGrouplId = (id: number | null) => {
    setGroupId(id as number);
    setPhysicianId(-1);
  };
  const setReportPhysicianlId = (id: number | null) => {
    setPhysicianId(id as number);
  };
  const setReportDateHandler = (date: string) => {
    setReportDate(date);
    setReportBtnDisabled(false);
  };
  const setReportTrainingGroupHandler = (data: TrainingProgressRouteProps) => {
    setTrainingGroupData(data);
  };
  const reportResetFilters = () => {
    setSystemId(defaultState.systemId);
    setHospitalId(defaultState.hospitalId);
    setGroupId(defaultState.groupId);
    setReportDate(defaultState.reportDate);
    setReportBtnDisabled(true);
  };
  const routeToReport = <T,>(route: string, state: T) => {
    navigation(`${location.pathname}/${route}`, { state: { ...state } });
  };
  const reportSubmitHandler = (report: ReportType) => {
    switch (report) {
      case ReportType.Provider:
      case ReportType.Group:
        routeToReport<EntityReportRouteProps>(report, { hospitalId, groupId });
        break;
      case ReportType.Quarterly:
        break;
      case ReportType.System:
        handleCreateReport(reportDate);
        break;
      case ReportType.Executive:
        handleCreateExecutiveReport(
          new Date(reportDate),
          systemId < 0 ? undefined : systemId,
          hospitalId < 0 ? undefined : hospitalId,
          groupId < 0 ? undefined : groupId
        );
        break;
      case ReportType.Training:
        routeToReport<TrainingProgressRouteProps>(report, trainingGroupData);
        break;
      default:
        console.log('something went wrong - report type: ' + report);
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        systemId,
        hospitalId,
        groupId,
        physicianId,
        trainingGroupData,
        reportDate,
        reportBtnDisabled,
        isCreating,
        isCreatingExecutiveReport,
        setReportSystemId,
        setReportHospitalId,
        setReportGrouplId,
        setReportPhysicianlId,
        setReportTrainingGroupHandler,
        setReportDateHandler,
        reportResetFilters,
        routeToReport,
        reportSubmitHandler,
        setReportBtnDisabled
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};
