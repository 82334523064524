import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  clickable: {
    color: theme.palette.blue.main,
    cursor: 'pointer'
  }
}));

interface Props {
  onClick?: () => void;
  entity?: number;
  condition?: number;
  rawValue: string | undefined;
  isFooter?: boolean;
  conditionTypeId?: number;
}

const NavigationCell: React.FC<Props> = ({
  children,
  onClick,
  entity,
  condition,
  rawValue,
  isFooter = false,
  conditionTypeId
}) => {
  const { clickable } = useStyles();

  return (
    <Box style={{ width: '100%', position: 'relative' }} data-cy="navigationCellContainer">
      <Box
        data-cy="navigationCell"
        onClick={(): void => (!!onClick ? onClick() : undefined)}
        className={!!onClick ? clickable : undefined}
        flexGrow={1}
        style={{
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Poppins'",
          fontSize: '0.9rem'
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default NavigationCell;
