import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, useTheme } from '@mui/material';
import CircleLoadingIndicator from '@clinintell/components/CircleLoadingIndicator';
import { ApplicationAPI } from '@clinintell/utils/api';
import useAlert from '@clinintell/components/alert/logic/useAlert';

export interface ActionOptions {
  title: string;
  action: 'view' | 'edit' | 'delete' | 'copy';
  actionUrl?: string;
  fileType?: string;
  viewFn?: (buffer: ArrayBuffer, name: string) => void;
  actionFn?: (id: number) => void;
}

interface Props {
  rawValue: string | number;
  rowId: number;
  actionOptions?: ActionOptions[];
}

type ResponseType = { status: number };

const ProviderReportCell: React.FC<Props> = ({ children, rowId, rawValue, actionOptions }) => {
  const theme = useTheme();
  const { pushAlert } = useAlert();
  const [isDownloading, setIsDownloading] = useState({
    downloading: false,
    id: -1,
    name: ''
  });

  useEffect(() => {
    const { downloading, id, name } = isDownloading;
    if (!downloading) {
      return;
    }

    const viewAction = actionOptions && actionOptions.find(action => action.action === 'view');
    const endpoint =
      viewAction && viewAction.actionUrl
        ? viewAction.actionUrl.replace('$id', id.toString())
        : `reports/Provider/${id}`;

    ApplicationAPI.download(endpoint, viewAction && viewAction.fileType ? viewAction.fileType : 'pdf')
      .then(response => {
        if (response.status === 204) {
          return response as ResponseType;
        }
        if (response.data) {
          return response.data;
        } else {
          throw new Error(response.error);
        }
      })
      .then((buffer: ArrayBuffer | ResponseType) => {
        if (Object(buffer).hasOwnProperty('status')) {
          pushAlert({ message: 'Not enough data to display', variant: 'error' });
          return;
        }
        if (viewAction && viewAction.viewFn) {
          viewAction.viewFn(buffer as ArrayBuffer, name);
        }
      })
      .catch(error => {
        throw new Error(`Error with the view request: ${error}`);
      })
      .finally(() => {
        setIsDownloading({
          downloading: false,
          id: -1,
          name: ''
        });
      });
  }, [isDownloading, actionOptions, pushAlert]);

  return (
    <Box style={{ display: 'flex' }}>
      {(!isDownloading.downloading && isDownloading.id !== rowId) ||
      (isDownloading.downloading && isDownloading.id !== rowId) ? (
        <Button
          id={`btnViewEdit${rowId}`}
          variant="outlined"
          size="small"
          style={{ height: '28px', marginTop: 5 }}
          onClick={(event: React.MouseEvent): void => {
            event.stopPropagation();
            setIsDownloading({
              downloading: true,
              id: rowId,
              name: rawValue.toString()
            });
          }}
          onTouchEnd={(event: React.TouchEvent): void => {
            event.stopPropagation();
            setIsDownloading({
              downloading: true,
              id: rowId,
              name: rawValue.toString()
            });
          }}
        >
          <Typography variant="p2" style={{ textTransform: 'none' }}>
            View
          </Typography>
        </Button>
      ) : (
        <Box
          marginTop={theme.spacing(1)}
          style={{ width: 65, marginBottom: 5, display: 'flex', justifyContent: 'center' }}
        >
          <CircleLoadingIndicator loadingIndicatorSize={24} />
        </Box>
      )}{' '}
      <span style={{ marginLeft: 15, fontSize: '0.9rem', fontFamily: theme.typography.fontFamilyMonospaced }}>
        {children}
      </span>
    </Box>
  );
};

export default ProviderReportCell;
