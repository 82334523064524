import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Typography, useTheme } from '@mui/material';
import Button from '@clinintell/components/button/Button';
import ActionPanel from '@clinintell/components/ActionPanel';
import HospitalAndGroupFilter from '../hospitalAndGroupFilter/HospitalAndGroupFilter';
import DropDown, { SelectOptionType } from '@clinintell/components/dropdown/DropDown';
import { validateEmail } from '@clinintell/utils/validators';
import { ApplicationAPI } from '@clinintell/utils/api';
import useAlert from '@clinintell/components/alert/logic/useAlert';
import CIModal from '@clinintell/components/modal/CIModal';
import { useScheduledReportDispatch } from '@clinintell/modules/store';
import { ScheduledReportActionTypes } from '@clinintell/modules/reports';

const ScheduleReport: React.FC = () => {
  const theme = useTheme();
  const [isOpened, setIsOpened] = useState(false);
  const [hospitalId, setHospitalId] = useState(-1);
  const [groupId, setGroupId] = useState(-1);
  const [hospitalName, setHospitalName] = useState('');
  const [groupName, setGroupName] = useState('');
  const [reportName, setReportName] = useState('');
  const [validEmailList, setValidEmailList] = useState<string[]>([]);
  const [invalidEmailList, setInvalidEmailList] = useState<string[]>([]);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const errorModalRef = useRef<HTMLButtonElement>();
  const [isBusy, setIsBusy] = useState(false);

  const { pushAlert } = useAlert();
  const frequencyOptions: SelectOptionType[] = [{ value: 0, label: 'Quarterly' }];
  const scheduledReportDispatch = useScheduledReportDispatch();

  const scheduledReportCloseClickHandler = () => {
    setIsOpened(!isOpened);
    setIsBusy(false);
    if (!isOpened) resetActionPanel();
    scheduledReportDispatch({ type: ScheduledReportActionTypes.FETCH_SCHEDULED_REPORTS });
  };

  const resetActionPanel = (): void => {
    setHospitalId(-1);
    setGroupId(-1);
    setHospitalName('');
    setGroupName('');
    setReportName('');
    setValidEmailList([]);
    setInvalidEmailList([]);
  };

  const scheduledReportClickHandler = () => {
    if (invalidEmailList.length > 0) {
      pushAlert({ message: `Please fix invalid emails` as string, variant: 'error' });
      return;
    } else {
      setIsBusy(true);
      const reportData = {
        groupOrgHierarchyId: groupId,
        reportType: {
          id: 20,
          typeDescription: 'Quarterly Summary Report'
        },
        frequency: {
          id: 5,
          frequencyName: 'Quarterly'
        },
        recipients: validEmailList.join(),
        reportName: reportName
      };
      ApplicationAPI.post({ endpoint: `schedule/reports/`, data: reportData }).then(res => {
        if (res.status && res.status === 200) {
          pushAlert({ message: 'Schedule successfully saved!' as string, variant: 'success' });
          scheduledReportCloseClickHandler();
        } else {
          setIsBusy(false);
          errorModalRef.current?.click();
        }
      });
    }
  };

  const hospitalFitlerHandler = (hospId: number | null, HospitalDisplayName?: string) => {
    if (hospId === hospitalId) return;
    setHospitalId(hospId as number);
    if (HospitalDisplayName) setHospitalName(HospitalDisplayName);
    if (groupId !== -1) {
      setReportName('');
      setGroupId(-1);
      setEnableSubmit(false);
    }
  };

  const groupFilterHandler = (physId: number | null, GroupDisplayName?: string) => {
    setGroupId(physId as number);
    if (GroupDisplayName) setGroupName(GroupDisplayName);
    if (validEmailList && invalidEmailList) setEnableSubmit(true);
  };

  const textboxChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    setReportName(event.target.value);
  };

  const emailListBlurHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const invalidTempList: string[] = [];
    const tempList: string[] = [];

    event.target.value.split(',').forEach(element => {
      if (validateEmail(element.trim())) tempList.push(element.trim());
      else {
        if (element.trim().length > 0) invalidTempList.push(element.trim());
      }
    });

    setValidEmailList(tempList);
    setInvalidEmailList(invalidTempList);
  };

  const emailTextBox = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (event.target.value.length > 0 && groupId !== -1 && reportName.length > 0) setEnableSubmit(true);
    else setEnableSubmit(false);
  };

  useEffect(() => {
    if (groupName !== '') setReportName(`Quarterly Summary Report ${hospitalName} ${groupName}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  return (
    <>
      <Box borderColor="grey.300" pl={2} ml={2} borderLeft={`1px solid ${theme.palette.grey[300]}`}>
        <Button label="Schedule Report" type="primary" variant="text" onClick={scheduledReportCloseClickHandler} />
      </Box>
      <ActionPanel
        title={
          <Box width="100%" padding={0} margin={0} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Typography variant="h4" sx={{ color: `${theme.palette.grey[700]} !important` }}>
              Schedule Quarterly Summary Report
            </Typography>
            <Button
              label={'Schedule'}
              type="primary"
              sx={{ marginLeft: 'auto', padding: '0px 34px 0px 34px' }}
              disabled={!enableSubmit}
              onClick={scheduledReportClickHandler}
              isBusy={isBusy}
            />
          </Box>
        }
        open={isOpened}
        closeFn={scheduledReportCloseClickHandler}
      >
        <Box display="flex" flexDirection="column" padding="40px 24px 0 26px">
          <HospitalAndGroupFilter
            hospitalId={hospitalId}
            groupId={groupId}
            onHospitalChange={hospitalFitlerHandler}
            onPhysicianGroupChange={groupFilterHandler}
          ></HospitalAndGroupFilter>

          <Box padding="8px">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography style={{ marginBottom: 8 }} variant="p2">
                Report Name
              </Typography>
              <TextField
                inputProps={{ padding: '0', maxLength: 120 }}
                size="small"
                onChange={textboxChangeHandler}
                value={reportName}
                sx={{
                  backgroundColor: 'shade.white',
                  borderRadius: `8px`,
                  width: '100%',
                  '& fieldset': {
                    borderColor: 'grey.300',
                    borderRadius: `8px`
                  },
                  '&&:hover fieldset': {
                    borderColor: 'blue.light2'
                  },
                  '&&.Mui-focused fieldset': {
                    borderColor: 'blue.light2'
                  },
                  '& fieldset > legend': {
                    width: '0px' // hiding the legend
                  }
                }}
              ></TextField>
            </Box>
          </Box>

          <Box padding="8px">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography style={{ marginBottom: 8 }} variant="p2">
                Frequency
              </Typography>
              <DropDown
                options={frequencyOptions}
                value={0}
                isDisabled={true}
                onChange={(value, label): void => {
                  // do something
                }}
                testId="frequencyOptionSelector"
              ></DropDown>
            </Box>
          </Box>

          <Box padding="8px">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography style={{ marginBottom: 8 }} variant="p2">
                Recipients
              </Typography>
              <Typography style={{ marginBottom: 8 }} variant="caption">
                Use a comma as a separator
              </Typography>
              <TextField
                multiline={true}
                error={invalidEmailList.length > 0 ? true : false}
                minRows={4}
                maxRows={5}
                inputProps={{ padding: '0' }}
                onChange={emailTextBox}
                onBlur={emailListBlurHandler}
                sx={{
                  backgroundColor: 'shade.white',
                  borderRadius: `8px`,
                  width: '100%',
                  '& fieldset': {
                    borderColor: 'grey.300',
                    borderRadius: `8px`
                  },
                  '&&:hover fieldset': {
                    borderColor: 'blue.light2'
                  },
                  '&&.Mui-focused fieldset': {
                    borderColor: 'blue.light2'
                  },
                  '& fieldset > legend': {
                    width: '0px' // hiding the legend
                  }
                }}
              ></TextField>
            </Box>
          </Box>

          {invalidEmailList.length > 0 ? (
            <Box padding="8px">
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    marginBottom: 8,
                    color: theme.palette.red[500],
                    fontWeight: 'bold'
                  }}
                  variant="p2"
                >
                  Invalid Emails :
                  {invalidEmailList.map((element, index) => {
                    return (
                      <Typography key={index} margin="0 0 0 4px">
                        {index === invalidEmailList.length - 1 ? `${element.trim()}` : `${element.trim()},`}
                      </Typography>
                    );
                  })}
                </Typography>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </ActionPanel>

      <CIModal modalBtn={<Box id="errorMdlBtn" ref={errorModalRef} sx={{ width: '0', height: '0' }} />}>
        <CIModal.Header showCloseIcon={true}>
          <Typography
            variant="body1"
            style={{ color: theme.palette.common.black, fontWeight: 'bold', fontSize: '1rem' }}
          >
            This schedule already exists
          </Typography>
        </CIModal.Header>
        <CIModal.Content>
          <Typography
            variant="body1"
            style={{
              color: '#64748B',
              fontFamily: 'Poppins',
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(2)
            }}
          >
            Your schedule couldn't be saved. A schedule with the same parameters already exists.
          </Typography>
        </CIModal.Content>
        <CIModal.Footer>
          <Button
            type="primary"
            variant="contained"
            label="Ok"
            onClick={() => errorModalRef.current?.click()}
            sx={{ marginRight: 2, minWidth: 130 }}
          />
        </CIModal.Footer>
      </CIModal>
    </>
  );
};

export default ScheduleReport;
