import { RowsPerPageValues, TableProps } from '@clinintell/components/Table';
import StyledTableCell from '@clinintell/components/StyledTableCell';
import { ProviderTrainingConditionType } from '@clinintell/containers/providerTrainingAssignment/types/providerTrainingConditionType';
import {
  providerHasAssignedTraining,
  providerHasCompletedTraining
} from '@clinintell/containers/trainingConfiguration/logic/providerTrainingStatuses';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';

type ProviderFooterTrainingConditionType = {
  isConditionTrainingEnabled: boolean;
  assignedDate: string;
  totalQuestions: number;
  questionAnswered: number;
  correctAnswers: number;
};

const calculateConditionScore = ({
  assignedDate,
  totalQuestions,
  questionAnswered,
  correctAnswers
}: ProviderTrainingConditionType | ProviderFooterTrainingConditionType): string => {
  if (!assignedDate) {
    return '-';
  }

  // Don't return a score if the provider hasn't completed training yet
  if (questionAnswered !== totalQuestions) {
    return '-';
  }

  return `${correctAnswers} of ${questionAnswered} (${formatToDecimalPlaces(
    (correctAnswers / questionAnswered) * 100,
    0
  )}%)`;
};

const generateProviderTrainingReportTableProps = (
  conditions: ProviderTrainingConditionType[],
  rowsPerPage: RowsPerPageValues
): TableProps => {
  return {
    rows: conditions.map(condition => {
      const conditionScore = calculateConditionScore(condition);

      return {
        condition: {
          rawValue: condition.conditionName,
          uId: condition.conditionId,
          component: <StyledTableCell isBubble={false} value={condition.conditionName} type="string" align="left" />
        },
        status: {
          rawValue: condition.isConditionTrainingEnabled,
          uId: condition.conditionId,
          component: (
            <StyledTableCell
              align="center"
              isBubble={false}
              value={condition.isConditionTrainingEnabled}
              type="boolean"
            />
          )
        },
        assignedDate: {
          rawValue: providerHasAssignedTraining(condition) ? new Date(condition.assignedDate as string).getTime() : 0,
          uId: condition.conditionId,
          component: (
            <StyledTableCell
              value={providerHasAssignedTraining(condition) ? condition.assignedDate : null}
              type="date"
              align="center"
              isBubble={false}
            />
          )
        },
        completionDate: {
          rawValue: providerHasCompletedTraining(condition) ? new Date(condition.completedDate as string).getTime() : 0,
          uId: condition.conditionId,
          component: (
            <StyledTableCell
              value={providerHasCompletedTraining(condition) ? condition.completedDate : null}
              type="date"
              align="center"
              isBubble={false}
            />
          )
        },
        score: {
          rawValue: conditionScore,
          uId: condition.conditionId,
          component: <StyledTableCell value={conditionScore} type="string" align="center" isBubble={false} />
        }
      };
    }),
    columns: {
      condition: {
        type: 'string',
        title: 'Clinical Condition'
      },
      status: {
        type: 'boolean',
        title: 'Status'
      },
      assignedDate: {
        type: 'string',
        title: 'Assigned Date'
      },
      completionDate: {
        type: 'string',
        title: 'Completion Date'
      },
      score: {
        type: 'integer',
        postfix: '%',
        title: 'Test Score'
      }
    },
    defaultSortColumn: 'condition',
    disablePaging: false,
    rowsPerPage,
    columnIdentifier: 'condition',
    sortDirection: 'asc',
    headerTooltipColumn: 'status',
    headerTooltipText: `Checked status indicates the condition is included in the current training`
  };
};

export default generateProviderTrainingReportTableProps;
