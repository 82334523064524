import React, { useEffect, useRef, useState } from 'react';
import { ApplicationAPI } from '@clinintell/utils/api';
import { Box, Button as MuiButton, Typography, useTheme } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ActionPanel, Button, CIModal, ClinIntellSkeleton, PageTitle, TextInput } from '@clinintell/components/index';
import { useScheduledReportState } from '@clinintell/modules/store';
import { ScheduledReportItem } from '@clinintell/modules/reports';
import generateScheduledReportTableProps from './logic/generateScheduledReportTableProps';
import ScheduleReportTable from './logic/scheduledReportTable';
import useAlert from '@clinintell/components/alert/logic/useAlert';
import useScheduleReport from './logic/useScheduleReport';

const AllScheduledReports: React.FC = () => {
  const theme = useTheme();
  const { pushAlert } = useAlert();
  const { reportList, hasReports, isLoading, hasError, errorMessage } = useScheduledReportState();
  const [openReportEditor, setOpenReportEditor] = useState(false);
  const [currentReport, setCurrentReport] = useState<ScheduledReportItem | null>(null);
  const [badEmails, setBadEmails] = useState<string[]>([]);
  const emailRef = useRef<HTMLInputElement>();
  const deleteBtnRef = useRef<HTMLButtonElement>(null);

  const {
    isUpdating,
    setIsUpdating,
    reloadReports,
    reportExist,
    downloadReport,
    validateEmails,
    deleteReport
  } = useScheduleReport();

  const updateReport = (id: number): void => {
    const { exists, report } = reportExist(id);
    if (exists) {
      setCurrentReport(report as ScheduledReportItem);
      setOpenReportEditor(true);
    }
  };

  const handleCloseReportEditor = (): void => {
    setOpenReportEditor(false);
    setIsUpdating(false);
    setBadEmails([]);
  };

  type TextFieldFocusEventType = React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>;
  const handleRecipientChange = (e: TextFieldFocusEventType): void => {
    setBadEmails(value => (value = []));
    const emailList: Set<string> = new Set(e.target.value.split(',').map(v => v.trim()));
    const badList = validateEmails([...emailList]);
    badList.size > 0 && setBadEmails([...badList]);
  };

  const handleSaveUpdatedReport = (): void => {
    if (badEmails && badEmails.length > 0) {
      pushAlert({ message: `Please fix invalid emails`, variant: `error` });
      return;
    }

    setIsUpdating(true);
    const endpoint = `schedule/reports/${currentReport?.id}`;
    ApplicationAPI.put({
      endpoint,
      data: JSON.stringify({
        ...currentReport,
        recipients: (emailRef.current as HTMLInputElement).value
      })
    })
      .then(response => {
        if (response.status === 200) {
          pushAlert({ message: `Report updated successfully`, variant: `success` });
          reloadReports();
        } else {
          pushAlert({ message: `Error updating report`, variant: `error` });
        }
      })
      .finally(handleCloseReportEditor);
  };

  const handleConfirmDeleteReport = (id: number): void => {
    const { exists, report } = reportExist(id);
    if (exists) {
      setCurrentReport(report as ScheduledReportItem);
      (deleteBtnRef.current as HTMLButtonElement).click();
    }
  };

  const handleDeleteReport = (): void => {
    deleteReport((currentReport as ScheduledReportItem).id);
    (deleteBtnRef.current as HTMLButtonElement).click();
  };

  useEffect(() => {
    if (!hasReports && !isLoading) window.history.back();
  }, [hasReports, isLoading]);

  if (hasError) throw new Error(`500 - ${errorMessage}`);

  return (
    <Box sx={{ my: 3, mx: 3 }}>
      <Button
        type={`primary`}
        sx={{ marginBottom: 2 }}
        variant={`text`}
        label={`Back to Reports`}
        leftAdornment={<KeyboardArrowLeftIcon style={{ marginTop: 4 }} />}
        onClick={() => {
          window.history.back();
        }}
      />
      <PageTitle title="All Scheduled Reports" />
      {isLoading || isUpdating || reportList === undefined || reportList.length === 0 ? (
        <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
      ) : (
        <ScheduleReportTable
          {...generateScheduledReportTableProps(reportList, downloadReport, updateReport, handleConfirmDeleteReport)}
        />
      )}
      <ActionPanel
        open={openReportEditor}
        closeFn={handleCloseReportEditor}
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ color: theme.palette.grey[700], fontWeight: 600 }}>
              Edit Recipients
            </Typography>
            <Button type={`primary`} variant={`contained`} label={`Save Edits`} onClick={handleSaveUpdatedReport} />
          </Box>
        }
      >
        <Box sx={{ m: 3, display: 'grid', gap: 2 }}>
          <Box sx={{ display: 'grid', gap: 1 }}>
            <Typography variant={`body1`}>Report Name</Typography>
            <TextInput value={currentReport?.reportName} sx={{ width: '100%' }} disabled />
          </Box>
          <Box sx={{ display: 'grid', gap: 1 }}>
            <Typography variant={`body1`}>Recipients</Typography>
            <Typography variant={`body2`}>Use a comma as a separator</Typography>
            <TextInput
              inputRef={emailRef}
              onBlur={handleRecipientChange}
              defaultValue={currentReport?.recipients}
              sx={{ width: '100%' }}
              multiline
              minRows={3}
              maxRows={5}
              error={badEmails.length > 0}
            />
            {badEmails.length > 0 && (
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', color: 'red.500' }}>
                <Typography variant={`body1`} sx={{ fontWeight: 'bold' }}>
                  Invalid Emails:
                </Typography>
                <Box>{badEmails.join(', ')}</Box>
              </Box>
            )}
          </Box>
        </Box>
      </ActionPanel>
      <CIModal modalBtn={<MuiButton ref={deleteBtnRef} variant={`text`} sx={{ opacity: 0 }} />}>
        <CIModal.Header showCloseIcon>
          <Typography variant={`h6`}>Are you sure you want to delete this schedule?</Typography>
        </CIModal.Header>
        <CIModal.Content>
          <Typography variant={`body1`}>This action cannot be undone.</Typography>
        </CIModal.Content>
        <CIModal.Footer cancelBtn>
          <Button key={`delete`} type={`primary`} variant={`contained`} label={`Delete`} onClick={handleDeleteReport} />
        </CIModal.Footer>
      </CIModal>
    </Box>
  );
};

export default AllScheduledReports;
