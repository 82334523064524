import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import AgGrid from '@clinintell/components/agGrid';
import { ColDef, ICellRendererParams } from 'ag-grid-community/dist/lib/main';
import { Row } from '@clinintell/components/agGrid/tablesTypes';
import * as Cells from '@clinintell/components/agGrid/cells';
import { tableColumnDefinition } from '../smartgoals/SmartGoalsWidgetTableData';
import { NodeTypeIds } from '@clinintell/modules/orgTree';
import { useDashboard2 } from '@clinintell/modules/store';
import { formatDateSlash } from '@clinintell/utils/dates';
import { DocScoreGoalItems } from '@clinintell/modules/dashboard2';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';

const SmartGoalsWidgetTable: React.FC = props => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { entity, groupId, groupName, smartGoalsWidget } = useDashboard2();
  const { isModalLoading } = smartGoalsWidget;
  const tableDataArray = smartGoalsWidget.modalTableData as DocScoreGoalItems[];

  const entityLevel =
    groupId === undefined || groupId === -1 || groupName === 'All' ? entity?.nodeTypeId : NodeTypeIds.SpecialtyGroup;

  const columnDefs: Array<ColDef> = tableColumnDefinition(entityLevel as number).filter(col =>
    isMobileView ? ['hospitalName', 'physicianGroup', 'status'].includes(col.field as string) : true
  );

  const formatStatusText = (text: string): string => {
    const words = text.split(' ');
    words.forEach((word, ix) => {
      words[ix] = word[0].toUpperCase() + word.substring(1);
    });
    return words.join(' ');
  };

  const getStatusColor = (text: string): string => {
    if (text.toLocaleLowerCase() === 'meeting goal') return theme.palette.teal.light4;
    if (text.toLocaleLowerCase() === 'below goal') return theme.palette.red[500];
    return theme.palette.grey[500];
  };

  let buildAgGridData: Row[] = [];
  try {
    buildAgGridData = tableDataArray.map(
      (row): Row => {
        return {
          hospitalName: {
            rawValue: row.hospitalName,
            component: (
              <Box width="100%" textAlign="left" component="span">
                {row.hospitalName}
              </Box>
            )
          },
          physicianGroupName: {
            rawValue: row.physicianGroupName,
            component: (
              <Box width="100%" textAlign="left" component="span">
                {row.physicianGroupName}
              </Box>
            )
          },
          providerName: {
            rawValue: row.providerName as string,
            component: (
              <Box width="100%" textAlign="left" component="span">
                {row.providerName}
              </Box>
            )
          },
          implementationDate: {
            rawValue: row.implementationDate,
            component: (
              <Box width="100%" textAlign="center" component="span">
                {formatDateSlash(new Date(row.implementationDate))}
              </Box>
            )
          },
          docScore: {
            rawValue: row.docScore,
            component: (
              <Box width="100%" textAlign="center" component="span">
                {`${Math.round(row.docScore)}%`}
              </Box>
            )
          },
          docScoreGoal: {
            rawValue: row.docScoreGoal,
            component: (
              <Box width="100%" textAlign="center" component="span">
                {`${row.docScoreGoal}%`}
              </Box>
            )
          },
          status: {
            rawValue: row.status,
            component: (
              <Box
                component="span"
                sx={{ width: '100%', textAlign: 'center', fontWeight: 'bold', color: getStatusColor(row.status) }}
              >
                {formatStatusText(row.status)}
              </Box>
            )
          }
        };
      }
    );
  } catch {}

  const getRenderCells = (params: ICellRendererParams): unknown => {
    const { key, rawValue, rowId, component } = params.value;

    return {
      [key]: key === 'action' ? Cells['ActionCell'] : Cells['DefaultCell'],
      restProps: { rawValue },
      value: rawValue,
      key,
      rowId,
      component
    };
  };

  return isModalLoading ? (
    <ClinIntellSkeleton width="100%" height="200px" variant={'rectangular'} />
  ) : (
    <Box mt={2}>
      <AgGrid
        headerHeight={38}
        getRenderCells={getRenderCells}
        rowData={buildAgGridData}
        columnDefs={columnDefs}
        rowsPerPage={10}
        tableFinalHeight={10 * 38 + 128}
        defaultColDef={{
          sortable: true,
          resizable: true,
          flex: 1,
          sortingOrder: ['asc', 'desc']
        }}
      />
    </Box>
  );
};

export default SmartGoalsWidgetTable;
