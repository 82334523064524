import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PopupMenu from '@clinintell/components/popupMenu/PopupMenu';
import PopupMenuItem from '@clinintell/components/popupMenu/PopupMenuItem';

export type RowMenu = {
  title: string;
  icon?: JSX.Element | React.ReactNode;
  onClick: (
    entity: number,
    condition?: number,
    conditionName?: string,
    isFooter?: boolean,
    conditionTypeId?: number
  ) => void;
};

const StyledIconButton = withStyles(theme => ({
  root: {
    height: 28,
    margin: 4,
    width: 20,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))(IconButton);

interface Props {
  rowMenu?: RowMenu[];
  menuDirection?: 'vertical' | 'horizontal';
  entity?: number;
  condition?: number;
  rawValue: string | undefined;
  isFooter?: boolean;
  conditionTypeId?: number;
}

const ActionCell: React.FC<Props> = ({
  children,
  rowMenu,
  entity,
  condition,
  rawValue,
  isFooter = false,
  conditionTypeId
}) => {
  const [graphAnchorEl, setGraphAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>): void => {
    setGraphAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setGraphAnchorEl(null);
  };

  if (children === undefined && entity && rowMenu && rowMenu.length > 0) {
    return (
      <Box width="100%" textAlign="center">
        <StyledIconButton onClick={handleMenuOpen} disableRipple disableTouchRipple data-cy="cellMoreButton">
          <MoreHorizIcon />
        </StyledIconButton>
        <PopupMenu anchorEl={graphAnchorEl} isOpen={Boolean(graphAnchorEl)} onClose={handleMenuClose} sx={{ ml: 3 }}>
          {rowMenu.map((menu: RowMenu, index: number) => (
            <Box key={`menuItem-${index}`}>
              <PopupMenuItem
                icon={menu.icon}
                label={menu.title}
                onClick={(): void => {
                  menu.onClick(entity, condition, rawValue, isFooter, conditionTypeId);
                  handleMenuClose();
                }}
                data-cy={'menuGraph'}
              />
            </Box>
          ))}
        </PopupMenu>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} data-cy="navigationCellContainer">
      {entity && rowMenu && rowMenu.length > 0 ? (
        <Box alignSelf="flex-start">
          <StyledIconButton
            onClick={handleMenuOpen}
            onTouchEnd={handleMenuOpen}
            disableRipple
            disableTouchRipple
            data-cy="cellMoreButton"
          >
            <MoreHorizIcon />
          </StyledIconButton>
          <PopupMenu anchorEl={graphAnchorEl} isOpen={Boolean(graphAnchorEl)} onClose={handleMenuClose} sx={{ ml: 2 }}>
            {rowMenu.map((menu: RowMenu, index: number) => (
              <Box key={`menuItem-${index}`}>
                <PopupMenuItem
                  onClick={(): void => {
                    menu.onClick(entity, condition, rawValue, isFooter, conditionTypeId);
                    handleMenuClose();
                  }}
                  data-cy={'menuGraph'}
                  icon={menu.icon}
                  label={menu.title}
                  sx={theme => ({ '&:hover': { backgroundColor: theme.palette.grey[50] } })}
                />
              </Box>
            ))}
          </PopupMenu>
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default ActionCell;
