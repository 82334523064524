import React from 'react';
import { Box } from '@mui/material';
import { Row, ColumnDefinition } from '@clinintell/components/agGrid/tablesTypes';
import { DefaultCell, ActionCell, RowMenu } from '@clinintell/components/agGrid/cells';
import { ReportTableProps } from './scheduledReportTable';
import { ScheduledReportItem } from '@clinintell/modules/reports';
import { DownloadIcon, EditIcon, DeleteWidgetIcon, Tooltip } from '@clinintell/components/index';
import { format } from 'date-fns';

const generateScheduledReportTableProps = (
  dataset: ScheduledReportItem[],
  downloadReport: (id: number) => void,
  updateReport: (id: number) => void,
  deleteReport: (id: number) => void
): ReportTableProps => {
  const columns: Record<string, ColumnDefinition> = {
    name: {
      title: 'Report Name',
      type: 'string'
    },
    action: {
      title: 'Action',
      type: 'string'
    },
    reportType: {
      title: 'Report Type',
      type: 'string'
    },
    frequency: {
      title: 'Frequency',
      type: 'string'
    },
    recipients: {
      title: 'Recipients',
      type: 'string'
    },
    owner: {
      title: 'Owner',
      type: 'string'
    },
    lastDelivery: {
      title: 'Last Delivery',
      type: 'string'
    }
  };
  const rows: Row[] = dataset.map((record, ix) => {
    const actionMenu: RowMenu[] = [
      {
        title: 'Edit Recipients',
        icon: (
          <Box mr={1} mt={0.5}>
            <EditIcon sx={{ height: 16 }} />
          </Box>
        ),
        onClick: (): void => updateReport(record.id)
      },
      {
        title: 'Delete Schedule',
        icon: (
          <Box mr={1} mt={0.5}>
            <DeleteWidgetIcon sx={{ height: 18 }} />
          </Box>
        ),
        onClick: (): void => deleteReport(record.id)
      }
    ];

    if (record.lastDelivery) {
      actionMenu.unshift({
        title: 'Download Report',
        icon: (
          <Box mr={1} mt={0.5}>
            <DownloadIcon sx={{ height: 16 }} />
          </Box>
        ),
        onClick: (): void => downloadReport(record.id)
      });
    }

    let shortenedRecipients = record.recipients.substring(0, 50);
    if (shortenedRecipients.length < record.recipients.length) shortenedRecipients += '...';
    const displayTooltip = shortenedRecipients.length < record.recipients.length;

    const row: Row = {
      name: {
        rawValue: record.reportName,
        uId: record.id,
        component: (
          <DefaultCell column="name" type="string">
            {record.reportName}
          </DefaultCell>
        )
      },
      action: {
        rawValue: record.id,
        uId: record.id,
        component: <ActionCell entity={record.id} rawValue={`${record.id}`} rowMenu={actionMenu} />
      },
      reportType: {
        rawValue: record.reportType.typeDescription,
        uId: record.id,
        component: (
          <DefaultCell column="name" type="string">
            <Box textAlign="center">{record.reportType.typeDescription}</Box>
          </DefaultCell>
        )
      },
      frequency: {
        rawValue: record.frequency.frequencyName,
        uId: record.id,
        component: (
          <DefaultCell column="name" type="string">
            <Box textAlign="center">{record.frequency.frequencyName}</Box>
          </DefaultCell>
        )
      },
      recipients: {
        rawValue: record.recipients,
        uId: record.id,
        component: (
          <DefaultCell column="name" type="string">
            <Tooltip content={displayTooltip ? record.recipients : undefined} position={`right`}>
              <Box textAlign="center">{shortenedRecipients}</Box>
            </Tooltip>
          </DefaultCell>
        )
      },
      owner: {
        rawValue: record.insertBy,
        uId: record.id,
        component: (
          <DefaultCell column="name" type="string">
            <Box textAlign="center">{record.insertBy}</Box>
          </DefaultCell>
        )
      },
      lastDelivery: {
        rawValue: record.lastDelivery ? record.lastDelivery.toString() : '',
        uId: record.id,
        component: (
          <DefaultCell column="name" type="string">
            <Box textAlign="center">
              {record.lastDelivery ? format(new Date(record.lastDelivery.toString()), 'MM/dd/yyyy') : ''}
            </Box>
          </DefaultCell>
        )
      }
    };

    return row;
  });
  return {
    rows,
    columns,
    defaultSortColumn: 'name',
    columnIdentifier: 'name'
  };
};

export default generateScheduledReportTableProps;
