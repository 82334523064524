import React from 'react';
import { TableProps, RowsPerPageValues } from '@clinintell/components/Table';
import StyledTableCell from '@clinintell/components/StyledTableCell';
import { ProviderTrainingAssignmentState } from '@clinintell/modules/trainingAssignment';
import { Box } from '@mui/material';
import ProgressBar from '@clinintell/components/ProgressBar';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import {
  providerHasAssignedTraining,
  providerHasCompletedTraining
} from '@clinintell/containers/trainingConfiguration/logic/providerTrainingStatuses';

const generateGroupTrainingReportTableProps = (
  providers: ProviderTrainingAssignmentState[],
  rowsPerPage: RowsPerPageValues
): TableProps => {
  return {
    rows: providers.map(provider => {
      return {
        providerName: {
          rawValue: provider.orgName,
          uId: provider.orgId,
          component: <StyledTableCell isBubble={false} value={provider.orgName} type="string" align="left" />
        },
        documentationScore: {
          rawValue: provider.currentDocumentationScore || -Number.MAX_SAFE_INTEGER,
          uId: provider.orgId,
          component: (
            <StyledTableCell
              isBubble={false}
              value={
                provider.currentDocumentationScore ? formatToDecimalPlaces(provider.currentDocumentationScore, 0) : null
              }
              type="integer"
              postfix="%"
              align="center"
            />
          )
        },
        assignedDate: {
          rawValue: providerHasAssignedTraining(provider) ? new Date(provider.assignedDate as string).getTime() : 0,
          uId: provider.orgId,
          component: (
            <StyledTableCell
              value={providerHasAssignedTraining(provider) ? provider.assignedDate : null}
              type="date"
              align="center"
              isBubble={false}
            />
          )
        },
        progress: {
          rawValue: provider.percentComplete,
          uId: provider.orgId,
          component: (
            <td style={{ borderBottom: '1px solid #F1F5F9' }}>
              <Box width="100%" display="flex" flexDirection="column" py={1}>
                <ProgressBar size="small" value={provider.percentComplete / 100} width="100%" />
              </Box>
            </td>
          )
        },
        completionDate: {
          rawValue: providerHasCompletedTraining(provider) ? new Date(provider.completedDate as string).getTime() : 0,
          uId: provider.orgId,
          component: (
            <StyledTableCell
              value={providerHasCompletedTraining(provider) ? provider.completedDate : null}
              type="date"
              align="center"
              isBubble={false}
            />
          )
        },
        testScore: {
          rawValue: providerHasCompletedTraining(provider) ? provider.percentScore : -1,
          uId: provider.orgId,
          component: providerHasCompletedTraining(provider) ? (
            <StyledTableCell isBubble={false} value={provider.percentScore} type="integer" postfix="%" align="center" />
          ) : (
            <StyledTableCell isBubble={false} value="-" type="string" align="center" />
          )
        }
      };
    }),
    columns: {
      providerName: {
        type: 'string',
        title: 'Provider'
      },
      documentationScore: {
        type: 'integer',
        postfix: '%',
        title: 'Documentation Score'
      },
      assignedDate: {
        type: 'string',
        title: 'Assigned Date'
      },
      progress: {
        type: 'integer',
        title: 'Progress'
      },
      completionDate: {
        type: 'string',
        title: 'Completion Date'
      },
      testScore: {
        type: 'integer',
        postfix: '%',
        title: 'Test Score'
      }
    },
    defaultSortColumn: 'testScore',
    disablePaging: false,
    rowsPerPage,
    columnIdentifier: 'providerName'
  };
};

export default generateGroupTrainingReportTableProps;
